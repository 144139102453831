'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddCtrl', function ($scope, $state, $stateParams, $rootScope, $acWizardHelpers) {

  // define the current state
  $scope.currentStep = $acWizardHelpers.getCurrentStep($state.current);

  // the error container
  $scope.registerError = null;

  // handle state changes to adapt the control
  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
    $scope.currentStep = $acWizardHelpers.getCurrentStep(toState);
  });

  // just redirect to the step01 as default
  if ($state.current.name === 'authorized.csp.wadd') { return $state.go('authorized.csp.wadd.step01', {resellerId: $stateParams.resellerId}); }
});
