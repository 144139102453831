'use strict';

angular.module('azureCostsFeApp').controller('EaWidgetGroupFilterListViewExportMixin', function($scope, $eaDataExportOperations, $busy, $eaDownload) {

  $scope.exportData = function() {

    // open the dialog
    $scope.openExportWizard($scope.options.dataItems.exportheader, $scope.options.dataItems.exporttags).then(function(exportConfig) {

      // flatten our history
      var historyElements = Object.keys($scope.groupedSpendingData.History).map(function(elementKey) {
        return $scope.groupedSpendingData.History[elementKey];
      });

      // inject tag properties
      if (exportConfig.config.tagsToExport)
      {
        var splittedTags = exportConfig.config.tagsToExport.split(';');
        splittedTags.forEach(function(t) {
          exportConfig.header.push('tag:' + t);
        })
      }

      // inject the cost headers
      $scope.groupedAllCheckdPeriods.reverse().forEach(function(period) {

        if ($scope.itemCostProperty) {
          exportConfig.header.push(period + '-Costs');
        }

        if ($scope.itemQuantityProperty) {
          exportConfig.header.push(period + '-Quantity');
        }
      });

      // export the data
      return $busy.during($eaDataExportOperations.exportListTo('csv', historyElements, exportConfig.header, exportConfig.config).then(function (data) {
        $eaDownload.do(data, $scope.contract + '-' + $scope.selectedPeriod + "-azure-costs.csv", "text/plain");
      }));
    });
  };
});
