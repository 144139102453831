'use strict';

/**
 * @ngdoc directive
 * @name azureCostsFeApp.directive:eaNavbar
 * @description
 * # eaNavbar
 */
angular.module('azureCostsFeApp').directive('eaNavbar', function () {
    return {
      templateUrl: 'views/directive_ea_navbar.html',
      restrict: 'C',
      controller: 'EaNavbarCtrl',
      scope: {
        currentSession: '='
      }
    };
}).controller('EaNavbarCtrl', function($scope, $q, $eaBackend, $state, $rootScope, $eaBackendDataModeSelector, $eaSession2, $location, $busy, $controller, $modal, $timeout, $toolbar, $eaTeamManagement, $eaBranding, $eaUserProfile, gravatarService, $eaConfig, $stateParams, $eaCspService, $window, $eaNotificationCenter) {
    var self = this;

  // inject mixins
  $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));
  $.extend(self, $controller('TeamsChooseTeamMixinCtrl', {$scope: $scope, $modal: $modal, $controller: $controller, $q: $q, $eaTeamManagement: $eaTeamManagement}));

  // define variables
  $scope.toolbarItems =   $toolbar.items;
  $scope.title = $eaBranding.currentCompanyInformation.title;
  $scope.fullWidthHeader = true;

  function updateNavBarBasicMenu() {

    if ($scope.currentSession) {
      $eaUserProfile.load($scope.currentSession.DataToken).then(function (profile) {

        if ($scope.currentSession.isActAsSession()) {
          $toolbar.addItem('leaveActAs', 'Leave Act-As', 'fa-sign-out', null, true, $scope.leaveActAs, null);
        }

        // define the username
        var userName;
        if ($scope.currentSession.FirstName) { userName = $scope.currentSession.FirstName + ' '; } else { userName = ''; }
        if ($scope.currentSession.LastName) { userName = userName + $scope.currentSession.LastName; }
        if (!userName) { userName = $scope.currentSession.Contact; }

        // add the user item
        $toolbar.addItem('user', userName, 'img:' + gravatarService.url($scope.currentSession.Contact, { size: 20, default: 'mm'}), $scope.currentSession.Contact, true, null, 99);

        // figure out activeTeam
        var activeTeam = $stateParams.teamid || $stateParams.resellerId;

        // Add the manage team entry for all users who are not part of any team. This means the
        // useres are not manager and can upgrade to a team or to the admins of a team.
        if (!$eaTeamManagement.isPartOfAtLeastOneTime(profile)) {
          $toolbar.addItem('user.manageTeams', 'Manage Team', 'fa-users', null, true, '/teams/migrate', 10, 'user');
        } else if ($eaTeamManagement.isAdminInAtLeastOneTime(profile)) {
          $toolbar.addItem('user.manageTeams', 'Manage Team', 'fa-users', null, true, function() {
            $scope.openTeamChooser(profile, activeTeam, 'admin', $eaSession2.getSession()).then(function(selectedTeamId) {
              $state.go('authorized.teamsManage', {teamid: selectedTeamId});
            });
          }, 10, 'user');
        }


        if ($eaConfig.features.billing) {
          $toolbar.addItem('user.billing', 'Billing', 'fa-dollar', null, true, '/billing', 20, 'user');
        }

        if ($location.path().indexOf('/csp') === 0) {
          $toolbar.addItem('user.csp', 'My Spending Portal', 'fa-bar-chart', null, true, '/signin?csp=false', 25, 'user');
        }

        if ($eaTeamManagement.isAdminInAtLeastOneTime(profile)) {
          $toolbar.addItem('user.reportcenter', 'Report Center', 'fa-pie-chart', null, true, function () {

            if ($stateParams.teamid) {
              $window.location.href = '/app2/teams/' + $stateParams.teamid + '/apps/reportcenter';
            } else if ($location.path().indexOf('/csp') === 0) {
              $window.location.href = '/app2/teams/' + $stateParams.resellerId + '/apps/reportcenter';
            }
          }, 28, 'user');
        }
        
        if ($eaConfig.features && $eaConfig.features.support) {
          $toolbar.addItem('user.feedback', 'Your Feedback', 'fa-lightbulb-o', null, true, 'https://azurecostmonitor.uservoice.com/', 30, 'user');
          $toolbar.addItem('user.support', 'Contact Support', 'fa-support', null, true, 'https://azurecostmonitor.uservoice.com/', 40, 'user');
        }

        $toolbar.addItem('user.divider', 'DIVIDER', null, null, true, null, 50, 'user');

        if ($scope.currentSession.hasClaim('ops')) {
          $toolbar.addItem('ops.admin', 'Admin', 'fa-user-secret', null, true, '/admin/users', 70, 'user');
          $toolbar.addItem('ops.divider', 'DIVIDER', null, null, true, null, 80, 'user');
        }

        if ($scope.currentSession.IdentityProvider === 'actAs') {
          $toolbar.addItem('user.leaveActAs', 'Leave Act-As', 'fa-sign-out', null, true, $scope.leaveActAs, 90, 'user');
        } else {
          $toolbar.addItem('user.refreshSession', 'Refresh Session', 'fa-refresh', null, true, $scope.refreshSession, 100, 'user');
          $toolbar.addItem('user.profile', 'Profile', 'fa-user', null, true, $scope.visitProfile, 110, 'user');
          $toolbar.addItem('user.signout', 'Sign Out', 'fa-power-off', null, true, $scope.signOut, 120, 'user');
        }

        // Notifications Panel operation
        $toolbar.addItem('notificationsPane.General', '', 'fa-bell-o', "Open the notifications panel", true, $scope.toggleNotificationsPanel);
      });
    }
  }

  function adaptHeaderWidth() {
    if ($location.path() === '/') {
      $scope.fullWidthHeader = false;
    } else {
      $scope.fullWidthHeader = true;
    }
  }

  // handle toolbar updates
  $toolbar.onUpdate( function() {
    adaptHeaderWidth();
    $scope.toolbarItems = $toolbar.items;
  });

  // handle session updates
  $eaSession2.onUpdate(function() {
    updateNavBarBasicMenu();
  });

  $eaSession2.onDestroying(function() {
    updateNavBarBasicMenu();
  });

  $rootScope.$on('$stateChangeSuccess', function() {
    updateNavBarBasicMenu();
  });

  $eaBranding.onUpdate(function() {
    $scope.title = $eaBranding.currentCompanyInformation.title;
  });

  $scope.toggleNotificationsPanel = function () {
    if ($eaNotificationCenter.isOpen()) {
      $eaNotificationCenter.close();
    } else {
      $eaNotificationCenter.show();
    }
  };

  // Manages the mobile menu when the browser window is too small
  $scope.toggleMobileNavMenuClasses = '';
  $scope.toggleMobileNavMenu = function() {
    if ($scope.toggleMobileNavMenuClasses === 'open-nav') {
      $scope.toggleMobileNavMenuClasses = '';
    } else {
      $scope.toggleMobileNavMenuClasses = 'open-nav';
    }
  };

  // initialize the navbar
  updateNavBarBasicMenu();
});
