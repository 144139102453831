'use strict';

angular.module('azureCostsFeApp').controller('EaDashboardsCtrl', function ($scope, $stateParams, $state, $q, $eaCurrentSession, $eaBackendDataModeSelector, $eaConfig, $toolbar, $controller, $modal, $eaBackend, $eaSession2, $eaDataCoordinator, $rootScope, $eaWidgetDashboardManager, $eaUserProfile, $eaTeamManagement, $eaLocalSettings, $eaBilling, $eaReportData, ngToast, $busy, jwtHelper, $window, $interval, $acTimelineGenerator, $timeout, $eaWidgetRepository) {

  // inject the mixins
  $.extend(self, $controller('BrandingCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));
  $.extend(self, $controller('TeamsChooseTeamMixinCtrl', {$scope: $scope, $modal: $modal, $controller: $controller, $q: $q, $eaTeamManagement: $eaTeamManagement }));
  $.extend(self, $controller('ManageContractSettingsCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('NotificationSettingsCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('SurveyCtrlMixin', {$scope: $scope, $modal: $modal, $eaBackend: $eaBackend, $window: $window }));
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal }));

  // contains the different dashboards
  // { name: "xxx", id: "yyy" }
  $scope.dashboards = [];
  $scope.dashboardsMap = {};
  $scope.activeDashboard = null;
  $scope.dataToken = $eaCurrentSession.DataToken;
  $scope.activeUser = null;
  $scope.dashboardEditMode = false;
  $scope.rienabled = false;

  $scope.autoRefreshInformation = {
    enabled:  false,
    timer:    1000,
    timeout:  1000 * 60 * 30,
    timeoutDefault: 1000 * 60 * 30,
    timeoutHandle: undefined
  };

  // check the caps
  $scope.rienabled = $eaCurrentSession.hasCap('ri');
  $eaConfig.riEnabled = $scope.rienabled;

  // wait until the extended user profile is available
  return $eaUserProfile.load($eaCurrentSession.DataToken).then(function(profile) {

    // configure margin
    $eaBackendDataModeSelector.enableNoMargin($stateParams.nomargin === 'true');

    // the profile
    $scope.activeUser = profile;
    $scope.activeTeamProfile = $eaUserProfile.getTeam(profile, $stateParams.teamid);
    $scope.activeRoleInTeam = ($eaTeamManagement.isAdminInOfTeam(profile, $stateParams.teamid) || $stateParams.teamid == 'personal') ? 'admin' : 'member';

    // shows a notification for the user in case if expired token
    function notifyUserAboutExpiredToken(dashboard) {
      if (!dashboard) { return; }

      // check special contract
      if (dashboard.Name === 'EA-98989898') { return; }

      // load the contract details
      $eaReportData.loadContractDetails(dashboard.Team, dashboard.Enrollment, $eaCurrentSession.DataToken, {maxPeriods: dashboard.MaxPeriods}).then(function(contractDetails) {

        // check if we have a token
        if (!contractDetails.Token) { return; }

        // verify our token
        try {

          // check if token is expired
          if (jwtHelper.isTokenExpired(contractDetails.Token)) {
            ngToast.create({
              className: 'danger',
              content:
              '<strong>Alert!</strong> The security token for the Azure Enterprise Portal is expired and needs to <a href="https://azurecostmonitor.uservoice.com/knowledgebase/articles/512546-renew-the-azure-ea-token-when-it-expires" target="_new">be renewed</a>. Please <br>' +
              'visit the settings section and update the security token for your Enterprise Agreement. Azure Costs will <br>'+
              'receive data from Microsoft within the next sync interval after updating the security token automatically.'
            });
          }

        } catch (error) {

          // at this point the token is invalid
          ngToast.create({
            className: 'danger',
            content:
            '<strong>Alert!</strong> The security token for the Azure Enterprise Portal is invalid and needs to <a href="https://azurecostmonitor.uservoice.com/knowledgebase/articles/512546-renew-the-azure-ea-token-when-it-expires" target="_new">be renewed</a>. Please <br>' +
            'visit the settings section and update the security token for your Enterprise Agreement. Azure Costs will <br>' +
            'receive data from Microsoft within the next sync interval after updating the security token automatically.'
          });

        }
      });
    };

    // update the navbar menu
    function updateControllerNavBarMenu() {

      // check if we are CSP
      var isCspCustomer = ($scope.activeDashboard.EnrollmentDefinition === 'CSP');

      // allow to set the refresh operation
      $toolbar.addItem('toggleAutoRefresh', ($scope.autoRefreshInformation.enabled ? 'Auto-Refresh in ' + ($scope.autoRefreshInformation.timeout / 1000).toString().toHHMMSS() : 'Enable Auto-Refresh'), 'fa-clock-o', "Enables or disables the automatic refresh operation", true, $scope.toggleAutoRefresh);

      // Switch Team
      if ($eaTeamManagement.getTeams(profile).length > 0) {
        $toolbar.addItem('switchTeam', 'Switch Team', 'fa-users', "Allows to switch to another team", true, function () {
          $scope.openTeamChooser(profile, $stateParams.teamid, 'member', $eaCurrentSession, {allowPersonal: true}).then(function (selectedTeamId) {
            $state.go('authorized.teams.dashboards', {teamid: selectedTeamId});
          })
        });
      }

      // Add Contract
      if (!isCspCustomer) {
        $toolbar.addItem('addContract', 'Add', 'fa-plus', "Adds an additional EA contract to your account", true, $scope.addContract);
      }

      // check if the user a team admin
      var isAdmin = ($scope.activeRoleInTeam == 'admin');
      var isRealTeamContract = ($stateParams.teamid != 'personal');

      // Open the Settings dialog
      if (isAdmin) {

        // add general settings
        $toolbar.addItem('generalSettings', 'Settings', 'fa-cogs', null, isAdmin, null);

        if (!isCspCustomer) {

          // Open the settings for CSP
          $toolbar.addItem('generalSettings.openSettings', 'General', 'fa-cog', "Allows to manage settings for the registered contracts", true, $scope.openSettingsDialog, null, 'generalSettings');
        } else {
          // Open the contract settings dialog
          $toolbar.addItem('generalSettings.openSettings', 'General', 'fa-cog', "Allows to manage settings for the registered contracts", true, $scope.openSettingsDialog, null, 'generalSettings');

        }

        // Open the notification settings dialog
        $toolbar.addItem('generalSettings.openNotificationSettings', 'Notifications', 'fa-envelope', "Allows to manage notification settings for the registered contracts", true, $scope.openNotiSettingsDialog, null, 'generalSettings');

        // Open the notification settings dialog
        $toolbar.addItem('generalSettings.openTriggers', 'Triggers', 'fa-bolt', "Allows to manage triggersfor the registered contracts", true, $scope.openTriggers, null, 'generalSettings');
        
        // Add Branding option as child of the settings
        if (isRealTeamContract) { $toolbar.addItem('generalSettings.openBranding', 'Edit Branding', 'fa-css3', "Allows to configure a specific team branding", true, $scope.openBrandingDialogEx, null, 'generalSettings'); }

        // check if we have at least a professional plan
        if ($eaBilling.hasRequiredPlan($scope.activeTeamProfile.AccountPlan, 'professional') || $eaCurrentSession.isActAsSession()) {

          // define a prefix
          var prefix = $eaCurrentSession.isActAsSession() ? '(ActAs) ' : '';

          // Add Sync operation as child of the settings
          if (!isCspCustomer) {
            $toolbar.addItem('generalSettings.performSync', prefix + 'Trigger Sync', 'fa-random', "Start synchronization with the Microsoft EA portal", true, $scope.startMonthSync, null, 'generalSettings');

            $toolbar.addItem('generalSettings.performMonthSync', prefix + 'Re-Sync All', 'fa-recycle', "Start synchronization with the Microsoft EA portal", true, $scope.startFullSync, null, 'generalSettings');
          }

        }

      } else {

        $toolbar.addItem('generalSettingsOpenNotificationSettings', 'Notifications', 'fa-envelope', "Allows to manage notification settings for the registered contracts", true, $scope.openNotiSettingsDialog, null);
      }

      // Purge Caches
      $toolbar.addItem('generalSettings.purgeCaches', 'Purge Reports', 'fa-eraser', "Cleans up all pre calculated reports", true, $scope.purgeReportCaches, null, 'generalSettings');

      // Reload operation
      $toolbar.addItem('performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadData);
    }

    $scope.toggleAutoRefresh = function() {
      $scope.autoRefreshInformation.enabled = !$scope.autoRefreshInformation.enabled;
      $scope.autoRefreshInformation.timeout = $scope.autoRefreshInformation.timeoutDefault;
      updateControllerNavBarMenu();

      if ($scope.autoRefreshInformation.enabled) {
        $scope.autoRefreshInformation.timeoutHandle = $interval(function() {

          // reduce the timeout
          $scope.autoRefreshInformation.timeout = $scope.autoRefreshInformation.timeout - $scope.autoRefreshInformation.timer;

          // update the ui
          updateControllerNavBarMenu();

          // check if we need to execute a reload
          if ( $scope.autoRefreshInformation.timeout <= 0) {
            $scope.autoRefreshInformation.timeout = $scope.autoRefreshInformation.timeoutDefault;
            $scope.refreshScreen();
          }
        }, $scope.autoRefreshInformation.timer);
      } else {
        $interval.cancel($scope.autoRefreshInformation.timeoutHandle);
        $scope.autoRefreshInformation.timeoutHandle = undefined;
      }
    };

    $scope.addContract = function() {
      $state.go('authorized.teams.contracts.add', { teamid: $stateParams.teamid});
    };

    $scope.reloadData = function () {
      if ($scope.activeDashboard) {

        // reload the widgets
        $eaWidgetDashboardManager.reload($scope.activeDashboard.Id);
      }
    };

    $scope.refreshScreen = function() {
      $state.go($state.current.name, { initial: null }, {reload: true});
    };

    $scope.selectDashboard = function(dashboard) {

      // load the contract information
      var contractPromise = $q.resolve();

      if (!dashboard.ContractInfo) {
        contractPromise = $eaBackend.dataGetContractInfo(dashboard.Team,dashboard.Id, $eaCurrentSession.DataToken);
      }

      // render
      return contractPromise.then(function(contractInfo) {

        // set the info
        dashboard.ContractInfo = contractInfo;
        if (!dashboard.ContractInfo) { dashboard.ContractInfo = { billingDay: 1 } };

        // select the dashboard
        if ($scope.activeDashboard) {
          $scope.activeDashboard.Active = false;
        }
        $scope.activeDashboard = dashboard;
        $scope.activeDashboard.Active = true;
        $eaLocalSettings.activeDashboard = dashboard.Id;

        // configure the billing cycle
        $eaBackendDataModeSelector.enableBillingCycle($stateParams.cycle === 'billing', $scope.activeDashboard.ContractInfo.billingDay);

        // update the url
        $state.transitionTo('authorized.teams.dashboards', {
          active: dashboard.Id,
          cycle: $eaBackendDataModeSelector.isBillingCycleEnabled() ? 'billing' : 'monthly'
        }, {
          location: true,
          inherit: true,
          relative: $state.$current,
          notify: false
        });

        // notify the user
        notifyUserAboutExpiredToken($scope.activeDashboard);
      });
    };

    $scope.openBrandingDialogEx = function() {
      $scope.openBrandingDialog($eaCurrentSession, $stateParams.teamid).then(function(brandingSettings) {
        $eaBackend.dataUpdateBranding($stateParams.teamid, brandingSettings, $eaCurrentSession.DataToken);
      })
    };

    $scope.toggelEditMode = function() {
      // toggle the edit mode
      $scope.dashboardEditMode = !$scope.dashboardEditMode;
    };

    $scope.startAddCustomWidget = function() {
      $eaWidgetRepository.showRepository();
    };

    $scope.openSettingsDialog = function() {
      $busy.during($eaReportData.loadContractDetails($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken)).then(function(loadedContractInformation) {

        // check if we are CSP
        var isCspCustomer = (loadedContractInformation.Definition === 'CSP');

        $scope.openManageSettingsDialog(loadedContractInformation, $scope.activeRoleInTeam, isCspCustomer).then(function (updatedContractInformation) {

          if (updatedContractInformation.action === 'remove') {          
            $busy.during($eaBackend.dataDeleteContract($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken).finally(function () {
              $state.reload();
            }));          
          } else {
            $busy.during($eaBackend.dataUpdateContract($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, updatedContractInformation.contract, $eaCurrentSession.DataToken)).then(function () {
              if (loadedContractInformation.Periods != updatedContractInformation.contract.Periods) {
                $eaUserProfile.invalidate();
                $eaDataCoordinator.invalidate($scope.activeUser.cacheHash);
                $state.reload();
              } else {
                $scope.reloadData();
              }
            });
          }
        })
      });
    };

    $scope.openTriggers = function() {
      if ($scope.activeDashboard) {        
        $window.location.href = '/app2/teams/' + $scope.activeDashboard.Team + '/apps/contracts/' + $scope.activeDashboard.Id + '/triggers';
      }
    }

    $scope.openNotiSettingsDialog = function() {
      $busy.during($eaReportData.loadContractDetails($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken)).then(function(loadedContractInformation) {
        $busy.during($eaBackend.dataGetContractNotifcationSettings($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken)).then(function (loadedNotificationSettings) {
          $scope.openNotificationSettingsDialog(loadedContractInformation, loadedNotificationSettings, $scope.activeTeamProfile, $scope.activeRoleInTeam).then(function (updatedData) {
            return $busy.during($eaBackend.updateGetContractNotifcationSettings($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, updatedData.notificationInformation, $eaCurrentSession.DataToken, updatedData.default ? { Default: true } : {})).then(function() {
              if (updatedData.sendMail) {
                return $eaBackend.triggerContractNotificationForUser($scope.activeDashboard.Team,  $eaCurrentSession.UserId, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken).then (function() {

                  // notify the user
                  ngToast.create({
                    className: 'success',
                    content: '<strong>Great!</strong> The notifcation settings was updated successfully and a test mail was triggered to <strong>' + $eaCurrentSession.Contact + '</strong>. It should not take longe than a couple minutes.'
                  });

                })
              } else {

                // notify the user
                ngToast.create({
                  className: 'success',
                  content: '<strong>Great!</strong> The notifcation settings was updated successfully'
                });

                return $q.resolve();
              }
            })
          })
        });
      });
    };

    $scope.purgeReportCaches = function() {
      var ok = confirm('Do you really want to purge all cached reports for this contract? This will remove all pre-calculated reports and it could take a while until the re-calculation is executed!');
      if (ok) {

        // clean up
        $busy.message = "Cleaning up all report caches";
        $busy.during($eaBackend.dataPostPurgeContractReports($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken));

        // reload
        $scope.reloadData();
      }
    };

    $scope.startMonthSync = function() {

      // load the contract details
      $eaReportData.loadContractDetails($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken).then(function(contractDetails) {


        // preselect the current month
        var currentMonth = (new Date()).currentMonthDefinition();

        // description
        var description = 'Please select all months you want to re-sync. Azure Costs will override all ' +
                          'existing information for this month and downloads the spending data freshly ' +
                          'from Microsoft:';

        // report timeline
        var reportStrings = $acTimelineGenerator.createMonthList(12, $acTimelineGenerator.addMonth(new Date(), -11));
        if (contractDetails && contractDetails.Periods && contractDetails.Periods === 24) {
          reportStrings = $acTimelineGenerator.createMonthList(24, $acTimelineGenerator.addMonth(new Date(), -23));
        }

        // show the month selector
        return $scope.openObjectPickerDialog('Select month to sync', description, 'fa-calendar-o', undefined, undefined, reportStrings.sortSimple().reverse(), [currentMonth], false).then(function (selectedMonthes) {

          // notify the user
          ngToast.create({
            className: 'success',
            content: '<strong>Great!</strong> A new sync for your contract <strong>' + $scope.activeDashboard.Name + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new reports after more than 15 minutes give us a note.'
          });

          // use the new api if requires
          if ($scope.activeDashboard.EnrollmentDefinition === 'AWS' || $scope.activeDashboard.EnrollmentDefinition === 'GCP') {

            return $eaBackend.dataMonthSyncReportsV2($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, selectedMonthes, $eaCurrentSession.DataToken).catch(function (e) {

              // schedule the syncs
              selectedMonthes.forEach(function (month) {

                // notify regarding error
                ngToast.create({
                  className: 'error',
                  content: '<strong>Error!</strong> Failed to process the synchronisation request of month <strong>' + month + '</strong> - Error: ' + e
                });
              });
            });

          } else {
            // schedule the syncs
            selectedMonthes.forEach(function (month) {

              // start the sync
              $eaBackend.dataMonthSyncReports($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, month, $eaCurrentSession.DataToken).catch(function (e) {

                // notify regarding error
                ngToast.create({
                  className: 'error',
                  content: '<strong>Error!</strong> Failed to process the synchronisation request of month <strong>' + month + '</strong> - Error: ' + e
                });

              });
            });
          }
        });
      });
    };

    $scope.startFullSync = function() {

      // notify the user
      ngToast.create({
        className: 'success',
        content: '<strong>Great!</strong> A new sync for your contract <strong>' + $scope.activeDashboard.Name + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new reports after more than 15 minutes give us a note.'
      });

      // use the new api if requires
      if ($scope.activeDashboard.EnrollmentDefinition === 'AWS' || $scope.activeDashboard.EnrollmentDefinition === 'GCP') {

          // load the contract details
          return $eaReportData.loadContractDetails($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken).then(function(contractDetails) {

            return $eaBackend.dataMonthSyncReportsV2($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, contractDetails.Reports, $eaCurrentSession.DataToken).catch(function (e) {

              // notify regarding error
              ngToast.create({
                className: 'error',
                content: '<strong>Error!</strong> Failed to process the synchronisation request: ' + e
              });

            });
          });

        } else {
          // start the sync
          return $eaBackend.dataAllSyncReports($scope.activeDashboard.Team, $scope.activeDashboard.Enrollment, $eaCurrentSession.DataToken).catch(function (e) {

            // notify regarding error
            ngToast.create({
              className: 'error',
              content: '<strong>Error!</strong> Failed to process the synchronisation request: ' + e
            });

          });
      }
    };

    // get the available contracts
    var availableContracts = $eaUserProfile.getContracts(profile);

    // generate the dashboards
    availableContracts.forEach(function(contract) {

      // check if the contract is in this team
      if (contract.Team !== $stateParams.teamid) { return; }

      // define the dashboarditem
      var dashboardItem = {
        Name: contract.Definition === 'EA' ? 'EA-' + contract.Enrollment : contract.Name,
        Id: contract.Enrollment,
        Team: contract.Team,
        Enrollment: contract.Enrollment,
        EnrollmentDefinition: contract.Definition,
        Active: false,
        Licensed: !(($scope.activeTeamProfile.AccountPlan !== 'enterprise' && $scope.activeTeamProfile.AccountPlan !== 'csp') && !$eaBilling.isAccountOwner($scope.activeUser.UserId, $scope.activeTeamProfile)),
        State: $stateParams.initial && $stateParams.initial === contract.Enrollment ? 'pending' : 'ready',
        Profile: $scope.activeUser,
        MaxPeriods: contract.Periods,
        Collection: contract.ResellerApiVersion && contract.ResellerApiVersion === 'v2' ? 'spendings.v2' : null,
        ContractInfo: undefined
      };

      // patch demo
      if (contract.Name === 'EA-98989898') {
        dashboardItem.Name = contract.Name;
      }

      // check AWS or GCP
      if (dashboardItem.EnrollmentDefinition === "AWS" || dashboardItem.EnrollmentDefinition === "GCP") {
        dashboardItem.Collection = "spendings.v3";
      }

      // override the version from state parameter
      if ($stateParams.version && contract.Definition !== 'EA') {
        dashboardItem.Collection = 'spendings.' + $stateParams.version;
        if ($stateParams.version != 'v2') {
          dashboardItem.Collection = null;
        }
      }

      // add the contract
      $scope.dashboards.push(dashboardItem);
      $scope.dashboardsMap[dashboardItem.Id] = dashboardItem;

      $eaWidgetDashboardManager.onDashboardFunction(dashboardItem.Id, 'func:6801CF0C-51A8-4814-B456-4777D0FA74D8', function (args) {
        var enabledState = $eaBackendDataModeSelector.isBillingCycleEnabled() ? 'BillingCycle' : 'Default';
        return $eaWidgetDashboardManager.finishDashboardFunction(dashboardItem.Id, args, enabledState, null);
      });

      $eaWidgetDashboardManager.onDashboardFunction(dashboardItem.Id, 'func:BBB71FCA-AB10-4851-A59F-89E68B4FDE3A', function (args) {

        if (args.args.state === 'BillingCycle') {
          $eaBackendDataModeSelector.enableBillingCycle(true, $scope.activeDashboard.ContractInfo.billingDay);
        } else {
          $eaBackendDataModeSelector.enableBillingCycle(false, 1);
        }

        // update the url
        $state.transitionTo('authorized.teams.dashboards', {
          active: $scope.activeDashboard.Id,
          cycle: $eaBackendDataModeSelector.isBillingCycleEnabled() ? 'billing' : 'monthly'
        }, {
          location: true,
          inherit: true,
          relative: $state.$current,
          notify: false
        });

        return $timeout(function() {}, 100).then(function () {
          return $eaWidgetDashboardManager.finishDashboardFunction(dashboardItem.Id, args, true, null);
        });
      });

      $eaWidgetDashboardManager.onDashboardFunction(dashboardItem.Id, 'func:2DD4B2BC-983F-434B-B449-7A655AEE2D23', function (args) {

        var hintText = '';
        var periodDate = new Date(args.args.selectedPeriod);

        if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
          var billingDay = $eaBackendDataModeSelector.getBillingCycleDay();
          if (billingDay !== 1) {
            periodDate = $acTimelineGenerator.addMonth($acTimelineGenerator.addDay(periodDate, billingDay - 1), -1);
          }
        }

        if (periodDate > new Date()) {
          hintText = "No consumption data available because period between <strong>" + periodDate.toDateString() + "</strong> - <strong>" + $acTimelineGenerator.addDay($acTimelineGenerator.addMonth(periodDate), -1).toDateString() + "</strong> not started yet";
        } else {
          hintText = "Consumption between <strong>" + periodDate.toDateString() + "</strong> - <strong>" + $acTimelineGenerator.addDay($acTimelineGenerator.addMonth(periodDate), -1).toDateString() + "</strong>";
        }

        return $eaWidgetDashboardManager.finishDashboardFunction(dashboardItem.Id, args, hintText, null);
      });
    });

    // mark the first dashboard as active
    var selectDashboardPromise = $q.resolve();

    if ($scope.dashboards.length > 0) {

      // reads the dashboard from last used dashboard
      var targetDashboard = $scope.dashboardsMap[$eaLocalSettings.activeDashboard];

      // check if we need to override this via active parameter
      if ($stateParams.active) { targetDashboard = $scope.dashboardsMap[$stateParams.active]; }

      // use the fallback
      if (!targetDashboard) { targetDashboard = $scope.dashboards[0]; }

      // activate our targetdashboard
      selectDashboardPromise = $scope.selectDashboard(targetDashboard);

    } else {

      // go to add becasue we have no dashboards
      return $state.go('authorized.teams.contracts.add');
    }

    selectDashboardPromise.then(function() {
      // update the menu navbar
      updateControllerNavBarMenu();

      // check if we ask for survey
      $scope.askForSurveyIfNeeded($eaCurrentSession);
    });
  });
});
