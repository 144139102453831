'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetWebPart', function() {
  return {
    restrict: 'C',
    template: '<div class="web-part" id=\"web-part-{{uuid}}\"><div ng-include="html"></div></div>',
    controller: 'EaWidgetWebPartCtrl'
  };
}).controller('EaWidgetWebPartCtrl', function($scope, $q, $eaConfig, $eaDataCoordinator) {

  // get the html path
  $scope.html = $scope.options.html;

  // replace all variables
  $scope.html = $scope.html.replace(/\{\{data\}\}/g,$eaConfig.dataPath);

  // check if we need to replace with cdn
  if ($eaConfig.cdnEntrypoint && $scope.html[0] === '/') {
    $scope.html = $eaConfig.cdnEntrypoint + $scope.html
  }

  function loadWebPart() {

    if ($scope.options.class) {

      // switch on the loading mode
      $scope.setLoading(true);

      // load the date from source
      return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.class, {collection: $scope.options.collection}).then(function(data) {
        $scope.Model = data;
        $scope.setLoading(false);
        return $q.when();
      });
    } else {
      $scope.setLoading(false);
      return $q.when();
    }
  }

  $scope.executeAction = function(actionId) {
    $scope.executeDashboardAction(actionId);
  };

  $scope.onResize(function(newSize) {
    return loadWebPart();
  });

  $scope.onInitialize(function() {
    return loadWebPart();
  });

  $scope.onReload(function() {
    return loadWebPart();
  });

  $scope.finalizeRegistration();
});
