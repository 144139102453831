'use strict';

angular.module('azureCostsFeApp').controller('ModalGsiUpdateSettingsCtrl', function($scope, $modalInstance, SystemIntegratorAccountModel) {

  $scope.gsiAccountModel = SystemIntegratorAccountModel || { ReferenceCurrency: "EUR" };

  $scope.ok = function () {
    $modalInstance.close({ account: $scope.gsiAccountModel, action: 'update' });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

});



