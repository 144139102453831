'use strict';

angular.module('azureCostsFeApp').controller('TeamManagementCtrlMixin', function($scope, $eaBackend, $q, $eaSession2) {

  $scope.capitalizeFirstLetter = function(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  $scope.isUserPseudoUser = function(user) {
    return (user.LiveId === null || user.LiveId === undefined || user.LiveId.indexOf('PSEUDO:') === 0)
  };

  $scope.isUserSelf = function(user) {
    var activeSession = $eaSession2.getSession();
    return (activeSession.UserId === user.UserId);
  };

  $scope.isUserAccountOwner = function(user, team) {
    return (user.UserId === team.AccountOwner);
  };

  $scope.isUserDelegationAdmin = function(user) {
    return (user.UserId.indexOf('DEL:') === 0);
  };

  $scope.hasUserRole = function(user, role) {
    return (user.Role === role);
  };

  $scope.deleteUserFromTeam = function(teamid, user, dataToken) {
    var defer = $q.defer();

    $eaBackend.dataRemoveUserFromTeam(teamid, user.UserId, dataToken).then(function() {
      defer.resolve()
    }).catch(function(error) {
      defer.reject();
    });

    return defer.promise;
  };
});
