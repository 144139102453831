'use strict';

angular.module('azureCostsFeApp').controller('ModalBillingAddressCtrl',
  ['$scope', '$modalInstance', 'BillingAddress', function($scope, $modalInstance, BillingAddress) {

    $scope.BillingAddress = BillingAddress;

    $scope.ok = function () {
      $modalInstance.close($scope.BillingAddress);
    };

    $scope.cancel = function () {
      $modalInstance.dismiss("cancel")
    };

  }]);



