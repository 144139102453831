'use strict';

angular.module('azureCostsFeApp').controller('AcCspInitCtrl', function ($scope, $eaCspService, $eaCurrentSession, $state, $busy) {

  $scope.enrollmentMode = false;
  $scope.Profiles = [];
  $scope.OtherProfiles = [];

  $scope.enroll = function() {
    var callbackUrl = $state.href('authorized.cspinit', {}, {absolute: true});
    $busy.during($eaCspService.enroll($eaCurrentSession, callbackUrl));
  };

  // load the csp profile
  return $eaCspService.loadProfiles($eaCurrentSession).then(function(cspProfiles) {

    // if we have no profile go to the enrollment page
    if(cspProfiles == null ||cspProfiles.length === 0) {
      $scope.enrollmentMode = true;
    // if we have just one profile visit it
    } else if (cspProfiles && cspProfiles.length === 1) {
      // visit the profile
      $state.go('authorized.csp.accounts', { resellerId: cspProfiles[0].Id})
    } else {
      // allow to select
      $scope.Profiles = cspProfiles;
    }
  })
});
