'use strict';

angular.module('azureCostsFeApp').controller('TeamEditUsersCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openTeamEditUserDialog = function(users, isCspTeam) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/manage/modals/modal_ea_team_edit_users.html",
      controller: 'ModalTeamEditUsersCtrl',
      resolve: {
        Users: function() { return users; },
        IsCspTeam: function() { return isCspTeam; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
