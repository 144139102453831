'use strict';

angular.module('azureCostsFeApp').controller('CspDefineResellerMarginCtrlMixin', function($scope, $modal) {

  $scope.openCspDefineResellerMarginDialog = function(resellerMargin, upliftCategories) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_define_reseller_margin.html",
      controller: 'ModalCspDefineResellerMarginCtrl',
      resolve: {
        ResellerMargin: function() {
          return resellerMargin ? resellerMargin : [];
        },
        UpliftCategories: function() {
          return upliftCategories ? upliftCategories : ['*'];
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
