'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceCspResellerManagement', function($acDataSourceSettings, $acDataSourceHelper, $acDataSourceClasses, $q, $eaBackend, $acTimelineGenerator, $injector, $eaBackendDataModeSelector, $acDataSourceHelperCspGenericSummary, $eaBackendAzureCachedContentLoader, rfc4122) {
  var self = this;

  function loadCSPDashboardItemsByTemplate(dashboardTemplate) {
    return $acDataSourceSettings.loadDataFromStaticUrl(dashboardTemplate).then(function(items) {
      return $q.when(items.map(function(item) { return new $acDataSourceClasses.DashboardItemClass(item); }))
    });
  }

  function loadCSPResellerDashboardItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_reseller_dashboard_definitions.json';
    return loadCSPDashboardItemsByTemplate(dashboardTemplate);
  }

  // load the available periods
  function loadAvailablePeriodClasses() {

    var monthList = $acTimelineGenerator.createMonthList(-13, $acTimelineGenerator.addMonth(new Date())).map(function(month) {
      return $acDataSourceClasses.PeriodClass({ReportId: month});
    });

    return $q.when(monthList);
  }

  function loadResellerItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {

    function resourceLoadRouting(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry) {
      return $eaBackend.dataGetCspResellerSummary(teamid, resourceId, cacheIdentifier, jwtToken, retry);
    }

    // prepare result items
    var resultItems = [];

    // load the current hashes for this report category
    return $eaBackend.dataGetTeamHashes(teamid, 'ResellerSummary', dataToken).then(function(hashesMap) {

      // ensure we have at least an empty hashmap
      hashesMap = hashesMap || {};

      // get all periods
      return loadAvailablePeriodClasses().then(function (periods) {

        return periods.forEachExecuteAsyncOperationParallel($q, function (period) {

          // build the hash key          
          var cacheHash = hashesMap[period.ReportId] || rfc4122.v4();

          // load the resources
          return $eaBackendAzureCachedContentLoader.dataRequestCachedResourcesWithRetry(teamid, contract, [{
            Id: period.ReportId,
            CacheIdentifier: cacheHash
          }], dataToken, {
            resourceEndpoint: resourceLoadRouting
          }).then(function (loadedSummaryData) {

            if (Array.isArray(loadedSummaryData)) {
              resultItems.appendArray(loadedSummaryData[0]);
            }
          })
        }).then(function () {
          return $q.when(resultItems);
        });
      });
    });
  }

  self.registerDataSources = function(dataSourceManager) {

    // register the dashboard items for the gsi portal
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'reseller.v1', loadCSPResellerDashboardItems);

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', 'reseller.v1', $acDataSourceSettings.loadStaticDataGenericCallback);

    // spending filter class
    $acDataSourceHelper.registerDataSourceSpendingFilerClass(dataSourceManager, 'reseller.v1');

    // load the data
    dataSourceManager.registerDataSource('global', 'ResellerT2CustomerEntryClass', 'reseller.v1', loadResellerItems);

    // load the available periods
    dataSourceManager.registerDataSource('global', 'PeriodClass', 'reseller.v1', loadAvailablePeriodClasses);
  };
});
