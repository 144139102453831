'use strict';

angular.module('azureCostsFeApp').controller('EaTeamBucketsCtrl', function ($scope, $location, $stateParams, $busy, $eaConfig, $eaBackend, $window, $q, $eaCurrentSession) {
    var self = this;

    function BucketModel(name) {
      var selfModel = this;

      selfModel.OrgName = name;
      selfModel.Name  = name;
      selfModel.State = 'ok';

      selfModel.getEditorKey = function() {
        return 'bucketEditor' + selfModel.Id;
      }
    }

    $scope.hostProtocol = $location.$$protocol;
    $scope.hostName = $eaConfig.feHost;

    if ($location.$$port && $location.$$port !== 443) {
      $scope.hostName = $scope.hostName + ':' + $location.$$port;
    }

    $scope.teamid = $stateParams.teamid;
    $scope.buckets = [];

    $scope.newBucket = function() {
      var bucket = new BucketModel('');
      bucket.State = 'new';
      bucket.OrgState = 'new';
      $scope.buckets.push(bucket);
    };

    $scope.deleteBucket = function(bucket) {
      if (bucket.OrgState == 'new') {
        $scope.buckets.splice($scope.buckets.indexOf(bucket), 1);
      } else {
        bucket.OrgState = bucket.State;
        bucket.State = 'deleted';
      }
    };

    $scope.undoBucket = function(bucket) {
      bucket.State = bucket.OrgState;
    };

    $scope.commitBucketEdit = function(data, bucket) {
      if (data !== bucket.Name) {

        // check the string
        if (!(/^[a-zA-Z0-9]*$/.test(data))) {
          return "Invalid name, please use alphanumeric characters only!";
        } else {
          // check if we can use this bucket
          var defer = $q.defer();

          $eaBackend.dataGetBucket(data).then(function () {
            defer.resolve("Bucket is in use");
          }).catch(function () {
            bucket.Name = data;
            bucket.State = 'dirty';
            defer.resolve();
          });

          return defer.promise;
        }
      }
    };

    $scope.updateBuckets = function() {

      var updatePromisses = [];
      $scope.buckets.forEach(function(bucket) {

        // check if we need to save the bucket
        if (bucket.State == 'dirty') {

          if (bucket.OrgState == 'new') {
            updatePromisses.push($eaBackend.dataCreateTeamBucket($scope.teamid, bucket.Name, $eaCurrentSession.DataToken));
          } else {
            updatePromisses.push($eaBackend.dataDeleteTeamBucket($scope.teamid, bucket.OrgName, $eaCurrentSession.DataToken).then(function() {
              return $eaBackend.dataCreateTeamBucket($scope.teamid, bucket.Name, $eaCurrentSession.DataToken);
            }));
          }
        } else if (bucket.State == 'deleted') {
          updatePromisses.push($eaBackend.dataDeleteTeamBucket($scope.teamid, bucket.Name, $eaCurrentSession.DataToken));
        }
      });

      $busy.setMessage('Updating buckets');
      $busy.during($q.all(updatePromisses).then(function() {
        $window.history.back();
      }))
    };


    // load the team buckets
    return $eaBackend.dataGetTeamBuckets($scope.teamid, $eaCurrentSession.DataToken).then(function(buckets) {
      buckets.forEach(function(bucketName) {
        $scope.buckets.push(new BucketModel(bucketName));
      })
    });
});

