'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetDashboard', function () {
  return {
    template:
      '<div ng-show="!fullScreenMode" gridster="gridsterOpts" style="background-color: #eeeeee;">' +
        '<ul>' +
          '<li gridster-item="item" ng-repeat="item in dashboardItems" style="background: white;">' +
            '<div ng-if="!fullScreenMode || fullScreenItem.uuid !== item.uuid" style="height: 100%" class="ea-widget-host"' +
              'data-widget-fs="false"' +
              'data-widget="item.widget"' +
              'data-uuid="item.uuid"' +
              'data-dashboard="$parent.dashboard"' +
              'data-title="item.title"' +
              'data-team="$parent.team"' +
              'data-contract="$parent.contract"' +
              'data-token="$parent.token"' +
              'data-options="item.options"' +
              'data-profiles="item.profiles"' +
              'data-cust01="cust01"' +
              'data-max-periods="$parent.maxPeriods">' +
            '</div>' +
            '<div ng-show="item.connections && item.connections.length > 0" class="ea-widget-host-connected-flag"' +
              'popover-title="Connected Widget" ' +
              'popover-html-unsafe="This widget displays a connected view which will be influenced from an other widget directly."' +
              'popover-trigger="mouseenter" ' +
              'popover-placement="left"><i class="fa fa-flag"></i>' +
            '</div>' +
            '<div ng-show="item.wtype && item.wtype === \'CustomWidget\' && editMode" class="ea-widget-host-delete-action">' +
              '<a href="#" ng-click="deleteCustomWidget(item)"><i class="fa fa-trash"></i></a>' +
            '</div>' +
            '<div ng-show="item.wtype && item.wtype === \'CustomWidget\' && !editMode && item.options && item.options.src" class="ea-widget-host-open-action">' +
              '<a href="#" ng-click="openCustomWidget(item)"><i class="fa fa-external-link"></i></a>' +
            '</div>' +
            '<div class="ea-widget-host-expand-action">' +
              '<a href="#" ng-click="enterFullScreen(item)"><i class="fa fa-expand"></i></a>' +
            '</div>' +
          '</li>'+
        '</ul>'+
      '</div>' +
      '<div ng-if="fullScreenMode" style="position: relative; background-color: #eeeeee; padding: 10px;">' +
        '<div style="background-color: white;" class="ea-widget-host"' +
          'data-widget-fs="true"' +
          'data-widget="fullScreenItem.widget"' +
          'data-uuid="fullScreenItem.uuid"' +
          'data-dashboard="dashboard"' +
          'data-title="fullScreenItem.title"' +
          'data-team="team"' +
          'data-contract="contract"' +
          'data-token="token"' +
          'data-options="fullScreenItem.options"' +
          'data-profiles="fullScreenItem.profiles"' +
          'data-cust01="cust01"' +
          'data-max-periods="maxPeriods">' +
        '</div>' +
        '<div class="ea-widget-host-expand-action" style="top: 10px; right: 20px;">' +
          '<a href="#" ng-click="leaveFullScreen(fullScreenItem)"><i class="fa fa-compress"></i></a>' +
        '</div>' +
      '</div>',
    restrict: 'EC',
    controller: 'EaWidgetDashboardCtrl',
    scope: {
      dashboard: '=',
      team: '=',
      contract: '=',
      token: '=',
      editMode: '=',
      profile: '=',
      collection: '=',
      layout: '=',
      maxPeriods: '=',
      cust01: '='
    }
  };
}).controller('EaWidgetDashboardCtrl', function($scope, $rootScope, $eaDataCoordinator, $eaWidgetDashboardManager, $eaWidgetDashboardLayoutManager, $eaBackend, $window) {

  // define the fullscreen mode
  $scope.fullScreenMode = false;
  $scope.fullScreenItem = undefined;

  // configure the grid
  $scope.gridsterOpts = {
    columns: 12,
    rowHeight: 120,
    resizable: {
      enabled: false
    },
    draggable: {
      enabled: false
    }
  };

  // ensure we have an array
  $scope.dashboardItems = [];
  $scope.dashboardItemsBackUp = [];

  // initialize the laoyut version for this dashboard
  if ($scope.layout && $scope.dashboard) {
    $eaWidgetDashboardLayoutManager.overridLayoutVersion($scope.dashboard, $scope.layout);
  }

  // initialize the cache hash
  $eaDataCoordinator.invalidate($scope.profile.cacheHash);

  // establish the rebuild function for a dashboard
  function rebuild() {

    // register events from our manager service
    $eaWidgetDashboardManager.onDashboardEvent($scope.dashboard, 'reload', function() {

      // invalidate the cache
      $eaDataCoordinator.invalidate($scope.profile.cacheHash);

      // send a reload event for every dashboard item
      $scope.dashboardItems.forEach(function(item) {
        $eaWidgetDashboardManager.reloadWidget($scope.dashboard, item.uuid);
      });

    });

    $scope.dashboardItems = [];

    // load the items
    return $eaWidgetDashboardManager.loadDashboard($scope.dashboard, $scope.team, $scope.contract, $scope.token, $scope.collection).then(function(items) {

      // load the dashboard layout and override it
      return $eaBackend.dataGetDashboardLayout($scope.team, $scope.dashboard, $scope.collection, $scope.token).then(function(layoutItems) {

        // override the layout
        $eaWidgetDashboardLayoutManager.overrideDashboardLayout($scope.dashboard, layoutItems.items);

        // apply the layout
        $eaWidgetDashboardLayoutManager.extendLayout($scope.dashboard, items);

        // done
        $scope.dashboardItems = items;
        Array.prototype.push.apply($scope.dashboardItemsBackUp, items);
      });
    });
  }

  // register events from our manager service
  $eaWidgetDashboardManager.onDashboardEvent($scope.dashboard, 'rebuild', function() {
    rebuild();
  });


  $scope.$watch('editMode', function(newValue, oldValue) {

    $scope.gridsterOpts.resizable.enabled = $scope.editMode;
    $scope.gridsterOpts.draggable.enabled = $scope.editMode;

    if ($scope.editMode === false && newValue !== oldValue && oldValue === true) {
      // store the layout to server
      var items = $eaWidgetDashboardLayoutManager.getDashboardLayout($scope.dashboard);
      $eaBackend.dataPostDashboardLayout($scope.team, $scope.dashboard, $scope.collection, items, $scope.token);
    }

  });

  $scope.$watch('dashboard', function() {
    rebuild();
  });

  $scope.$watch('profile', function() {
    $eaDataCoordinator.invalidate($scope.profile.cacheHash);
  });

  // Check when the user moves the tiles around
  $scope.$watch('dashboardItems', function(items){
    if (items.length === 0) { return; }
    $eaWidgetDashboardLayoutManager.storeLayout($scope.dashboard, items);
  }, true);

  // Show on item in fullscreen mode
  $scope.enterFullScreen = function(item) {
    $scope.fullScreenItem = item;
    $scope.fullScreenMode = true;
  };

  $scope.leaveFullScreen = function(/* item */) {
    // leave full screen mode
    $scope.fullScreenMode = false;
    $scope.fullScreenItem = undefined;

    // rebuild charts
    rebuild();
  };

  $scope.deleteCustomWidget = function(item) {
    return $eaDataCoordinator.deleteModel($scope.team, $scope.contract, $scope.token, 'DashboardItemClass', item, {collection: $scope.collection}).then(function() {
      // rebuild charts
      rebuild();
    });
  };

  $scope.openCustomWidget = function(item) {

    // adjust the target url for relative uris
    var targetUri = item.options.src;

    if (item.options.src.indexOf('http') !== 0) {
      targetUri = item.options.src.split('?')[0];
    }

    // open new window with the url
    $window.open(targetUri, '_blank');

  }

}).service('$eaWidgetDashboardManager', function($rootScope, $timeout, $q, $eaDataCoordinator, $eaUserProfile, rfc4122) {
  var self = this;

  var dashboardItemCache = {};
  var dashboardFunctionCache = {};

  function createDashboardBasedEventName(dashboardId, event) {
    return 'dashboard.' + dashboardId + '.' + event;
  }

  function createWidgetBasedEventName(dashboardId, widgetId, event) {
    return 'dashboard.' + dashboardId + '.widgets.' + widgetId + '.' + event;
  }

  function lookupDashboardItem(dashboardId, widgetId) {
    return dashboardItemCache[dashboardId][widgetId];
  }

  // loads the dashboard items from the datasource
  self.loadDashboard = function(dashboardId, team, contract, token, collection) {

    // resolve the user profile
    return $eaUserProfile.load(token).then(function(userProfile) {

      // resolve the team profile
      var teamProfile = $eaUserProfile.getTeam(userProfile, team);

      // load the dashboard information
      return $eaDataCoordinator.query(team, contract, token, 'DashboardItemClass', {collection: collection}).then(function(dashboardItems) {

        // store the information
        dashboardItemCache[dashboardId] = {};
        dashboardItems.forEach(function(item) {
          item._internalDashboardState = 0;
          item._internalDelayedActivations = [];
          item._internalReferencedBy = [];
          dashboardItemCache[dashboardId][item.ItemUuid] = item;
        });

        // convert the widgets
        return $q.when(dashboardItems.map(function(item) {
          return {
            sizeX: item.Size.x, sizeY: item.Size.y, row: item.Position.x, col: item.Position.y,
            widget: item.ItemType,
            uuid: item.ItemUuid,
            title: item.ItemTitle,
            wtype: item.ItemWidgetType ? item.ItemWidgetType : 'System',
            options: item.ItemOptions,
            connections: item.ItemDependencies,
            profiles: {
              user: userProfile,
              team: teamProfile
            }
          };
        }));
      });
    });
  };

  // allow to lookup dashboard item
  self.findDashboardItem = function(dashboardId, widgetId) {
    return lookupDashboardItem(dashboardId, widgetId);
  };

  // allows to reload the data
  self.reload = function(dashboardId) {
    $rootScope.$emit(createDashboardBasedEventName(dashboardId, 'reload'));
  };

  // allows to rebuild the data
  self.rebuild = function(dashboardId) {
    $rootScope.$emit(createDashboardBasedEventName(dashboardId, 'rebuild'));
  };

  self.reloadWidget = function(dashboardId, widgetId) {
    $rootScope.$emit(createWidgetBasedEventName(dashboardId, widgetId, 'reload'));
  };

  self.postDashboardEvent = function(dashboardId, eventName, payload) {
    $rootScope.$emit(createDashboardBasedEventName(dashboardId, eventName), payload);
  };

  self.onDashboardEvent = function(dashboardId, eventName, cb) {
    var eventName = createDashboardBasedEventName(dashboardId, eventName);
    var eventHandlerFunc = $rootScope.$on(eventName, function(event, args) {
      cb(args);
    });

    dashboardFunctionCache[eventName] = eventHandlerFunc;
  };

  self.offDashboardEvent = function(dashboardId, eventName) {
    var eventName = createDashboardBasedEventName(dashboardId, eventName);
    var eventHandlerFunc = dashboardFunctionCache[eventName];
    if (eventHandlerFunc) {
      eventHandlerFunc();
      delete dashboardFunctionCache[eventName];
    }
  };

  self.sendDashboardFunction = function(dashboardId, functionId, args) {
    var defer = $q.defer();

    // establish a listener for ack function
    var sessionAck = 'execute.ack.' + rfc4122.v4();
    self.onDashboardEvent(dashboardId, sessionAck, function(ackArgs) {

      // unregister listener
      self.offDashboardEvent(dashboardId, sessionAck);

      // process the result
      if (ackArgs.success) {
        return defer.resolve(ackArgs.result);
      } else {
        return defer.reject(ackArgs.error);
      }
    });

    // send teh event
    self.postDashboardEvent(dashboardId, 'execute.' + functionId, { func: functionId, ack: sessionAck, args: args});

    // done
    return defer.promise;
  };

  self.onDashboardFunction = function(dashboardId, functionId, cb) {
    return self.onDashboardEvent(dashboardId, 'execute.' + functionId, cb);
  };

  self.finishDashboardFunction = function(dashboardId, args, result, error) {
    var payload = {
      success: error ? false : true,
      result: result,
      error: error
    };

    self.postDashboardEvent(dashboardId, args.ack, payload);
  };

  self.finishWidgetRegistration = function(dashboardId, widgetId) {

    // log
    console.log('Processing widget registration: ' + dashboardId + '.' + widgetId);

    // check if all widgets available which are associated with this widget
    // if not put it on the pending registration list and initialize it later
    var dashboardItem = lookupDashboardItem(dashboardId, widgetId);
    if (!dashboardItem) { return; }

    var missingDependencies = [];
    if (dashboardItem.ItemDependencies && dashboardItem.ItemDependencies.length > 0) {

      // log
      console.log('Widget has depedencies, checking if we need to delay the activation');

      // check if we have all dependencies loads
      dashboardItem.ItemDependencies.forEach(function(dependency) {
        var referencedItem = lookupDashboardItem(dashboardId, dependency);
        if (!referencedItem ||referencedItem._internalDashboardState === 0) {
          // log
          console.log('\tDependent widget not registered yet: ' + dashboardId + '.' + dependency);

          // register
          missingDependencies.push(dependency);
          referencedItem._internalDelayedActivations.push(dashboardItem);
          referencedItem._internalReferencedBy.push(dashboardItem);
        }
      });
    }

    // put the widget in the list of widget we need to initialize delayed or
    // jsut initialize it now
    if (missingDependencies.length === 0 ) {

      // mark the item as initialize
      dashboardItem._internalDashboardState = 1;

      // init the widget with data
      $timeout(function() {

        // send the message
        $rootScope.$emit(createWidgetBasedEventName(dashboardId, widgetId, 'initialize'));

        // try to finish the registration of all delayed activations
        if (dashboardItem._internalDelayedActivations.length > 0) {

          // log
          console.log('\tActivating delayed activations...');

          dashboardItem._internalDelayedActivations.forEach(function(delayedItems) {
            self.finishWidgetRegistration(dashboardId, delayedItems.ItemUuid);
          });
        }

      }, 100);
    }
  };

  self.refreshDependentWidgets = function(dashboardId, widgetId) {

    // load the dependent items
    var dashboardItem = lookupDashboardItem(dashboardId, widgetId);
    if (!dashboardItem || !dashboardItem._internalReferencedBy || dashboardItem._internalReferencedBy.length === 0) { return; }

    // reload all dependent items
    dashboardItem._internalReferencedBy.forEach(function(item) {
      self.reloadWidget(dashboardId, item.ItemUuid);
    });

  };

  self.onWidgetInitialize = function(dashboardId, widgetId, cb) {

    console.log('Received onWidgetInitialize for ' + dashboardId + '.' + widgetId);

    $rootScope.$on(createWidgetBasedEventName(dashboardId, widgetId, 'initialize'), function() {
      cb().then(function() {
        console.log('Finsihed onWidgetInitialize for ' + dashboardId + '.' + widgetId);
      }).catch(function(error) {
        console.log('Failed onWidgetInitialize for ' + dashboardId + '.' + widgetId +': ' + error);
      });
    });
  };

  self.onWidgetReload = function(dashboardId, widgetId, cb) {
    $rootScope.$on(createWidgetBasedEventName(dashboardId, widgetId, 'reload'), cb);
  };

  self.addCustomWidget = function(dashboardId, team, contract, token, collection, widgetDescription) {

    // inject the custom widget flag
    widgetDescription.ItemWidgetType = 'CustomWidget';

    // inject the widget into the data source
    return $eaDataCoordinator.storeModel(team, contract, token, 'DashboardItemClass', widgetDescription, {collection: collection}).then(function() {

      // rebuild the dashboard
      self.postDashboardEvent(dashboardId, "rebuild", null);

      // done
      return $q.when(widgetDescription);
    });
  }

}).service('$eaWidgetDashboardLayoutManager', function() {

  var self = this;

  var layoutSchemeVersion = 'v2';
  var layoutSchemeOverrides = {};

  function getLayoutSchemeVersion(dashboardId) {
    if (layoutSchemeOverrides[dashboardId]) {
      return layoutSchemeOverrides[dashboardId];
    } else {
      return layoutSchemeVersion;
    }
  }

  self.overridLayoutVersion = function(dashboardId, newVersion) {
    layoutSchemeOverrides[dashboardId] = newVersion;
  };

  self.storeLayout = function(dashboardId, dashboardItems) {
    console.log('Storing dashboard layout for ' + dashboardId);

    // prepare the payload
    var payload = {};
    dashboardItems.forEach(function(item) {
      payload[item.uuid] = { 'sizeX': item.sizeX,'sizeY': item.sizeY, 'row': item.row ,'col': item.col };
    });

    // store
    localStorage.setItem(dashboardId + '#' + getLayoutSchemeVersion(dashboardId) + '.layout', JSON.stringify(payload));
  };

  self.extendLayout = function(dashboardId, dashbordItems) {
    console.log('Loading dashboard layout for ' + dashboardId);

    // load the layout
    var layout = localStorage.getItem(dashboardId + '#' + getLayoutSchemeVersion(dashboardId) + '.layout');
    if (layout) {
      layout = JSON.parse(layout);

      dashbordItems.forEach(function(item) {
        angular.extend(item, layout[item.uuid]);
      });
    }
  };

  self.getDashboardLayout = function(dashboardId) {

    // load the layout
    var layout = localStorage.getItem(dashboardId + '#' + getLayoutSchemeVersion(dashboardId) + '.layout');
    if (!layout) { return []; }

    // parse
    layout = JSON.parse(layout);

    // convert into layout items
    var resultItems = [];
    Object.keys(layout).forEach(function(el) {
      resultItems.push(angular.extend(layout[el], { itemId: el }));
    });

    return resultItems;
  };

  self.overrideDashboardLayout = function(dashboardId, layoutItems) {
    // prepare the payload
    var payload = {};
    layoutItems.forEach(function(item) {
      payload[item.itemId] = { 'sizeX': item.sizeX,'sizeY': item.sizeY, 'row': item.row ,'col': item.col };
    });
    localStorage.setItem(dashboardId + '#' + getLayoutSchemeVersion(dashboardId) + '.layout', JSON.stringify(payload));
  }
});
