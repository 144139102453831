'use strict';

angular.module('azureCostsFeApp').controller('ModalCspUpdateT1AccountCtrl', function($scope, $modalInstance, AccountModel) {

  $scope.newAccount = {
    Name: "",
    ClientId: "",
    ClientSecret: "",
    Domain: "",
    BillingDay: 1,
    ExchangeRate: 1.0,
    AutoActivateCustomers: false,
    DefaultResellerManagement: false
  };

  $scope.updateMode = false;

  // Adjust the Name of the account model in case of missing
  if (!AccountModel.Name || AccountModel.Name.length == 0) {
    AccountModel.Name = AccountModel.Domain;
  }

  // edit the account model
  if (AccountModel && AccountModel.Name) {
    $scope.updateMode = true;
    $scope.newAccount = JSON.parse(JSON.stringify(AccountModel));
    $scope.newAccount.BillingDay == 0 ? $scope.newAccount.BillingDay = 1 : $scope.newAccount.BillingDay = $scope.newAccount.BillingDay;
    $scope.title = "Update Cloud Solutions Provider Program Account";
  } else {
    $scope.title = "Register Cloud Solutions Provider Program Account";
  }

  $scope.ok = function () {
    $modalInstance.close({ account: $scope.newAccount, action: $scope.updateMode ? 'update' : 'create'});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.removeAccount = function() {
    var ok = confirm('Do you really want to remove this account? This will remove all data from the system and you need to re-register the account later if required.');
    if (ok) { $modalInstance.close({action: 'remove'}); }
  };

  $scope.consentCPV = function() {
    $modalInstance.close({action: 'consent'});
  };

});



