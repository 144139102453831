'use strict';

angular.module('azureCostsFeApp').service('$ccDemoModeService', function($eaBackendDataModeSelector, $eaSession2, $eaBackend, $state, $stateParams, $busy) {
  var self = this;

  self.enableDemoMode = function(currentSession, options) {

    // register the demo contract
    $busy.during($eaBackend.dataDemoRegisterContract($stateParams.teamid, currentSession.DataToken)).then(function(contractId) {

      // set the options
      options = options || {};

      // add our options
      options.team = $stateParams.teamid;
      options.enrollment = contractId;

      // just redirect to sign in
      $state.go('public.signin', options);
    });
  };

});
