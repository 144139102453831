'use strict';

angular.module('azureCostsFeApp').controller('CcAddGcpContractConnectCtrl', function ($scope, $eaBackend, $busy, $stateParams, $eaCurrentSession, $state) {

  $scope.form = {
    accountkey: undefined,
    dataset: undefined
  };

  var gcpData = sessionStorage.getItem('gcpdata');
  if (gcpData) {
    $scope.form = JSON.parse(gcpData);
  }

  $scope.error = undefined;

  $scope.next = function()
  {
    if(location.href.indexOf('localhost') !== -1) {
      sessionStorage.setItem('gcpdata', JSON.stringify($scope.form));
    }

    $busy.setMessage('Verifying connection to GCP...');
    $busy.during($eaBackend.dataGcpVerifyConnection($stateParams.teamid, $scope.form.accountkey, $scope.form.dataset, $eaCurrentSession.DataToken)).then(function() {

      $scope.error = undefined;

      $busy.setMessage('Registering Contract...');
      return $busy.during($eaBackend.dataGcpRegisterContract($stateParams.teamid, $scope.form.accountkey, $scope.form.dataset, $eaCurrentSession.DataToken)).then(function(contractId) {

        // show the finish page
        $state.go('authorized.teams.contracts.add-gcp.finish', {teamid: $stateParams.teamid, cid: contractId});
      });
    }).catch(function(error) {
      $scope.error = error && error.data ? error.data : 'Unknown error';
    });
  };
});
