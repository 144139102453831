'use strict';

angular.module('azureCostsFeApp').controller('EaAdminJobsDetailsCtrl', function ($scope, $state, $eaBackendAdminV2, $busy, $eaCurrentSession, $base64, $eaConfig) {

  function loadEntries() {

    // generate one out if the month
    var jobPartitionHash = $state.params.jobpartition;

    // TestLogs202102WebJobsLogEntries/e971ab61-be79-4652-8cd6-dfd18be613d3

    if ($state.params.jobmonth) {
      jobPartitionHash =  $base64.encode(($eaConfig.envType === 'production' ? 'ProdLogs' : 'TestLogs') + $state.params.jobmonth + 'WebJobsLogEntries/' + $state.params.jobpartition);
    }

    // load the jobs
    return $eaBackendAdminV2.dataGetJobEntries(jobPartitionHash, $eaCurrentSession.DataToken).then(function (entries) {
      $scope.error = null;
      $scope.entries = entries;
    });
  }

  $scope.onReload(function() {
    $busy.during(loadEntries());
  });

  // init all
  $scope.updateNavBar();
  $busy.during(loadEntries());
});
