'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgCspCustomerTemplate', function() {
  return {
    restrict: 'C',
    template:
      '<div class="subscriptionColorBox" ng-style="{ \'background-color\': groupData.GroupColor }"></div>' +
      '<div class="subscriptionNameBox">{{groupData.PrimaryGroupName}}' +
        '&nbsp;&nbsp;<a href="" ng-click="openCustomerDashboard(groupData, false)" title="Open Customer Portal"><i class="fa fa-external-link"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="openCustomerDashboard(groupData, true)" title="Open Customer Portal without margin & filters"><i class="fa fa-line-chart"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-click="defineResellerMargin(groupData)" title="Define uplifts/margin for customers"><i class="fa fa-credit-card"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-click="defineResellerFilter(groupData)" title="Define Reseller Filter"><i class="fa fa-filter"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="publishToReseller(groupData)" title="Publish to Reseller"><i class="fa fa-taxi"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="updateVirtualMeters(groupData)" title="Update Virtual Meters"><i class="fa fa-id-card"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="manageVouchers(groupData)" title="Manage Vouchers"><i class="fa fa-shopping-cart"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="isVirtual()" ng-click="manageContainers(groupData)" title="Manage Containers"><i class="fa fa-archive"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="openCustomerSettings(groupData)" title="Settings"><i class="fa fa-cog"></i></a>' +
        '&nbsp;&nbsp;<span ng-if="isCSP() && !isT1N() && syncHandlesEarnings(groupData) && !hasFullEarnings(groupData)" class="badge badge-pill badge-listview badge-alert" title="This element does not get all earnings">Reduced Earnings</span>' +
        '&nbsp;&nbsp;<span ng-if="isCSP() && !isT1N() && syncHandlesEarnings(groupData) && hasFullEarnings(groupData)" class="badge badge-pill badge-listview badge-success" title="This element does not get all earnings"><i class="fa fa-check"></i>All Earnings</span>' +
        // '&nbsp;&nbsp;<span ng-if="isCSP() && !isT1N() && syncHandlesEarnings(groupData) && hasFullEarnings(groupData)" class="badge badge-pill badge-listview badge-success" title="This element does not get all earnings"><i class="fa fa-check"></i>AOBO</span>' +
        '&nbsp;&nbsp;<span ng-if="isNewCommerce(groupData)" class="badge badge-pill badge-listview badge-textcolor" title="This is an Azure Plan Customer based on the New Commerce Model.">Azure Plan</span>' +
        '&nbsp;&nbsp;<span ng-if="isAlpha(groupData)" class="badge badge-pill badge-listview badge-warning" title="The functionality has currently the Alpha-Status. Should you have any issues please contact our support!">Alpha</span>' +
        '<span ng-show="showVersionChooser(groupData)">&nbsp;&nbsp;<a href="" ng-if="options.dataItems.collection !== \'csp.ea.v2\'" ng-click="toggleVersion(groupData)" title="Toggle Customer Portal Version"><i class="fa fa-star"></i></a><span>' +
      '</div>' +
      '<div class="clearfix"></div>',
    scope: false,
    controller: 'EaWidgetGroupFilterListViewPgCspCustomerTemplateCtrl'
  };
}).controller('EaWidgetGroupFilterListViewPgCspCustomerTemplateCtrl', function($scope, $stateParams, $window) {

  $scope.syncHandlesEarnings = function(customerGroupData) {

    // check if we have data
    if (!customerGroupData || !customerGroupData.ServiceItems || customerGroupData.ServiceItems.length === 0) {
      return false;
    }

    // get the reference item
    var refItem = customerGroupData.ServiceItems[0];

    // debugger;
    return refItem.ReportLastSync && (moment(refItem.ReportLastSync) > moment("2020-05-04T22:00"));
  };

  $scope.isCSP = function() {
    return ($scope.cust01 && $scope.cust01.TierLevel === 'T1');
  };

  $scope.isT1N = function() {
    return ($scope.cust01 && $scope.cust01.TierLevel === 'T1N');
  };

  $scope.isVirtual = function() {    
    return ($scope.cust01 && $scope.cust01.TierLevel === 'V1');
  }

  $scope.isNewCommerce = function(customerGroupData) {

    // check if we have a service item
    // debugger;
    if (!customerGroupData.ServiceItems || customerGroupData.ServiceItems.length === 0) {
      return false;
    }

    // get the reference item
    var refItem = customerGroupData.ServiceItems[0];

    // debugger;
    return refItem.CustomerNewCommerce || false;
  };

  $scope.isAlpha = function(customerGroupData) {
    return $scope.isNewCommerce(customerGroupData);
  };

  $scope.hasFullEarnings = function(customerGroupData) {

    // no services
    if (!customerGroupData.ServiceItems || customerGroupData.ServiceItems.length === 0) {
      return true;
    }

    // check
    var partialEarnings = customerGroupData.ServiceItems.filter(function(a) { return a.FullEarnings === false });
    return !partialEarnings || partialEarnings.length === 0;
  };

  $scope.defineResellerMargin = function(customerGroupData) {
    $scope.executeDashboardAction('func:28A17E4C-960F-4D79-AD31-B6BBC9B7CFF0', customerGroupData);
  };

  $scope.defineResellerFilter = function(resellerFilterData) {
    $scope.executeDashboardAction('func:4A5811F8-8BA7-484F-9D18-138E4E2F3786', resellerFilterData);
  };

  $scope.openCustomerDashboard = function(customerGroupData, nomargin) {
    var canCustomerSelectVersion = $scope.showVersionChooser(customerGroupData);
    var teamId = customerGroupData.ServiceItems.length > 0 && customerGroupData.ServiceItems[0].CustomerTeamId ? customerGroupData.ServiceItems[0].CustomerTeamId : customerGroupData.PrimaryGroupId;
    $scope.executeDashboardAction('func:2929E37C-B3D7-483C-9BF9-4BFCA5D9C92D', { TeamId: teamId, Cycle: $scope.options.dataItems.propertyState, NoMargin: nomargin, ForceV2: !canCustomerSelectVersion });
  };

  $scope.toggleVersion = function(customerGroupData) {
    $scope.executeDashboardAction('func:9148E92E-6C1C-4846-BE9C-A8A5F9417AB5', customerGroupData);
  };

  $scope.updateVirtualMeters = function(customerGroupData) {
    $scope.executeDashboardAction('func:632E41FF-BE9B-4793-B22B-B71EC997FEE1', customerGroupData);
  };

  $scope.openCustomerSettings = function(customerGroupData) {
    $scope.executeDashboardAction('func:EE74EAF3-E461-4A11-9E29-F9A9069799D1', customerGroupData);
  };


  $scope.showVersionChooser = function(customerGroupData) {

    // check if we have a service item
    if (!customerGroupData.ServiceItems || customerGroupData.ServiceItems.length == 0) {
      return false;
    }

    // get the reference item
    var refItem = customerGroupData.ServiceItems[0];

    // check the activation date
    if (!refItem.ActivatedAt) {
      return true;
    } else if (refItem.ActivatedAt.indexOf('1970-') === 0) {
      return true;
    } else {
      return false;
    }
  };

  $scope.publishToReseller = function(customerGroupData) {
    $scope.executeDashboardAction('func:55E8F243-E782-426C-825B-70E4C484846A', customerGroupData);
  };

  $scope.manageVouchers = function(customerGroupData) {
    var targetUri = '/app2/teams/' + $stateParams.resellerId + '/apps/accounts/' + $stateParams.active + '/customers/' + customerGroupData.PrimaryGroupId + '/vouchers';
    console.log('Target-Uri is: ' + targetUri);
    $window.location.href = targetUri;
  };

  $scope.manageContainers = function(customerGroupData) {
    var targetUri = '/app2/teams/' + $stateParams.resellerId + '/apps/accounts/' + $stateParams.active + '/customers/' + customerGroupData.PrimaryGroupId + '/containers';
    console.log('Target-Uri is: ' + targetUri);
    $window.location.href = targetUri;
  };
});
