'use strict';

angular.module('azureCostsFeApp').controller('EaBillingStartTrialCtrl', [
  '$scope', '$eaBackend', '$eaUserProfile', '$toolbar', '$busy', '$eaSession2', '$location', function ($scope, $eaBackend, $eaUserProfile, $toolbar, $busy, $eaSession2, $location) {

    $eaSession2.lazy().then(function(session) {

      return $eaUserProfile.load(session.DataToken).then(function(profile) {

        // start the trial
        $eaBackend.dataStartTrial('enterprise', session.DataToken).then(function() {

            // sign in with our current url
            $eaSession2.destroySession();

            // sign in and comeback
            var path = '/sso?idp=' + session.IdentityProvider + '&redirect=' + encodeURIComponent('/app/signin');
            $location.url(path);

        }).catch(function(error) {

        })
      })
    });
  }]);
