'use strict';

angular.module('azureCostsFeApp').controller('CcAddGcpContractCtrl', function ($scope, $rootScope, $state) {

  function handleStep(state) {
    // define the step
    $scope.currentStep = 'step01';

    // check the correct one
    if (state.name === 'authorized.teams.contracts.add-gcp.connect') {
      $scope.currentStep = 'step02';
    } else if (state.name === 'authorized.teams.contracts.add-gcp.finish') {
      $scope.currentStep = 'step03';
    }
  }

  // handle the state
  handleStep($state.current);

  // handle state changes to adapt the control
  $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams)
  {
    handleStep(toState);
  });


});
