'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02T1Ctrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession) {

  $scope.newAccount = {
    Name: "",
    ClientId: "",
    ClientSecret: "",
    Domain: "",
    BillingDay: 1,
    SyncCustomers: true,
    AutoActivateCustomers: false
  };

  $scope.errorRaised = false;


  $scope.next = function()
  {
    // reset the error
    $scope.errorRaised = false;

    // register the account
    return $busy.during($eaCspService.registerAccount($scope.Profile, $eaCurrentSession, $scope.newAccount.Name, $scope.newAccount.ClientId, $scope.newAccount.ClientSecret, $scope.newAccount.Domain, $scope.newAccount.BillingDay, $scope.newAccount.SyncCustomers, $scope.newAccount.AutoActivateCustomers).then(function() {
      $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
    })).catch(function() {
      $scope.errorRaised = true;
    })
  }

});
