'use strict';

angular.module('azureCostsFeApp').directive('eaListView', function () {
  return {
    template: '<ng-transclude></ng-transclude>',
    restrict: 'C',
    controller: 'EaListViewCtrl',
    scope: true,
    transclude: true
  };
}).controller('EaListViewCtrl', function() {


});

angular.module('azureCostsFeApp').directive('eaListViewItem', function () {
  return {
    template: '<ng-transclude></ng-transclude>',
    restrict: 'C',
    controller: 'EaListViewItemCtrl',
    scope: {
      item: '=',
      onCollapse: '&'
    },
    transclude: true,
    link: function(scope, element) {
      scope.initializeWithElement(element);
    }
  };
}).controller('EaListViewItemCtrl', ['$scope', function($scope) {
  var htmlElement;

  // define the state
  $scope.subItemsCollapsed = true;

  // Initializes the controller and will be called from the link function
  $scope.initializeWithElement = function(element) {
    htmlElement = element;

    // collapse the element
    htmlElement.find('.ea-list-view-subitems').addClass('collapse');

    // check what we have open or not
    if (htmlElement.find('.ea-list-view-subitems').hasClass('open')) {
      $scope.subItemsCollapsed = false;
    }

    // inject the click binding for toggling state
    htmlElement.find('.ea-list-view-item-content').bind('click', function(e) {
      if (angular.element(e.target).hasClass('ea-list-view-item-content') || angular.element(e.target).parent('a').length === 0) {
        $scope.subItemsCollapsed = !$scope.subItemsCollapsed;
        $scope.$apply();
        e.preventDefault();
      }
    });
  };

  // change the classes in html element when the collapsed property is changed
  $scope.$watch('subItemsCollapsed', function() {

    if ($scope.subItemsCollapsed) {
      htmlElement.find('.ea-list-view-subitems.collapse').removeClass('in');
    } else {
      htmlElement.find('.ea-list-view-subitems.collapse').addClass('in');
    }

    $scope.onCollapse({ item: $scope.item, open: !$scope.subItemsCollapsed});
  });
}]);
