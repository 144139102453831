'use strict';

angular.module('azureCostsFeApp').controller('TeamEditPermissionsCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openTeamEditPermissionsDialog = function(user, availableSubscriptions) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/manage/modals/modal_ea_team_edit_permissions.html",
      controller: 'ModalTeamEditPermissionsCtrl',
      resolve: {
        User: function() { return user; },
        AvailableSubscriptions: function() { return availableSubscriptions; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
