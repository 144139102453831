'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceRi', function($acDataSourceSettings, $acDataSourceHelper, $acDataSourceClasses, $q, $eaBackend, $eaBackendAzureCachedContentLoader, $acCacheIdentifierGenerator) {
  var self = this;

  function loadRIDashboardItemsByTemplate(dashboardTemplate) {
    return $acDataSourceSettings.loadDataFromStaticUrl(dashboardTemplate).then(function(items) {
      return $q.when(items.map(function(item) { return new $acDataSourceClasses.DashboardItemClass(item); }))
    });
  }

  // The RI Dashboard Template loaded
  function loadRIDashboardItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'ri_purchase_dashboard_definitions.json';
    if (contract === 'RI:Welcome') { dashboardTemplate = 'ri_welcome_dashboard_definitions.json'; }
    return loadRIDashboardItemsByTemplate(dashboardTemplate);
  }

  // called from our infrastructure to the define the different
  // datasources this service handles
  self.registerDataSources = function(dataSourceManager) {

    // our collection id
    var collectionId = 'ri.v1';

    // register the dashboard items for the csp portal
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'ri', loadRIDashboardItems);

    // register the data load stuff
    dataSourceManager.registerDataSource('global', 'RiAnalysisResultItemClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {

      // generate the cacheIdentifier
      var cacheIdentifier = $acCacheIdentifierGenerator.buildDailyCacheIdentifier(className);
      return $eaBackendAzureCachedContentLoader.dataRequestCachedResourceWithRetry(teamid, contract, optionalParams.ResourceInstanceId, cacheIdentifier, dataToken, undefined, false, {cacheIdentifierExtenion: cacheIdentifier, resourceEndpoint: $eaBackend.dataGetRiAnalysisResult, queryoptions:optionalParams.query });
    });

    dataSourceManager.registerDataSource('global', 'RiAnalysisVirtualMachineItemClass', collectionId, function (teamid, contract, dataToken, className, cacheHash, optionalParams) {
      return $eaBackendAzureCachedContentLoader.dataRequestCachedResourceWithRetry(teamid, contract, undefined, cacheHash, dataToken, undefined, false, {cacheIdentifierExtenion: cacheHash, resourceEndpoint: function(teamId, contract, resourceInstanceId, cacheIdentifier, dataToken, retry) {
        return $eaBackend.dataGetRiVirtualMachines(teamId, contract, cacheHash, dataToken, retry);
      }});
    });
  };
});
