'use strict';

angular.module('azureCostsFeApp').controller('CspUpdateT1AccountCtrlMixin', function($scope, $modal) {

  $scope.openCspUpdateT1AccountDialog = function(exitingAccountModel) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_update_t1_account.html",
      controller: 'ModalCspUpdateT1AccountCtrl',
      resolve: {
        AccountModel: function() {
          return exitingAccountModel ? exitingAccountModel : {};
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
