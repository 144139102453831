'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02V1Ctrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession, jwtHelper) {

  $scope.formAddContract = {
    name: null,
    currency: null
  };

  $scope.errorRaised = false;


  $scope.next = function()
  {
    // reset the error
    $scope.errorRaised = false;


    // register the account
    return $busy.during($eaCspService.registerAccountV1($scope.Profile, $eaCurrentSession, $scope.formAddContract.name, $scope.formAddContract.currency).then(function() {
      $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
    })).catch(function() {
      $scope.errorRaised = true;
    });
  };
});
