'use strict';

angular.module('azureCostsFeApp').directive('eaBrandingTheme', function() {
  return {
    restrict: 'C',
    templateUrl: 'views/directive_ea_branding_theme.html',
    controller: 'EaBrandingCtrl',
    scope: true
  };
}).controller('EaBrandingCtrl', ['$scope', '$eaBranding', function($scope, $eaBranding) {

  // set the default values
  $scope.colors = $eaBranding.currentColors;
  $scope.customCss = $eaBranding.currentCustomCss;

  // establish an update handler
  $eaBranding.onUpdate(function() {

    // set the updatd values
    $scope.colors = $eaBranding.currentColors;
    $scope.customCss = $eaBranding.currentCustomCss;
  });
}]);
