'use strict';

angular.module('azureCostsFeApp').directive('eaTagList', function () {
  return {
    templateUrl: 'views/directive_ea_tag_list.html',
    restrict: 'C',
    controller: 'EaTagListCtrl',
    scope: {
      service: '=',
      tagProperty: '=',
      tagAdded: '&',
      tagRemoved: '&',
      tagAutoComplete: '&'
    }
  };
}).controller('EaTagListCtrl', ['$scope', function($scope) {

  // defines a couple standard keys
  var KEYS = {
    backspace: 8,
    tab: 9,
    enter: 13,
    escape: 27,
    space: 32,
    up: 38,
    down: 40,
    comma: 188
  };

  $scope.currentTagValue = undefined;
  $scope.currentTagProposal = [];

  $scope.reset = function() {
    $scope.currentTagValue = undefined;
    $scope.currentTagProposal = [];
  };

  $scope.onSubmit = function() {
    // add the tag
    $scope.tagAdded({service: $scope.service, tag: $scope.currentTagValue});

    // reset the form
    $scope.reset();
  };

  $scope.onRemove = function(tag) {
    $scope.tagRemoved({service: $scope.service, tag: tag});
  };

  $scope.onKey = function(event) {

    if (event) {

      // check if we have hotkeys
      if (event.keyCode === KEYS.escape) {

        // reset
        $scope.reset();

        // prevent default behaviour
        event.preventDefault();

        // done
        return;
      }
    }

    // check if we have an empty query
    if ($scope.currentTagValue === '') {

      // reset
      $scope.reset();
    }

    // query for tag proposals
    $scope.currentTagProposal = $scope.tagAutoComplete({service: $scope.service, query: $scope.currentTagValue});
  };

  $scope.onTagSelected = function(tagValue) {
    $scope.currentTagValue = tagValue;
    $scope.onSubmit();
  };
}]);
