'use strict';

angular.module('azureCostsFeApp').controller('EaBillingCtrl', function ($scope, $eaBackend, $eaUserProfile, $toolbar, $busy, $eaSession2, $location, moment, $eaBilling, $controller, $modal, $acTimelineGenerator, $state, $eaConfig) {

    $.extend(self, $controller('ModalBillingAddressCtrlMixin', {$scope: $scope, $modal: $modal }));

    $busy.setMessage("Loading Billing-Data");
    $busy.during($eaSession2.lazy().then(function(session) {

      // load the profile
      return $eaUserProfile.load(session.DataToken, true).then(function(profile) {
        return $eaBilling.loadBillingInformation(session).then(function (billing) {

          $scope.SvpMode = (angular.isObject($eaConfig.svp) && !$eaConfig.features.fastspring);

          // generate the contracts list
          var contracts = [];
          profile.Teams.forEach(function(team) {

            // check if we are account owner
            if ($eaBilling.isAccountOwner(session.UserId, team)) {

              team.Contracts.forEach(function(contract) {
                contracts.push(contract);
              })
            }
          });

          $scope.BillingInformation = {
            Plan: {
              Label: billing.CurrentPlan,
              Edition: $eaBilling.getDisplayTextForPlan(billing.CurrentPlan) + " Edition",
              RetentionPeriod: $eaBilling.calculateRetentionPeriod(billing),
              ChargingModel: $eaBilling.isInTrial(billing) ? "Trial" : billing.CurrentPlan === 'basic' ? "Free Plan" : "Paid Plan"
            },
            Payment: {
              Method: billing.Reference ? "fastspring" : "invoice",
              NextPaymentDue: billing.NextPaymentDue ? new Date(billing.NextPaymentDue) : null,
              NextPaymentAmount: $eaBilling.calculateCurrentPlanPrice(billing)
            },
            Extra: {
              Info: billing.InvoiceAddress,
              Contracts: contracts.sort()
            },
            Account: {
              Contact: profile.Contact,
              Id: profile.UserId
            }
          };

          // calculate the amount string
          $scope.BillingInformation.Payment.NextPaymentAmountString = '$' + $scope.BillingInformation.Payment.NextPaymentAmount.toFixed(2);
          if (angular.isObject($eaConfig.svp) && $eaConfig.features.fastspring && ($eaConfig.feHost === 'azure-costs.de' || $eaConfig.feHost === 'costs-test-ger.applimit.de')) {
            $scope.BillingInformation.Payment.NextPaymentAmountString = $scope.BillingInformation.Payment.NextPaymentAmount.toFixed(2) + '€';
          }

          $scope.Teams = profile.Teams.filter(function(item) {
            return (item.Id !== 'personal');
          }).map(function(item) {
            return {
              Id: item.Id,
              Edition: $eaBilling.getDisplayTextForPlan(item.AccountPlan) + " Edition"
            };
          }).sort();

          $scope.BillingStatus = {
            AllowedToStartTrial: !$eaBilling.hasEverStartedTrial(billing),
            AllowedToChangePlan: true,
            AllowedToChangeExtraInfo: true
          };

          // handle the next payment due in case we are doing fastspring
          if ($scope.BillingInformation.Payment.Method === 'fastspring' && $scope.BillingInformation.Payment.NextPaymentDue) {
            var now = new Date();

            // set the right date
            while ($scope.BillingInformation.Payment.NextPaymentDue.getTime() < now.getTime()) {
              $scope.BillingInformation.Payment.NextPaymentDue = $acTimelineGenerator.addMonth($scope.BillingInformation.Payment.NextPaymentDue);
            }
          }

          $scope.updateBillingInformation = function () {
            $scope.openUpdateBillingAddressDialog($scope.BillingInformation.Extra.Info).then(function (updatedInformation) {
              $busy.during($eaBackend.dataUpdateBillingInformation(updatedInformation, session.DataToken).then(function () {
                $scope.BillingInformation.Extra.Info = updatedInformation;
              }));
            })
          };

          $scope.navigateToBillingPage = function() {
            if ($scope.SvpMode) {
              $state.go('authorized.profileBillingServiceProvider')
            } else {
              $state.go('authorized.profileBillingUpgradeEdition')
            }
          };

          // Add Back Entry
          $toolbar.addItem('billing.Back', 'Back to Reports', 'fa-chevron-left', null, true, '/signin');

        })
      })
    }));
});
