'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackend
 * @description
 * # eaBackend
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$acWizardHelpers', function() {
  var self = this;

  self.getCurrentStep = function(currentState)
  {
    try {
      var splittedState = currentState.name.split('.');
      return splittedState[splittedState.length - 1].split('-')[0];
    } catch(e) {
      return 'step01';
    }
  }

});


