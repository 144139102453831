'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgDefaultTemplate', function() {
  return {
    restrict: 'C',
    template:
      '<div class="subscriptionColorBox" ng-style="{ \'background-color\': groupData.GroupColor }"></div>' +
      '<div class="subscriptionNameBox">{{groupData.PrimaryGroupName}}</div>' +
      '<div class="clearfix"></div>',
    scope: false
  };
});
