'use strict';

angular.module('azureCostsFeApp').controller('EaWidgetGroupFilterListViewSortingMixin', function($scope) {

  $scope.sortOrderDesc = false;
  $scope.sortOrderByGroupName = true;
  $scope.sortOrderByPeriod = null;

  $scope.sortPrimaryGroups = function(groupItem) {
    if ($scope.sortOrderByPeriod) {
      var groupCollection = groupItem.SecondaryGroups[$scope.sortOrderByPeriod];
      if (!groupCollection) { return 0.0; }

      return groupCollection.ServiceCosts;
    } else {
      return groupItem.PrimaryGroupName;
    }
  };

  $scope.sortServiceItems  = function(serviceItem) {

    var primaryGroupKeyComparition   = $scope.groupOption.Compare || 'CaseSensitive';

    if ($scope.sortOrderByPeriod) {
      var groupKeyValue = serviceItem[$scope.groupOption.GroupProperty];
      if (!groupKeyValue) { return 0.0; }

      var groupKeyValueAdapted = groupKeyValue;
      if (angular.isArray(groupKeyValue)) {
        groupKeyValueAdapted = groupKeyValue[0];
      }

      // check if we need to compare case sensitive or insensitive
      if (primaryGroupKeyComparition === 'CaseInSensitive') {
        groupKeyValueAdapted = groupKeyValueAdapted.toLowerCase();
      }

      var groupCollection = $scope.groupedSpendingData.PrimaryGroups[groupKeyValueAdapted].SecondaryGroups[$scope.sortOrderByPeriod];
      if (!groupCollection) { return 0.0; }

      var service = groupCollection.ServiceItemsMap[serviceItem[$scope.subitemIdProperty]];
      if (!service) { return 0.0; }

      return service[$scope.itemCostProperty];
    } else {
      return serviceItem.ServiceName;
    }
  };

  $scope.setPrimaryGroupOrderSettings = function() {

    if ($scope.sortOrderByGroupName) {
      $scope.sortOrderDesc = !$scope.sortOrderDesc;
    } else {
      $scope.sortOrderByGroupName = true;
      $scope.sortOrderByPeriod = null;
      $scope.sortOrderDesc = false;
    }
  };

  $scope.setOrderByCosts = function(period) {
    if ($scope.sortOrderByPeriod && $scope.sortOrderByPeriod === period) {
      $scope.sortOrderDesc = !$scope.sortOrderDesc;
    } else if ($scope.sortOrderByPeriod) {
      $scope.sortOrderByGroupName = false;
      $scope.sortOrderByPeriod = period;
    } else {
      $scope.sortOrderByGroupName = false;
      $scope.sortOrderByPeriod = period;
      $scope.sortOrderDesc = false;
    }
  };
});
