'use strict';

angular.module('azureCostsFeApp').service('$eaBranding', function($q, $http, $rootScope, $eaBackend, $eaConfig, $location, $eaBucket) {
  var self = this;

  var defaultColors = {
    generalBackgroundColor:   '#fff',
    headerBackgroundColor:    'rgba(255,255,255,.97)',
    headerFontColor:          '##8f8f8f',
    headerFontColorHover:     '#1dabec',
    bodyLineColor:            '#dddddd',
    buttonDefaultColor:       '#1dabec',
    buttonDefaultHoverColor:  '#444',
    buttonPrimaryColor:       '#89c402',
    buttonPrimaryHoverColor:  '#333',
    reportItemColor:          '#777',
    reportItemColorHover:     '#1dabec'
  };

  var defaultCustomCss = '';

  var defaultCompanyInformation = {
    logo: null,
    title: $eaConfig.appTitle || 'azure <strong>costs</strong>'
  };

  self.currentColors              = defaultColors;
  self.currentCompanyInformation  = defaultCompanyInformation;
  self.currentCustomCss           = defaultCustomCss;

  self.loadBrandingFromHostSegment = function() {

    if ($eaBucket.hashHostPrefix()) {
      $eaBucket.getTeamIdFromHostSegment().then(function (teamId) {
        return self.loadBranding(teamId);
      });
    } else {
      return $q.when();
    }
  };

  self.loadBranding = function(teamid) {
    var defer = $q.defer();

    $eaBackend.dataGetBranding(teamid).then(function(brandingSettings) {

      // set the current values
      self.currentCompanyInformation = brandingSettings.company ? brandingSettings.company : defaultCompanyInformation;
      self.currentColors = brandingSettings.colors;
      self.currentCustomCss = brandingSettings.css;

      // notify the change
      $rootScope.$emit('branding.updated');

      // done
      defer.resolve();
    }).catch(function() {
      defer.resolve();
    });

    return defer.promise;
  };

  self.onUpdate = function(callback) {
    $rootScope.$on('branding.updated', callback);
  };

  self.setColors = function(colors) {
    self.currentColors = colors;
    $rootScope.$emit('branding.updated');
  };

  self.setCustomCss = function(css) {
    self.currentCustomCss = css;
    $rootScope.$emit('branding.updated');
  };

  self.setCompany = function(company) {
    self.currentCompanyInformation = company;
    $rootScope.$emit('branding.updated');
  };

});
