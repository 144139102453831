'use strict';

angular.module('azureCostsFeApp').controller('CspDefineResellerFilterCtrlMixin', function($scope, $modal) {

  $scope.openCspDefineResellerFilterDialog = function(existingResellerFilter) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_define_reseller_filter.html",
      controller: 'ModalCspDefineResellerFilterCtrl',
      resolve: {
        ResellerFilter: function() {
          return existingResellerFilter ? existingResellerFilter : {};
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
