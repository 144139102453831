'use strict';

angular.module('azureCostsFeApp').controller('CcAddContractCtrl', function ($scope, $state, $stateParams, $window, $ccDemoModeService, $controller, $modal, $eaCurrentSession) {
  $.extend(self, $controller('ModalMessageBoxCtrlMixin', {$scope: $scope, $modal: $modal }));

  $scope.addAzureContract = function()
  {
    $state.go('authorized.reportAdd', { teamid: $stateParams.teamid});
  };

  $scope.addAwsContract = function()
  {
    $state.go('authorized.teams.contracts.add-aws.prepare', { teamid: $stateParams.teamid});
  };

  $scope.addGoogleContract = function()
  {
    $state.go('authorized.teams.contracts.add-gcp.prepare', { teamid: $stateParams.teamid});
  };

  $scope.startDemoMode = function()
  {
    $ccDemoModeService.enableDemoMode($eaCurrentSession);
  };
});
