'use strict';

angular.module('azureCostsFeApp').service('$eaDataAggregateOperations', function() {
  var self = this;

  self.sum = function(data, property) {
    var result = 0;
    data.forEach(function(element) {

      if (property) {
        result += element[property];
      } else {
        result += element
      }
    });
    return result;
  };

  self.count = function(reportsData, property) {
    if (property && !Array.isArray(reportsData)) {
      return reportsData[property].length;
    } else {
      return reportsData.length;
    }
  };

  self.avg = function(reportsData, property) {
    var c = self.count(reportsData, property);
    var s = self.sum(reportsData, property);
    return s / c;
  };

  self.distinct = function(reportsData, property, containsValue) {
    var result = [];

    function addToResultIfFitsToContainsValue(element) {

      if (!containsValue) {
        result.push(element);
      } if (element && element.indexOf && element.indexOf(containsValue) !== -1) {
        result.push(element);
      }
    }

    // check if we have data
    if (!reportsData || reportsData.length === 0) { return []; }

    // distinct the values
    reportsData.forEach(function(element) {
      var v = element[property];

      if (Array.isArray(v)) {
        v.forEach(function(i) {
          if (result.indexOf(i) === -1) {
            addToResultIfFitsToContainsValue(i);
          }
        });
      } else {
        if (result.indexOf(v) === -1) {
          addToResultIfFitsToContainsValue(v);
        }
      }
    });

    // sort
    result.sort();

    // done
    return result;
  };

  self.sumplusaggr = function(data, property, prevValue)
  {
    return self.sum(data, property) + prevValue;
  }

  var operations = {
    sum: self.sum,
    count: self.count,
    avg: self.avg,
    distinct: self.distinct,
    sumplusaggr: self.sumplusaggr
  };

  self.execute = function(func, reportsData, property, other) {
    var op = operations[func];
    if (op) {
      return op(reportsData, property, other);
    } else {
      return -1;
    }
  };

});
