'use strict';

angular.module('azureCostsFeApp').controller('ModalSingleLineBoxCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openSingleLineBox = function(title, description, value) {

    var modalInstance = $modal.open({
      templateUrl: 'views/modals/ac-single-line-box.html',
      controller: 'ModalSingleLineBoxCtrl',
      resolve: {
        Metadata: function() { return { Title: title, Description: description, Value: value }; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
