'use strict';

/**
 * @ngdoc directive
 * @name azureCostsFeApp.directive:eaNavbar
 * @description
 * # eaNavbar
 */
angular.module('azureCostsFeApp').directive('eaFileDropZone', function () {
  return {
    templateUrl: 'views/directive_ea_file_drop_zone.html',
    restrict: 'C',
    controller: 'EaFileDropZoneCtrl',
    scope: {
      image: '=',
      upload: '&'
    },
    link: function(scope, element, attrs, controller) {
        controller.init(element);
    }
  };
}).controller('EaFileDropZoneCtrl', ['$scope', function($scope) {
    var self = this;

    var htmlElement = null;

    $scope.handleDrop = function(e) {

      // prevent standard behaviour
      e.stopPropagation();
      e.preventDefault();

      // check if we have no file
      if (event.dataTransfer.files.length === 0) {
        return;
      }

      // collect file to transfer
      var fileToUpload = event.dataTransfer.files[0];

      // set the image in the tag
      var reader = new FileReader();
      reader.onload = function (e) {
        $scope.image = e.target.result;
        $scope.$apply();
      };
      reader.readAsDataURL(fileToUpload);

      // upload the file in his original location
      $scope.upload({ image: fileToUpload}).catch(function() {
        // handle error
        $scope.image = undefined;
        $scope.$apply();
      });
    };

    self.init = function(element) {
      htmlElement = element;

      // establish the listener
      var dropBox = angular.element(htmlElement).find('.drop-box');

      // enabled drag and drop operations
      dropBox.bind('dragover', function (e) {
        e.stopPropagation();
        e.preventDefault();
      });

      // handles the drop
      dropBox.bind('drop', $scope.handleDrop);
    };

}]);
