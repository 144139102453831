'use strict';

angular.module('azureCostsFeApp').controller('ModalCspDefineResellerFilterCtrl', function($scope, $modalInstance, ResellerFilter, $eaFilterPanel) {

  $scope.activeFilter = JSON.parse(JSON.stringify(ResellerFilter));

  if (!$scope.activeFilter.Rules) { $scope.activeFilter.Rules = []; }

  $scope.availableFilterFields = $eaFilterPanel.buildFieldModelsFromArray([
    "SubscriptionName" ,
    "ServiceName",
    "ServiceCategory",
    "ServiceType",
    "ServiceSize",
    "ServiceCosts",
    "ServiceQuantity",
    "ServiceUnit",
    "Tag",
    "CostCenter",
    "Department",
    "ResourceGroup"
  ]);

  $scope.operators = $eaFilterPanel.availableOperations;

  $scope.conditions = $eaFilterPanel.availableConditions;

  $scope.ok = function() {

    // Ask
    var ok = confirm('Changing the filters outdates all cached reports, when the customer is visiting his portal ' +
      'the next time it could take a very long time. You can force this process by visiting the portal ' +
      'in admin on behalf of! Do you really want to change the filters?');

    if (ok) {
      $modalInstance.close($scope.activeFilter);
    }
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.removeCondition = function(index) {
    $scope.activeFilter.Rules.splice(index, 1);
  };

  $scope.addCondition = function() {
    $scope.activeFilter.Rules.push($eaFilterPanel.buildRule('AND'));
  }
});



