'use strict';

angular.module('azureCostsFeApp').controller('ModalTeamEditUsersCtrl', function($scope, $modalInstance, $eaSession2, $controller, $eaBackend, $q, Users, IsCspTeam) {

  $.extend(self, $controller('TeamManagementCtrlMixin', {$scope: $scope, $eaBackend: $eaBackend, $q: $q}));

  $scope.title = "Edit Users";

  $scope.existingUsers = Users;
  $scope.newUsers = [];
  $scope.removedUsers = [];

  $scope.newUser = {
    Contact: "",
    Role: IsCspTeam ? 'admin' : 'member',
    WildCardSubscriptions: true
  };

  $scope.isCspTeam = IsCspTeam;

  $scope.ok = function () {
    $modalInstance.close({newUsers: $scope.newUsers, removedUsers: $scope.removedUsers});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.addUser = function() {
    var userModel = {Name: null, Contact: $scope.newUser.Contact, Role: $scope.newUser.Role, Subscriptions: $scope.newUser.WildCardSubscriptions ? { '*': ['*'] } : {} };
    $scope.existingUsers.push(userModel);
    $scope.newUsers.push(userModel);
    $scope.newUser.Contact = "";
    $scope.newUser.Role = IsCspTeam ? 'admin' : 'member';
    $scope.newUser.WildCardSubscriptions = true;
  };

  $scope.removeUser = function(userModel) {

    // add the model to the removed ones
    $scope.removedUsers.push(userModel);

    // try to find the index in existing Users
    var idx = $scope.existingUsers.indexOf(userModel);
    if (idx != -1) {
      $scope.existingUsers.splice(idx, 1);
    }

    // try to find the index in new Users
    var idx = $scope.newUsers.indexOf(userModel);
    if (idx != -1) {
      $scope.newUsers.splice(idx, 1);
    }
  };
});



