'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceCsp', function($acDataSourceSettings, $acDataSourceHelper, $acDataSourceClasses, $q, $eaBackend, $acTimelineGenerator, $injector, $eaBackendDataModeSelector, $acDataSourceHelperCspGenericSummary) {
  var self = this;

  function loadCSPDashboardItemsByTemplate(teamid, contract, dataToken, className, currentHashKey, optionalParams, dashboardTemplate) {
    var query = $acDataSourceHelper.getQueryDashboardItemsCallback(dashboardTemplate);
    return query(teamid, contract, dataToken, className, currentHashKey, optionalParams);
  }

  // defines all functions for the CSP dashboard
  function loadCSPDashboardItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_account_dashboard_definitions.json';
    if (contract === 'CSP:Welcome') { dashboardTemplate = 'csp_welcome_dashboard_definitions.json'; }
    return loadCSPDashboardItemsByTemplate(teamid, contract, dataToken, className, currentHashKey, optionalParams, dashboardTemplate);
  }

  function loadCSPDashboardItemsT2(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_account_t2_dashboard_definitions.json';
    if (contract === 'CSP:Welcome') { dashboardTemplate = 'csp_welcome_dashboard_definitions.json'; }
    return loadCSPDashboardItemsByTemplate(teamid, contract, dataToken, className, currentHashKey, optionalParams, dashboardTemplate);
  }

  function loadCSPDashboardItemsV2(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_account_dashboard_definitions_v2.json';
    if (optionalParams && optionalParams.collection && optionalParams.collection === 'csp.ea.v2') { dashboardTemplate = 'csp_account_ea_dashboard_definitions_v2.json'; };
    if (contract === 'CSP:Welcome') { dashboardTemplate = 'csp_welcome_dashboard_definitions.json'; }
    return loadCSPDashboardItemsByTemplate(teamid, contract, dataToken, className, currentHashKey, optionalParams, dashboardTemplate);
  }

  function loadCSPDashboardItemsT2V2(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_account_t2_dashboard_definitions.json';
    if (contract === 'CSP:Welcome') { dashboardTemplate = 'csp_welcome_dashboard_definitions.json'; }
    return loadCSPDashboardItemsByTemplate(teamid, contract, dataToken, className, currentHashKey, optionalParams, dashboardTemplate);
  }

  // loads all customer report items for a specific contract
  function loadCustomerReportItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {

    // inject dynamically to prevent circular dependency
    var $eaDataCoordinator = $injector.get('$eaDataCoordinator');

    // load the available periods
    return $eaDataCoordinator.query(teamid, contract, dataToken, 'PeriodClass', optionalParams).then(function(periods) {

      // load the customer index first
      return $eaDataCoordinator.query(teamid, contract, dataToken, 'ResellerCustomerClass', optionalParams).then(function(customers) {

        // get the accountid
        var accountId = contract.split(':')[1];

        // loading the account
        return $eaBackend.dataCspGetAccount(teamid, accountId, dataToken).then(function (account) {

          // index the customer array
          var customerIndex = customers.buildIndex('CustomerTenantId');

          // query the required data
          var pendingPeriodRequests = [];
          periods.forEach(function (period) {

            var result = [];

            // now load the reports for the last 12 months
            var pendingPeriodCall = $eaBackend.dataCspGetCustomersSpendingsInAccount(teamid, accountId, period.ReportId, dataToken).then(function (spendings) {

              spendings.forEach(function (spending) {

                var customerReport = new $acDataSourceClasses.ResellerCustomerEntryClass();
                customerReport.ReportId = spending.ReportId;
                customerReport.ReportMonth = spending.ReportId.split('-')[1];
                customerReport.ReportYear = spending.ReportId.split('-')[0];
                customerReport.ReportLastSync = null;
                customerReport.ReportCurrency = account.Currency;
                customerReport.ReportCurrencySymbol = account.Currency && account.Currency === 'EUR' ? '€' : account.Currency;
                customerReport.AccountId = spending.AccountId;

                var customer = customerIndex[spending.MicrosoftCustomerId];
                if (customer) {
                  customerReport.CustomerId = customer.CustomerId;
                  customerReport.CustomerCompany = customer.CustomerCompany;
                  customerReport.CustomerContact = customer.CustomerContact;
                  customerReport.CustomerDomain = customer.CustomerDomain;
                  customerReport.CustomerState = customer.CustomerState;
                  customerReport.CustomerTenantId = customer.CustomerTenantId;
                  customerReport.ActivatedAt = customer.ActivatedAt;
                  customerReport.CustomerNewCommerce = customer.newCommerce || false;
                }

                customerReport.Costs = spending.Costs;

                // override the billing cycle
                if ($eaBackendDataModeSelector.isBillingCycleEnabled() && $eaBackendDataModeSelector.getBillingCycleDay() !== 1) {
                  customerReport.Costs = spending.CostsBC ? spending.CostsBC : 0.0;
                }

                customerReport.EntitlementId = spending.EntitlementId;
                customerReport.EntitlementName = spending.EntitlementName;
                customerReport.EntitlementSymbol = "servicetype azure_app_service";

                result.push(customerReport);
              });

              return $q.when(result);
            });

            pendingPeriodRequests.push(pendingPeriodCall);
          });

          return $q.all(pendingPeriodRequests).then(function (results) {
            var resultList = [];
            results.forEach(function (result) {
              resultList.push.apply(resultList, result);
            });

            return $q.when(resultList);
          });
        });
      });
    });
  }

  function loadCustomerReportItemsV2(teamid, contract, dataToken, className, currentHashKey, optionalParams) {

    // get the accountid
    var accountId = contract.split(':')[1];

    // load the data
    return $acDataSourceHelperCspGenericSummary.loadGenericView(teamid, accountId, 'meter', 'csp', dataToken, className, currentHashKey, optionalParams);
  }

  function loadAllCustomerItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var accountId = contract.split(':')[1];

    // load the customers of a reseller
    return $eaBackend.dataCspGetCustomersInAccount(teamid, accountId, dataToken).then(function(customers) {

      var result = [];

      customers.forEach(function(customer) {
          var customerReport = new $acDataSourceClasses.ResellerCustomerClass();
          customerReport.AccountId = accountId;
          customerReport.CustomerId = customer.microsoftCustomerId;
          customerReport.CustomerCompany = customer.customerCompany;
          customerReport.CustomerContact = customer.customerContact;
          customerReport.CustomerDomain = customer.customerDefaultDomain;
          customerReport.CustomerState = customer.state;
          customerReport.CustomerTenantId = customer.customerTenantId;
          customerReport.CustomerTeamId = customer.customerTeamId ? customer.customerTeamId : customer.microsoftCustomerId;
          customerReport.CustomerContractId = customer.customerContractId ? customer.customerContractId : customer.customerTenantId;
          customerReport.CustomerNewCommerce  = customer.newCommerce || false;

          // set the correct activation date
          if (customer.activatedAt == null ||customer.activatedAt === undefined) {
            customerReport.ActivatedAt = undefined;
          } else if (customer.activatedAt.indexOf('1970-') === 0) {
            customerReport.ActivatedAt = undefined;
          } else {
            customerReport.ActivatedAt = customer.activatedAt;
          }

          result.push(customerReport);
      });

      // order ersult
      result.sortOn('CustomerCompany');

      // done
      return $q.when(result);
    });
  }

  // load the available periods
  function loadAvailablePeriodClasses() {

    var startDate = new Date();
    if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
      startDate = $acTimelineGenerator.addMonth(new Date());
    }
    var monthCount = 13;

    var monthList = $acTimelineGenerator.createMonthList(-1 * monthCount, startDate).map(function(month) {
      return $acDataSourceClasses.PeriodClass({ReportId: month});
    });

    return $q.when(monthList);
  }

  // defines all functions for the CSP account model
  // ###

  // defines all functions for the CSP customer model
  // ###

  // called from our infrastructure to the define the different
  // datasources this service handles
  self.registerDataSources = function(dataSourceManager) {

    // register the dashboard items for the csp portal
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'csp', loadCSPDashboardItems, $acDataSourceHelper.getStoreDashboardItemsCallback(), $acDataSourceHelper.getDeleteDashboardItemsCallback());
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'csp.t2', loadCSPDashboardItemsT2, $acDataSourceHelper.getStoreDashboardItemsCallback(), $acDataSourceHelper.getDeleteDashboardItemsCallback());
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'csp.v2', loadCSPDashboardItemsV2, $acDataSourceHelper.getStoreDashboardItemsCallback(), $acDataSourceHelper.getDeleteDashboardItemsCallback());
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'csp.ea.v2', loadCSPDashboardItemsV2, $acDataSourceHelper.getStoreDashboardItemsCallback(), $acDataSourceHelper.getDeleteDashboardItemsCallback());
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'csp.t2.v2', loadCSPDashboardItemsT2V2, $acDataSourceHelper.getStoreDashboardItemsCallback(), $acDataSourceHelper.getDeleteDashboardItemsCallback());

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', 'csp', $acDataSourceSettings.loadStaticDataGenericCallback);

    // spending filter class
    $acDataSourceHelper.registerDataSourceSpendingFilerClass(dataSourceManager, 'csp');

    // loads the customer index
    dataSourceManager.registerDataSource('global', 'ResellerCustomerClass', 'csp', loadAllCustomerItems);

    // loads the customer data
    dataSourceManager.registerDataSource('global', 'ResellerCustomerEntryClass', 'csp', loadCustomerReportItems);
    dataSourceManager.registerDataSource('global', 'ResellerCustomerEntryClass', 'csp.v2', loadCustomerReportItemsV2);
    dataSourceManager.registerDataSource('global', 'ResellerCustomerEntryClass', 'csp.ea.v2', loadCustomerReportItemsV2);


    // load the available periods
    dataSourceManager.registerDataSource('global', 'PeriodClass', 'csp', loadAvailablePeriodClasses);
  };
});
