'use strict';

/*
 * This service just contains general settings which can be used from teh data sources.
 */
angular.module('azureCostsFeApp').service('$acDataSourceSettings', function($eaBackendBase, $http, $q, $acDataSourceClasses, $eaConfig) {
  var self = this;

  self.loadDataFromStaticUrl = function(url) {

    // define the load url
    var loadUrl = $eaConfig.dataPath + '/' + url;

    // replace with cdnEntrypoint if possible
    if ($eaConfig.cdnEntrypoint && loadUrl[0] === '/') {
      loadUrl = $eaConfig.cdnEntrypoint + loadUrl
    }

    return $eaBackendBase.extractDataFromResponse($http.get(loadUrl));
  };

  self.loadStaticDataGenericCallback = function(teamid, contract, dataToken, className, cacheHash, optionalParams) {
    // add group options if required
    optionalParams = optionalParams || {};

    // set the collection parameter
    optionalParams.collection = optionalParams.collection || 'default';

    // get the class model from className
    var classModel = $acDataSourceClasses[className];
    if (!classModel) { return $q.reject(new Error('Invalid class name: ' + className)); }

    // load by collection
    return self.loadDataFromStaticUrl('static_' + className + '_' + optionalParams.collection + '.json').then(function(data) {
      return $q.when(data.map(function(item) {
        return new classModel(item);
      }));
    });
  }
});
