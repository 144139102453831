'use strict';

angular.module('azureCostsFeApp').service('$eaDataExportOperations', function($q) {
  var self = this;
  var csvDelimiter = ';';

  function addToResultSafely(result, value) {
    if (!result) {
      return value;
    } else if (result[result.length - 1] === '\n') {
      return result + value;
    } else {
      return result + csvDelimiter + value;
    }
  }

  self.exportListTo = function(format, data, headerProperties, options) {
    if (headerProperties.length === 0) {
      return $q.when('No Header');
    }

    // check the aount of data
    if (data === null ||data === undefined ||data.length === 0) {
      return $q.when('No Data');
    }

    // generate the headline
    var result;
    headerProperties.forEach(function (k) {

      // check if we need to generate sub-subElement
      if (angular.isObject(k)) {
        k[(Object.keys(k)[0])].forEach(function(s) {
          // remove String in the property
          s = s.replace('String', '');

          // uppercase the string
          s = s.capitalizeFirstLetter();

          // assign
          result = addToResultSafely(result, s);
        });
      }  else {
        // remove String in the property
        k = k.replace('String', '');

        // remove tag if needd
        k = k.replace('tag:', '');

        // uppercase the string
        k = k.capitalizeFirstLetter();

        // assign
        result = addToResultSafely(result, k);
      }
    });

    result += '\r\n';

    // visit every element
    data.forEach(function(line) {

      // visit the properties
      headerProperties.forEach(function(k) {

        var subKeys = [];

        if (angular.isObject(k)) {
          subKeys = k[Object.keys(k)[0]];
          k = Object.keys(k)[0];
        }

        if (k.startsWith('tag:')) {
          var tagName = k.replace('tag:', '');

          if (line.GroupTags) {
            result = addToResultSafely(result, line.GroupTags[tagName] || line.GroupTags[tagName.downCaseFirstLetter()] || 'Default');
          } else if (line['AzureTag:' + tagName])
          {
            result = addToResultSafely(result, line['AzureTag:' + tagName]);
          } else {
            result = addToResultSafely(result, 'Default');
          }
        } else {
          var value = line[k];
          if (csvDelimiter === ',' && Array.isArray(value)) {
            result = addToResultSafely(result, value.join(';'));
          } else if (angular.isObject(value) && !angular.isArray(value)) {
            subKeys.forEach(function (sk) {
              var subKeyValue = value[sk] ? value[sk] : 0;
              result = addToResultSafely(result, subKeyValue.toFixed(20).replace('.', options.decimalSeparator));
            });
          } else if (angular.isNumber(value)) {
            result = addToResultSafely(result, value.toFixed(20).replace('.', options.decimalSeparator));
          } else if (value === undefined || value === null) {
            result = addToResultSafely(result, '');
          } else {
            result = addToResultSafely(result, value.toString());
          }
        }
      });

      result += '\r\n';
    });

    // done
    return $q.when(result);
  };
});
