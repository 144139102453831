'use strict';

angular.module('azureCostsFeApp').directive('eaUpgradePlan', function () {
  return {
    templateUrl: 'views/directive_ea_upgrade_plan.html',
    restrict: 'C',
    transclude: true,
    controller: 'EaUpgradePlanCtrl',
    scope: {
      team: '=',
      requiredPlan: '=',
      realElement: '=',
      onAction: '&'
    }
  };
}).controller('EaUpgradePlanCtrl', function($q, $element, $scope, $eaSession2, $eaBilling, $eaUserProfile, $state, $eaConfig) {

  $scope.showTrial = $eaConfig.features.billing;

  var loadTeamPromise = null;

  if (typeof $scope.team === 'string') {
    loadTeamPromise = $eaUserProfile.load($eaSession2.getSession().DataToken).then(function(userProfile) {
      // get the team profile
      $scope.team = $eaUserProfile.getTeam(userProfile, $scope.team);
    });
  } else {
    loadTeamPromise = $q.when();
  }

  loadTeamPromise.then(function() {
    $scope.trialPossible = false;

    // check if we have the required plan
    if ($eaBilling.hasRequiredPlan($scope.team.AccountPlan, $scope.requiredPlan)) {

      // if so hide the element
      angular.element($element).addClass('hidden');

      // show the real element
      if ($scope.realElement) { angular.element('#' + $scope.realElement).removeClass('hidden'); }

    } else {

      // if not show the element
      angular.element($element).removeClass('hidden');

      // hide the real element
      if ($scope.realElement) { angular.element('#' + $scope.realElement).addClass('hidden'); }

    }

    // just define if it's possible to start a trial
    $scope.trialPossible = !$eaBilling.hasEverStartedTrial($scope.team);

    // check if caller is account owner
    $scope.isAccountOwner = $eaBilling.isAccountOwner($eaSession2.getSession().UserId, $scope.team);

  });

  $scope.startTrial = function() {
    // notify the coller
    if ($scope.onAction) { $scope.onAction(); }

    // start the trial
    $state.go('authorized.profileBillingStartTrial');
  };

  $scope.upgradePlan = function() {

    // notify the caller
    if ($scope.onAction) { $scope.onAction(); }

    // navigate to the scope
    $state.go('authorized.profileBillingUpgradeEdition');
  };
});
