'use strict';

angular.module('azureCostsFeApp').service('$eaDataFilterOperations', function($q) {
  var self = this;

  var fieldPropertyMapping = {
    'SubscriptionName': 'SubscriptionName',
    'ServiceName': 'ServiceName',
    'ServiceCategory': 'ServiceCategory',
    'ServiceType': 'ServiceType',
    'ServiceSize': 'ServiceSize',
    'ServiceCosts': 'ServiceCosts',
    'ServiceQuantity': 'ServiceQuantity',
    'ServiceUnit' : 'ServiceQuantityUnit',
    'Tag': 'ServiceTags',
    'CostCenter': 'CostCenter',
    'Department': 'Department',
    'ResourceGroup': 'ResourceGroup'
  };

  // ReportLastSync: Sat Nov 07 2015 09:13:20 GMT+0100 (CET)
  // ReportMonth: "11"
  // ReportYear: "2015"

  var conditionOperations = {
    '=': function(left, right) {
      return (left === right);
    },
    '<>': function(left, right) {
      return left !== right;
    },
    '<': function(left, right) {
      return left < right;
    },
    '<=': function(left, right) {
      return left <= right;
    },
    '>': function(left, right) {
      return left > right;
    },
    '>=': function(left, right) {
      return left >= right;
    },
    'contains': function(left, right) {
      return (left.indexOf(right) !== -1);
    },
    'notContains': function(left, right) {
      return (left.indexOf(right) === -1);
    },
    'startsWith': function(left, right) {
      return (left.indexOf(right) === 0);
    },
    'notStartsWith': function(left, right) {
      return (left.indexOf(right) !== 0);
    }
  };

  var conditionOperationsValueForUndefined = {
    '=': false,
    '<>': true,
    '<': true,
    '<=': true,
    '>': false,
    '>=': false,
    'contains': false,
    'notContains': true,
    'startsWith': false,
    'notStartsWith': true
  };

  function createFilterGroups(rules) {
    // build the filter tree
    var filterGroups= [];

    var currentFilterChilds = [];

    rules.forEach(function(filterElement) {

      // check if we need to create a new group
      if (filterElement.Operator === 'OR') {

        // add the element to the groups
        filterGroups.push(currentFilterChilds);

        // recreate the element
        currentFilterChilds = [];
      }

      // add the element to the group
      currentFilterChilds.push(filterElement);
    });

    // we need to add the last child
    filterGroups.push(currentFilterChilds);

    return filterGroups;
  }

  self.compileFilter = function(filterToApply) {
    if (!filterToApply || !filterToApply.Id) { return function() { return true; }; }

    var filterGroups= createFilterGroups(filterToApply.Rules);

    // return the filter function
    return function(element) {

      // contains the global state
      var passedRuleCheck = false;

      // visit every filter group until the first filter group
      // could be validated
      filterGroups.every(function(filterSubGroup) {

        // visit every subgroup
        passedRuleCheck = filterSubGroup.every(function(filterElement) {

          // map the field alue
          var fieldPropertyMapped = fieldPropertyMapping[filterElement.Field];
          if (!fieldPropertyMapped) { fieldPropertyMapped = filterElement.Field; }

          // read the field value
          var fieldValue = element[fieldPropertyMapped];

          // check if we have a value
          if (!fieldValue) {
            return conditionOperationsValueForUndefined[filterElement.Condition];
          }

          // check if we need to convert the rule data to a number
          var ruleValue = filterElement.Value;
          if (!isNaN(fieldValue)) {
            ruleValue = parseFloat(filterElement.Value);
          }

          // compare strings correctly
          if (filterElement.Compare === 'CaseInSensitive') {
            fieldValue = typeof fieldValue === 'string' ? fieldValue.toLowerCase() : fieldValue;
            ruleValue = typeof ruleValue === 'string' ? ruleValue.toLowerCase() : ruleValue;
          }

          // check if we pass the test
          if (!conditionOperations[filterElement.Condition](fieldValue, ruleValue)) {
            return false;
          } else {
            return true;
          }
        });

        if (passedRuleCheck) {
          return false;
        } else {
          return true;
        }
      });

      // return the global result
      return passedRuleCheck;
    };
  };

  self.applyFilter = function(data, filterToApply) {

    // if no filter applied return the element
    if (!filterToApply || !filterToApply.Id) { return $q.when(data); }

    // compile the filter
    var compiledFilter = self.compileFilter(filterToApply);

    // filter the elements
    return self.applyCompiledFilter(data, compiledFilter);
  };

  self.applyCompiledFilter = function(data, compiledFilter) {

    var filteredElements = data.filter(function(element) {
      return compiledFilter(element);
    });

    // done
    return $q.when(filteredElements);
  };
});
