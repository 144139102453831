'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02T2Ctrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession) {

  $scope.newAccount = {
    Name: "",
    Currency: "",
    Country: "",
    BillingDay: 1
  };

  $scope.errorRaised = false;


  $scope.next = function()
  {
    // reset the error
    $scope.errorRaised = false;

    // register the account
    return $busy.during($eaCspService.registerAccountT2($scope.Profile, $eaCurrentSession, $scope.newAccount.Name, $scope.newAccount.Currency, $scope.newAccount.Country, $scope.newAccount.BillingDay).then(function() {

      $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
    })).catch(function() {
      $scope.errorRaised = true;
    })
  }
});
