'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetMap', function() {
  return {
    restrict: 'C',
    templateUrl: 'views/directive_ea_widget_map.html',
    controller: 'EaWidgetMapCtrl'
  };
}).controller('EaWidgetMapCtrl', function($scope, $element, $q, $timeout) {

  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);

    // switch off the loading mode
    $scope.setLoading(false);

    // trigger a resize during load
    $timeout(function() {
      $scope.requestResize();
    }, 500);

    // done
    return $q.when(true);
  }

  // establish the resize callback
  $scope.onResize(function(newSize) {

    // find the img element
    var imgElement = $element.find('img')[0];

    // calc ratio
    var ratio = imgElement.width / imgElement.height;

    // calc parent ratio
    var pratio=newSize.w / newSize.h;
    if (ratio < pratio) {
      angular.element(imgElement).width(imgElement.width);
    } else {
      angular.element(imgElement).width(newSize.w - 20);
    }

  });

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
