'use strict';

angular.module('azureCostsFeApp').directive('eaNotificationCenter', function () {
  return {
    template: '<iframe ng-if="!notificationCenterRunningLocal" src="{{shownotificationspanelhref}}"></iframe>' +
                '<div ng-if="notificationCenterRunningLocal" style="text-align: center">' +
                  '<h1>Running local!</h1>\n' +
                  '<p>The Notifications Center is only available in a production ready environment</p>' +
                '</div>',
    restrict: 'C',
    controller: 'EaNotificationCenterCtrl',
    scope: true
  };
}).controller('EaNotificationCenterCtrl', function($scope) {

}).service('$eaNotificationCenter', function($rootScope, $stateParams, $location) {
  var self = this;

  self.show = function() {

    $rootScope.notificationCenterRunningLocal = (location.hostname === 'localhost' || location.hostname === '127.0.0.1');

    if (!$rootScope.notificationCenterRunningLocal) {

      if ($stateParams.teamid) {
        $rootScope.shownotificationspanelhref = '/app2/teams/' + $stateParams.teamid + '/apps/notificationcenter?embedded=1';
      } else if ($location.path().indexOf('/csp') === 0) {
        $rootScope.shownotificationspanelhref = '/app2/teams/' + $stateParams.resellerId + '/apps/notificationcenter?embedded=1';
      }
    }

    $rootScope.notificationCenterShow = true;
  };

  self.close = function() {
    $rootScope.notificationCenterShow = false;
  };

  self.isOpen = function() {
    return $rootScope.notificationCenterShow;
  }
});
