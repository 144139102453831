angular.module('azureCostsFeApp').controller('ModalNotificationSettingsCtrl', function($scope, $modalInstance, contract, notificationSettings, team, role, $eaBackend, $busy, jwtHelper, $eaBilling) {

  $scope.contractInformation = null;
  $scope.notificationInformation = null;
  $scope.repeatInformation = {
    Repeats: 0,
    RepeatsEvery: 1,
    RepeatsOnMon: false,
    RepeatsOnTue: false,
    RepeatsOnWed: false,
    RepeatsOnThu: false,
    RepeatsOnFri: false,
    RepeatsOnSat: false,
    RepeatsOnSun: false
  };

  $scope.activePanel = 1;

  var localBreakDownFields = [
    "SubscriptionName" ,
    "ServiceName",
    "ServiceCategory",
    "ServiceType",
    "ServiceSize",
    "ServiceUnit",
    "Tag",
    "ResourceGroup"
  ];

  if (contract && contract.Definition === 'EA') {
    localBreakDownFields.push('CostCenter');
    localBreakDownFields.push('Department');
  }

  $scope.breakDownFields = localBreakDownFields.map(function(v) {
    return { name: v };
  });

  $scope.repeatsFields = [
    { name: 'Daily', value: 0 },
    { name: 'Monday, Wednesday & Friday', value: 1 },
    { name: 'Weekday (Monday to Friday)', value: 2 },
    { name: 'Weekly', value: 3 },
    { name: 'Monthly', value: 4 }
  ];

  $scope.everyFieldsWeeks = [
    { name: 'every week', value: 1 },
    { name: 'every 2 weeks', value: 2 },
    { name: 'every 3 weeks', value: 3 },
    { name: 'every 4 weeks', value: 4 },
    { name: 'every 5 weeks', value: 5 },
    { name: 'every 6 weeks', value: 6 },
    { name: 'every 7 weeks', value: 7 },
    { name: 'every 8 weeks', value: 8 }

  ];

  $scope.breakDownFields.removeObjectByProperty('name', 'Tag');
  $scope.breakDownFields.removeObjectByProperty('name', 'ServiceCosts');

  $scope.requiredPlan = 'professional';
  $scope.team = team;
  $scope.hasRequiredPlan = $eaBilling.hasRequiredPlan($scope.team.AccountPlan, $scope.requiredPlan);

  $scope.alertDimensions = [
    { name: "Costs", display: "Costs" },
    { name: "DailyBurnrateAbs", display: "Daily Burn Rate (absolute)" },
    { name: "DailyBurnrateRel", display: "Daily Burn Rate (relative)" },
    { name: "DailyBurnrateDiff", display: "Daily Burn Rate Difference" },
    { name: "DailyBurnrateMax", display: "Daily Burn Rate Maximum" }
  ];

  $scope.alertDimensionUnits = {
    DailyBurnrateRel: '%'
  };

  function setRepeatsOn(repeatsOn) {
    $scope.repeatInformation.RepeatsOnMon = (repeatsOn.indexOf('mo') !== -1);
    $scope.repeatInformation.RepeatsOnTue = (repeatsOn.indexOf('tu') !== -1);
    $scope.repeatInformation.RepeatsOnWed = (repeatsOn.indexOf('we') !== -1);
    $scope.repeatInformation.RepeatsOnThu = (repeatsOn.indexOf('th') !== -1);
    $scope.repeatInformation.RepeatsOnFri = (repeatsOn.indexOf('fr') !== -1);
    $scope.repeatInformation.RepeatsOnSat = (repeatsOn.indexOf('sa') !== -1);
    $scope.repeatInformation.RepeatsOnSun = (repeatsOn.indexOf('su') !== -1);
  }

  function buildEmptyAlertPolicy() {
    return { Dimension: undefined, Limit: undefined };
  }

  $scope.newAlertPolicy = buildEmptyAlertPolicy();

  function convertRepeatsFromDisplayToModel() {
    if ($scope.repeatInformation.Repeats === 0) {
      $scope.notificationInformation.DailyReportRepeatsOn = ['mo', 'tu', 'we', 'th','fr', 'sa', 'su'];
      $scope.notificationInformation.DailyReportRepeatsEvery = 1;
    } else if ($scope.repeatInformation.Repeats === 1) {
      $scope.notificationInformation.DailyReportRepeatsOn = ['mo', 'we', 'fr'];
      $scope.notificationInformation.DailyReportRepeatsEvery = $scope.repeatInformation.RepeatsEvery;
    } else if ($scope.repeatInformation.Repeats === 2) {
      $scope.notificationInformation.DailyReportRepeatsOn = ['mo', 'tu', 'we', 'th', 'fr'];
      $scope.notificationInformation.DailyReportRepeatsEvery = $scope.repeatInformation.RepeatsEvery;
    } else if ($scope.repeatInformation.Repeats === 3) {
      $scope.notificationInformation.DailyReportRepeatsOn = [];
      if ($scope.repeatInformation.RepeatsOnMon) { $scope.notificationInformation.DailyReportRepeatsOn.push('mo'); };
      if ($scope.repeatInformation.RepeatsOnTue) { $scope.notificationInformation.DailyReportRepeatsOn.push('tu'); };
      if ($scope.repeatInformation.RepeatsOnWed) { $scope.notificationInformation.DailyReportRepeatsOn.push('we'); };
      if ($scope.repeatInformation.RepeatsOnThu) { $scope.notificationInformation.DailyReportRepeatsOn.push('th'); };
      if ($scope.repeatInformation.RepeatsOnFri) { $scope.notificationInformation.DailyReportRepeatsOn.push('fr'); };
      if ($scope.repeatInformation.RepeatsOnSat) { $scope.notificationInformation.DailyReportRepeatsOn.push('sa'); };
      if ($scope.repeatInformation.RepeatsOnSun) { $scope.notificationInformation.DailyReportRepeatsOn.push('su'); };
      $scope.notificationInformation.DailyReportRepeatsEvery = $scope.repeatInformation.RepeatsEvery;
    } else if ($scope.repeatInformation.Repeats === 4) {
      $scope.notificationInformation.DailyReportRepeatsOn = ['mo'];
      $scope.notificationInformation.DailyReportRepeatsEvery = 4;
    }
  }

  $scope.ok = function () {
    convertRepeatsFromDisplayToModel();
    $modalInstance.close({ notificationInformation: $scope.notificationInformation, default: false, sendMail: false});
  };

  $scope.saveDefault = function() {
    convertRepeatsFromDisplayToModel();
    $modalInstance.close({ notificationInformation: $scope.notificationInformation, default: true, sendMail: false});
  };

  $scope.saveAndSendTestMail = function() {
    convertRepeatsFromDisplayToModel();
    $modalInstance.close({ notificationInformation: $scope.notificationInformation, default: false, sendMail: true});
  };
  
  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.addAlertPolicy = function() {

    // define the unit
    var currency = $scope.alertDimensionUnits[$scope.newAlertPolicy.Dimension];
    if (!currency) { currency = $scope.contractInformation.Currency; }
    $scope.newAlertPolicy.LimitUnit = currency;

    // add the alert policy
    $scope.notificationInformation.AlertNotificationPolicies.push($scope.newAlertPolicy);

    // build a new empty one
    $scope.newAlertPolicy = buildEmptyAlertPolicy();
  };

  $scope.deleteAlertPolicy = function(policy) {
    var idx = $scope.notificationInformation.AlertNotificationPolicies.indexOf(policy);
    if (idx === -1) { return; }
    $scope.notificationInformation.AlertNotificationPolicies.splice(idx, 1);
  };

  // set the contract
  $scope.contractInformation = contract;

  // set the notification settings
  $scope.notificationInformation = notificationSettings;

  // set the default repeats information
  if ($scope.notificationInformation.DailyReportRepeatsOn === undefined) { $scope.notificationInformation.DailyReportRepeatsOn = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']; };
  if ($scope.notificationInformation.DailyReportRepeatsEvery === undefined) { $scope.notificationInformation.DailyReportRepeatsEvery = 1; };

  // convert to our more readably structure
  setRepeatsOn($scope.notificationInformation.DailyReportRepeatsOn);
  $scope.repeatInformation.RepeatsEvery = $scope.notificationInformation.DailyReportRepeatsEvery;

  // Daily
  if ($scope.notificationInformation.DailyReportRepeatsOn.length === 7 && $scope.notificationInformation.DailyReportRepeatsEvery == 1) {
    $scope.repeatInformation.Repeats = 0;
  // Monday, Wednesday & Friday
  } else if ($scope.notificationInformation.DailyReportRepeatsOn.isSame(['mo', 'we', 'fr'])) {
    $scope.repeatInformation.Repeats = 1;
  } else if ($scope.notificationInformation.DailyReportRepeatsOn.isSame(['mo', 'tu', 'we', 'th','fr'])) {
    $scope.repeatInformation.Repeats = 2;
  } else if ($scope.repeatInformation.RepeatsEvery === 4) {
    $scope.repeatInformation.Repeats = 4;
  } else {
    $scope.repeatInformation.Repeats = 3;
  }


  // check if we have alert notification policies
  if (!$scope.notificationInformation.AlertNotificationPolicies) { $scope.notificationInformation.AlertNotificationPolicies = [];}

  // set the role
  $scope.role = role;

  // check that we get default values for the breakdown properties
  if (!$scope.notificationInformation.DailyReportBreakDownFirst) {
    $scope.notificationInformation.DailyReportBreakDownFirst = $scope.breakDownFields[0].name;
  }
});



