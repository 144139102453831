angular.module('azureCostsFeApp').controller('ModalContractSettingsCtrl', function($scope, $modalInstance, contract, role, csp, $eaBackend, $busy, jwtHelper) {

  $scope.contractInformation = null;
  $scope.contractInformationVirtual = {};
  $scope.contractTokenInPayload = false;

  $scope.contractGeneralSettings = {
    Enabled: false,
    NotifyZeroValue: false
  };

  $scope.contractInformationCurrency = {
    Currency: null,
    CurrencyOther: null
  };

  $scope.currencies = [ 'EUR', 'USD', 'Other'];

  $scope.retentions = [
    { Value: 12, Display: '12 Months'},
    { Value: 24, Display: '24 Months'}
  ];

  $scope.isCspCustomer = csp;

  $scope.ok = function () {

    // adjust state
    $scope.contractInformation.Disabled = !$scope.contractGeneralSettings.Enabled;
    $scope.contractInformation.NotifyZeroValue = $scope.contractGeneralSettings.NotifyZeroValue;
    console.log($scope.contractGeneralSettings.Enabled);

    // adjust the currency
    if ($scope.contractInformationCurrency.Currency === 'Other') {
        $scope.contractInformation.Currency = $scope.contractInformationCurrency.CurrencyOther;
    } else {
        $scope.contractInformation.Currency = $scope.contractInformationCurrency.Currency;
    }

    // done
    $modalInstance.close({ contract: $scope.contractInformation, notificationInformation: $scope.notificationInformation});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.removeContract = function() {
    var ok = confirm('Do you really want to remove the contract? This will remove all data from the system and you need to re-register the contract later if required.');
    if (ok) { $modalInstance.close({action: "remove"}); }
  };

  $scope.generateVirtualContractInformationFromToken = function(enteredToken) {

    // check the token structure
    var decodedToken = null;
    var tokenExpired = false;
    try {
      decodedToken = jwtHelper.decodeToken(enteredToken);
      tokenExpired = jwtHelper.isTokenExpired(enteredToken);
    } catch(error) {
      decodedToken = null;
    }

    if (decodedToken) {


      $scope.contractInformationVirtual.decodedToken = decodedToken;
      $scope.contractInformationVirtual.tokenExpired = tokenExpired;
      $scope.contractInformationVirtual.expirationDate = new Date($scope.contractInformationVirtual.decodedToken.exp * 1000);

      if ($scope.contractInformationVirtual.decodedToken.ReportView === "IndirectEnterprise") {
        $scope.contractInformationVirtual.indirectContract = true;
      } else {
        $scope.contractInformationVirtual.indirectContract = false;
      }

      $scope.contractInformationVirtual.validToken = true;

    } else {
        $scope.contractInformationVirtual.validToken = false;
    }
  };

  // set the contract
  $scope.contractInformation = JSON.parse(JSON.stringify(contract));
  $scope.contractGeneralSettings.Enabled = !$scope.contractInformation.Disabled;
  $scope.contractGeneralSettings.NotifyZeroValue = $scope.contractInformation.NotifyZeroValue ? true : false;

  // set the role
  $scope.role = role;

  // set the currency information
  if ($scope.currencies.indexOf(contract.Currency) == -1) {
      $scope.contractInformationCurrency.Currency= 'Other';
      $scope.contractInformationCurrency.CurrencyOther = contract.Currency;
  } else {
      $scope.contractInformationCurrency.Currency= contract.Currency;
      $scope.contractInformationCurrency.CurrencyOther = null;
  }

  // calculate the virtual settings
  if ( $scope.contractInformation.DisplayName !== 'EA-98989898' && $scope.contractInformation.Token !== null && $scope.contractInformation.Token !== undefined) {
    $scope.contractInformationVirtual.showTokenDialog = true;
    $scope.generateVirtualContractInformationFromToken($scope.contractInformation.Token);
    $scope.contractTokenInPayload = true;
  }

  $scope.getContractType = function() {
    if ( $scope.contractInformationVirtual.indirectContract ) {
      return "Indirect Contract (Contract brought via Microsoft Reseller)";
    } else {
      return "Direct Contract (Contract brought directly at Microsoft)";
    }
  };
});



