'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgTemplateHost', function($compile) {
  return {
    restrict: 'C',
    scope: {
      template: '='
    },
    link: function(scope, element) {
      element.attr('class', scope.template);
      $compile(element)(scope.$parent);
    }
  };
});
