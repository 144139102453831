'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceCspGsi', function($acDataSourceSettings, $acDataSourceHelper, $acDataSourceClasses, $q, $eaBackend, $acTimelineGenerator, $injector, $eaBackendDataModeSelector, $acDataSourceHelperCspGenericSummary) {
  var self = this;

  function loadCSPDashboardItemsByTemplate(dashboardTemplate) {
    return $acDataSourceSettings.loadDataFromStaticUrl(dashboardTemplate).then(function(items) {
      return $q.when(items.map(function(item) { return new $acDataSourceClasses.DashboardItemClass(item); }))
    });
  }

  function loadCSPGsiDashboardItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {
    var dashboardTemplate = 'csp_gsi_dashboard_definitions.json';
    return loadCSPDashboardItemsByTemplate(dashboardTemplate);
  }

  // load the available periods
  function loadAvailablePeriodClasses() {

    var monthList = $acTimelineGenerator.createMonthList(-13, $acTimelineGenerator.addMonth(new Date())).map(function(month) {
      return $acDataSourceClasses.PeriodClass({ReportId: month});
    });

    return $q.when(monthList);
  }

  function loadAccountReportItems(teamid, contract, dataToken, className, currentHashKey, optionalParams) {

    // prepare result items
    var resultItems = [];

    // load gsi settingsd
    return $eaBackend.dataCspGetGsiSettings(teamid, dataToken).then(function(gsiAccount) {

      // get all acocunts becasue we do not have a contractId
      return $eaBackend.dataCspGetAccounts(teamid, dataToken).then(function(accounts) {

        return accounts.forEachExecuteAsyncOperationParallel($q, function(account) {

          return $acDataSourceHelperCspGenericSummary.loadGenericView(teamid, account.AccountId, 'v2customer', 'gsi.v1', dataToken, className, currentHashKey, optionalParams).then(function (result) {

            resultItems.appendArray(result.map(function (item) {

              // map in our standard ICON
              item.CustomerIcon = 'fa-building-o';

              // map in the account information
              item.AccountName = account.Name;
              item.AccountCountry = account.Country;
              item.AccountCurrency = account.Currency;
              item.AccountExchangeRate = account.ExchangeRate;
              item.AccountType = account.TierLevel;

              // convert the costs to the right currency
              item.Costs = item.Costs * item.AccountExchangeRate;

              // adjust the currency symbols
              item.ReportCurrency = gsiAccount.referenceCurrency;
              item.ReportCurrencySymbol = gsiAccount.referenceCurrencySymbol;

              // done
              return item;
            }));
          })
        });
      }).then(function() {
        return $q.when(resultItems);
      })
    });
  }

  self.registerDataSources = function(dataSourceManager) {

    // register the dashboard items for the gsi portal
    dataSourceManager.registerDataSource('global', 'DashboardItemClass', 'gsi.v1', loadCSPGsiDashboardItems);

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', 'gsi.v1', $acDataSourceSettings.loadStaticDataGenericCallback);

    // spending filter class
    $acDataSourceHelper.registerDataSourceSpendingFilerClass(dataSourceManager, 'gsi.v1');

    // load the data
    dataSourceManager.registerDataSource('global', 'ResellerCustomerEntryClass', 'gsi.v1', loadAccountReportItems);

    // load the available periods
    dataSourceManager.registerDataSource('global', 'PeriodClass', 'gsi.v1', loadAvailablePeriodClasses);
  };
});
