'use strict';

angular.module('azureCostsFeApp').service('$eaLocalSettings', function() {
  var self = this;

  var settingsStorageVersion = 'v1';
  var defaultValues = {};

  function defineSettingsValueAccessor(valueName, defaultValue) {

    // register the default value
    defaultValues[valueName] = defaultValue;

    // define the setter
    self.__defineSetter__(valueName, function(value) {
      localStorage.setItem('com.azure-costs.settings.' + settingsStorageVersion + '.' + valueName, value);
    });

    // define the getter
    self.__defineGetter__(valueName, function() {

      var value = localStorage.getItem('com.azure-costs.settings.' + settingsStorageVersion + '.' + valueName);
      if (value) { return value; }

      return defaultValues[valueName];
    });

  }

  // active dashboard
  defineSettingsValueAccessor('activeDashboard', 'default');
});
