'use strict';

angular.module('azureCostsFeApp').controller('ModalCspUpdateEAAccountCtrl', function($scope, $modalInstance, AccountModel, jwtHelper) {

  $scope.newAccount = {
    Name: "",
    Currency: "",
    SecurityTokenExpirationDate: "",
    SecurityToken: undefined,
    SecurityTokenValid: false,
    SecurityTokenExpired: false,
    ExchangeRate: 1.0,
    DefaultResellerManagement: false
  };

  // Adjust the Name of the account model in case of missing
  if (!AccountModel.Name || AccountModel.Name.length == 0) {
    AccountModel.Name = AccountModel.Domain;
  }

  // initial set
  $scope.newAccount.Name = AccountModel.Name;
  $scope.newAccount.Currency = AccountModel.Currency;
  $scope.newAccount.SecurityTokenExpirationDate = AccountModel.TokenExpires ? new Date(AccountModel.TokenExpires) : new Date("2019-01-19");
  $scope.newAccount.SecurityTokenExpired = ($scope.newAccount.SecurityTokenExpirationDate < new Date());
  $scope.newAccount.SecurityTokenValid = true;
  $scope.newAccount.ExchangeRate = AccountModel.ExchangeRate;
  $scope.newAccount.DefaultResellerManagement = AccountModel.DefaultResellerManagement;


  $scope.title = "Update Cloud Solutions Provider Program Account";

  $scope.ok = function () {
    $modalInstance.close({ account: $scope.newAccount, action: 'update' });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.removeAccount = function() {
    var ok = confirm('Do you really want to remove this account? This will remove all data from the system and you need to re-register the account later if required.');
    if (ok) { $modalInstance.close({action: "remove"}); }
  };

  $scope.verifySecurityToken = function(enteredToken) {

    // check the token structure
    var decodedToken = null;
    var tokenExpired = false;
    try {
      decodedToken = jwtHelper.decodeToken(enteredToken);
      tokenExpired = jwtHelper.isTokenExpired(enteredToken);
    } catch(error) {
      decodedToken = null;
    }

    if (decodedToken) {
      $scope.newAccount.SecurityTokenExpirationDate = new Date(decodedToken.exp * 1000);
      $scope.newAccount.SecurityTokenExpired = tokenExpired;
      $scope.newAccount.SecurityTokenValid = true;
    } else {
      $scope.newAccount.SecurityTokenValid = false;
    }
  }
});



