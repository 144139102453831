'use strict';

angular.module('azureCostsFeApp').controller('ModalBillingPurchaseCtrl',
['$scope', '$modalInstance', '$eaBilling', 'Plan', 'BillingAddress', function($scope, $modalInstance, $eaBilling, Plan, BillingAddress) {

  $scope.CurrentPlanDisplayText = $eaBilling.getDisplayTextForPlan(Plan);
  $scope.CurrentPlanPrice       = $eaBilling.calculateCurrentPlanPrice(Plan) * 12;
  $scope.BillingAddress         = BillingAddress;

  $scope.ok = function () {
    $modalInstance.close($scope.BillingAddress);
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

}]);



