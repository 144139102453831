'use strict';

angular.module('azureCostsFeApp').directive('eaTrial', function () {
  return {
    templateUrl: 'views/directive_ea_trial.html',
    restrict: 'C',
    controller: 'EaTrialCtrl',
    scope: {
      team: '=',
      profile: '='
    }
  };
}).controller('EaTrialCtrl', function($scope, $eaBilling, $eaUserProfile, $eaConfig) {

  $scope.open = false;
  $scope.showTrial = $eaConfig.features.billing;

  $scope.toggleState = function() {
    $scope.open = !$scope.open;
  };

  function setTrialSettings() {
    if ($scope.team && $scope.profile && $eaBilling.isInTrial($scope.team)) {
      $scope.trialEnds = $scope.team.TrialEnds;

      var teamFromProfile = $eaUserProfile.getTeam($scope.profile, $scope.team.Id);
      $scope.isAccountOwner = $eaBilling.isAccountOwner($scope.profile.UserId, teamFromProfile);
    }
  }

  $scope.$watch('team', function () {
    setTrialSettings();
  });

  $scope.$watch('profile', function () {
    setTrialSettings();
  });

});
