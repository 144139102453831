'use strict';

angular.module('azureCostsFeApp').controller('EaSSOCallbackCtrl', function ($scope, $controller, $location, $eaBucket, $eaBackend, $eaBackendDataModeSelector, $eaSession2, $state, $window, $eaConfig) {
  var self = this;

  // inject mixins
  $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));

  function isValidHttpUrl(string) {
    var url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
  }

  if ($location.search().server_error) {
    $scope.message = 'Auth-Error: ' + $location.search().server_error;
    $scope.showRefreshSessionButton = true;
  } else if ($location.search().access_token) {
    $scope.message = 'Processing identity...';
    $scope.showRefreshSessionButton = false;

    // get the stuff
    var accessToken = $location.search().access_token;
    var state = $location.search().state;

    // define the correct state
    var checkState = state ? state : $location.absUrl();

    // check if we have actAs
    var isActAs = $location.absUrl() && ($location.absUrl().indexOf('actas=true') !== -1);

    // check if we have a customer domain and redirect first to the customer domain callback
    // *.azure-costs.com
    // costs.acme.com
    if (checkState.indexOf('.' + $eaConfig.feHost) !== -1 && !$location.search().final ) {
        // cut of all parameters
        var splitPoint = checkState.indexOf('?') === -1 ? checkState.length : checkState.indexOf('?');
        checkState = checkState.substring(0, splitPoint).split('/');
        state = state ? state : '';
        $window.location = checkState[0] + '//' + checkState[2] + '/app/sso/callback?final=1&access_token=' + accessToken + '&state=' + state + '&actas=' + (isActAs ? 'true' : 'false');
        return;
    }


    // Load the profile for this user (no return becuase we knoe the user exists)
    $eaBackend.dataGetProfile(accessToken).then(function (profile) {

      // Touch the profile as used
      return $eaBackend.dataPostProfileTouch(accessToken).then(function (touchResult) {

        // Generate the login session
        return $eaSession2.generateSession(profile.UserId, profile.FirstName, profile.LastName, profile.Contact, profile.Avatar, touchResult.LoginCount, touchResult.SurveyAtLogin, profile.Type, profile.LiveId, null, accessToken, (touchResult.LoginCount <= 1), isActAs).then(function () {

          // Redirect to final location
          if (state) {
            // verify if it's only a url
            var decodedUri = decodeURIComponent(state);
            if (!isValidHttpUrl(decodedUri)) {
              $location.url('/signin');
            } else {
              $window.location = decodedUri;
            }
          } else {
            $location.url('/signin');
          }
        });
      });
    }).catch(function () {
      $scope.message = 'Something went wrong, please try again';
      $scope.showRefreshSessionButton = true;
    });
  } else {
    $scope.message = 'Something went wrong, please retry';
    $scope.showRefreshSessionButton = true;
  }

});
