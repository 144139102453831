'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02DemoCtrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession) {

  $scope.formData = {
    contractType: 'EA'
  };

  $scope.next = function() {

    // define the mapping
    var mapping = "CostCenter";
    if ($scope.formData.contractType === 'AWS') {
      mapping = 'Subscription';
    }

    // register the account
    return $busy.during($eaCspService.registerAccountDemo($scope.Profile, $scope.formData.contractType, mapping, $eaCurrentSession).then(function () {
      $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
    })).catch(function (error) {
      if (error && error.data && error.data.message) {
        $scope.error = error.data.message;
      } else if (error && error.data) {
        $scope.error = error.data;
      } else {
        $scope.error = 'Unknown error'
      }
    });
  }
});
