'use strict';

angular.module('azureCostsFeApp').controller('EaAdminContractsCtrl', function ($controller, $scope, $rootScope, $stateParams, $eaBackendAdmin, $busy, $modal, $q, jwtHelper, $eaCurrentSession) {

  function loadStats() {
    return $eaBackendAdmin.dataGetSpendingStats($scope.serviceProvider.Id, $eaCurrentSession.DataToken).then(function (stats) {
      $scope.error = null;
      $scope.orderByField = 'Enrollment';
      $scope.reverseSort = false;
      $scope.spendingStats = stats;


      // calculate the sums
      $scope.spendingKeys = [];
      $scope.spendingSumsAll = {};
      $scope.spendingSumsLastYear = {};
      $scope.spendingSumsLastMonth = {};

      $scope.spendingStats.forEach(function (stat) {
        if ($scope.spendingSumsAll[stat.Currency] === undefined) {
          $scope.spendingSumsAll[stat.Currency] = 0;
          $scope.spendingKeys.push(stat.Currency)
        }
        if (!$scope.spendingSumsLastYear[stat.Currency]) {
          $scope.spendingSumsLastYear[stat.Currency] = 0;
        }
        if (!$scope.spendingSumsLastMonth[stat.Currency]) {
          $scope.spendingSumsLastMonth[stat.Currency] = 0;
        }

        $scope.spendingSumsAll[stat.Currency] += stat.Spendings.all;
        $scope.spendingSumsLastYear[stat.Currency] += stat.Spendings.lastYear;
        $scope.spendingSumsLastMonth[stat.Currency] += stat.Spendings.lastMonth;
      });

    })
  }

  $scope.updateNavBar();

  $scope.verifyToken = function(token) {
    if (token === 'app-only') {
      return true;
    } else {
      try {
        return !jwtHelper.isTokenExpired(token);
      } catch(error) {
        return false;
      }
    }
  };

  $scope.syncCurrentMonth = function(teamId, userId, contract) {
    var currentMonthDefinition = (new Date()).currentMonthDefinition();

    var promise = null;

    if (teamId === 'personal') {
      promise = $eaBackendAdmin.dataPutPersonalContractsSyncMonth($scope.serviceProvider.Id, userId, contract, currentMonthDefinition, $eaCurrentSession.DataToken);
    } else {
      promise = $eaBackendAdmin.dataPutTeamContractsSyncMonth($scope.serviceProvider.Id, teamId, contract, currentMonthDefinition, $eaCurrentSession.DataToken);
    }

    // check promise
    promise.then(function() {
      alert("Sync triggered");
    })
  };

  $scope.syncAll = function(teamId, userId, contract) {
    var currentMonthDefinition = (new Date()).currentMonthDefinition();

    var promise = null;

    if (teamId === 'personal') {
      promise = $eaBackendAdmin.dataPutPersonalContractsSync($scope.serviceProvider.Id, userId, contract, $eaCurrentSession.DataToken);
    } else {
      promise = $eaBackendAdmin.dataPutTeamContractsSync($scope.serviceProvider.Id, teamId, contract, $eaCurrentSession.DataToken);
    }

    // check promise
    promise.then(function() {
      alert("Sync triggered");
    })
  };

  $scope.deleteUsageData = function(teamId, userId, contract) {
    if (confirm('Do you realy want to remove the usage data of contract' + contract + '?')) {
      var promise = null;

      if (teamId === 'personal') {
        promise = $eaBackendAdmin.dataDeletePersonalContractsReports($scope.serviceProvider.Id, userId, contract, $eaCurrentSession.DataToken);
      } else {
        promise = $eaBackendAdmin.dataDeleteTeamContractsReports($scope.serviceProvider.Id, teamId, contract, $eaCurrentSession.DataToken);
      }

      // check promise
      promise.then(function() {
        alert("Delete Contract triggered");
      })
    }
  };

  $scope.onReload(function() {
    $busy.during(loadStats());
  });

  $busy.during(loadStats());
});
