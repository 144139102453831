'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackendAdmin
 * @description
 * # eaBackendAzure
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$eaBackendAdmin', function($q, $timeout, $http, $eaBackendBase, $eaConfig) {
  var self = this;

  // This is the main uri of the backend
  var adminUri    = ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + $eaConfig.apiHost + '/admin/v1';

  /*
   * Admin API
   */

  self.dataGetServiceProviders = function(dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(adminUri + '/serviceproviders', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetSpendingStats = function(providerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(adminUri + '/' + providerId + '/spendings/stats', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetUsers = function(providerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(adminUri + '/' + providerId + '/users', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataDeleteUser = function(providerId, userid, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(adminUri + '/' + providerId + '/users/' + userid, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPutTeamContractsSyncMonth = function(providerId, teamid, contract, month, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(adminUri + '/' + providerId + '/teams/' + teamid + '/contracts/' + contract + '/reports/' + month + '/sync', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPutPersonalContractsSyncMonth = function(providerId, userid, contract, month, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(adminUri + '/' + providerId + '/personal/' + userid + '/contracts/' + contract + '/reports/' + month + '/sync', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPutTeamContractsSync = function(providerId, teamid, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(adminUri + '/' + providerId + '/teams/' + teamid + '/contracts/' + contract + '/reports/sync', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPutPersonalContractsSync = function(providerId, userid, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(adminUri + '/' + providerId + '/personal/' + userid + '/contracts/' + contract + '/reports/sync', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataDeleteTeamContractsReports = function(providerId, teamid, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(adminUri + '/' + providerId + '/teams/' + teamid + '/contracts/' + contract + '/reports', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataDeletePersonalContractsReports = function(providerId, userid, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(adminUri + '/' + providerId + '/personal/' + userid + '/contracts/' + contract + '/reports', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };
});
