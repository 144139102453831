'use strict';

angular.module('azureCostsFeApp').controller('CspResellerRegisterCtrl', function($scope, $modalInstance) {

  $scope.resellerAccountModel = { Name: undefined };

  $scope.ok = function () {
    $modalInstance.close({ account: $scope.resellerAccountModel, action: 'new' });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

});



