'use strict';

angular.module('azureCostsFeApp').controller('ModalCspUpdateAWSAccountCtrl', function($scope, $modalInstance, AccountModel, jwtHelper) {

  $scope.newAccount = {
    Name: "",
    Currency: "",
    ExchangeRate: 1.0,
    DefaultResellerManagement: false
  };

  // Adjust the Name of the account model in case of missing
  if (!AccountModel.Name || AccountModel.Name.length == 0) {
    AccountModel.Name = AccountModel.Domain;
  }

  // initial set
  $scope.newAccount.Name = AccountModel.Name;
  $scope.newAccount.Currency = AccountModel.Currency;
  $scope.newAccount.ExchangeRate = AccountModel.ExchangeRate;
  $scope.newAccount.DefaultResellerManagement = AccountModel.DefaultResellerManagement;


  $scope.title = "Update Cloud Solutions Provider Program Account";

  $scope.ok = function () {
    $modalInstance.close({ account: $scope.newAccount, action: 'update' });
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.removeAccount = function() {
    var ok = confirm('Do you really want to remove this account? This will remove all data from the system and you need to re-register the account later if required.');
    if (ok) { $modalInstance.close({action: "remove"}); }
  };
});



