'use strict';

angular.module('azureCostsFeApp').service('$eaLiveId', ['$http', function($http) {
  var self = this;

  self.loadProfile = function(accesstoken) {
    return $http({
      method: 'GET',
      url: 'https://apis.live.net/v5.0/me?access_token=' + accesstoken,
      transformRequest: [function(data, headersGetter){
        var headers = headersGetter();
        delete headers.Authorization;
        delete headers.authorization;

        // We need to return the data for the next in
        // transformation chain
        return data;
      }].concat($http.defaults.transformRequest)}).then(function(profile) {
      return profile.data;
    });
  };

}]);
