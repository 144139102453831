'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetRepository', function () {
  return {
    templateUrl: 'views/directive_ea_widget_repository.html',
    restrict: 'C',
    controller: 'EaWidgetRepositoryCtrl',
    scope: {
      dashboard: '=',
      team: '=',
      contract: '=',
      token: '=',
      collection: '='
    }
  };
}).controller('EaWidgetRepositoryCtrl', function($scope, $eaWidgetRepository, rfc4122, ngToast, $q, $eaBackend) {
  var self = this;

  $scope.selectedWidget = undefined;

  $scope.formData = {
    General: {
      Id: rfc4122.v4(),
      Title: '',
      Type: ''
    },

    IFrame: {
      Url: ''
    },

    Report: {
      Id: '',
      ShowGridActions: true,
      GridPageSize: 50,
      GridMode: 'table',
      GridPieBy: undefined,
      GridPieReduce: undefined,
      GridPieAt: undefined
    }
  };

  $scope.reportGridModes = [
    { id: 'table', name: 'Table'},
    { id: 'pie', name: 'Pie Chart'}
  ];

  $scope.reportGridPieOperations = [
    { id: 'count', name: 'Count'},
    { id: 'sum', name: 'Sum'},
    { id: 'min', name: 'Min'},
    { id: 'max', name: 'Max'},
    { id: 'avg', name: 'Avg'}
  ];

  $scope.loadedReports = [];

  function loadReportsFromReportCenter() {
    return $eaBackend.dataGetReportCenterReportDefinitions($scope.team, $scope.token).then(function(reports) {
      $scope.loadedReports = reports;
      return $q.when();
    })
  }

  function loadDataForWidgetInRepository(widgetType) {
    switch(widgetType) {
      case 'report':
        return loadReportsFromReportCenter();
        break;
      default:
        return $q.when();
        break;
    }
  }

  $scope.closePanel = function() {
    $eaWidgetRepository.hideRepository();
  };

  $scope.selectWidget = function(widgetType) {

    // execute the data loaded for this widget type
    return loadDataForWidgetInRepository(widgetType).then(function() {
      $scope.formData.General.Id = rfc4122.v4();
      $scope.selectedWidget = widgetType;
    });
  };

  $scope.cancelForm = function()
  {
    $scope.selectedWidget = undefined;
  };

  $scope.saveWidget = function() {

    // set the correct type
    $scope.formData.General.Type = $scope.selectedWidget;

    // close the panel
    $eaWidgetRepository.hideRepository();

    // register the widget
    return $eaWidgetRepository.registerCustomWidget($scope.dashboard, $scope.team, $scope.contract, $scope.token, $scope.collection, $scope.formData).then(function() {}, function(error) {

      // notify regarding error
      ngToast.create({
        className: 'danger',
        content: '<strong>Error!</strong> Failed to attach widget: <strong>' + error + '</strong>'
      });
    })
  }


}).service('$eaWidgetRepository', function($rootScope, $eaWidgetDashboardManager) {
  var self = this;

  self.showRepository = function() {
    $rootScope.showWidgetRepository = true;
  };

  self.hideRepository = function() {
    $rootScope.showWidgetRepository = false;
  };

  self.registerCustomWidget = function(dashboardId, teamId, contractId, dataToken, collection, formData) {

    // build a typically widget
    var widgetDescription = {
      Size : {
        x: 4,
        y: 2
      },
      Position : {
        x: 0,
        y: 0
      },
      ItemType: formData.General.Type,
      ItemUuid: formData.General.Id,
      ItemTitle: formData.General.Title,
      ItemOptions: {
        collection: collection
      },
      ItemDependencies : []
    };

    // put the specific data into it
    switch(formData.General.Type) {
      case 'iframe': {
        widgetDescription.ItemOptions.src = formData.IFrame.Url;
        break;
      }
      case 'report': {
        widgetDescription.ItemType = 'iframe';
        widgetDescription.ItemOptions.src = '/app2/teams/'+  teamId + '/apps/reportcenter/'+  formData.Report.Id + '/show/latest?embedded=1&gridActions=' +  (formData.Report.ShowGridActions ? '1' : '0') + '&gridPageSize=' + formData.Report.GridPageSize;

        if (formData.Report.GridMode && formData.Report.GridMode === 'pie') {
          widgetDescription.ItemOptions.src += '&gridMode=pie&gridPieBy=' + formData.Report.GridPieBy + '&gridPieReduce=' + formData.Report.GridPieReduce + '&gridPieAt=' + formData.Report.GridPieAt;
        }

        break;
      }
      default:
        break;
    }

    return $eaWidgetDashboardManager.addCustomWidget(dashboardId, teamId, contractId, dataToken, collection, widgetDescription);
  }
});

