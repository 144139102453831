'use strict';

angular.module('azureCostsFeApp').controller('ModalSingleLineBoxCtrl', function($scope, $modalInstance, $controller, Metadata) {

  $scope.title = Metadata.Title;
  $scope.description = Metadata.Description;
  $scope.line = Metadata.Line;
  $scope.value = Metadata.Value;

  $scope.ok = function () {
    $modalInstance.close($scope.value);
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

});



