'use strict';

angular.module('azureCostsFeApp').controller('CspChooseCustomerCtrlMixin', function($scope, $modal, $controller, $q) {

  // Re-Using the object picker
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal}));

  $scope.openCustomerChooser = function(customers /*, options*/) {

    var description = 'Please choose customers you want to perform the operation for:';

    // prepare the callbacks
    var getImageUrl = function(model) {
        return 'fa-building';
    };

    var getDisplayName = function(model) {
      return model.CustomerName;
    };

    var getDetailsString = function(model) {
      return model.CustomerId;
    };

    return $scope.openObjectPickerDialog('Choose customers', description, getImageUrl, getDisplayName, getDetailsString, customers, []);
  };
});
