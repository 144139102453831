'use strict';

angular.module('azureCostsFeApp').service('$eaChartConfig', [ function() {
  var self = this;

  self.createGaugeConfig = function() {
    return {
      chart: {
        type: 'solidgauge',
        renderTo: null
      },

      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },

      title: null,

      pane: {
        center: ['50%', '80%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
        backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },

      tooltip: {
        enabled: false
      },

      // the value axis
      yAxis: {
        min: 0,
        max: 100,
        stops: [],
        lineWidth: 0,
        minorTickInterval: null,
        tickPixelInterval: 400,
        tickWidth: 0,
        labels: {
          y: 16
        }
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
              borderWidth: 0,
              useHTML: true
          }
        }
      },

      series: []
    };
  };

  self.createStackedBarConfig = function() {
    return {
      chart: {
        renderTo: null,
        backgroundColor: null
      },
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text: null
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        labels: {
          enabled: true
        },
        stackLabels: {
          enabled: false
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        column: {
          stacking: 'normal',
            dataLabels: {
            enabled: false
          }
        },
        spline: {
          color: '#F69D4D',
            dashStyle: 'LongDash'
        }
      },
      series: []
    };
  };

  self.createPieConfig = function() {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },

      exporting: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      title: null,

      tooltip: {
        pointFormat: null
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          showInLegend: false
        }
      },

      series: []
    };
  };

  self.createAreaConfig = function() {
    return {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'area'
      },

      exporting: {
        enabled: false
      },

      credits: {
        enabled: false
      },

      legend: {
        enabled: false
      },

      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: false
        }
      },

      xAxis: {
        labels: {
          enabled: false
        }
      },

      title: null,

      tooltip: {
        pointFormat: null
      },

      plotOptions: {
        area: {
          color: '#1DABEC',
          marker: {
            enabled: false
          }
        }
      },

      series: []
    };
  };

}]);
