'use strict';

angular.module('azureCostsFeApp').controller('CspDownloadPriceSheetCtrlMixin', function($scope, $modal, $controller, $q) {

  $scope.openPriceSheetDownload = function(teamId, contractId, currency, region, token) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/model-csp-download-price-sheet.html",
      controller: 'ModalCspDownloadPriceSheetCtrl',
      resolve: {
        PriceSheetOptions: function() {
          return {
            TeamId: teamId,
            ContractId: contractId,
            Token: token,
            Currency: currency,
            Region: region
          };
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
