angular.module('azureCostsFeApp').controller('ModalBrandingCtrl', ['$scope', '$modalInstance', '$eaBranding', '$eaBackend', 'session', 'team', function($scope, $modalInstance, $eaBranding, $eaBackend, session, team) {
  // activate the first panel
  $scope.activePanel = 1;

  // set the current values
  $scope.companyInformation = $eaBranding.currentCompanyInformation;
  $scope.colorSettings = $eaBranding.currentColors;
  $scope.customStyleSettings = $eaBranding.currentCustomCss;
  $scope.bucketSettings = {
    name: ""
  };

  // do a copy of the org values
  $scope.orgColorSettings = angular.copy($scope.colorSettings);
  $scope.orgCustomStyleSettings = angular.copy($scope.customStyleSettings);

  $scope.companyChanged = function() {
    $eaBranding.setCompany($scope.companyInformation);
  };

  $scope.colorChanged = function() {
    $eaBranding.setColors($scope.colorSettings);
  };

  $scope.aceLoaded = function(_editor) {
    $scope.aceSession = _editor.getSession();
  };

  $scope.aceChanged = function () {
    $scope.customStyleSettings = $scope.aceSession.getDocument().getValue();
    $eaBranding.setCustomCss($scope.customStyleSettings);
  };

  $scope.resetBranding = function() {
    $scope.colorSettings = angular.copy($scope.orgColorSettings);
    $eaBranding.setColors($scope.colorSettings);
  };

  $scope.resetCustomCss = function() {
    $scope.customStyleSettings = angular.copy($scope.orgCustomStyleSettings);
    try { $scope.aceSession.getDocument().setValue($scope.customStyleSettings) } catch(error) {};
    $eaBranding.setCustomCss($scope.customStyleSettings);
  };

  $scope.ok = function () {
    $modalInstance.close({colors: $scope.colorSettings, css: $scope.customStyleSettings, company: $scope.companyInformation});
  };

  $scope.cancel = function () {
    // reset the CSS
    $scope.resetBranding();
    $scope.resetCustomCss();

    // close
    $modalInstance.dismiss("cancel")
  };
}]);



