'use strict';

angular.module('azureCostsFeApp').controller('CspRegisterCustomerCtrlMixin', function($scope, $modal) {

  $scope.openCspRegisterCustomerDialog = function() {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_register_customer.html",
      controller: 'ModalCspRegisterCustomerCtrl'
    });

    // returns a promise
    return modalInstance.result;
  };
});
