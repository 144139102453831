'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListView', function() {
  return {
    restrict: 'C',
    templateUrl: 'views/directive_ea_group_filter_list_view.html',
    controller: 'EaWidgetGroupFilterListViewCtrl'
  };
}).controller('EaWidgetGroupFilterListViewCtrl', function($scope, $q, $controller, $modal, $eaDataCoordinator, $eaBilling, $eaDataItemProcessorChain, $eaDataColorThemeGenerator, $eaWidgetGroupFilterListViewDataSources, $busy, $eaBackend, $eaUserProfile, $eaDataFilterOperations, $eaDataGroupOperations) {
  var self = this;

  // Mixins
  $.extend(self, $controller('ModalUpgradePlanCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('ModalCSVDataExportCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('EaWidgetGroupFilterListViewFiltersMixin', {$scope: $scope, $busy: $busy, $eaBackend: $eaBackend, $eaDataCoordinator: $eaDataCoordinator, $q: $q}));
  $.extend(self, $controller('EaWidgetGroupFilterListViewSortingMixin', {$scope: $scope }));
  $.extend(self, $controller('EaWidgetGroupFilterListViewExportMixin', {$scope: $scope }));

  // static data
  var monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

  function applyStatePropery(property, currentState) {
    if (typeof(property) === 'object') {
      return property[currentState];
    } else {
      return property;
    }
  }

  // View Bags
  function setViewBagsDefault() {

    $scope.groupOptions = [];
    $scope.groupOption = null;
    $scope.groupOptionUpgradePlan = {GroupLabel: 'Add more Groups', GroupProperty: 'AddMoreGroups'};

    $scope.availablePeriods = [];
    $scope.selectedPeriod = null;
    $scope.selectedPeriodsMulti = {};
    $scope.selectedPeriodsMultiNormalized = [];

    $scope.periodActions = [];

    $scope.availableFilters = null;
    $scope.activeFilter = null;

    $scope.rawItems = [];
    $scope.rawItemsFiltered = [];

    $scope.groupedSpendingData = {};
    $scope.groupedAllCheckdPeriods = [];
    $scope.groupedServiceDefinitions = {};

    $scope.buildDashboardDefer = $q.defer();

    $scope.allowExport = !(!$scope.options.dataItems.exportable || $scope.options.dataItems.exportable === undefined);
    $scope.allowFiltering = false;

    $scope.itemIdProperty = applyStatePropery($scope.options.dataItems.itemId, $scope.options.dataItems.itemIdState);
    $scope.subitemIdProperty = applyStatePropery($scope.options.dataItems.subItemId, $scope.options.dataItems.subItemIdState);
    $scope.subitemIconProperty = applyStatePropery($scope.options.dataItems.subItemSymbol, $scope.options.dataItems.subItemSymbolState);
    $scope.itemCostProperty = applyStatePropery($scope.options.dataItems.property, $scope.options.dataItems.propertyState);
    $scope.itemQuantityProperty = applyStatePropery($scope.options.dataItems.propertyQuantity, $scope.options.dataItems.propertyQuantityState);
    $scope.lastSyncDateProperty = $scope.options.dataItems.lastSyncDate;
    $scope.allowZeroCostItems = applyStatePropery($scope.options.dataItems.enableZeroCostItems, $scope.options.dataItems.enableZeroCostItemsState);

    $scope.userProfile = null;

    $scope.noData = false;

    // handle filtering
    $scope.availableFilterFields = $scope.options.dataItems.filterProperties || [];
    $scope.availableFilterFieldMappings = $scope.options.dataItems.filterPropertyMapping || {};
    $scope.allowFiltering = ($scope.availableFilterFields.length > 0)

    if (!$scope.options.dataItems.filterClass) { $scope.options.dataItems.filterClass = "SpendingFilterClass"; }
  }

  function setGroupOptionsByQueryData() {

    // load the group options
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.groupOptions.class, {collection: $scope.options.groupOptions.collection}).then(function (groupOptions) {
      $scope.groupOptions = [];

      var injectUpgradePlan = false;
      groupOptions.forEach(function (groupOption) {

        // check if we need to inject upgrade plan
        if (groupOption.RequiredPlan && !$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, groupOption.RequiredPlan)) {
          injectUpgradePlan = true;
          return;
        }

        // inject the element
        $scope.groupOptions.push(groupOption);
      });

      // inject the upgrade plan if required
      if (injectUpgradePlan) {
        $scope.groupOptions.push($scope.groupOptionUpgradePlan);
      }
      
      // remember on the last selected group option
      var previousSelectedGroupOption = localStorage.getItem($scope.dashboard + "#" + $scope.uuid + '.groupoption.selection');
      if (previousSelectedGroupOption) {
        try {
          debugger;
          $scope.groupOption = JSON.parse(previousSelectedGroupOption);
        } catch(e) {
          $scope.groupOption = groupOptions[0];
        }
      } else {
        // pre select the first group
        $scope.groupOption = groupOptions[0];
      }
    });
  }

  function updateGroupOptionsByDynamicPropertyPrefix() {

    // check if we have at least the professional plan
    if (!$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'professional')) { return $q.when(); }
    if (!$scope.groupedSpendingData.DynamicGroupOptions) { return $q.when(); }

    // sort the dynamic groups
    $scope.groupedSpendingData.DynamicGroupOptions.sortOn('GroupLabel');

    // add to the groups
    $scope.groupedSpendingData.DynamicGroupOptions.forEach(function(go) {
      $scope.groupOptions.push(go);
    });

    return $q.when();
  }

  function updateAvailableFiltersByDynamicPropertyPrefix() {

    // check if we have at least the professional plan
    if (!$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'professional')) { return $q.when(); }
    if (!$scope.groupedSpendingData.DynamicGroupOptions) { return $q.when(); }

    $scope.groupedSpendingData.DynamicGroupOptions.forEach(function(go) {
      $scope.availableFilterFields.push(go.GroupProperty);
    });

    return $q.when();
  }

  function setAvailablePeriodsByQueryData() {

    if (!$scope.options.periodItems)
    {
      $scope.availablePeriods = [];
      return $q.when();
    } else {
      // load the group options
      return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.periodItems.class, {collection: $scope.options.periodItems.collection, maxPeriods: $scope.maxPeriods}).then(function (periodItems) {

        // define the periods
        $scope.availablePeriods = periodItems.map(function(p) {
          return p[$scope.options.periodItems.property];
        });

        // reduce the available periods if needed
        if (!$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'startup') && $scope.availablePeriods.length > 2) {

          // remove all reports until we have to
          $scope.availablePeriods.splice(0, $scope.availablePeriods.length - 2)
        } else if (!$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'professional') && $scope.availablePeriods.length > 24) {

          // remove all reports until we have to
          $scope.availablePeriods.splice(0, $scope.availablePeriods.length - 24)
        }

        // select the last one per default
        if ($scope.availablePeriods && $scope.availablePeriods.length > 0) { $scope.selectedPeriod = $scope.availablePeriods[$scope.availablePeriods.length -1]; };
      });
    }
  }

  function loadAvailableFiltersIfPossible() {

    if ($scope.allowFiltering) {
      // load the available filters
      return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.dataItems.filterClass, {collection: $scope.options.dataItems.filterCollection}).then(function (dataFilters) {

        // oder the filters
        if (dataFilters) { dataFilters.sortOn('Name'); }

        // assign the filters
        $scope.availableFilters = dataFilters;

        // done
        return $q.resolve();
      });
    } else {

      // disable filtering
      $scope.allowFiltering = true;

      // done
      return $q.resolve();
    }
  }

  function loadServiceDefinitionMetadata() {
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, 'ServiceDefinitionClass', null).then(function (elementMetaData) {
      return $eaDataGroupOperations.groupBy(elementMetaData, ['ServiceType']).then(function (groupedMetaData) {
        return groupedMetaData;
      });
    });
  }

  function renderDataByGroupAndPeriod(options) {
    options = options || {};

    // define the no data values
    $scope.noData = (!$scope.rawItems || $scope.rawItems.length === 0);

    // set the deferrer pending
    $scope.buildDashboardDefer = $q.defer();

    // define the group keys
    var primaryGroupKey   = $scope.groupOption.GroupProperty;
    var primaryGroupKeyComparition   = $scope.groupOption.Compare || 'CaseSensitive';
    var secondaryGroupKey = $scope.options.periodItems.property;

    // apply the filter mappings
    var adaptedFilter = $scope.activeFilter;
    if ($scope.activeFilter !== undefined && $scope.activeFilter !== null && $scope.availableFilterFieldMappings) {
      adaptedFilter = JSON.parse(JSON.stringify($scope.activeFilter));

      adaptedFilter.Rules.forEach(function(rule) {
        if ($scope.availableFilterFieldMappings[rule.Field]) {
          rule.Field = $scope.availableFilterFieldMappings[rule.Field];
        }
      });
    }

    // build the compiled filter
    var compiledFilter = $eaDataFilterOperations.compileFilter(adaptedFilter);

    // reset the pre-filtered items
    $scope.rawItemsFiltered = [];

    // contains all checked groups
    var allCheckedPeriods = [];

    // add the other selected groups
    $scope.selectedPeriodsMultiNormalized.forEach(function(checkedPeriod) {
      allCheckedPeriods.push(checkedPeriod);
    });

    // add the selectd one if required
    if (allCheckedPeriods.indexOf($scope.selectedPeriod) === - 1) {
      allCheckedPeriods.push($scope.selectedPeriod);
    }

    // generate the processor chain
    var processorChain = $eaDataItemProcessorChain.createProcesserChain();

    // filter out all element which are not matching our active Filter
    // and generate indirectly a list of all items filtered
    processorChain.onVisit(function(item, resultBucket) {

      // check if we have an active filter
      if (!$scope.activeFilter) { return $eaDataItemProcessorChain.Status.Next; }

      // apply the compiled filter
      if (compiledFilter(item)) {
        $scope.rawItemsFiltered.push(item);
        return $eaDataItemProcessorChain.Status.Next;
      } else {
        return $eaDataItemProcessorChain.Status.Skip;
      }
    });

    // filter out all elements which are not part of the selected period
    processorChain.onVisit(function(item, resultBucket) {

      // the item should have at least a cost value
      if(item[$scope.itemCostProperty] <= 0 && !$scope.allowZeroCostItems) {
        return $eaDataItemProcessorChain.Status.Skip;
      }

      // set the detected selectedPeriod
      if (!resultBucket.DetectedSelectedPeriod ) {
        resultBucket.DetectedSelectedPeriod = $scope.availablePeriods.length > 0 ? $scope.availablePeriods[0] : '';
      }

      // check if we have a better one
      if (item[secondaryGroupKey] > resultBucket.DetectedSelectedPeriod) {
        resultBucket.DetectedSelectedPeriod = item[secondaryGroupKey];
      }

      // check if we skip it
      return (allCheckedPeriods.indexOf(item[secondaryGroupKey]) !== -1) ? $eaDataItemProcessorChain.Status.Next : $eaDataItemProcessorChain.Status.Skip
    });

    // define the last sync date based on the selected data
    processorChain.onVisit(function(item, resultBucket) {

      if ($scope.lastSyncDateProperty && item[$scope.lastSyncDateProperty]) {
        // set the initial value
        if (!resultBucket.LastSyncDate) {
          resultBucket.LastSyncDate = item[$scope.lastSyncDateProperty];
        }

        // check if we need to update
        if (item[$scope.lastSyncDateProperty] > resultBucket.LastSyncDate) {
          resultBucket.LastSyncDate = item[$scope.lastSyncDateProperty];
        }
      }

      // done
      return $eaDataItemProcessorChain.Status.Next;
    });


    // build the history used for exports e.g.
    processorChain.onVisit(function(item, resultBucket) {

      // build the history
      resultBucket.History = resultBucket.History  || {};

      // the item id
      var parentItemId = item[$scope.itemIdProperty];
      var itemId = item[$scope.subitemIdProperty];
      var itemKey = parentItemId + '-' + itemId;

      // add the serviec item if required
      if (!resultBucket.History[itemKey]) { resultBucket.History[itemKey] = JSON.parse(JSON.stringify(item)) }

      // add the costs for a specific period
      resultBucket.History[itemKey][item.ReportId + '-Costs'] = item[$scope.itemCostProperty];

      if ($scope.itemQuantityProperty && item[$scope.itemQuantityProperty]) {
        resultBucket.History[itemKey][item.ReportId + '-Quantity'] = item[$scope.itemQuantityProperty];
      } else if (item.ServiceMeters && item.ServicePrimaryMeter) {
        var meterIndex = item.ServiceMeters.buildIndex("Id") || {};
        var meterModel = meterIndex[item.ServicePrimaryMeter] || { Quantity: 0.0 };
        resultBucket.History[itemKey][item.ReportId + '-Quantity'] = meterModel.Quantity;
      } else {
        resultBucket.History[itemKey][item.ReportId + '-Quantity'] = 0.0;
      }

      // done
      return $eaDataItemProcessorChain.Status.Next;
    });

    // applies the correct item template
    processorChain.onVisit(function(item, resultBucket) {

      // set the correct subitem view template
      if ($scope.options.dataItems.subItemViewTemplate) {
        item.viewTemplate = $scope.options.dataItems.subItemViewTemplate;
      } else {
        item.viewTemplate = 'ea-widget-group-filter-list-view-pg-subitem-default-template';
      }

      // done
      return $eaDataItemProcessorChain.Status.Next;
    });

    // build the dynamic group options
    processorChain.onVisit(function(item, resultBucket) {

      // check if we defined a specific prefix
      if (!$scope.options.groupOptions.dynamic) { return $eaDataItemProcessorChain.Status.Next; }

      // generate the dynamic group options
      if (!resultBucket.DynamicGroupOptions) { resultBucket.DynamicGroupOptions = []; resultBucket.DynamicGroupOptionsMap = {}; }

      // inject the properties
      Object.keys(item).forEach(function(k) {
        if (k.indexOf($scope.options.groupOptions.dynamic) !== 0) { return; }
        if (resultBucket.DynamicGroupOptionsMap[k]) { return; }

        var groupLabel = k.replace($scope.options.groupOptions.dynamic, '');
        groupLabel = groupLabel.capitalizeFirstLetter();

        var groupOptionModel = {GroupLabel: groupLabel, GroupProperty: k, GroupIcon: 'fa-globe', GroupDescription: $scope.options.groupOptions.dynamicDescription };

        if (!resultBucket.DynamicGroupOptionsMap[k]) {
          resultBucket.DynamicGroupOptions.push(groupOptionModel);
          resultBucket.DynamicGroupOptionsMap[k] = groupOptionModel;
        }
      });

      // done
      return $eaDataItemProcessorChain.Status.Next;
    });


      // build the primary and secondary group
    processorChain.onVisit(function(item, resultBucket) {

      // generate the primary group key values
      var primaryGroupKeyValues = item[primaryGroupKey];
      var primaryGroupKeyValuesWasArray = true;
      if (!angular.isArray(primaryGroupKeyValues)) { primaryGroupKeyValues ? primaryGroupKeyValues = [primaryGroupKeyValues] : primaryGroupKeyValues = []; primaryGroupKeyValuesWasArray = false; }

      // build the secondary group key value
      var secondaryGroupKeyValue = item[secondaryGroupKey];

      // check if we have group elements for the different pathes
      resultBucket['All'] = resultBucket['All'] || { ServiceCosts: 0, Count: 0, ServiceItems: [] };
      resultBucket.PrimaryGroups = resultBucket.PrimaryGroups || {};

      // add the item to the summary group
      var summaryGroup = resultBucket['All'];
      summaryGroup.ServiceCosts += item.ServiceCosts;
      summaryGroup.Count++;
      summaryGroup.ServiceItems.push(item);

      // inject currency
      resultBucket.Currency = {
        Symbol: item[$scope.options.dataItems.currencySymbol],
        String: item[$scope.options.dataItems.currency]
      };

      // in the case the group value area is empty we need to assign this item to a special group
      // call not_set
      if (primaryGroupKeyValues.length === 0) { primaryGroupKeyValues = ['Without ' + primaryGroupKey]; }

      // visit every primary group key value the item should become part of
      primaryGroupKeyValues.forEach(function(primaryGroupKeyValue) {

        // check if we need to compare case sensitive or insensitive
        if (primaryGroupKeyComparition === 'CaseInSensitive') {
          primaryGroupKeyValue = primaryGroupKeyValue.toLowerCase();
        }

        // generate the group
        resultBucket.PrimaryGroups[primaryGroupKeyValue] = resultBucket.PrimaryGroups[primaryGroupKeyValue] || { PrimaryGroupKey: primaryGroupKey, PrimaryGroupId: primaryGroupKeyValue, PrimaryGroupName: undefined, ServiceCosts: 0, Count: 0, SecondaryGroups: {}, ServiceItemsIdentifiers: [], ServiceItems: [] };
        resultBucket.PrimaryGroups[primaryGroupKeyValue].SecondaryGroups[secondaryGroupKeyValue] = resultBucket.PrimaryGroups[primaryGroupKeyValue].SecondaryGroups[secondaryGroupKeyValue] || { SecondaryGroupId: secondaryGroupKeyValue, SecondaryGroupName: undefined, SecondaryGroupKey: secondaryGroupKey, ServiceCosts: 0, Count: 0, ServiceItemsMap: {}};
        resultBucket.SecondaryGroupsSummary = resultBucket.SecondaryGroupsSummary || {};
        resultBucket.SecondaryGroupsSummary[secondaryGroupKeyValue] = resultBucket.SecondaryGroupsSummary[secondaryGroupKeyValue] || { SecondaryGroupId: secondaryGroupKeyValue, SecondaryGroupName: undefined, SecondaryGroupKey: secondaryGroupKey, ServiceCosts: 0, Count: 0 };

        // define the item id
        var itemIdProperty = $scope.itemIdProperty;
        var subitemIdProperty = $scope.subitemIdProperty;
        var itemCostProperty = $scope.itemCostProperty;

        // add the item to his primary group
        var primaryItemGroup = resultBucket.PrimaryGroups[primaryGroupKeyValue];
        if (primaryItemGroup.ServiceItemsIdentifiers.indexOf(item[subitemIdProperty]) === -1) {
          primaryItemGroup.ServiceItemsIdentifiers.push(item[subitemIdProperty]);
          primaryItemGroup.ServiceItems.push(item);
        }

        primaryItemGroup.ServiceCosts += item[$scope.options.dataItems.property];
        primaryItemGroup.Count++;

        // Calculate the primary GroupName
        var primaryGroupName = item[primaryGroupKey];

        // set in case it is array
        if (primaryGroupKeyValuesWasArray) {
          primaryGroupName = primaryGroupKeyValue;
          primaryGroupName = $scope.options.dataItems.primaryGroupNameMapping[primaryGroupName] || primaryGroupName;
        }

        // get the mapping
        if ($scope.options.dataItems.primaryGroupNameMapping && $scope.options.dataItems.primaryGroupNameMapping[primaryGroupKey]) {
          primaryGroupName = item[$scope.options.dataItems.primaryGroupNameMapping[primaryGroupKey]];
        }

        // check if we have a value otherwise we are using our fallback
        if (!primaryGroupName || primaryGroupName.length === 0) {
          primaryGroupName = primaryGroupKeyValue;
        }

        // assign the group name
        primaryItemGroup.PrimaryGroupName = primaryGroupName;

        // add the item to his secondary group
        var secondaryItemGroup = resultBucket.PrimaryGroups[primaryGroupKeyValue].SecondaryGroups[secondaryGroupKeyValue];
        var secondaryItemGroupSummary = resultBucket.SecondaryGroupsSummary[secondaryGroupKeyValue];

        secondaryItemGroup.ServiceItemsMap[item[subitemIdProperty]] = item;
        secondaryItemGroup.ServiceCosts += item[itemCostProperty];
        secondaryItemGroup.Count++;

        secondaryItemGroupSummary.ServiceCosts += item[itemCostProperty];
        secondaryItemGroupSummary.Count++;

        // Calculate the secondary GroupName
        secondaryItemGroup.SecondaryGroupName = item[secondaryGroupKey];
        secondaryItemGroupSummary.SecondaryGroupName = item[secondaryGroupKey];
      });

      // done
      $eaDataItemProcessorChain.Status.Next;
    });

    // process all the item specific handlers
    var globalResultBucket = {};
    processorChain.process($scope.rawItems, globalResultBucket);

    // dump stats
    console.log("Processor Chain Stats:");
    globalResultBucket.ChainStats.forEach(function(cs, ci) {
      console.log('#' + ci + ' c: ' + cs.c + ' t: ' + cs.t + 'ms');
    });

    // check if we have a new selected period becuase of detection
    if (options.SetSelectedItemAutomatically && $scope.selectedPeriod != globalResultBucket.DetectedSelectedPeriod) {
      $scope.selectedPeriod = globalResultBucket.DetectedSelectedPeriod;
      options.SetSelectedItemAutomatically = false;
      return renderDataByGroupAndPeriod(options);
    }

    // check if we have data
    if (globalResultBucket.PrimaryGroups !== undefined && globalResultBucket.PrimaryGroups !== null) {

      // get the view templates
      var viewTemplates = $scope.options.dataItems.primaryGroupViewTemplates;
      if (!viewTemplates) { viewTemplates = {}; }

      // do a bit post processing
      Object.keys(globalResultBucket.PrimaryGroups).forEach(function (e) {

        // get the primary group
        var currentPrimaryGroup = globalResultBucket.PrimaryGroups[e];

        // Calculate the color for ever group
        currentPrimaryGroup.GroupColor = $eaDataColorThemeGenerator.getColorFor($scope.contract + '#' + currentPrimaryGroup.PrimaryGroupKey, currentPrimaryGroup.PrimaryGroupId);

        // add the default view template
        if (viewTemplates && viewTemplates[currentPrimaryGroup.PrimaryGroupKey]) {
          currentPrimaryGroup.viewTemplate = viewTemplates[currentPrimaryGroup.PrimaryGroupKey];
        } else {
          currentPrimaryGroup.viewTemplate = 'ea-widget-group-filter-list-view-pg-default-template';
        }

        // convert the promary groups to an array
        globalResultBucket.PrimaryGroupsList = globalResultBucket.PrimaryGroupsList || [];
        globalResultBucket.PrimaryGroupsList.push(currentPrimaryGroup);

        // Visit every secondary group summaries
        var lastSecondaryGroupSummary = undefined;
        Object.keys(globalResultBucket.SecondaryGroupsSummary).sort().forEach(function (secondaryGroupKeyValue) {
          if (!lastSecondaryGroupSummary) {
            lastSecondaryGroupSummary = globalResultBucket.SecondaryGroupsSummary[secondaryGroupKeyValue];
            lastSecondaryGroupSummary.ServiceCostsDiff = 0;
            lastSecondaryGroupSummary.ServiceCostsDiffRel = 0;
            return;
          }

          // generate the diff
          var currentSecondaryGroupSummary = globalResultBucket.SecondaryGroupsSummary[secondaryGroupKeyValue];
          currentSecondaryGroupSummary.ServiceCostsDiff = currentSecondaryGroupSummary.ServiceCosts - lastSecondaryGroupSummary.ServiceCosts;
          currentSecondaryGroupSummary.ServiceCostsDiffRel = (currentSecondaryGroupSummary.ServiceCosts / lastSecondaryGroupSummary.ServiceCosts) - 1;

          // set the last
          lastSecondaryGroupSummary = currentSecondaryGroupSummary;
        });

        // Visit every secondary group
        var lastSecondaryGroup = undefined;
        Object.keys(currentPrimaryGroup.SecondaryGroups).sort().forEach(function (secondaryGroupKeyId) {
          if (!lastSecondaryGroup) {
            lastSecondaryGroup = currentPrimaryGroup.SecondaryGroups[secondaryGroupKeyId];
            lastSecondaryGroup.ServiceCostsDiff = 0;
            lastSecondaryGroup.ServiceCostsDiffRel = 0;
            return;
          }

          // generate the diff
          var currentSecondaryGroup = currentPrimaryGroup.SecondaryGroups[secondaryGroupKeyId];
          currentSecondaryGroup.ServiceCostsDiff = currentSecondaryGroup.ServiceCosts - lastSecondaryGroup.ServiceCosts;
          currentSecondaryGroup.ServiceCostsDiffRel = (currentSecondaryGroup.ServiceCosts / lastSecondaryGroup.ServiceCosts) - 1;

          // set the last
          lastSecondaryGroup = currentSecondaryGroup;
        });
      });
    } else {
      $scope.noData = true;
    }

    // assign the used group option
    globalResultBucket.SelectedGroupOption = $scope.groupOption;

    // set the hinttext
    if ($scope.options.dataItems.hintText && $scope.options.dataItems.hintText.length > 0)
    {
      if ($scope.options.dataItems.hintText.indexOf('func:') === 0) {
        $scope.executeDashboardAction($scope.options.dataItems.hintText, { groupSepndingData: globalResultBucket, selectedPeriod: $scope.selectedPeriod }).then(function(generatedHintText) {
          globalResultBucket.HintText = generatedHintText
        })
      } else {
        globalResultBucket.HintText = $scope.options.dataItems.hintText;
      }
    }

    // assign the data
    $scope.groupedSpendingData = globalResultBucket;
    $scope.groupedAllCheckdPeriods = allCheckedPeriods.sort().reverse();

    // refresh dependent widgets
    $scope.refreshDependentWidgets();

    // commit the defer
    $scope.buildDashboardDefer.resolve($scope.groupedSpendingData);

    // return the promise
    return $scope.buildDashboardDefer.promise;
  }

  // generate the initial dashboard content
  function buildDashboard() {

    // enable loading
    $scope.setLoading(true);

    // set the view bags
    setViewBagsDefault();

    // get the user profile
    return $eaUserProfile.load($scope.token).then(function(userProfile) {

      $scope.userProfile = userProfile;

      // load the group options
      return setGroupOptionsByQueryData().then(function () {

        // load the available periods
        return setAvailablePeriodsByQueryData().then(function () {

          // load all filters
          return loadAvailableFiltersIfPossible().then(function () {

            // load the meta data
            return loadServiceDefinitionMetadata().then(function(groupedMetaData) {

              // set the meta data
              $scope.groupedServiceDefinitions = groupedMetaData;

              // load the data items
              return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.dataItems.class, {collection: $scope.options.dataItems.collection}).then(function (dataItems) {

                // set the rawItems
                $scope.rawItems = dataItems;

                // render the data
                return renderDataByGroupAndPeriod({SetSelectedItemAutomatically: true}).then(function () {

                  // update the group options in the case we have set dynamic prefix
                  return updateGroupOptionsByDynamicPropertyPrefix().then(function() {

                    // inject group options in filter fields
                    return updateAvailableFiltersByDynamicPropertyPrefix().then(function() {

                      // define the actions
                      $scope.periodActions = [];


                      if ($scope.options.periodItems.actions) {

                        $scope.options.periodItems.actions.forEach(function(action) {
                          var actionVisible = true;

                          if (action && action.actionHideProperty && action.actionHideValue) {      
                            var hideElements = action.actionHideValue.split(',');                      
                            actionVisible = $scope.cust01 && $scope.cust01[action.actionHideProperty] && hideElements.indexOf($scope.cust01[action.actionHideProperty]) == -1;
                          }

                          if (actionVisible) {
                            $scope.periodActions.push(action);
                          }
                        })
                      }


                      // define primary actions
                      $scope.primaryActions = $scope.options.primaryActions ? $scope.options.primaryActions : [];

                      // done with loading
                      $scope.setLoading(false);
                    });
                  });
                })
              });
            });
          });
        });
      });
    });
  }

  // called when selecting a gorup
  $scope.selectGroupOption = function(selectedGroupOption) {

    if (selectedGroupOption == $scope.groupOptionUpgradePlan) {
      $scope.openUpgradePlanWizard('startup', $scope.profiles.team, 'Enable Advanced Data Grouping by upgrading to a paid plan or starting your 14 days trial!');
    } else {
      $scope.groupOption = selectedGroupOption;
      localStorage.setItem($scope.dashboard + "#" + $scope.uuid + '.groupoption.selection', JSON.stringify(selectedGroupOption));
      $scope.refreshWidget();
    }
  };

  // allows to select a new period
  $scope.selectPeriod = function(period) {
    $scope.selectedPeriod = period;
    $scope.refreshWidget();
  };

  // we do not allow to expand the items
  $scope.groupItemExpanded = function (item, open) {
    if ($scope.options.dataItems.expandable) {
      item.viewOpen = open;
    } else {
      item.viewOpen = false;
    }
  };

  // generates the period label
  $scope.getPeriodLabel = function (period) {
    if (period === null || period === undefined) { return ''; }
    if (period.length != 7) { return period; }

    var monthLabelArray = period.split('-');
    var month = parseInt(monthLabelArray[1]) - 1;

    return monthNames[month] + ' ' + monthLabelArray[0];
  };

  // generates the pariod label short
  $scope.getPeriodLabelShort = function (period) {
    if (period === null || period === undefined) { return ''; }
    if (period.length != 7) { return period; }

    var monthLabelArray = period.split('-');
    var month = parseInt(monthLabelArray[1]) - 1;

    return monthNames[month].substr(0,3) + ' ' + monthLabelArray[0];
  };

  // allows to handle multi select of period groups
  $scope.changedSelectedPeriodMulti = function() {

    // reset the normalized selection
    $scope.selectedPeriodsMultiNormalized = [];

    // check if we have the right permissions
    if ( !$eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'professional')) {

      // reset all selections
      $scope.selectedPeriodsMulti = [];

      // show the purchase information
      $scope.openUpgradePlanWizard('professional', $scope.profiles.team, 'Smart Compare is a feature available starting with the Professional plan. Try it out by upgrading your plan or starting a 14 days trial!');

    } else {
      // normalize the selected periods

      Object.keys($scope.selectedPeriodsMulti).forEach(function (k) {
        if ($scope.selectedPeriodsMulti[k]) {
          $scope.selectedPeriodsMultiNormalized.push(k);
        }
      });

      // order
      $scope.selectedPeriodsMultiNormalized.sort().reverse();

      // do it
      $scope.refreshWidget();
    }
  };

  $scope.calculateCellStyle = function(item, isHeader) {
    var factor = 0.20;
    if (item > factor) {
      return {
        'background-color': 'rgba(239,55,14,0.6)',
        'color': 'white',
        'font-weight': 'bold',
        'margin-left': isHeader ? '20px' : '10px',
        'width': '90px',
        'padding-right': '5px'
      };
    } if (item < -1 * factor) {
      return {
        'background-color': 'rgba(108,178,4,0.6)',
        'color': 'white',
        'font-weight': 'bold',
        'margin-left': isHeader ? '20px' : '10px',
        'width': '90px',
        'padding-right': '5px'
      };
    } else {
      return {
        'margin-left': isHeader ? '20px' : '10px',
        'width': '90px',
        'padding-right': '5px'
      };
    }
  };

  $scope.calculateCostDiffsForService = function(primaryGroup, currentPeriodIndex, serviceIdentifier) {

    // check if we have a chance
    if ( !$scope.groupedAllCheckdPeriods || $scope.groupedAllCheckdPeriods.length <= 1 || currentPeriodIndex === $scope.groupedAllCheckdPeriods.length - 1) { return 0.0; }

    // get the right groupIds
    var currentGroupId = $scope.groupedAllCheckdPeriods[currentPeriodIndex];
    var prevGroupId = $scope.groupedAllCheckdPeriods[currentPeriodIndex + 1];

    // get the group elements for the pre group
    var currentServiceItemMap = primaryGroup.SecondaryGroups[currentGroupId];
    var currentService = currentServiceItemMap ? currentServiceItemMap.ServiceItemsMap[serviceIdentifier] : undefined;
    if (!currentService) { currentService = {}; currentService[$scope.itemCostProperty] = 0.0 };

    var prevServiceItemMap = primaryGroup.SecondaryGroups[prevGroupId];
    var prevService = prevServiceItemMap ? prevServiceItemMap.ServiceItemsMap[serviceIdentifier] : undefined;
    if (!prevService) { prevService = {}; prevService[$scope.itemCostProperty] = 0.0 };

    // calculate
    return (currentService[$scope.itemCostProperty] / prevService[$scope.itemCostProperty]) - 1;
  };

  $scope.calculateServiceCostsString = function(element) {
    try {
      if (!element) {
        return "-/-";
      } else if (element.ServiceCosts) {
        return element.ServiceCosts.toFixed(2) + ' ' + $scope.groupedSpendingData.Currency.Symbol;
      } else if (element[$scope.itemCostProperty]) {
        return element[$scope.itemCostProperty].toFixed(2) + ' ' + $scope.groupedSpendingData.Currency.Symbol;
      } else {
        return '0.00 ' + $scope.groupedSpendingData.Currency.Symbol;
      }
    } catch(e) {
      console.log("Error in calculateServiceCostsString")
      console.log(e)
      console.log(element)
      return '-/-';
    }
  };

  $scope.getSubItemTitle = function(item) {
    var subItemId = $scope.options.dataItems.subItemId;
    if ($scope.options.dataItems.subItemGroupNameMapping) { subItemId = $scope.options.dataItems.subItemGroupNameMapping[subItemId]; }
    if (!subItemId) { subItemId = $scope.options.dataItems.subItemId; }
    return item[subItemId] ? item[subItemId] : item[$scope.options.dataItems.subItemId];
  };

  $scope.executeAction = function(actionItem) {
    $scope.executeDashboardAction(actionItem.actionOperation, { data: $scope.groupedSpendingData, period: $scope.selectedPeriod}).then(function() {
      renderDataByGroupAndPeriod();
    })
  };

  // refreshes the widgte content
  $scope.refreshWidget = function() {
    renderDataByGroupAndPeriod();
  };

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // register all datasources
  $eaWidgetGroupFilterListViewDataSources.registerDataSources($scope);

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();
});
