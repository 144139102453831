'use strict';

angular.module('azureCostsFeApp').controller('EaTeamLinkAADCtrl', function ($scope, $location, $stateParams, $eaConfig, $window, $state, $busy, $eaBackend, $eaCurrentSession) {

  function linkAADInternal(instanceType) {
    // redirect to our backend
    var redirectUri = $location.absUrl().split('?')[0];
    $window.location =  ($eaConfig.aadProtocol ? $eaConfig.aadProtocol: 'https') + '://' + $eaConfig.aadHost + '/aad/link/' + instanceType + '?redirect=' + encodeURIComponent(redirectUri);
  }

  // show the link UI
  $scope.callback = false;

  // check if we have a tid, if so create the AAD settings and
  // redirect to edit state
  // $state.go('authorized.teamsManageAADEdit');
  if ($stateParams.tid) {

    // show nothing
    $scope.callback = true;

    // load the team config
    $busy.setMessage('Saving team settings');

    $busy.during($eaBackend.dataRegisterAADConnection($stateParams.teamid, {TenantId: $stateParams.tid, RequiredGroup: null, DefaultIdentityProvider: false, CloudInstance: $stateParams.scope }, $eaCurrentSession.DataToken).then(function(teamProfile) {

      // navigate to the edit teams section
      $state.go('authorized.teamsManageAADEdit', { teamid: $stateParams.teamid});

    }).catch(function() {
      $scope.errorMessage = "Failed to connect to Azure Active Directory";
    }));

  } else {

    // starts the link process to AAD
    $scope.linkAAD = function () {
      linkAADInternal('graph');
    };

    // starts the link process to AAD
    $scope.linkAADGer = function () {
      linkAADInternal('graphger');
    }

  }
});

