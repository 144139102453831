'use strict';

angular.module('azureCostsFeApp').controller('AcCspGsiCtrl', function ($scope, $state, $eaCspService, $eaCurrentSession, $busy, $q, $controller, $modal, $toolbar, $eaBackendDataModeSelector, $eaSession2, $eaWidgetDashboardManager, ngToast, $eaBackend, $eaBackendAzureV2, $timeout, $window, $stateParams, $acTimelineGenerator) {

  $.extend(self, $controller('GsiUpdateSettingsCtrlMixin', {$scope: $scope, $modal: $modal}));

  $scope.dashboardEditMode = false;
  $scope.activeDashboard = null;
  $scope.dataToken = $eaCurrentSession.DataToken;

  $scope.toggelEditMode = function() {
    $scope.dashboardEditMode = !$scope.dashboardEditMode;
  };

  // update the navbar menu
  $scope.updateControllerNavBarMenu = function() {

    // Add csp button
    $toolbar.addItem('gsi.switchToCsp', 'Cloud Solution Providers Portal', 'fa-map-marker', 'Visit the portal for Cloud Solution Providers', true, function() {
      $state.go('authorized.csp.accounts', {resellerId: $stateParams.resellerId});
    });

    // Open the contract settings dialog
    $toolbar.addItem('gsi.openSettings', 'Settings', 'fa-cog', "Allows to edit the System Integrator Account", true, $scope.openEditGsiSettingsDialog);

    // Reload operation
    $toolbar.addItem('gsi.performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadData);
  };

  var dashboardId = 'gsioverview';

  $scope.activeDashboard = {
    Id: dashboardId,
    Profile: $scope.Profile,
    Team: $scope.Profile.Id,
    ContractId: 'GSI:' + $scope.Profile.Id,
    Collection: 'gsi.v1',
    Layout: 'v1'
  };

  $scope.reloadData = function () {
    $eaWidgetDashboardManager.reload($scope.activeDashboard.Id);
  };

  $scope.openEditGsiSettingsDialog = function()
  {
    // load the SI
    return $eaBackend.dataCspGetGsiSettings($scope.Profile.Id, $eaCurrentSession.DataToken).then(function(settings) {

      // open dialog
      return $scope.openGsiUpdateSettingsDialog({ReferenceCurrency: settings.referenceCurrency}).then(function(settingsResult) {

        // Update
        return $eaBackend.dataCspSetGsiSettings($scope.Profile.Id, settingsResult.account.ReferenceCurrency, $eaCurrentSession.DataToken).then(function() {

          // reload
          $scope.reloadData();
        })
      });
    })
  };

  // update the menu navbar
  $scope.updateControllerNavBarMenu();
});
