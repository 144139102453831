'use strict';

angular.module('azureCostsFeApp').controller('TeamsChooseContractMixinCtrl', function($scope, $modal, $controller, $eaUserProfile) {

  // Re-Using the object picker
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal}));

  $scope.openContractChooser = function(profile, options) {
    options = options || {};

    var availableContracts = $eaUserProfile.getContracts(profile, options.team).toArray();


    // prepare the callbacks
    var getImageUrl = function(model) {
        return 'fa-user';
    };

    var getDisplayName = function(model) {
      if (model.Definition === 'EA') {
        return 'EA-' + model.Enrollment;
      } else {
        return model.Name;
      }
    };

    var getDetailsString = function(model) {
      if (model.Definition === 'EA') {
        return 'Microsoft Enterprise Agreement';
      } else {
        return 'Microsoft Rate Plan or Pay-as-You-Go Plan';
      }
    };

    var description = 'Please select the contract you want to define notifications for this user:';

    return $scope.openObjectPickerDialog('Choose a contract', description, getImageUrl, getDisplayName, getDetailsString, availableContracts, [availableContracts[0]], true);
  };
});
