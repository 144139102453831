'use strict';

angular.module('azureCostsFeApp').controller('CspUpdateAWSAccountCtrlMixin', function($scope, $modal) {

  $scope.openCspUpdateAWSAccountDialog = function(exitingAccountModel) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_update_aws_account.html",
      controller: 'ModalCspUpdateAWSAccountCtrl',
      resolve: {
        AccountModel: function() {
          return exitingAccountModel ? exitingAccountModel : {};
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
