'use strict';

angular.module('azureCostsFeApp').controller('GsiUpdateSettingsCtrlMixin', function($scope, $modal) {

  $scope.openGsiUpdateSettingsDialog = function(existingGSIAccountModel) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_gsi_update_settings.html",
      controller: 'ModalGsiUpdateSettingsCtrl',
      resolve: {
        SystemIntegratorAccountModel: function() {
          return existingGSIAccountModel;
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
