'use strict';

angular.module('azureCostsFeApp').service('$eaDataJoinOperations', function() {
  var self = this;

  function isObject(val) {
    if (val === null) { return false;}
    return ( (typeof val === 'function') || (typeof val === 'object') );
  }

  /*
   * This operation joins the leftData with the rightData and is able
   * to build new object whcih contains the different value of two properties with the same name
   */
  self.join = function(dataArrays, objectIdentifier, objectonizePropertiesWithKey) {

    // some checks
    if (!dataArrays || dataArrays.length === 0) { return []; }

    // our caches
    var groupedDataArrays = {};
    var flatDataArray = [];

    // visit every data array
    var dataArrayIndex = 0;
    dataArrays.forEach(function(dataArray) {

      // get the index
      dataArrayIndex = dataArrays.indexOf(dataArray);

      // visit every element
      dataArray.forEach(function(dataElement) {

        // ensure the dataElement contains the objectIdentifier
        var objIdentifierValue = dataElement[objectIdentifier];
        if (!objIdentifierValue) { return; }

        // check what we need to do, just add the element or merge into
        var objectClone = null;
        if (!groupedDataArrays[objIdentifierValue]) {

          // generate the clone
          objectClone = JSON.parse(JSON.stringify(dataElement));
          groupedDataArrays[objIdentifierValue] = objectClone;
          flatDataArray.push(objectClone);

          // add the initial marker
          if (dataArrayIndex === 0) {
            /*jshint -W069 */
            objectClone['PartOfInitialArray'] = true;
          } else {
            /*jshint -W069 */
            objectClone['PartOfInitialArray'] = false;
          }

        } else {
          objectClone = groupedDataArrays[objIdentifierValue];
        }

        // just handle the objectonize-Properties
        if (objectonizePropertiesWithKey) {

          Object.keys(objectonizePropertiesWithKey).forEach(function(tokenizeKey) {

            // get the right sub key
            var tokenizeSubKey = dataElement[objectonizePropertiesWithKey[tokenizeKey]];

            // read the value from our data element
            var tokenizeValue = dataElement[tokenizeKey];

            // extend the key
            tokenizeKey = tokenizeKey + 'Aggregated';

            // read the existing property
            var targetProperty = objectClone[tokenizeKey];

            // generate the object
            if (!isObject(targetProperty)) {
              objectClone[tokenizeKey] = {};
              targetProperty = objectClone[tokenizeKey];
            }

            // assign the value
            targetProperty[tokenizeSubKey] = tokenizeValue;
          });
        }
      });

    });

    // done
    return flatDataArray;
  };
});
