'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceTeaming', function($acDataSourceSettings, $acDataSourceClasses, $q, $eaBackend) {
  var self = this;

  function loadTeamMembership(teamid, contract, dataToken /*, className, currentHashKey, optionalParams */) {
    return $eaBackend.dataGetTeamProfile(teamid, true, dataToken).then(function(loadedTeamProfile) {

      var models = [];

      if (loadedTeamProfile.Members !== null && loadedTeamProfile.Members !== undefined) {
        loadedTeamProfile.Members.forEach(function (member) {
          var memberModel = new $acDataSourceClasses.TeamMembershipEntryClass();
          memberModel.TeamId = loadedTeamProfile.Id;
          memberModel.UserId = member.UserId;
          memberModel.FirstName = member.FirstName;
          memberModel.LastName = member.LastName;
          memberModel.Contact = member.Contact;
          memberModel.LastLogin = member.LastLogin;
          memberModel.LoginCount = member.Logins;
          memberModel.Role = member.Role;
          memberModel.SignUp = member.SignUp;
          memberModel.SubscriptionPermissions = member.Subscriptions;
          memberModel.LiveId = member.LiveId;
          memberModel.Type = member.Type;
          models.push(memberModel);
        });
      }

      return models;
    });
  }

  // called from our infrastructure to the define the different
  // datasources this service handles
  self.registerDataSources = function(dataSourceManager) {

    // Allows to load the membership
    dataSourceManager.registerDataSource('global', 'TeamMembershipEntryClass', null, loadTeamMembership);

  };
});
