'use strict';

/*
 * This service allows to send messages to the frontend when ever the data processing
 * takes a while
 */
angular.module('azureCostsFeApp').service('$acDpnService', function($rootScope) {
  var self = this;

  var processingItems = [];

  self.addProcessingItem = function(itemId)
  {
    // add the item
    if (processingItems.indexOf(itemId) === -1) {
      processingItems.push(itemId);
    }

    // update the listener
    $rootScope.$emit('acDpnService.processingItemAdded', itemId);
  };

  self.removeProcessingItem = function(itemId)
  {
    var idx = processingItems.indexOf(itemId);

    if (idx > -1) {
      processingItems.splice(idx, 1);
    }

    // update the listener
    $rootScope.$emit('acDpnService.processingItemRemoved', itemId);

    if (processingItems.length === 0)
    {
      // update the listener
      $rootScope.$emit('acDpnService.processingFinished', itemId);
    }
  };

  self.onProcessingItemsChanged = function(cb)
  {
    $rootScope.$on('acDpnService.processingItemAdded', function (event, data) {
      cb(processingItems);
    });

    $rootScope.$on('acDpnService.processingItemRemoved', function (event, data) {
      cb(processingItems);
    });
  };

  self.onProcessingFinished = function(cb)
  {
    $rootScope.$on('acDpnService.processingFinished', function (event, data) {
      cb();
    });
  };
});
