'use strict';

angular.module('azureCostsFeApp').controller('ModalAdminAddUserCtrl', function($scope, $modalInstance) {

  $scope.newUser= {
    upn: undefined
  };

  $scope.ok = function () {
    $modalInstance.close({ user: $scope.newUser});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel");
  };

});



