'use strict';

/*
 * This widget is able to render a gauge by a give property. The value of this property will aggregated with one of the
 * following aggregate function:
 *
 * - sum
 * - count
 * - avg
 *
 * Beside this the widget accepts the following additional options:
 *
 * - title  - The title of the widget
 * - min    - The min value
 * - max    - The max value
 * - stops  - The different stops incl. color codes
 * - filter - Check the general filter documentation
 *
 * The following JSON structure needs to be applied to the dashboards overview
 *
 * options: {
 *    filter: [],
 *    value: {
 *      property: 'spendings.Costs',
 *      func: 'sum||count||avg',
 *      max: 56575,
 *      min: 0,
 *      stops: [ [ stopValue, color], ... ]
 *    }
 * }
 *
 */
angular.module('azureCostsFeApp').directive('eaWidgetChartGauge', function() {
  return {
    restrict: 'C',
    template: '<div id=\"chart-container-{{uuid}}\"></div>',
    controller: 'EaWidgetChartGaugeCtrl'
  };
}).controller('EaWidgetChartGaugeCtrl', ['$scope', '$eaChartConfig', '$eaDataCoordinator', '$eaDataAggregateOperations', function($scope, $eaChartConfig, $eaDataCoordinator, $eaDataAggregateOperations) {

  // store the chart element
  var chartElement = null;

  function buildDashboard() {

    // check if we are active or not
    if (!$scope.isActive()) { return; }

    // switch on the loading mode
    $scope.setLoading(true);

    // load the data of the specific property by filter
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.value.class, {maxPeriods: $scope.maxPeriods}).then(function(data) {

      // apply the aggregate function to the data to get the single value
      var aggregatedValue = $eaDataAggregateOperations.execute($scope.options.value.func, data, $scope.options.value.property);

      // the highcharts container
      var containerId = 'chart-container-' + $scope.uuid;

      var gaugeOptions = $eaChartConfig.createGaugeConfig();
      gaugeOptions.chart.renderTo = containerId;
      gaugeOptions.yAxis.min = $scope.options.value.min;
      gaugeOptions.yAxis.max = $scope.options.value.max;
      gaugeOptions.yAxis.stops = $scope.options.value.stops;

      gaugeOptions.series.push({name: $scope.title, data: [aggregatedValue]});

      // check if we are active before painting to prevent exceptions
      if (!$scope.isActive()) { return; }

      chartElement = new Highcharts.Chart(gaugeOptions);
      $scope.requestResize();

      // switch off the loading mode
      $scope.setLoading(false);

    });
  }

  // establish the resize callback
  $scope.onResize(function(newSize) {
    if ( chartElement) { chartElement.setSize(newSize.w, newSize.h, false); }
  });

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

}]);
