'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgSpendingsSubscriptionTemplate', function() {
  return {
    restrict: 'C',
    template: '<div class="subscriptionColorBox" ng-style="{ \'background-color\': groupData.GroupColor }"></div>' +
    '<div ng-if="!pgSpendingsSubscriptionsTemplateScope.allowPrimaryContact" class="subscriptionNameBox">{{groupData.PrimaryGroupName}}</div>' +
    '<div ng-if="pgSpendingsSubscriptionsTemplateScope.allowPrimaryContact" class="subscriptionNameBox">' +
      '<p style="line-height: 13px; padding: 0; margin: 0; margin-top: 3px; margin-bottom: 3px;">{{groupData.PrimaryGroupName}}</p>' +
      '<p style="font-size: 10px; line-height: 10px; padding: 0; margin: 0;">' +
      '  Contacts:&nbsp;' +
      '  <a ng-repeat="groupContact in groupData.Contacts" href="mailto:{{groupContact.Contact}}"  ng-click="$event.stopPropagation();">{{groupContact.DisplayName}}{{$parent.groupData.Contacts.indexOf(groupContact) === $parent.groupData.Contacts.length - 1 ? \'&nbsp;\' : \',&nbsp;\'}}</a>' +
      '  <a href="" ng-click="pgSpendingsSubscriptionsTemplate.editPrimaryContacts(groupData)"><i class="fa fa-edit"></i></a>' +
      '</p>' +
    '</div>' +
    '<div class="clearfix"></div>',
    scope: false,
    controller: 'EaWidgetGroupFilterListViewPgSpendingsSubscriptionTemplateCtrl'
  };
}).controller('EaWidgetGroupFilterListViewPgSpendingsSubscriptionTemplateCtrl', function($scope, $element, $controller, $modal, $q, gravatarService, $eaDataCoordinator, $eaBackend, $eaSession2, $eaBilling) {

  // include the required mixin
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('TeamManagementCtrlMixin', {$scope: $scope, $eaBackend: $eaBackend, $q: $q, $eaSession2: $eaSession2}));

  $scope.pgSpendingsSubscriptionsTemplateScope = {
    teamProfileMembers: [],
    allowPrimaryContact: ($scope.profiles.team.Id !== 'personal' && ($scope.profiles.team.Role == 'admin') && $eaBilling.hasRequiredPlan($scope.profiles.team.AccountPlan, 'enterprise'))
  };

  // check if we are allows to handle primary contacts
  if ($scope.pgSpendingsSubscriptionsTemplateScope.allowPrimaryContact) {

    // at this point start loading the group members should we are the admin
    $eaDataCoordinator.query($scope.team, $scope.contract, $scope.token, 'TeamMembershipEntryClass', null).then(function (teamMembers) {

      // cache
      $scope.pgSpendingsSubscriptionsTemplateScope.teamProfileMembers = teamMembers;

      // build the teammeber Index
      var teamMembersIndex = teamMembers.buildIndex('UserId');

      // load all the subscriptions
      return $eaDataCoordinator.query($scope.team, $scope.contract, $scope.token, 'SubscriptionEntryClass', null).then(function (subscriptions) {

        // build subscription index
        var subscriptionsIndex = subscriptions.buildIndex('SubscriptionId');

        // get the right contacts
        var subscription = subscriptionsIndex[$scope.groupData.PrimaryGroupId];
        if (!subscription) {
          return;
        }

        // assign the contacts
        $scope.groupData.Contacts = subscription.Contacts.map(function (c) {

          var user = teamMembersIndex[c];
          if (!user) {
            return {};
          }

          return {
            UserId: c,
            Contact: user.Contact,
            DisplayName: user.FirstName + ' ' + user.LastName
          };
        });
      })
    });
  }

  $scope.pgSpendingsSubscriptionsTemplate = {

    editPrimaryContacts: function (groupModel) {

      // var index groupContacts
      var lookupExistingContacts = groupModel.Contacts.buildIndex('UserId');

      // de-couple the groupModel
      var contacts = JSON.parse(JSON.stringify($scope.pgSpendingsSubscriptionsTemplateScope.teamProfileMembers)).filter(function (c) {
        return (c.Type !== 'app-only');
      });

      var existingContacts = [];
      contacts.forEach(function (c) {
        if (lookupExistingContacts[c.UserId]) {
          existingContacts.push(c);
        }
      });

      // description
      var description = 'Please select the users who should become responsible contacts for the subscription <b>' + groupModel.PrimaryGroupName + '</b>. This helps to contact the right person to clarify unexpected spending changes.';

      var getDisplayName = function (model) {
        if ($scope.isUserPseudoUser(model)) {
          return model.Contact;
        } else {
          return model.FirstName + ' ' + model.LastName;
        }
      };

      var getDetailsString = function (model) {
        if ($scope.isUserPseudoUser(model)) {
          return 'Invitation pending';
        } else {
          return model.Contact;
        }
      };

      var getImageUrl = function (model) {
        return gravatarService.url(model.Contact, {size: 32, default: 'mm'});
      };

      $scope.openObjectPickerDialog('Select responsible user', description, getImageUrl, getDisplayName, getDetailsString, contacts, existingContacts).then(function (selectedUsers) {


        // geenerate the userIdList
        var userIdList = selectedUsers.map(function (user) {
          return user.UserId;
        });

        // update the contacts in the network
        $eaBackend.dataPutContractSubscription($scope.team, $scope.contract, groupModel.PrimaryGroupId, {Contacts: userIdList}, $scope.token).then(function () {

          // update the cache
          $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SubscriptionEntryClass', ['SubscriptionId'], [groupModel.PrimaryGroupId], 'Contacts', userIdList, 'set');

          // rerender
          $scope.refreshWidget();
        })
      })
    }
  };
});
