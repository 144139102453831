'use strict';

angular.module('azureCostsFeApp').controller('EaWidgetGroupFilterListViewFiltersMixin', function($scope, $busy, $eaBackend, $eaDataCoordinator, $eaFilterPanel, $q) {

  // Filter Handing
  $scope.resetFilter = function() {
    $scope.activeFilter = null;
    $scope.rawItemsFiltered = [];
    $scope.refreshWidget();
  };

  $scope.applyFilter = function(filter) {
    $scope.activeFilter = filter;
    $scope.rawItemsFiltered = [];
    $scope.refreshWidget();
  };

  $scope.removeFilter = function(filter, force) {
    // ask for removal
    var deleteOk = force;
    deleteOk = deleteOk || confirm('Do you really want to remove the filter: ' + (filter && filter.Name ? filter.Name : 'unknown') );

    // check
    if (deleteOk) {

      // remove from backend
      $busy.during($eaBackend.dataRemoveDataFilter(filter.TeamId, $scope.contract, filter.Id, $scope.token).then(function() {

        // remove from cached data
        $eaDataCoordinator.removeModels($scope.team, $scope.contract, 'SpendingFilterClass', ['Id'], [filter.Id]);

        return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, 'SpendingFilterClass', null).then(function (dataFilters) {
          $scope.availableFilters = dataFilters;
        });
      }));
    }
  };

  $scope.createFilter = function(filter) {
    $scope.activeFilter = $eaFilterPanel.buildFilter($scope.userProfile.UserId);
  };

  $scope.saveFilter = function(teamFilter) {

    // check if it's a new filter or if we need to update the filter
    if (!$scope.activeFilter.Id) {

      // create the filter in the server
      return $busy.during($eaBackend.dataCreateDataFilter(teamFilter ? $scope.team : 'personal', $scope.contract, $scope.activeFilter, $scope.token).then(function(result) {

        // assign the new id
        $scope.activeFilter.Id = result.FilterId;

        // add the filter to the UI
        $eaDataCoordinator.addModels($scope.team, $scope.contract, 'SpendingFilterClass', [$scope.activeFilter]);

        // apply the filter
        $scope.applyFilter($scope.activeFilter);
      }));

    } else {

      // update the filter in storage
      return $busy.during($eaBackend.dataUpdateDataFilter(teamFilter ? $scope.team : 'personal', $scope.contract, $scope.activeFilter, $scope.token)).then(function() {

        // update the ui model
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingFilterClass', ['Id'], [$scope.activeFilter.Id], ['Name', 'Rules', 'Owner', 'TeamId'], [$scope.activeFilter.Name, $scope.activeFilter.Rules, $scope.activeFilter.Owner, $scope.activeFilter.TeamId]);

        // ensure the filter is active
        $scope.applyFilter($scope.activeFilter);
      });
    }
  };

  $scope.shareFilter = function() {
    // save the personal filter
    $busy.during($scope.saveFilter(false).then(function() {

      // remove the ID
      var oldFilterId = $scope.activeFilter.Id;
      $scope.activeFilter.Id = null;

      // store the filter again for the team
      return $scope.saveFilter(true).then(function() {
        $scope.activeFilter.TeamId = $scope.team;
        return $scope.removeFilter({Id: oldFilterId, TeamId: 'personal'}, true);
      });

    }));
  };

  $scope.unShareFilter = function() {
    // save the team filter
    $busy.during($scope.saveFilter(true).then(function() {

      // remove the ID
      var oldFilterId = $scope.activeFilter.Id;
      $scope.activeFilter.Id = null;

      // store the filter again for the team
      return $scope.saveFilter(false).then(function() {
        $scope.activeFilter.TeamId = 'personal';
        return $scope.removeFilter({Id: oldFilterId, TeamId: $scope.team}, true);
      })
    }))
  };

});
