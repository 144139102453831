'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02EACtrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession, jwtHelper) {

  $scope.availableCustomerMappings = ['CostCenter', 'Department', 'Subscription', 'ResourceGroup', 'Account'];

  $scope.formAddToken = {
    name: null,
    ea: null,
    eaType: null,
    token: null,
    currency: null,
    customermapping: $scope.availableCustomerMappings[0]
  };

  $scope.errorRaised = false;


  $scope.next = function()
  {
    // reset the error
    $scope.errorRaised = false;


    // register the account
    return $busy.during($eaCspService.registerAccountEA($scope.Profile, $eaCurrentSession, $scope.formAddToken.name, $scope.formAddToken.currency, $scope.formAddToken.token, $scope.formAddToken.customermapping).then(function() {
      $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
    })).catch(function() {
      $scope.errorRaised = true;
    })
  };

  $scope.tokenFieldChanged = function() {

    try {
      // read the token from the input field
      var decodedToken = jwtHelper.decodeToken($scope.formAddToken.token);

      // check if we have an enrollment number, if so add
      if (decodedToken.EnrollmentNumber !== undefined) {
        $scope.formAddToken.ea = decodedToken.EnrollmentNumber;
      } else {
        $scope.formAddToken.ea = null;
      }

      // check what type the contract is
      if (decodedToken.ReportView !== undefined) {
        $scope.formAddToken.eaType = decodedToken.ReportView;
      } else {
        $scope.formAddToken.eaType = null;
      }
    } catch(e) {
      $scope.formAddToken.ea = null;
      $scope.formAddToken.eaType = null;
    }
  };
});
