'use strict';

angular.module('azureCostsFeApp').controller('EaTeamManageCtrl', function ($scope, $eaSession2, $eaBackend, $busy, $stateParams, $controller, $modal, $q, $eaConfig,$toolbar, $location,$eaUserProfile, $eaCurrentSession, rfc4122, ngToast, $eaReportData) {

  $.extend(self, $controller('TeamEditUsersCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('TeamEditAppCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('TeamEditPermissionsCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('TeamsChooseContractMixinCtrl', {$scope: $scope, $modal: $modal, $controller: $controller, $eaUserProfile: $eaUserProfile }));
  $.extend(self, $controller('TeamManagementCtrlMixin', {$scope: $scope, $eaBackend: $eaBackend, $q: $q }));
  $.extend(self, $controller('NotificationSettingsCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('ModalSingleLineBoxCtrlMixin', {$scope: $scope, $modal: $modal }));

  $scope.sessionLoaded  = false;
  $scope.teamProfile    = undefined;
  $scope.teamLogo       = undefined;
  $scope.teamAADConnection = undefined;
  $scope.availableRoles = [ { display: 'Member', value: 'member' }, { display: 'Administrator', value: 'admin' }];
  $scope.bucketsEnabled = $eaConfig.features.buckets;

  // generate the callback uri
  var splittedUrl = $location.absUrl().split('/');
  var callbackUri = splittedUrl[0] + '//' + splittedUrl[2] + '/app/teams/invitation/accept';

  $scope.getDisplayName = function(user) {
    var userName;
    if (user.FirstName) { userName = user.FirstName + ' '; } else { userName = ''; }
    if (user.LastName) { userName = userName + user.LastName; };
    if (!userName) { userName = user.Contact; }

    return userName;
  };

  // allows to add a user
  $scope.addUser = function() {

      var existingUsers = $scope.teamProfile.Members.filter(function(user) {
        return user.Type !== 'app-only'
      });

      $scope.openTeamEditUserDialog(existingUsers, $scope.teamProfile.CloudSolutionProvider).then(function(result) {

          // wait for operations
          var operationPromisses= [];

          // removing all removed users
          result.removedUsers.forEach(function(user) {
            operationPromisses.push($scope.deleteUserFromTeam($stateParams.teamid, user, $eaCurrentSession.DataToken));
          });

          // inviting all other users
          result.newUsers.forEach(function(user) {

            if ($scope.hasUserRole(user, 'notification'))
            {
              operationPromisses.push($eaBackend.dataRegisterUserToTeam($stateParams.teamid, user.Contact, user.Role, user.Subscriptions, $eaCurrentSession.DataToken));
            } else {

              var fullAccess = false;

              if (user.Subscriptions && user.Subscriptions['*']) {
                fullAccess = true;
              }

              operationPromisses.push($eaBackend.dataInviteUserToTeamV2($stateParams.teamid, user.Contact, user.Role, fullAccess, callbackUri, $eaCurrentSession.DataToken));
            }
          });

          // send out
          $busy.during($q.all(operationPromisses).finally(function() {
              return $scope.reloadData();
          }));
      })
  };

  $scope.editPermissions = function(user) {

    // load all contracts for this team
    $busy.setMessage('Loading Subscriptions');
    $busy.during($eaBackend.helperGetAvailableSubscriptions($stateParams.teamid, $eaCurrentSession.DataToken)).then(function(availableSubscriptions) {

        $scope.openTeamEditPermissionsDialog(user, availableSubscriptions).then(function (result) {

            $busy.during($eaBackend.dataUpdateTeamMembership($stateParams.teamid, user.UserId, user.Role, result, undefined, $eaCurrentSession.DataToken).finally(function() {
              return $scope.reloadData();
            }));
        })
    });
  };

  $scope.editNotifications = function(user) {

    // ensure we have a fresh profile
    return $eaUserProfile.load($eaCurrentSession.DataToken, true).then(function(profile) {

      // show the select contract dialog
      return $scope.openContractChooser(profile, {team: $scope.teamProfile.Id}).then(function(selectedContract) {

        // load some contract information
        return $busy.during($eaReportData.loadContractDetails($scope.teamProfile.Id, selectedContract.Enrollment, $eaCurrentSession.DataToken)).then(function(loadedContractInformation) {

          // load the notification settings for the selected contract and user
          return $busy.during($eaBackend.dataGetContractNotifcationSettingsForUser($scope.teamProfile.Id, selectedContract.Enrollment, user.UserId, $eaCurrentSession.DataToken)).then(function (loadedNotificationSettings) {

            // open the select permissions dialog
            $scope.teamProfile.AccountPlan = 'enterprise';
            return $scope.openNotificationSettingsDialog(loadedContractInformation, loadedNotificationSettings, $scope.teamProfile, 'member').then(function (updatedData) {

              // store everything
              return $busy.during($eaBackend.updateGetContractNotifcationSettingsForUser($scope.teamProfile.Id, selectedContract.Enrollment, user.UserId, updatedData.notificationInformation, $eaCurrentSession.DataToken));
            });
          });
        });
      });
    })

  };

  $scope.addApp = function() {
    var newApp = { Name: "", ClientId: rfc4122.v4(), ClientSecret: rfc4122.v4(), Role: "member", WildCardSubscriptions: true };
    $scope.openTeamEditAppDialog(newApp).then(function(appModel) {
      var subscriptions = appModel.WildCardSubscriptions ? { '*': ['*'] } : {};
      return $busy.during($eaBackend.dataRegisterAppToTeam($stateParams.teamid, appModel.Name, appModel.ClientId, appModel.ClientSecret, appModel.Role, subscriptions, $eaCurrentSession.DataToken).finally(function() {
        return $scope.reloadData();
      }));
    })
  };

  $scope.deleteUserFromTeamEx = function(user) {

      var deleteOk = confirm("Do you really want to remove user " + $scope.getDisplayName(user) + ' from this team?');

      if (deleteOk) {

          $busy.setMessage('Removing User');

          $busy.during($scope.deleteUserFromTeam($stateParams.teamid, user, $eaCurrentSession.DataToken).then(function () {

            // just remove from the collection
            var idx = $scope.teamProfile.Members.indexOf(user);
            if (idx != -1) {
                $scope.teamProfile.Members.splice(idx, 1);
            }

          }));
      }
  };

  $scope.resendInvitation = function(user) {

      // build the invitation id
      var invitationId = user.UserId.replace(/PSEUDO:/g,'');

      // reinvite
      $busy.during($eaBackend.dataReInviteUserToTeam($stateParams.teamid, invitationId, callbackUri, $eaCurrentSession.DataToken).then(function() {
        ngToast.create({
          className: 'success',
          content:
          '<strong>Invitation Sent!</strong> The invitation notification was send again to <strong>' + user.Contact + '</strong>'
        });
      }));
  };

  $scope.reloadData = function() {

      // load the user profile to get the information if the user is allowed to manage the team
      return $eaUserProfile.load($eaCurrentSession.DataToken, true).then(function(profile) {

        $scope.activeTeamBilling = $eaUserProfile.getTeam(profile, $stateParams.teamid);

        return $eaBackend.dataGetTeamProfile($stateParams.teamid, true, $eaCurrentSession.DataToken).then(function (teamProfile) {

          return $eaBackend.dataGetTeamImageLocation($stateParams.teamid).then(function (profileImageLocation) {

            $scope.teamProfile = teamProfile;
            $scope.teamLogo = profileImageLocation;

            return $eaBackend.dataGetAADConnections($stateParams.teamid, $eaCurrentSession.DataToken).then(function (connections) {
              if (connections && connections.length > 0) {
                $scope.teamAADConnection = connections[0];
              } else {
                $scope.teamAADConnection = null;
              }
            })
          });
        });
      });
  };

  $scope.reloadDataWithBusy = function() {

      $busy.setMessage("Loading Team...");

      return $busy.during($scope.reloadData());
  };

  $scope.updateTeamName = function(data) {
    return $eaBackend.dataUpdateTeamProfile($stateParams.teamid, { Name: data }, $eaCurrentSession.DataToken).then(function() {
      return true;
    });
  };

  $scope.updateTeamImage = function(image) {
    return $eaBackend.dataUpdateTeamImage($stateParams.teamid, image, $eaCurrentSession.DataToken);
  };

  $scope.linkAAD = function() {
    $location.url('/teams/' + $stateParams.teamid + '/manage/aad/link');
  };

  $scope.editAAD = function() {
    $location.url('/teams/' + $stateParams.teamid + '/manage/aad/edit');
  };

  $scope.openBuckets = function() {
    $location.url('/teams/' + $stateParams.teamid + '/manage/buckets');
  };

  $scope.openSettings = function() {
    $location.url('/teams/' + $stateParams.teamid + '/manage/settings');
  };

  $scope.changeUserRole = function(user) {

    // check if we need to reset teh users subscriptions
    if (user.Role === 'admin') {
      user.Subscriptions = {'*': ['*']};
    }

    $busy.during($eaBackend.dataUpdateTeamMembership($stateParams.teamid, user.UserId, user.Role, user.Subscriptions, undefined, $eaCurrentSession.DataToken).finally(function() {
      return $scope.reloadData();
    }));
  };

  $scope.editContactAddress = function(user) {
    $scope.openSingleLineBox("Update Contact Information", "Please enter a new e-mail address to update the contact information of your team member.", user.Contact).then(function(updatedContact) {
      $busy.during($eaBackend.dataUpdateTeamMembership($stateParams.teamid, user.UserId, undefined, undefined, updatedContact, $eaCurrentSession.DataToken).finally(function() {
        return $scope.reloadData();
      }));
    });
  };

  $scope.getClientId = function(appProfile) {
    return appProfile.Contact.split('@')[0];
  };

  // Add Back Entry
  $toolbar.addItem('manageTeam.Back', 'Back to Reports', 'fa-chevron-left', null, true, '/signin');

  // Sync operation
  $toolbar.addItem('manageTeam.performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadDataWithBusy);


  return $scope.reloadData().then(function() {
      $scope.sessionLoaded  = true;
  });
});
