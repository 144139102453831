'use strict';

angular.module('azureCostsFeApp').controller('TeamsChooseTeamMixinCtrl', function($scope, $modal, $controller, $q, $eaTeamManagement) {

  // Re-Using the object picker
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal}));

  /*
   * Opens a team chooser and allows to select a specific team. Only teams where the user has the defined minimum
   * role will be shown. If only one team is choosable the function does not open a chooser it returns this team.
   * Should no team match the criteria it ends up with an error.
   */
  $scope.openTeamChooser = function(profile, activeTeamId, minimumRoleInTeam, $eaCurrentSession, options) {
    options = options || {};

    // load all the teams
    return $eaTeamManagement.getTeamProfilesByRole(profile, minimumRoleInTeam, false, $eaCurrentSession.DataToken).then(function(teamProfiles) {

      // check if we have no team
      if (!teamProfiles || teamProfiles.length === 0) { return $q.reject(); }

      // check if we have just one team
      if (teamProfiles.length === 1 && !options.allowPersonal) { return $q.when(teamProfiles[0].Id); }

      // add the personal team
      if (options && options.allowPersonal) {
        teamProfiles.push({
          Name: 'Personal',
          Id: 'personal'
        });
      }

      // define the selectedTeamProfile
      var selectedTeamProfile = teamProfiles[0];
      if (activeTeamId) {
        teamProfiles.forEach(function(t) {
          if (t.Id == activeTeamId) { selectedTeamProfile = t; }
        })
      }

      // prepare the callbacks
      var getImageUrl = function(model) {
        if (model.Id === 'personal') {
          return 'fa-user';
        } else {
          return 'fa-users';
        }
      };

      var getDisplayName = function(model) {
        return model.Name;
      };

      var getDetailsString = function(model) {
        if (model.Id === 'personal') {
          return 'This is your personal account';
        } else if (model.Role === 'admin') {
          return 'You are currently an administrator of this team';
        } else {
          return 'You are part of this team as a normal user';
        }
      };

      var description = 'Currently you are a member of several different teams. Please choose the team you want to perform the operation:';

      return $scope.openObjectPickerDialog('Choose a team', description, getImageUrl, getDisplayName, getDetailsString, teamProfiles, [selectedTeamProfile], true).then(function(selectedTeamProfile) {
        return selectedTeamProfile.Id;
      })
    });
  };
});
