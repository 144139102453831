'use strict';

angular.module('azureCostsFeApp').controller('BrandingCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openBrandingDialog = function(session, team) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/dashboards/modals/modal_ea_branding.html",
      controller: 'ModalBrandingCtrl',
      resolve: {
        session: function() {
          return session;
        },
        team: function() {
          return team;
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
