'use strict';

/*
 * This service implements the API to the session behaviour of the application. In controllers
 * the application should use lazy() to ensure a session is available. There are some event handlers
 *
 * onUpdate   -> Is triggered when the session object is updated or created. All session plugins should register for this
 * onRegister -> Is triggered when the user was registered newly in the system.
 */
angular.module('azureCostsFeApp').service('$eaSession2', function($location, $q, $eaBackend, $eaBackendDataModeSelector, $eaConfig, jwtHelper, $window, $state, $eaBucket, rfc4122) {

  function SessionModel(sessionData) {
    var selfSessionModel = this;

    selfSessionModel.UserId = sessionData.UserId;
    selfSessionModel.FirstName = sessionData.FirstName;
    selfSessionModel.LastName = sessionData.LastName;
    selfSessionModel.IdentityProvider = sessionData.IdentityProvider;
    selfSessionModel.IdentityProviderId = sessionData.IdentityProviderId;
    selfSessionModel.IdentityProviderToken = sessionData.IdentityProviderToken;
    selfSessionModel.Contact = sessionData.Contact;
    selfSessionModel.Avatar = sessionData.Avatar;
    selfSessionModel.DataToken = sessionData.DataToken;
    selfSessionModel.LoginCount = sessionData.LoginCount;
    selfSessionModel.Surveys = sessionData.Surveys;
    selfSessionModel.ActAs = sessionData.ActAs ? sessionData.ActAs : false;

    selfSessionModel.isActAsSession = function() {
      return selfSessionModel.ActAs;
    };

    selfSessionModel.getServiceProviderId = function() {
      if (!selfSessionModel.DataToken) {
        return 'default';
      }

      try {
        var decodedToken = jwtHelper.decodeToken(selfSessionModel.DataToken);
        if (!decodedToken ||!decodedToken.svp) {
          return 'default';
        } else {
          return decodedToken.svp;
        }
      } catch(error) {
        return 'default';
      }
    };

    selfSessionModel.hasCap = function(cap) {
      if (!selfSessionModel.DataToken) {
        return false;
      }

      try {
        var decodedToken = jwtHelper.decodeToken(selfSessionModel.DataToken);

        // no caps return false
        if (!decodedToken.cap ||decodedToken.cap.length == 0) {
          return false;
        }

        // check
        return (decodedToken.cap.indexOf(cap) !== -1);
      } catch(error) {
        return false;
      }
    };

    selfSessionModel.hasClaim = function(claim) {
      return selfSessionModel.hasClaims([claim]);
    };

    selfSessionModel.hasClaims = function(claims) {
      if (!selfSessionModel.DataToken) {
        return false;
      }

      if (!claims || claims.length === 0) {
        return true;
      }

      try {
        var decodedToken = jwtHelper.decodeToken(selfSessionModel.DataToken);

        // currently a permission means the attribute is true in the token
        var claimMissing = false;
        claims.every(function(claim) {
          if (!decodedToken[claim]) {
            claimMissing = true;
            return false;
          } else {
            return true;
          }
        });

        return !claimMissing;
      } catch(error) {
        return false;
      }
    };

    selfSessionModel.isCapabilityActivated = function(cap) {
      var decodedToken = jwtHelper.decodeToken(selfSessionModel.DataToken);
      if (!decodedToken || !decodedToken.cap || decodedToken.cap.length === 0) {
        return false;
      } else {
        return (decodedToken.cap.indexOf(cap) !== -1);
      }
    };
  }

  var self = this;
  var activeSession = null;
  var actAsModeEanbled = false;

  var eventCallBacks = {
    onUpdate: [],
    onRegister: [],
    onDestroying: [],
    onDestroyed: []
  };

  self.onUpdate = function(onUpdateCallback) {
    eventCallBacks.onUpdate.push(onUpdateCallback);
  };

  self.onRegister = function(onUpdateCallback) {
    eventCallBacks.onRegister.push(onUpdateCallback);
  };

  self.onDestroying = function(onUpdateCallback) {
    eventCallBacks.onDestroying.push(onUpdateCallback);
  };

  self.onDestroyed = function(onUpdateCallback) {
    eventCallBacks.onDestroyed.push(onUpdateCallback);
  };

  function triggerEvent(eventName, param) {
    var eventCallbackCollection = eventCallBacks[eventName];
    if (eventCallbackCollection) {
      eventCallbackCollection.forEach(function(evcb) {
        evcb(param);
      });
    }
  }

  self.lazy = function(optionalRequiredPermissions) {
    var defer = $q.defer();

    // try to load the session from cache, if so we have a page reload and we need to send the update
    // event as well
    var cachedSessionModel = JSON.parse(sessionStorage.getItem('com.azure-costs.session'));
    if (!activeSession && cachedSessionModel) {
      activeSession = new SessionModel(cachedSessionModel);
      triggerEvent('onUpdate', activeSession);
    }

    // check if we have an active session
    if (!activeSession) {

      // check if we have a prefix
      var bucketLookupPromise = $q.when(undefined);
      if ($eaBucket.hashHostPrefix()) {

        // try to resolve the bucket prefix into a team id
        bucketLookupPromise = $eaBucket.getTeamIdFromHostSegment().then(function (teamId) {

          // try to load the auth information
          return $eaBackend.dataGetAuthInformation(teamId).then(function (authInfo) {

            // check if we need to redirect to AAD automatically, if not
            // we just render the idps
            var authProvider;
            if (authInfo.aad && authInfo.aad.DefaultIdentityProvider) {
              authProvider = 'aad';
            }

            // done
            return $q.when(authProvider);
          });
        }).catch(function() {
          return $q.when(undefined);
        });
      }

      // wait for the bucket lookup
      bucketLookupPromise.then(function(bucketIdp) {

        // ok we need to create one which means a redirect to our Idp integration
        console.log('BucketIdp:' + bucketIdp);

        // build the oauth2 url
        var oAuth2State = encodeURIComponent($location.$$absUrl);
        var oAuth2Callback = encodeURIComponent($eaConfig.stsRedirectUri);

        // handle idp parameter to force it
        var idp = $location.search().idp;
        if (idp === 'offline') {
          // go to our callback directly
          /*jshint camelcase: false */
          return $state.go('ssoCallback', {access_token: 'offline', state: oAuth2State});
        } else {
          // attach an idp parameter to our sts if required
          var idpState = '';
          if (idp !== undefined && idp !== null) {
            idpState = '&idp=' + idp;
          } else if (bucketIdp !== undefined && bucketIdp !== null) {
            idpState = '&idp=' + bucketIdp;
          }

          // visit our sts
          $window.location = ($eaConfig.stsProtocol ? $eaConfig.stsProtocol : 'https') + '://' + $eaConfig.stsHost + '/sts/issue/oauth/authorize?client_id=' + $eaConfig.stsClientId + '&response_type=token&redirect_uri=' + oAuth2Callback + '&state=' + oAuth2State + idpState;
        }
      });
    } else {

      // check if we have specific permissions
      if (activeSession.hasClaims(optionalRequiredPermissions)) {
        // return the session which was generated from the idp integration
        defer.resolve(activeSession);
      } else {
        defer.reject(new Error('Permission denied'));
      }
    }

    return defer.promise;
  };

  self.destroySession = function() {

    // inform the plugins
    triggerEvent('onDestroying', activeSession);

    // destroy the session
    activeSession = null;

    // destroy the cache
    sessionStorage.removeItem('com.azure-costs.session');

    // inform the plugins
    triggerEvent('onDestroyed', activeSession);
  };

  /*
   * Generates just a session model without interactiving with our backend
   */
  self.generateSession = function(id, firstName, lastName, contact, avatar, loginCount, surveyAtLogin, idpType, idpId, idpToken, dataToken, optionalRegisterHint, optionalActAsMode) {

    // build the session object
    activeSession = new SessionModel({
      UserId: id,
      FirstName: firstName, LastName: lastName, Contact: contact, Avatar: avatar,
      LoginCount: loginCount,
      IdentityProvider: idpType, IdentityProviderId: idpId, IdentityProviderToken: idpToken,
      DataToken: dataToken,
      Surveys: { Influencer: surveyAtLogin},
      ActAs: optionalActAsMode});

    // execute all session plugins by triggering the update event
    if (optionalRegisterHint) {
      triggerEvent('onRegister', activeSession);
    } else {
      triggerEvent('onUpdate', activeSession);
    }

    // cache the active session
    sessionStorage.setItem('com.azure-costs.session', JSON.stringify(activeSession));

    // return the result
    return $q.when(activeSession);
  };

  self.getSession = function() {
    return activeSession;
  };

  self.signOut = function(idp) {

    // get the redirect uri
    var redirectUri = $state.href('public.signin', {}, {absolute: true});
    var idpParam = '';
    if (idp) {
      redirectUri = $state.href('public.signin', {idp: idp}, {absolute: true});
      idpParam = '&idp=' + idp;
    }

    // call the logout endpoint
    $window.location =  ($eaConfig.stsProtocol ? $eaConfig.stsProtocol : 'https') + '://' + $eaConfig.stsHost + '/sts/logout?redirect=' + encodeURIComponent(redirectUri) + idpParam;
  };

  self.leaveActAs = function() {

    // set as the current one
    sessionStorage.setItem('com.azure-costs.session', null);

    // close the browser window
    $window.close();
  };

  self.updateTokenFromSTS = function(currentSessionService, options) {

    // get the current session
    var currentSession = self.getSession();

    // update the token
    return $eaBackend.stsUpdateToken(options, currentSession.DataToken).then(function(updatedToken) {

      // update the token
      /*jshint camelcase: false */
      currentSession.DataToken = updatedToken.access_token;
      /*jshint camelcase: false */
      currentSessionService.DataToken = updatedToken.access_token;

      // cache the active session
      sessionStorage.setItem('com.azure-costs.session', JSON.stringify(currentSession));

      // done
      return $q.when(currentSession);
    });
  };

  self.visitProfileInSTS = function(newTab) {
    var target = ($eaConfig.stsProtocol ? $eaConfig.stsProtocol : 'https') + '://' + $eaConfig.stsHost + '/sts/account';;

    if (newTab) {
      $window.open(target, '_blank');
    } else {
      $window.location = target;
    }
  };

  self.openActAsSession = function(actAsUserId, serviceProviderId) {

    // define the protocol
    var hostProtocol = $eaConfig.stsProtocol ? $eaConfig.stsProtocol : 'https';
    if ($eaConfig.feHost === 'localhost')
      hostProtocol = 'http';

    // build the oauth2 url
    var oAuth2State = encodeURIComponent(($eaConfig.feProtocol ? $eaConfig.feProtocol : 'https') + '://actas-' + sha1(actAsUserId + '.' + rfc4122.v4()) + '.' + $eaConfig.feHost + ($eaConfig.fePort ? ':' + $eaConfig.fePort : '') + '/app');
    var oAuth2Callback = encodeURIComponent($eaConfig.stsRedirectUri);
    var location =
      hostProtocol + '://' +
      $eaConfig.stsHost +
      '/sts/issue/oauth/authorize?client_id=' + $eaConfig.stsClientId +
      '&response_type=token' +
      '&scope=actas;' + actAsUserId + ';sp;' + serviceProviderId +
      '&redirect_uri=' + oAuth2Callback +
      '&state=' + oAuth2State;

    // open the url
    $window.open(location, actAsUserId);
  };

  self.setActAsMode = function() {
    actAsModeEanbled = true;
  }
});
