'use strict';

/*
 * The following JSON structure needs to be applied to the dashboards overview
 *
 * options: {
 *    filter: [],
 *    value: {
 *      property: 'spendings.Costs',
 *      func: 'sum||count||avg',
 *      max: 56575,
 *      min: 0,
 *      stops: [ [ stopValue, color], ... ]
 *    }
 * }
 */
angular.module('azureCostsFeApp').directive('eaWidgetChartArea', function() {
  return {
    restrict: 'C',
    template: '<div id=\"chart-container-{{uuid}}\"></div>',
    controller: 'EaWidgetChartAreaCtrl'
  };
}).controller('EaWidgetChartAreaCtrl', function($scope, $eaChartConfig, $eaDataCoordinator, $eaDataAggregateOperations, $eaDataGroupOperations, $acDataSourceWidgetFunctions) {

  // store the chart element
  var chartElement = null;

  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);


    // load the data of the specific property by filter
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.class, {collection: $scope.options.collection, maxPeriods: $scope.maxPeriods}).then(function(data) {

      // check if we are active or not
      if (!$scope.isActive()) {
        $scope.setLoading(false);
        return;
      }

      // check if we got data
      if (!data) {
        $scope.setLoading(false);
        return;
      }

      // group by series key
      return $eaDataGroupOperations.groupBySingleDimension(data, $scope.options.series).then(function (groupedData) {

        // load the series keys
        var series = [];
        var allOver = 0;

        $acDataSourceWidgetFunctions.callWidgetFunction($scope.options.seriesValues, $scope).forEach(function(element) {

            // check if we have a value for this group
            var value = groupedData[element] ? groupedData[element].elements : null;
            if (value) {
              var y = $eaDataAggregateOperations.execute($scope.options.func, value, $scope.options.value, allOver);
              series.push({ name: element, y: y});
              allOver += $eaDataAggregateOperations.execute('sum', value, $scope.options.value);
            } else {
              series.push({ name: '', y: 0});
            }
        });

        // unit symbol
        var unitSymbol = data && data[0] ? data[0][$scope.options.unit] : null;
        if (!unitSymbol) {
          unitSymbol = '';
        }

        // the highcharts container
        var containerId = 'chart-container-' + $scope.uuid;

        var areaOptions = $eaChartConfig.createAreaConfig();
        areaOptions.chart.renderTo = containerId;
        areaOptions.series.push({ data: series});
        areaOptions.tooltip.pointFormat = $scope.options.valueLabel + ': <b>{point.y:,.2f} ' + unitSymbol + '</b>';

        // check if we are active before painting to prevent exceptions
        if (!$scope.isActive()) { return; }

        chartElement = new Highcharts.Chart(areaOptions);
        $scope.requestResize();

        // switch off the loading mode
        $scope.setLoading(false);
      });
    });
  }

  // establish the resize callback
  $scope.onResize(function(newSize) {
    if ( chartElement) { chartElement.setSize(newSize.w, newSize.h, false); }
  });

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
