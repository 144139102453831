'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgCspResellerT2Template', function() {
  return {
    restrict: 'C',
    template:
      '<div class="subscriptionColorBox" ng-style="{ \'background-color\': groupData.GroupColor }"></div>' +
      '<div class="subscriptionNameBox">{{groupData.PrimaryGroupName}}' +
        '&nbsp;&nbsp;<a href="" ng-click="openResellerPortal(groupData, false); $event.stopPropagation();" title="Open Reseller Portal"><i class="fa fa-external-link"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-if="!isT1N()" ng-click="defineResellerMargin(groupData)" title="Define uplifts/margin for reseller"><i class="fa fa-credit-card"></i></a>' +
        '&nbsp;&nbsp;<a href="" ng-click="unpublishAllCustomers(groupData, false); $event.stopPropagation();" title="UnPublish all customers"><i class="fa fa-trash"></i></a>' +
      '</div>' +
      '<div class="clearfix"></div>',
    scope: false,
    controller: 'EaWidgetGroupFilterListViewPgCspResellerT2TemplateCtrl'
  };
}).controller('EaWidgetGroupFilterListViewPgCspResellerT2TemplateCtrl', function($scope) {

  $scope.openResellerPortal = function(customerGroupData) {
    if (customerGroupData.ServiceItems && customerGroupData.ServiceItems.length > 0 && customerGroupData.ServiceItems[0].ResellerTeamId) {
      $scope.executeDashboardAction('func:75FF743F-21BC-4208-BB05-7AC447A17E19', {TeamId: customerGroupData.ServiceItems[0].ResellerTeamId, ResellerId: customerGroupData.ServiceItems[0].ResellerAccountId});
    }
  };

  $scope.defineResellerMargin = function(customerGroupData) {
    if (customerGroupData.ServiceItems && customerGroupData.ServiceItems.length > 0 && customerGroupData.ServiceItems[0].ResellerTeamId) {
      $scope.executeDashboardAction('func:AD35F300-7307-4E33-B69C-E5576CDCDA4D', {ResellerTeamId: customerGroupData.ServiceItems[0].ResellerTeamId, ResellerId: customerGroupData.ServiceItems[0].ResellerId});
    }
  };

  $scope.unpublishAllCustomers = function(customerGroupData) {
    if (customerGroupData.ServiceItems && customerGroupData.ServiceItems.length > 0 && customerGroupData.ServiceItems[0].ResellerTeamId) {
      $scope.executeDashboardAction('func:8910991F-B227-4A61-B4FB-3F2C6F69D276', {TeamId: customerGroupData.ServiceItems[0].ResellerTeamId, ResellerId: customerGroupData.ServiceItems[0].ResellerAccountId, Customers: customerGroupData.ServiceItems});
    }
  };

});
