'use strict';

/*
 * This service is responsible to handle everyhting around our analytics data. This means the service is
 * responsible to load a specific collection from the network or give the collection out from the cache.
 *
 * Normally we load data collections in a specific timeframe which is currently 12 months
 */
angular.module('azureCostsFeApp').service('$eaDataCoordinator', function($q, $eaDataRawSources, $eaDataSourceManager, rfc4122) {
  var self = this;
  var cache = {};
  var currentCacheHash = '';

  // register all global data sources
  $eaDataRawSources.registerDataSources();

  // This function returns a promise which will be ready as soon the data for the give collection
  // is in the cache. A collection can also be some virtual thing which will be calculated in a back
  // ground worker. Currently the system supports the following collections
  function queryRawDataFromBackend(teamid, contract, dataToken, className, optionalParams) {
    optionalParams = optionalParams || {};

    var useCacheIfPossible = true;

    // try to split the classname to get the host
    var classNameValues = className.split('.');
    var hostName = 'global';
    var classNameValue = className;
    if (classNameValues.length >= 2) {
      hostName = classNameValues[0];
      classNameValues.splice(0,1);
      classNameValue = classNameValues.join('.');
    }

    // only global namespace is allowed to use caching
    if (hostName !== 'global') { useCacheIfPossible = false; }

    // check if we ask for a valid collection
    var loadDataCallback = $eaDataSourceManager.queryDataSource(hostName, classNameValue, optionalParams.collection);
    if (!loadDataCallback) {
      return $q.reject(new Error('Invalid collection ' + optionalParams.collection + ' for datasource ' + hostName + ':' + classNameValue));
    }

    // generate the collection cache key
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;
    if (optionalParams && optionalParams.cachedCollectionKey) { cachedCollectionKey = optionalParams.cachedCollectionKey; }

    // check if we have the collection in cache
    var cachedCollection = null;
    if (useCacheIfPossible) { cachedCollection = cache[cachedCollectionKey]; }

    // check if the cachedCollection is a promise. If so it's currently a network in
    // progress and we just register a then on this
    if (cachedCollection && angular.isFunction(cachedCollection.then)) {
      return cachedCollection;
    // check if we have just data
    } else if (cachedCollection) {
      return $q.when(cachedCollection);
    } else {
      // build a promise
      var defer = $q.defer();

      // add the promise to the collection cache
      cache[cachedCollectionKey] = defer.promise;

      // load the data
      loadDataCallback(teamid, contract, dataToken, className, currentCacheHash, optionalParams).then(function(data) {
        // add the data to the cache
        cache[cachedCollectionKey] = data;

        // done
        defer.resolve(data);
      }).catch(function (error) {
        defer.reject(error);
      });

      // return the promise
      return defer.promise;
    }
  }

  self.query = function(teamid, contract, dataToken, className, optionalParams) {
    return queryRawDataFromBackend(teamid, contract, dataToken, className, optionalParams);
  };

  self.queryData = function(teamid, contract, dataToken, className, optionalParams) {
    return self.query(teamid, contract, dataToken, className, optionalParams).then(function(data) {
      // TODO: apply the filter operation on the data

      // return the values
      return $q.when(data);
    });
  };

  function selectModels(teamid, contract, className, searchKeys, searchValues) {
    // generate the collection cache key
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;

    // load the cached collection
    var models = cache[cachedCollectionKey];
    if (!models || models.length === 0) { return; }

    // search and update the model
    return models.filter(function(m) {

      // the result
      var result = true;

      // check for every key
      searchKeys.forEach(function(k, idx) {
        result = result && (m[k] && (m[k] === searchValues[idx]));
      });

      // done
      return result;

    });
  }

  // Allows to manioulate a property of a specific model in the cache. This will not change
  // anything on the server
  self.updateModels = function(teamid, contract, className, searchKeys, searchValues, propertyKey, propertyValue, propertyOperation) {
    selectModels(teamid, contract, className, searchKeys, searchValues).forEach(function(m) {
      if (m[propertyKey] && Array.isArray(m[propertyKey])) {
        if (propertyOperation === 'remove') {
          var elementIndex = m[propertyKey].indexOf(propertyValue);
          if (elementIndex !== -1) {
            m[propertyKey].splice(elementIndex, 1);
          }
        } if (propertyOperation === 'set') {
          m[propertyKey] = propertyValue;
        } else {
          if (m[propertyKey].indexOf(propertyValue) === -1) {
            m[propertyKey].push(propertyValue);
          }
        }
      } else {
        m[propertyKey] = m[propertyValue];
      }
    });
  };


  // Allows to remove a list of elements from the cache
  self.removeModels = function(teamid, contract, className, searchKeys, searchValues) {

    // generate the collection cache key
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;

    // load the cached collection
    var models = cache[cachedCollectionKey];
    if (!models) { return; }

    // finde the models
    selectModels(teamid, contract, className, searchKeys, searchValues).forEach(function(m) {
      var idx = models.indexOf(m);
      models.splice(idx, 1);
    });
  };

  // Allows to add elemnets to the cache
  self.addModels = function(teamid, contract, className, elementsToAdd) {

    // generate the collection cache key
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;

    // load the cached collection
    var models = cache[cachedCollectionKey];
    if (!models) { return; }

    elementsToAdd.forEach(function(element) {
      models.push(element);
    });
  };

  self.storeModel = function(teamid, contract, dataToken, className, modelToStore, optionalParams) {

    // try to split the classname to get the host
    var classNameValues = className.split('.');
    var hostName = 'global';
    var classNameValue = className;
    if (classNameValues.length >= 2) {
      hostName = classNameValues[0];
      classNameValues.splice(0,1);
      classNameValue = classNameValues.join('.');
    }

    // invalidate cache
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;
    delete cache[cachedCollectionKey];

    // check if we ask for a valid collection
    var operationCallback = $eaDataSourceManager.queryInsertOrMergeDataSourceOperation(hostName, classNameValue, optionalParams.collection);
    if (!operationCallback) {
      return $q.reject(new Error('StoreModel: Invalid collection ' + optionalParams.collection + ' for datasource ' + hostName + ':' + classNameValue));
    } else {
      return operationCallback(teamid, contract, dataToken, className, modelToStore, optionalParams);
    }
  };

  self.deleteModel = function(teamid, contract, dataToken, className, modelToDelete, optionalParams) {

    // try to split the classname to get the host
    var classNameValues = className.split('.');
    var hostName = 'global';
    var classNameValue = className;
    if (classNameValues.length >= 2) {
      hostName = classNameValues[0];
      classNameValues.splice(0,1);
      classNameValue = classNameValues.join('.');
    }

    // invalidate cache
    var cachedCollectionKey = className + ':' + teamid + ':' + contract;
    delete cache[cachedCollectionKey];

    // check if we ask for a valid collection
    var operationCallback = $eaDataSourceManager.queryDeleteDataSourceOperation(hostName, classNameValue, optionalParams.collection);
    if (!operationCallback) {
      return $q.reject(new Error('DeleteModel: Invalid collection ' + optionalParams.collection + ' for datasource ' + hostName + ':' + classNameValue));
    } else {
      return operationCallback(teamid, contract, dataToken, className, modelToDelete, optionalParams);
    }
  };

  // Invalidates all cached collections so that everything need to be reloaded from the network again
  self.invalidate = function(cacheHash) {
    cache = {};
    currentCacheHash = cacheHash || rfc4122.v4();
  };
});
