'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep02RateCtrl', function ($scope, $state, $stateParams, $window, $location, $eaBackend, $eaCurrentSession, $eaConfig, $busy) {

  if ($state.params.enrollment) {
    return $state.go('authorized.reportAdd.step02-rate-connect', $state.params);
  } else {

    // load the needed metadat before we can start
    $busy.setMessage('Loading details');
    $busy.during($eaBackend.dataGetMetadata('currencies', $eaCurrentSession.DataToken).then(function(currencies) {
      $scope.availableCurrencies = currencies;
      $scope.availableCloudinstances = [
        { Id: 'rates', Display: 'Azure Cloud World Wide (Default)'},
        { Id: 'ratesger', Display: 'Azure German Cloud' }
      ];

      $scope.formAddContract = {
        team: $state.params.team ? $state.params.team : 'personal',
        tenantId: null,
        defaultCurrency: $state.params.currency ? $state.params.currency : null,
        cloudInstance: 'rates'
      };

      $scope.disableCurrency = ($state.params.currency !== null && $state.params.currency !== undefined);

      $scope.next = function () {


        // ask the api for a creation token
        $eaBackend.dataPostRatePlanRegistrationId($scope.formAddContract.team, $location.absUrl(), $scope.formAddContract.tenantId, $scope.formAddContract.defaultCurrency, $eaCurrentSession.DataToken).then(function (result) {

          // redirect to authorisation endpoint, state should become the creation token
          $window.location = ($eaConfig.aadProtocol ? $eaConfig.aadProtocol: 'https') + '://' + $eaConfig.aadHost + '/aad/link/' + $scope.formAddContract.cloudInstance + '?tenant=' + $scope.formAddContract.tenantId + '&state=' + result.RegistrationId;
        });
      }

    }));
  }
});
