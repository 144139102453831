'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgSubItemCspResellerT2Template', function() {
  return {
    restrict: 'C',
    template:
      '<div ng-if="serviceItem[subitemIconProperty] && serviceItem[subitemIconProperty].indexOf(\'fa-\') !== 0" class="subscriptionServiceIcon" ng-class="serviceItem[subitemIconProperty]"></div>' +
      '<div ng-if="serviceItem[subitemIconProperty] && serviceItem[subitemIconProperty].indexOf(\'fa-\') === 0" class="subscriptionServiceIcon"><i class="fa" ng-class="serviceItem[subitemIconProperty]" style="font-size: 16px; margin-top: 2px; margin-bottom: 2px; display: block;"></i></div>' +
      '<div class="subscriptionServiceName" style="width: calc(100% - 36px);">{{getSubItemTitle(serviceItem)}}' +
        '&nbsp;&nbsp;<a href="" ng-if="serviceItem.CustomerState !== \'pseudo\'" ng-click="unPublishCustomer(serviceItem)" title="Remove Customer from Reseller"><i class="fa fa-trash"></i></a>' +
      '</div>' +
      '<div class="clearfix"></div>',
    scope: false,
    controller: 'EaWidgetGroupFilterListViewPgSubItemCspResellerT2TemplateCtrl'
  };
}).controller('EaWidgetGroupFilterListViewPgSubItemCspResellerT2TemplateCtrl', function($scope) {

  $scope.unPublishCustomer = function(serviceItem) {
    $scope.executeDashboardAction('func:E6F0F729-6374-4A62-81A9-7D26EFE82346', {
      ResellerTeamId: serviceItem.ResellerTeamId,
      ResellerAccountId: serviceItem.ResellerAccountId,
      ResellerId: serviceItem.ResellerId,
      CustomerId: serviceItem.CustomerId
    });
  };
});
