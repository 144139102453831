'use strict';

angular.module('azureCostsFeApp').controller('CspDefineResellerFeaturesCtrlMixin', function($scope, $modal) {

  $scope.openCspDefineResellerFeaturesDialog = function(features, customer, account) {

    var modalInstance = $modal.open({
      templateUrl: "views/csp/modals/modal_ac_csp_define_reseller_features.html",
      controller: 'ModalCspDefineResellerFeaturesCtrl',
      resolve: {
        Features: function() {
          return features ? features : {};
        },
        Customer: function() {
          return customer ? customer : {};
        },
        Account: function() {
          return account ? account : {};
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
});
