'use strict';

angular.module('azureCostsFeApp').service('$eaDataRawSources', function($q, $http, $injector, $eaDataSourceManager, $eaReportData, $eaBackend, moment, $eaBackendBase, $acDataSourceClasses, $acDataSourceSettings, $acDataSourceHelper) {
  var self = this;
  var registeredPlugins = [];

  function loadServiceTypeMetainformation(/*teamid, contract, dataToken, className, cacheHash, optionalParams*/) {
    // load the service definitions from the server
    return $acDataSourceSettings.loadDataFromStaticUrl('service_definitions.json').then(function(result) {

      var models = [];

      result.forEach(function(item) {
        var model = new $acDataSourceClasses.ServiceDefinitionClass();
        model.ServiceType = item.ServiceType;
        model.ServiceTitle = item.ServiceTitle;
        model.ServiceDescription = item.ServiceDescription;
        models.push(model);
      });

      return $q.when(models);
    });
  }

  // register the global data sources
  self.registerDataSources = function() {

    // general data
    $eaDataSourceManager.registerDataSource('global', 'ServiceDefinitionClass', null, loadServiceTypeMetainformation);

    // get the module
    var mod = angular.module('azureCostsFeApp');

    // visit all service
    mod._invokeQueue.forEach(function(item) {

      // we only work with services
      if (item[1] !== 'service') { return; }

      // every service needs to start with $acDataSource-prefix
      if (item[2][0].indexOf('$acDataSource') !== 0) { return; }

      // check if the service was registered
      if (registeredPlugins.indexOf(item[2][0]) !== -1) {
        console.log("!!!ALERT!!!: Datasource Plugin " + item[2][0] + " was used one times more, looks like you forgot to rename a copied service")
        return;
      }

      // add the service plugin
      registeredPlugins.push(item[2][0]);

      // instanciate the service
      var serviceToWorkWith = $injector.get(item[2][0]);

      // check if we implement our interface
      if (!angular.isFunction(serviceToWorkWith.registerDataSources)) {return; }

      // register the data sources
      console.log('Register Datasources from ' + item[2][0]);
      serviceToWorkWith.registerDataSources($eaDataSourceManager);
    });
  };

});


