'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceHelperCspGenericSummary', function($acDataSourceSettings, $acDataSourceClasses, $q, $eaReportData, $eaBackend, $acTimelineGenerator, $injector, $eaBackendAzureCachedContentLoader) {
  var self = this;


  self.loadGenericView = function (teamid, accountId, viewId, collection, dataToken, className, currentHashKey, optionalParams) {
    // inject dynamically to prevent circular dependency
    var $eaDataCoordinator = $injector.get('$eaDataCoordinator');

    // duplicate options
    var duplicatedOptions = JSON.parse(JSON.stringify(optionalParams));
    duplicatedOptions.collection = collection;

    // build the hashkey
    var hashKeyPartition = 'CSPAggregationCustomer.forAccount.'  + viewId + '.' + accountId;

    // load the current hashes for this report category
    return $eaBackend.dataGetTeamHashes(teamid, hashKeyPartition, dataToken).then(function(hashMap) {

      // ensure we have a map
      if (!hashMap) { hashMap = {}; }

      // load the available periods
      return $eaDataCoordinator.query(teamid, accountId, dataToken, 'PeriodClass', duplicatedOptions).then(function (periods) {

        // prepare the data
        var resultData = [];

        // build resource information
        var resourceInformation = periods.map(function(period) {
          return {
            Id: period.ReportId,
            CacheIdentifier: 'empty',
            CacheHash: 'empty'
          }
        });

        // load the resources
        return $eaBackendAzureCachedContentLoader.dataRequestCachedResourcesWithRetry(teamid, accountId, resourceInformation, dataToken, {
          resourceEndpoint: function(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry) {
            return $eaBackend.dataCspGetCustomersSpendingsInAccountV2(viewId, teamid, accountId, resourceId, jwtToken, hashMap, retry);
          }
        }).then(function (loadedItems) {
          if (Array.isArray(loadedItems)) {
            loadedItems.forEach(function(items) {
              resultData.appendArray(items);
            })
          }

          return $q.when(resultData);
        });
      });
    });
  };
});
