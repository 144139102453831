'use strict';

/*
 * This widget is able to render a stacked bar. The widget accepts the following additional options:
 *
 * - title        - The title of the widget
 * - categories   - The property we use to define the categories
 * - series       - The property we use to define the series
 * - value        - The property which will be aggregated in the series group
 * - filter       - Check the general filter documentation
 *
 * The following JSON structure needs to be applied to the dashboards overview
 *
 * options: {
 *    filter: [],
 *    categories: 'spendings.Month',
 *    series:     'spendings.SubscriptionId',
 *    value:      'spendings.Costs'
 * }
 *
 */
angular.module('azureCostsFeApp').directive('eaWidgetChartStackedBar', function() {
  return {
    restrict: 'C',
    template: '<div id=\"chart-container-{{uuid}}\"><div ng-show="!hasData" style="margine: 10px; text-align: center"><p style="margin: 50px; font-size: 24px; color: lightgray">Currently no data available</p></div></div>',
    controller: 'EaWidgetChartStackedBarCtrl'
  };
}).controller('EaWidgetChartStackedBarCtrl', function($scope, $timeout, $filter, $interpolate, $eaChartConfig, $eaDataCoordinator, $eaDataGroupOperations, $eaDataAggregateOperations, $eaDataColorThemeGenerator, $acDpnService, $acLocaleService) {

  // store the chart element
  var chartElement = null;
  $scope.hasData = false;

  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);

    // load the group option we want to use
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.series, null).then(function(groupOption) {

      // check if we are active or not
      if (!$scope.isActive()) { return; }

      // the series property
      var seriesProperty = $scope.options.series;
      if (groupOption) { seriesProperty = groupOption.GroupProperty; }

      // load the data of the specific property by filter
      return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.class, null).then(function(data) {

        // check if we are active or not
        if (!$scope.isActive()) { return; }

        // load the categories from the datasource if possible
        return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.categories, null).then(function (loadedCategories) {

          // check if we are active or not
          if (!$scope.isActive()) { return; }

          // distinct the category values
          var categories = [];
          var categoryProperty = null;
          if (loadedCategories) {
            categories = loadedCategories.PeriodsAvailable;
            categoryProperty = loadedCategories.PeriodProperty;
          } else {
            categories = $eaDataAggregateOperations.execute('distinct', data, $scope.options.categories);
            categoryProperty = $scope.options.categories;
          }

          // prevent exception
          if (!categories) { categories = []; }

          var realCategoryCount = categories.length;

          // extend the categories to 12
          // this is our minimum amount of month we show
          if (categories.length < 12) {
            var newCategories = [];
            for(var i = 0; i < 12 -categories.length; i++) { newCategories.push(' '); }
            categories.forEach(function(e) { newCategories.push(e); })
            categories = newCategories;
          }

          // contains the aggregated values
          var predictionCategory = [];

          // group by our series attributeand by the categories attributes
          return $eaDataGroupOperations.groupBy(data, [seriesProperty, categoryProperty]).then(function (groupedData) {


            // check if we have data
            if (!groupedData || Object.keys(groupedData).length === 0) {

              // switch off the loading mode
              $scope.setLoading(false);

              // done without data
              return;
            }

            // define the series
            var series = [];
            var seriesHash = {};

            // visit every first level group
            Object.keys(groupedData).forEach(function (firstLevelGroupsKey) {

              // deifne the series name
              var seriesname = firstLevelGroupsKey;
              if (firstLevelGroupsKey === '') { seriesname = 'Without ' + seriesProperty; }

              // build a basic serie for this group
              var serie = {name: seriesname, display: undefined, type: 'column', data: []};

              // ensure we have the correct amount of elements
              categories.forEach(function () {
                serie.data.push(0);
              });

              // prepare the prediction field
              if (predictionCategory.length == 0) {
                categories.forEach(function () {
                  predictionCategory.push(0);
                });
              }

              var firstLevelGroups = groupedData[firstLevelGroupsKey];

              Object.keys(firstLevelGroups).forEach(function (secondLevelGroupKeys) {

                // calculate the values
                var secondLevelGroup = firstLevelGroups[secondLevelGroupKeys];
                var aggregateValue = $eaDataAggregateOperations.execute($scope.options.func, secondLevelGroup, $scope.options.value);

                // get the correct index
                var color = $eaDataColorThemeGenerator.getColorFor($scope.contract + '#' + seriesProperty, seriesname.toLowerCase());

                // $scope.options.displayNameMapper
                if (secondLevelGroup && secondLevelGroup.length > 0 && $scope.options.displayNameMapper && $scope.options.displayNameMapper[seriesProperty]) {
                  var displayValue = secondLevelGroup[0][$scope.options.displayNameMapper[seriesProperty]];
                  serie.display = displayValue ? displayValue : serie.display;
                } else {
                  var displayValue = Array.isArray(secondLevelGroup[0][seriesProperty]) ? seriesname : secondLevelGroup[0][seriesProperty];
                  serie.display = displayValue ? displayValue : serie.display;
                }

                var categoryIndex = categories.indexOf(secondLevelGroupKeys);
                serie.data[categoryIndex] = {y: aggregateValue, color: color};
                predictionCategory[categoryIndex] += aggregateValue;
              });

              // add the serie
              series.push(serie);
              seriesHash[serie.name] = serie;
            });

            if ($scope.options.prediction && realCategoryCount >= 3) {
              // calculate the prediction (which is currently the average of the three month before)
              for (var i = categories.length - 1; i > 2; i--) {
                predictionCategory[i] = (predictionCategory[i - 1] + predictionCategory[i - 2] + predictionCategory[i - 1] - 3) / 3;
              }

              // set the first 0 values to 0
              predictionCategory[0] = null;
              predictionCategory[1] = null;
              predictionCategory[2] = null;

              var predictionSeries = {
                type: 'spline',
                name: 'Average',
                display: 'Trend',
                data: predictionCategory,
                marker: {
                  lineWidth: 2,
                  lineColor: '#F69D4D',
                  fillColor: 'white'
                }
              };

              series.push(predictionSeries);
              seriesHash[predictionSeries.name] = predictionSeries;
            }

            // the highcharts container
            var containerId = 'chart-container-' + $scope.uuid;

            var stackBarOptions = $eaChartConfig.createStackedBarConfig();
            stackBarOptions.chart.renderTo = containerId;

            stackBarOptions.xAxis.categories = categories;
            stackBarOptions.series = series;

            var unitExpression = $interpolate($scope.options.unitLegend ? $scope.options.unitLegend : $scope.options.unit);
            stackBarOptions.yAxis.title.text = unitExpression(data[0]);

            var unitSymbolExpression = $interpolate($scope.options.unit);
            var unitSymbol = unitSymbolExpression(data[0]);

            // add a tooltip
            stackBarOptions.tooltip = {
                enabled: true,
                formatter: function() {
                    var serie = seriesHash[this.series.name];
                    if (!serie) { return ""; }

                    var decimalSep = $acLocaleService.getDecimalSeperator();

                    // convert the number
                    var numberOutPut = $filter('currency')(this.y, '', 4);

                    // do it better
                    if (decimalSep === ',') {
                      numberOutPut = numberOutPut.replace(',', 'X');
                      numberOutPut = numberOutPut.replace('.', ',');
                      numberOutPut = numberOutPut.replace('X', '.');
                    }

                    return '<b>' + serie.display + '</b><br/><span>' + numberOutPut + ' ' + unitSymbol + '</span>';
                }
            };

            // switch off the loading mode
            $scope.setLoading(false);

            // Build the bar chart
            $timeout(function() {

              // check if we are active before painting to prevent exceptions
              if (!$scope.isActive()) { return; }

              // render the charts
              chartElement = new Highcharts.Chart(stackBarOptions);
              $scope.hasData = true;

              // resize the diagram
              $scope.requestResize();

            }, 100);
          });
        });
      });
    });
  }

  // initialize when the host is ready
  $scope.onInitialize(function() {

    // register operation for processing information
    $acDpnService.onProcessingItemsChanged(function(items) {

      if (items.length === 1) {
        $scope.setLoadingMesage('Processing of 1 new or updated report is pending, it takes just a couple seconds...');
      } else {
        $scope.setLoadingMesage('Processing of ' + items.length + ' new or updated reports are pending, it takes just a couple seconds...');
      }
    });

    $acDpnService.onProcessingFinished(function() {
      $scope.setLoadingMesage('All reports are processed');
    });

    // generate the dashboard
    return buildDashboard();
  });


  // establish the resize callback
  $scope.onResize(function(newSize) {
    if ( chartElement) { chartElement.setSize(newSize.w, newSize.h, false); }
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
