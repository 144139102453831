'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackend
 * @description
 * # eaBackend
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$eaBackendDataModeSelector', function eaBackend() {
    var self = this;

    self.BillingCycleActive = false;
    self.BillingCycleDay = 1;
    self.NoMargin = false;

    self.enableBillingCycle = function(enable, day) {
      self.BillingCycleActive = enable;
      self.BillingCycleDay = day ? day : 1;
    };

    self.isBillingCycleEnabled = function() {
      return self.BillingCycleActive;
    };

    self.getBillingCycleDay = function() {
      return self.BillingCycleDay;
    };

    self.enableNoMargin = function(enable) {
      self.NoMargin = enable;
    };

    self.isNoMarginEnabled = function() {
      return self.NoMargin;
    }

}).service('$eaBackend', function eaBackend($eaBackendAzure, $eaBackendAzureV2, $eaBackendDataModeSelector, $q) {
    var self = this;

    self.stsUpdateToken = function(options, dataToken) {
      return $eaBackendAzure.stsUpdateToken(options, dataToken);
    };

    self.dataPostProfileTouch = function(dataToken) {
        return $eaBackendAzure.dataPostProfileTouch(dataToken);
    };

    self.dataGetProfile = function(dataToken) {
        return $eaBackendAzure.dataGetProfile(dataToken);
    };

    self.dataUpdateProfile = function(profileProperties, dataToken) {
        return $eaBackendAzure.dataUpdateProfile(profileProperties, dataToken);
    };

    self.dataGetContracts = function(teamId, dataToken) {
        return $eaBackendAzure.dataGetContracts(teamId, dataToken);
    };

    self.dataGetContract = function(teamId, contract, dataToken) {
        return $eaBackendAzure.dataGetContract(teamId, contract, dataToken);
    };

    self.dataGetContractInfo = function(teamId, contract, dataToken) {
      return $eaBackendAzureV2.dataGetContractInfo(teamId, contract, dataToken);
    };

    self.dataPostPurgeContractReports = function(teamId, contract, dataToken) {
      return $eaBackendAzureV2.dataPostPurgeContractReports(teamId, contract, dataToken);
    };

    self.dataGetContractSubscriptions = function(teamId, contract, dataToken, options) {
        return $eaBackendAzure.dataGetContractSubscriptions(teamId, contract, dataToken, options);
    };

    self.dataGetContractSubscriptionsVerifyConnection = function(teamId, contract, subscriptionId, offerid, country, currency, dataToken) {
        return $eaBackendAzure.dataGetContractSubscriptionsVerifyConnection(teamId, contract, subscriptionId, offerid, country, currency, dataToken);
    };

    self.dataPutContractSubscription = function(teamId, contract, subscriptionId, updatedFields, dataToken) {
        return $eaBackendAzure.dataPutContractSubscription(teamId, contract, subscriptionId, updatedFields, dataToken);
    };

    self.dataGetContractReports = function(teamId, contract, dataToken) {
        return $eaBackendAzureV2.dataGetContractReportsV2(teamId, contract, dataToken);
    };

    self.dataGetContractReportData = function(teamId, contract, report, cacheIdentifier, dataToken, retry) {
        return $eaBackendAzure.dataGetContractReportData(teamId, contract, report, cacheIdentifier, dataToken, retry);
    };

    self.dataGetContractReportDataV3 = function(teamId, contract, report, cacheIdentifier, dataToken, retry) {
      return $eaBackendAzureV2.dataGetContractReportDataV3(teamId, contract, report, cacheIdentifier, dataToken, retry);
    };

    self.dataRegisterContract = function(teamid, contract, token, dataToken) {
      return $eaBackendAzure.dataRegisterContract(teamid, contract, token, dataToken);
    };

    self.dataPostRatePlanRegistrationId = function(teamid, callbackUri, directoryDomain, defaultCurrency, dataToken) {
      return $eaBackendAzure.dataPostRatePlanRegistrationId(teamid, callbackUri, directoryDomain, defaultCurrency, dataToken);
    };

    self.dataAllSyncReports = function(team, contract, dataToken) {
      return $eaBackendAzure.dataAllSyncReports(team, contract, dataToken);
    };

    self.dataMonthSyncReports = function(team, contract, month, dataToken) {
      return $eaBackendAzure.dataMonthSyncReports(team, contract, month, dataToken);
    };

    self.dataMonthSyncReportsV2 = function(teamid, contract, months, dataToken)
    {
      return $eaBackendAzureV2.dataMonthSyncReportsV2(teamid, contract, months, dataToken);
    };

    self.dataUpdateContract = function(teamid, contract, contractInformation, dataToken) {
        return $eaBackendAzure.dataUpdateContract(teamid, contract, contractInformation, dataToken);
    };

    self.dataDeleteContract = function(teamid, contract, dataToken) {
        return $eaBackendAzureV2.dataDeleteContract(teamid, contract, dataToken);
    };

    self.dataSetServiceTagsV1 = function(teamid, contract, serviceName, serviceTags, dataToken) {
      return $eaBackendAzure.dataSetServiceTagsV1(teamid, contract, serviceName, serviceTags, dataToken);
    };

    self.dataSetServiceTagsV2 = function(teamid, contract, serviceName, serviceType, serviceTags, dataToken) {
      return $eaBackendAzure.dataSetServiceTagsV2(teamid, contract, serviceName, serviceType, serviceTags, dataToken);
    };

    self.dataSetServiceTagsV3 = function(teamid, contract, serviceName, serviceType, serviceSubscriptionId, serviceTags, dataToken) {
      return $eaBackendAzure.dataSetServiceTagsV3(teamid, contract, serviceName, serviceType, serviceSubscriptionId, serviceTags, dataToken);
    };

    self.dataSetServiceTagsV4 = function(teamid, contract, serviceId, serviceTags, dataToken) {
      return $eaBackendAzureV2.dataSetServiceTagsV4(teamid, contract, serviceId, serviceTags, dataToken);
    };

    /*
     * History API
     */
    self.dataGetContractReportHistory = function(teamid, contract, dataToken) {
      return $eaBackendAzure.dataGetContractReportHistory(teamid, contract, dataToken);
    };

    self.dataGetContractReportHistoryV3 = function(teamid, contract, cacheIdentifier, dataToken, retry) {
      return $eaBackendAzureV2.dataGetContractReportHistoryV3(teamid, contract, cacheIdentifier, dataToken, retry);
    };

    /*
     * MetaData API
     */
    self.dataGetMetadata = function(collection, dataToken) {
      return $eaBackendAzure.dataGetMetadata(collection, dataToken);
    };

    /*
     * Team API
     */
    self.dataMigrateToTeamAccount = function(teamName, dataToken) {
        return $eaBackendAzure.dataMigrateToTeamAccount(teamName, dataToken);
    };

    self.dataGetTeamProfile = function(teamid, members, dataToken) {
        return $eaBackendAzure.dataGetTeamProfile(teamid, members, dataToken);
    };

    self.dataInviteUserToTeam = function(teamid, contact, role, subscriptions, callback, dataToken) {
        return $eaBackendAzure.dataInviteUserToTeam(teamid, contact, role, subscriptions, callback, dataToken);
    };

    self.dataInviteUserToTeamV2 = function(teamid, contact, role, fullAccess, callback, dataToken) {
      return $eaBackendAzureV2.dataInviteUserToTeamV2(teamid, contact, role, fullAccess, callback, dataToken);
    };

    self.dataReInviteUserToTeam = function(teamid, invitationid, callback, dataToken) {
        return $eaBackendAzure.dataReInviteUserToTeam(teamid, invitationid, callback, dataToken);
    };

    self.dataRemoveUserFromTeam = function(teamid, userid, dataToken) {
        return $eaBackendAzure.dataRemoveUserFromTeam(teamid, userid, dataToken);
    };

    self.dataUpdateTeamProfile = function(teamid, profile, dataToken) {
        return $eaBackendAzure.dataUpdateTeamProfile(teamid, profile, dataToken);
    };

    self.dataUpdateTeamImage = function(teamid, teamImageFile, dataToken) {
        return $eaBackendAzure.dataUpdateTeamImage(teamid, teamImageFile, dataToken);
    };

    self.dataUpdateTeamMembership = function(teamid, userid, role, subscriptions, contact, dataToken) {
        return $eaBackendAzure.dataUpdateTeamMembership(teamid, userid, role, subscriptions, contact, dataToken);
    };

    self.dataRegisterAppToTeam = function(teamid, name, clientId, clientSecret, role, subscriptions, dataToken) {
        return $eaBackendAzure.dataRegisterAppToTeam(teamid, name, clientId, clientSecret, role, subscriptions, dataToken);
    };

    self.dataRegisterUserToTeam = function(teamid, contact, role, subscriptions, dataToken) {
      return $eaBackendAzure.dataRegisterUserToTeam(teamid, contact, role, subscriptions, dataToken);
    };

    self.dataGetTeamImageLocation = function(teamid, dataToken) {
        return $eaBackendAzure.dataGetTeamImageLocation(teamid, dataToken);
    };

    self.helperGetAvailableSubscriptions = function(teamid, dataToken) {
      var defer = $q.defer();

      // query the contracts
      self.dataGetContracts(teamid, dataToken).then(function(contracts) {

          var subscritpionsForContractPromisses = [];
          var contractsCollection = [];
          contracts.forEach(function(contract) {
              contractsCollection.push(contract);
              subscritpionsForContractPromisses.push(self.dataGetContractSubscriptions(teamid, contract.Enrollment, dataToken));
          });

          return $q.all(subscritpionsForContractPromisses).then(function(results) {

              var resultModel  = [];
              results.forEach(function(result, idx) {
                resultModel.push({ Contract: contractsCollection[idx], Subscriptions: result});
              });

              defer.resolve(resultModel);
          });
      }).catch(function(error) {
          defer.reject(error);
      });

      return defer.promise;
    };

    /*
     * Notification Settings API
     */
    self.dataGetContractNotifcationSettings = function(teamid, contract, dataToken) {
      return $eaBackendAzure.dataGetContractNotifcationSettings(teamid, contract, dataToken);
    };

    self.dataGetContractNotifcationSettingsForUser = function(teamid, contract, targetUserId, dataToken) {
      return $eaBackendAzure.dataGetContractNotifcationSettingsForUser(teamid, contract, targetUserId, dataToken);
    };

    self.updateGetContractNotifcationSettings = function(teamid, contract, notificationSettings, dataToken, options) {
      return $eaBackendAzure.updateGetContractNotifcationSettings(teamid, contract, notificationSettings, dataToken, options);
    };

    self.updateGetContractNotifcationSettingsForUser = function(teamid, contract, targetUserId, notificationSettings, dataToken, options) {
      return $eaBackendAzure.updateGetContractNotifcationSettingsForUser(teamid, contract, targetUserId, notificationSettings, dataToken, options);
    };

    self.triggerContractNotificationForUser = function(teamId, userId, contractId, dataToken) {
      return $eaBackendAzureV2.triggerContractNotificationForUser(teamId, userId, contractId, dataToken);
    };

    /*
     * Branding API
     */
    self.dataUpdateBranding = function(teamid, brandingSettings, dataToken) {
      return $eaBackendAzure.dataUpdateBranding(teamid, brandingSettings, dataToken);
    };

    self.dataGetBranding = function(teamid) {
      return $eaBackendAzure.dataGetBranding(teamid);
    };

    self.dataGetBucket = function(bucketname) {
      return $eaBackendAzure.dataGetBucket(bucketname);
    };

    self.dataGetTeamBuckets = function(teamid, dataToken) {
      return $eaBackendAzure.dataGetTeamBuckets(teamid, dataToken);
    };

    self.dataCreateTeamBucket = function(teamid, bucketName, dataToken) {
      return $eaBackendAzure.dataCreateTeamBucket(teamid, bucketName, dataToken);
    };

    self.dataDeleteTeamBucket = function(teamid, bucketName, dataToken) {
      return $eaBackendAzure.dataDeleteTeamBucket(teamid, bucketName, dataToken);
    };

    /*
     * Azure AAD API (Data Token required)
     */
    self.dataGetAADConnections = function(teamid, dataToken) {
      return $eaBackendAzure.dataGetAADConnections(teamid, dataToken);
    };

    self.dataRegisterAADConnection = function(teamid, connectionData, dataToken) {
      return $eaBackendAzure.dataRegisterAADConnection(teamid, connectionData, dataToken);
    };

    self.dataDeleteAADConnection = function(teamid, aadId, dataToken) {
      return $eaBackendAzure.dataDeleteAADConnection(teamid, aadId, dataToken);
    };

    self.dataGetAuthInformation = function(teamid) {
      return $eaBackendAzure.dataGetAuthInformation(teamid);
    };

    self.dataGetAADConnectionGroups = function(teamid, tenantId, dataToken) {
      return $eaBackendAzure.dataGetAADConnectionGroups(teamid, tenantId, dataToken);
    };

    /*
     * Billing API
     */
    self.dataStartTrial = function(plan, dataToken) {
      return $eaBackendAzure.dataStartTrial(plan, dataToken);
    };

    self.dataPurchase = function(plan, dataToken) {
      return $eaBackendAzure.dataPurchase(plan, dataToken);
    };


    self.dataUpdateBillingInformation = function(billingAddress, dataToken) {
      return $eaBackendAzure.dataUpdateBillingInformation(billingAddress, dataToken);
    };

    self.dataGetBillingInformation = function(dataToken) {
      return $eaBackendAzure.dataGetBillingInformation(dataToken);
    };

    /*
     * Data Filter API
     */
    self.dataCreateDataFilter = function(teamid, resourceCollection, filter, dataToken) {
      return $eaBackendAzure.dataCreateDataFilter(teamid, resourceCollection, filter, dataToken);
    };

    self.dataGetDataFilters = function(teamid, resourceCollection, dataToken) {
      return $eaBackendAzure.dataGetDataFilters(teamid, resourceCollection, dataToken);
    };

    self.dataUpdateDataFilter = function(teamid, resourceCollection, filter, dataToken) {
      return $eaBackendAzure.dataUpdateDataFilter(teamid, resourceCollection, filter, dataToken);
    };

    self.dataRemoveDataFilter = function(teamid, resourceCollection, filterid, dataToken) {
      return $eaBackendAzure.dataRemoveDataFilter(teamid, resourceCollection, filterid, dataToken);
    };

    /*
     * CSP
     */
    self.dataCspEnroll = function(teamName, dataToken) {
      return $eaBackendAzure.dataCspEnroll(teamName, dataToken);
    };

    self.dataCspGetAccounts = function(teamId, dataToken) {
      return $eaBackendAzure.dataCspGetAccounts(teamId, dataToken);
    };

    self.dataCspGetAccount = function(teamId, accountId, dataToken) {
      return $eaBackendAzureV2.dataCspGetAccount(teamId, accountId, dataToken).then(function(account) {
        return {
          AccountId: accountId,
          TeamId: teamId,
          Name: account.name,
          ClientId: account.clientId,
          ClientSecret: account.clientSecret,
          Domain: account.domain,
          Currency:account.currency,
          Country:account.country,
          BillingDay:account.billingDay,
          TierLevel:account.tierLevel,
          SyncCustomers:account.syncCustomers,
          AutoActivateCustomers:account.autoActivateCustomers,
          DefaultResellerManagement:account.defaultResellerManagement,
          ExchangeRate:account.exchangeRate,
          TokenExpires:account.tokenExpires
        };
      });
    };

    self.dataCspRegisterAccount = function(teamId, name, clientId, clientSecret, domain, billingDay, syncCustomers, autoActivateCustomers, dataToken) {
      return $eaBackendAzureV2.dataCspRegisterAccountT1V2(teamId, name, clientId, clientSecret, domain, billingDay, syncCustomers, autoActivateCustomers, dataToken);
    };

    self.dataCspRegisterAccountT2 = function(teamId, name, currency, country, billingDay, dataToken) {
      return $eaBackendAzure.dataCspRegisterAccountT2(teamId, name, currency, country, billingDay, dataToken);
    };

    self.dataCspRegisterAccountEA = function(teamId, name, currency, token, cmapping, dataToken) {
      return $eaBackendAzureV2.dataCspRegisterAccountEAV2(teamId, name, currency, token, cmapping, dataToken);
    };

    self.dataCspRegisterAccountV1 = function(teamId, name, currency, dataToken) {
      return $eaBackendAzureV2.dataCspRegisterAccountV1(teamId, name, currency, dataToken);
    };

    self.dataCspRegisterAccountDemo = function(teamId, name, contractType, currency, cmapping, dataToken) {
      return $eaBackendAzureV2.dataCspRegisterAccountDemo(teamId, name, contractType, currency, cmapping, dataToken);
    };

    self.dataCspUpdateAccountAWSV2 = function(teamId, accountId, name, currency, exchangeRate, defaultResellerManagement, dataToken) {
      return $eaBackendAzureV2.dataCspUpdateAccountAWSV2(teamId, accountId, name, currency, exchangeRate, defaultResellerManagement, dataToken);
    };

    self.dataCspUpdateAccount = function(teamId, accountId, name, clientId, clientSecret, domain, billingDay, dataToken) {
      return $eaBackendAzure.dataCspUpdateAccount(teamId, accountId, name, clientId, clientSecret, domain, billingDay, dataToken);
    };

    self.dataCspDeleteAccount = function(teamId, accountId, dataToken) {
      return $eaBackendAzure.dataCspDeleteAccount(teamId, accountId, dataToken);
    };

    self.dataCspSyncAccount = function(teamId, accountId, dataToken) {
      return $eaBackendAzureV2.dataCspSyncAccount(teamId, accountId, dataToken);
    };

    self.dataCspUpdateVirtualMetersForCustomerInAccount = function(teamId, accountId, customerId, month, dataToken) {
      return $eaBackendAzureV2.dataCspUpdateVirtualMetersForCustomerInAccount(teamId, accountId, customerId, month, dataToken);
    };

    self.dataCspRegisterCustomer = function(teamId, accountId, tenantIdOrDomain, dataToken) {
      return $eaBackendAzureV2.dataCspRegisterCustomer(teamId, accountId, tenantIdOrDomain, dataToken);
    };

    self.dataCspRegisterCostMgntCustomer = function(teamId, accountId, customerName, hasConsumption, dataToken) {
    return $eaBackendAzureV2.dataCspRegisterCostMgntCustomer(teamId, accountId, customerName, hasConsumption, dataToken);
  };

    self.dataCspGetCustomersInAccount = function(teamId, accountId, dataToken) {
      return $eaBackendAzureV2.dataCspGetCustomersInAccount(teamId, accountId, dataToken);
    };

    self.dataCspGetCustomersSpendingsInAccountV2 = function(viewId, teamId, accountId, month, dataToken, cacheIdentifierMap, retry) {
      return $eaBackendAzureV2.dataCspGetCustomersSpendingsInAccountV2(viewId, teamId, accountId, month, dataToken, cacheIdentifierMap, retry);
    };

    self.dataCspActiveCustomersInAccount = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspActiveCustomersInAccount(teamId, accountId, customerId, dataToken);
    };

    self.dataCspDeActiveCustomersInAccount = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspDeActiveCustomersInAccount(teamId, accountId, customerId, dataToken);
    };

    self.dataCspGetGsiSettings = function(teamId, dataToken) {
      return $eaBackendAzureV2.dataCspGetGsiSettings(teamId, dataToken);
    };

    self.dataCspSetGsiSettings = function(teamId, gsiCurrency, dataToken) {
      return $eaBackendAzureV2.dataCspSetGsiSettings(teamId, gsiCurrency, dataToken);
    };

    self.dataCspGetCustomersSpendingsInAccount = function(teamId, accountId, month, dataToken) {
      return $eaBackendAzure.dataCspGetCustomersSpendingsInAccount(teamId, accountId, month, dataToken);
    };

    self.dataCspSyncCustomerInAccount = function(teamId, accountId, customerId, optionalMonth, dataToken) {
      return $eaBackendAzureV2.dataCspSyncCustomerInAccount(teamId, accountId, customerId, optionalMonth, dataToken);
    };

    self.dataCspGetResellerMarginForCustomer = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspGetResellerMarginForCustomer(teamId, accountId, customerId, dataToken);
    };

    self.dataCspSetResellerMarginForCustomer = function(teamId, accountId, customerId, margins, dataToken) {
      return $eaBackendAzureV2.dataCspSetResellerMarginForCustomer(teamId, accountId, customerId, margins, dataToken);
    };

    self.dataCspGetResellerFilterForCustomer = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspGetResellerFilterForCustomer(teamId, accountId, customerId, dataToken);
    };

    self.dataCspSetResellerFilterForCustomer = function(teamId, accountId, customerId, filter, dataToken) {
      return $eaBackendAzureV2.dataCspSetResellerFilterForCustomer(teamId, accountId, customerId, filter, dataToken);
    };

    self.dataCspUpgradeResellerVersionForCustomer = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspUpgradeResellerVersionForCustomer(teamId, accountId, customerId, dataToken);
    };

    self.dataCspGetResellerFeaturesForCustomer = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataCspGetResellerFeaturesForCustomer(teamId, accountId, customerId, dataToken);
    };

    self.dataCspSetResellerFeaturesForCustomer = function(teamId, accountId, customerId, features, dataToken) {
      return $eaBackendAzureV2.dataCspSetResellerFeaturesForCustomer(teamId, accountId, customerId, features, dataToken);
    };

    self.dataCspGetUpliftCategories = function(teamId, dataToken) {
      return $eaBackendAzureV2.dataCspGetUpliftCategories(teamId, dataToken);
    };
    /*
     * AWS API
     */
    self.dataAwsVerifyConnection = function(teamId, roleArn, externalId, bucket, dataToken) {
      return $eaBackendAzureV2.dataAwsVerifyConnection(teamId, roleArn, externalId, bucket, dataToken);
    };

    self.dataAwsRegisterContract = function(teamId, roleArn, externalId, bucket, prefix, reportname, dataToken)
    {
      return $eaBackendAzureV2.dataAwsRegisterContract(teamId, roleArn, externalId, bucket, prefix, reportname, dataToken);
    };

    /*
     * GCP API
     */
    self.dataGcpVerifyConnection = function(teamId, accountKey, dataSetName, dataToken) {
      return $eaBackendAzureV2.dataGcpVerifyConnection(teamId, accountKey, dataSetName, dataToken);
    };

    self.dataGcpRegisterContract = function(teamId, accountKey, dataSetName, dataToken)
    {
      return $eaBackendAzureV2.dataGcpRegisterContract(teamId, accountKey, dataSetName, dataToken);
    };

    /*
     * EA API
     */
    self.dataEARegisterContract = function(teamId, contractId, eaToken, dataToken)
    {
      return $eaBackendAzureV2.dataEARegisterContract(teamId, contractId, eaToken, dataToken);
    };

    self.dataCostMgntRegisterContract = function(teamId, dataToken)
    {
      return $eaBackendAzureV2.dataCostMgntRegisterContract(teamId, dataToken);
    };

    self.dataCostMgntAddContainerToContract = function(teamId, contractId, storageAccountName, storageAccountKey, reportContainer, reportFolder, dataToken) {
      return $eaBackendAzureV2.dataCostMgntAddContainerToContract(teamId, contractId, storageAccountName, storageAccountKey, reportContainer, reportFolder, dataToken);
    }

    /*
     * Demo Mode API
     */
    self.dataDemoRegisterContract = function(teamId, dataToken) {
      return $eaBackendAzureV2.dataDemoRegisterContract(teamId, dataToken);
    };

    /*
     * Service Charts API
     */
    self.dataGetServiceCharts = function(group) {
      return $eaBackendAzure.dataGetServiceCharts(group);
    };

    /*
     * RI Analysis
     */

    self.dataGetRiVirtualMachines = function(teamId, contract, cacheIdentifier, dataToken, retry) {
      return $eaBackendAzureV2.dataGetRiVirtualMachines(teamId, contract, cacheIdentifier, dataToken, retry);
    };

    self.dataGetRiAnalysisResult = function(teamId, contract, resourceInstanceId, cacheIdentifier, dataToken, retry, queryoptions) {
      return $eaBackendAzureV2.dataGetRiAnalysisResult(teamId, contract, resourceInstanceId, cacheIdentifier, dataToken, retry, queryoptions);
    };

    /*
     * Report Center API for Widgets
     */
    self.dataGetReportCenterReportDefinitions = function(teamid, dataToken) {
      return $eaBackendAzureV2.dataGetReportCenterReportDefinitions(teamid, dataToken);
    };

    /*
     * Hashes
     */
    self.dataGetTeamHashes = function(teamId, hashCategory, dataToken) {
      return $eaBackendAzureV2.dataGetTeamHashes(teamId, hashCategory, dataToken).then(function(result) {
        var map = {};
        result.forEach(function(item) {
          map[item.key] = item.value;
        });

        return map;
      })
    };

    self.dataGetTeamHash = function(teamId, hashCategory, hashId, dataToken) {
      return $eaBackendAzureV2.dataGetTeamHash(teamId, hashCategory, hashId, dataToken);
    };

    /*
     * Reseller
     */
    self.dataGetCspResellers = function(teamId, dataToken) {
      return $eaBackendAzureV2.dataGetCspResellers(teamId, dataToken);
    };

    self.dataRegisterCspResellers = function(teamId, resellerName, dataToken) {
      return $eaBackendAzureV2.dataRegisterCspResellers(teamId, resellerName, dataToken);
    };

    self.dataDeleteEmptyCspResellers = function(teamId, resellerId, dataToken) {
      return $eaBackendAzureV2.dataDeleteEmptyCspResellers(teamId, resellerId, dataToken);
    };

    self.dataPublishCspCustomer = function(teamId, resellerId, accountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataPublishCspCustomer(teamId, resellerId, accountId, customerId, dataToken);
    };

    self.dataUnPublishCspCustomer = function(teamId, resellerId, resellerAccountId, customerId, dataToken) {
      return $eaBackendAzureV2.dataUnPublishCspCustomer(teamId, resellerId, resellerAccountId, customerId, dataToken);
    };

    self.dataGetCspResellerSummary = function(teamId, reportId, cacheIdentifier, dataToken, retry) {
      return $eaBackendAzureV2.dataGetCspResellerSummary(teamId, reportId, cacheIdentifier, dataToken, retry);
    };

    self.dataGetCspDownloadAccountInvoice = function(teamid, accountId, month, dataToken)  {
      return $eaBackendAzureV2.dataGetCspDownloadAccountInvoice(teamid, accountId, month, dataToken);
    };

    self.dataCspAwsRegisterContract = function(teamId, accountName, roleArn, externalId, bucket, prefix, reportname, customerMapping, dataToken) {
      return $eaBackendAzureV2.dataCspAwsRegisterContract(teamId, accountName, roleArn, externalId, bucket, prefix, reportname, customerMapping, dataToken);
    };

    self.dataCspSetResellerMarginForReseller = function(teamId, resellerId, margins, dataToken) {
      return $eaBackendAzureV2.dataCspSetResellerMarginForReseller(teamId, resellerId, margins, dataToken);
    };

    self.dataCspGetResellerMarginForReseller = function(teamId, resellerId, dataToken) {
      return $eaBackendAzureV2.dataCspGetResellerMarginForReseller(teamId, resellerId, dataToken);
    };

    self.dataGetDashboardLayout = function(teamId, dashboardId, dashboardVersion, dataToken) {
      return $eaBackendAzureV2.dataGetDashboardLayout(teamId, dashboardId, dashboardVersion, dataToken);
    };

    self.dataPostDashboardLayout = function(teamId, dashboardId, dashboardVersion, dashboardLayoutItems, dataToken) {
      return $eaBackendAzureV2.dataPostDashboardLayout(teamId, dashboardId, dashboardVersion, dashboardLayoutItems, dataToken);
    };

    self.dataGetDashboardWidgets = function(teamId, dashboardId, dataToken) {
      return $eaBackendAzureV2.dataGetDashboardWidgets(teamId, dashboardId, dataToken);
    };

    self.dataPostDashboardWidget = function(teamId, dashboardId, widgetDescription, dataToken) {
      return $eaBackendAzureV2.dataPostDashboardWidget(teamId, dashboardId, widgetDescription, dataToken);
    };

    self.dataDeletDashboardWidget = function(teamId, dashboardId, widgetId, dataToken) {
      return $eaBackendAzureV2.dataDeletDashboardWidget(teamId, dashboardId, widgetId, dataToken);
    };

    self.dataGetContractTags = function(teamId, contractId, dataToken) {
      return $eaBackendAzureV2.dataGetContractTags(teamId, contractId, dataToken);
    };

    self.dataGetMetersForService = function(teamId, contractId, month, serviceId, dataToken) {
      return $eaBackendAzureV2.dataGetMetersForService(teamId, contractId, month, serviceId, dataToken);
    };

    self.dataCpvGetConsentLink = function(teamId, accountId, dataToken) {
      return $eaBackendAzureV2.dataCpvGetConsentLink(teamId, accountId, dataToken);
    };
  });
