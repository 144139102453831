'use strict';

/*
 */
angular.module('azureCostsFeApp').directive('eaWidgetRiPurchaseImpact', function() {
  return {
    restrict: 'C',
    templateUrl: 'views/directive_ea_ri_purchase_impact.html',
    controller: 'EaWidgetRiPurchaseImpactCtrl'
  };
}).controller('EaWidgetRiPurchaseImpactCtrl', function($scope, $rootScope, $q, $state, $stateParams, $eaDataCoordinator, $eaWidgetRiPurchaseImpactDataSources, $eaUserProfile, $acCacheIdentifierGenerator) {

  // some UI states
  $scope.hasData = false;
  $scope.hasError = false;
  $scope.selectorHeight = 63;
  $scope.disclaimerHeight = 40;

  $scope.buildDashboardDefer = $q.defer();
  $scope.lastLoadedData;

  $scope.enrollments = [];
  $scope.currentEnrollment;

  $scope.virtualMachines = [];
  $scope.currentVirtualMachine;

  // other state internal
  var chartElement;
  var lastSize;

  function handleProfileData() {

    $eaUserProfile.getContracts($scope.profiles.user, $scope.team).forEach(function(contract) {

      var contractModel = {
        Name: contract.Definition === 'EA' ? 'EA-' + contract.Enrollment : contract.Name,
        Id: contract.Enrollment,
        Team: contract.Team
      };

      $scope.enrollments.push(contractModel);

      if (!$scope.currentEnrollment && $stateParams.contract && contractModel.Id === $stateParams.contract) {
        $scope.currentEnrollment = contractModel;
      }
    });

    if ($scope.enrollments.length > 0) { $scope.currentEnrollment = $scope.enrollments[0]; }
  }

  function handleVirtualMachines() {

    // load the virtual machines which are available
    return $eaDataCoordinator.queryData($scope.team, $scope.currentEnrollment.Id, $scope.token, 'RiAnalysisVirtualMachineItemClass', {collection: 'ri.v1'}).then(function (riVirtualMachines) {

      // set the vms
      $scope.virtualMachines = riVirtualMachines;

      // check if we have a special VM request in the url
      if ($stateParams.service)
      {
        riVirtualMachines.forEach(function(vm) {
          if (vm.serviceid === $stateParams.service) {
            $scope.currentVirtualMachine = vm;
          }
        });
      }

      // select the first one
      if (!$scope.currentVirtualMachine && $scope.virtualMachines.length > 0) {
        $scope.currentVirtualMachine = $scope.virtualMachines[0];
      }

      // done
      return $q.when();
    })
  }

  function buildDashboard() {

    // set the deferrer pending
    $scope.buildDashboardDefer = $q.defer();

    // register the build chart callback
    $scope.buildChartCallback = function (chartElementFromDirective) {

      // get the cart element
      chartElement = chartElementFromDirective;

      // adapt to last know size
      if (lastSize) {
        chartElement.setSize(lastSize.w, lastSize.h - $scope.selectorHeight - $scope.disclaimerHeight, false);
      }
    };

    // register the configure callback
    $scope.configureCallback = function (chartOptions) {

      // legend
      chartOptions.legend = {
        enabled: true
      };

      // tooltip
      chartOptions.tooltip = {
        enabled: true,
        headerFormat: '',
        formatter: function() {

          var dateLine = '<b>' + moment(new Date($scope.lastLoadedData.startdate)).add('day', this.point.x).format('YYYY/MM/DD') + ' (' + this.series.name + ')</b><br/>';
          var costsLine = '<span style="color:{point.color}">\u25CF</span> Costs: <b>' + this.point.y.toFixed(2) + $scope.lastLoadedData.currencysymbol + '</b><br/>';

          if (this.series.name.toUpperCase().indexOf('FEE') !== -1) {
            return dateLine + costsLine;
          } else {

            // build the usage line
            var usageLine = '<span style="color:{point.color}">\u25CF</span> Usage: <b>' + ($scope.lastLoadedData.usages && $scope.lastLoadedData.usages.length > this.point.x ? $scope.lastLoadedData.usages[this.point.x].toFixed(2) + ' hrs' : 'n/a') + '</b><br/>';

            // return the data
            return dateLine + costsLine + usageLine;
          }

        }
      };

      // xAxis
      chartOptions.xAxis = {
        tickInterval: 31,
        labels: {
          x: 50,
          formatter: function () {
            return moment($scope.lastLoadedData.startdate).add(this.value, 'days').format('MM/YY');
          }
        },
        plotLines: [{
          color: '#000000',
          dashStyle: 'longdashdot',
          value: $scope.lastLoadedData.projectionindex,
          width: 1
        }]
      };

      // yAxis
      chartOptions.yAxis = {
        title: {
          text: 'Accumulated Costs'
        }
      }
    };

    // regsiter the data callback
    $scope.dataCallback = function (seriesArray) {

      // load the spendings information from service layer
      // TODO: Ensure Retry is working when the report is in progress
      return $eaDataCoordinator.queryData($scope.team, $scope.currentEnrollment.Id, $scope.token, 'RiAnalysisResultItemClass', {
        ResourceInstanceId: $scope.currentVirtualMachine.serviceid,
        collection: 'ri.v1',
        cachedCollectionKey: 'RiAnalysisResultItemClass:' + $scope.team + ':' + $scope.currentEnrollment.Id + ':' + $scope.currentVirtualMachine.serviceid,
        query: {
          vmeter: $scope.currentVirtualMachine.vmeter
        }
      }).then(function (riAnalysisData) {

        if (riAnalysisData.error) {
          $scope.hasError = true;
          $scope.errorMessage = riAnalysisData.message;
          $scope.hasData = false;

        } else {
          // set the last loaded data
          $scope.lastLoadedData = riAnalysisData;

          // set that we have data
          $scope.hasData = true;
          $scope.hasError = false;

          // the last value
          var lastValue = 0;

          // prepare data
          var dataFromPast = [];
          var dataForFuture = [];
          var data1YearRi = [];

          for (var dataIndex = 0; dataIndex < riAnalysisData.spendings.length; dataIndex++) {

            // define the consumption values
            if (dataIndex < riAnalysisData.projectionindex) {
              dataFromPast.push([dataIndex, lastValue + riAnalysisData.spendings[dataIndex]]);
            } else if (dataIndex === riAnalysisData.projectionindex) {
              dataFromPast.push([dataIndex, lastValue + riAnalysisData.spendings[dataIndex]]);
              dataForFuture.push([dataIndex, lastValue + riAnalysisData.spendings[dataIndex]]);
            } else {
              dataForFuture.push([dataIndex, lastValue + riAnalysisData.spendings[dataIndex]]);
            }

            // span the ri values
            data1YearRi.push([dataIndex, riAnalysisData.rifees.y1]);

            // add the last value
            lastValue = lastValue + riAnalysisData.spendings[dataIndex];
          }

          // define the series
          seriesArray.push({
            type: 'area',
            color: '#b1cf95',
            name: 'Used Consumption',
            data: dataFromPast
          });

          seriesArray.push({
            type: 'area',
            color: '#e5efdb',
            name: 'Projected Consumption',
            data: dataForFuture
          });

          seriesArray.push({
            type: 'line',
            color: '#d38580',
            name: 'Annual RI Fee',
            data: data1YearRi,
            lineWidth: 1,
            dashStyle: 'Dash'
          });
        }

        // refresh dependent widgets
        $scope.refreshDependentWidgets();

        // commit the defer
        $scope.buildDashboardDefer.resolve();

        // done
        return $q.when();
      })
    };

    // trigger the build of the diagram
    $rootScope.$emit('build-chart-' + $scope.uuid);

    // return the promise
    return $scope.buildDashboardDefer.promise;
  }

  function processRefreshRequest() {

    // handle the profiles
    handleProfileData();

    // process all for the selected enrollment
    processChangeEnrollmentRequest();
  }

  function processChangeEnrollmentRequest() {
    // switch on the loading mode
    $scope.setLoading(true);

    // load the virtual machines
    return handleVirtualMachines().then(function() {

      // refresh the VM
      return processChangeVMRequest();
    })
  }

  function processChangeVMRequest() {

    // switch on the loading mode
    $scope.setLoading(true);

    // check if we have an active vm
    if ($scope.currentVirtualMachine) {

      return buildDashboard().then(function() {

        // switch off the loading mode
        $scope.setLoading(false);

      })
    } else {

      // switch off the loading mode
      $scope.setLoading(false);

      // done
      return $q.when();

    }
  }

  function resizeChart(newSize) {
    lastSize = newSize;
    if (chartElement) { chartElement.setSize(newSize.w, newSize.h - $scope.selectorHeight - $scope.disclaimerHeight, false); }
  }

  $scope.selectEnrollment = function(enrollment) {

    // set the current enrollment
    $scope.currentEnrollment = enrollment;

    // process the reuqest
    processChangeEnrollmentRequest();
  };

  $scope.selectVirtualMachine = function(vm) {

    $scope.currentVirtualMachine = vm;

    processChangeVMRequest();
  };

  $scope.reCalculateReports = function() {

    $acCacheIdentifierGenerator.updateDailyCacheLocalInjector('RiAnalysisResultItemClass');

    // refresh screen
    $state.go($state.current.name, { initial: null }, {reload: true});
  };

  // establish the resize callback
  $scope.onResize(function(newSize) {
    resizeChart(newSize);
  });

  // initialize when the host is ready
  $scope.onInitialize(processRefreshRequest);

  // rebuild the dashboard during reload
  $scope.onReload(processRefreshRequest);

  // register all datasources
  $eaWidgetRiPurchaseImpactDataSources.registerDataSources($scope);

  // if we are here everyhting is ready to load data
  $scope.finalizeRegistration();

});
