'use strict';

angular.module('azureCostsFeApp').service('$eaReportData', function($eaBackend, $eaBackendDataModeSelector, $q, $acCacheIdentifierGenerator, $timeout, $acDpnService) {
  var self = this;

  /*
   * Loads the meta data about a contract. This includes the contract as self and available reports
   * in our system
   */
  self.loadContractDetails = function(teamId, contractNumber, jwtToken, optionalParams) {

    // define the parameters
    optionalParams = optionalParams || {};
    optionalParams.maxPeriods = optionalParams.maxPeriods || 12;

    // get the contract
    return $eaBackend.dataGetContract(teamId, contractNumber, jwtToken).then(function(contractInformation) {

        // get the reports
        return $eaBackend.dataGetContractReports(teamId, contractNumber, jwtToken).then(function (contractReports) {

          // just take the last 12 months at max
          var contractReports = contractReports;
          if (contractReports.length > optionalParams.maxPeriods) { contractReports = contractReports.slice(0, optionalParams.maxPeriods); }
          var contractReportsSmall = contractReports.map(function(report) { return report.Id; });

          // assign the reports
          contractInformation.Reports = contractReportsSmall;
          contractInformation.ReportsFull = contractReports;

          // good to go
          return contractInformation;
        });
    });
  };

  self.loadReportHistory = function(teamid, contractNumber, jwtToken) {
    return $eaBackend.dataGetContractReportHistory(teamid, contractNumber, jwtToken);
  };

});
