'use strict';

angular.module('azureCostsFeApp').controller('TeamEditAppCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openTeamEditAppDialog = function(app) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/manage/modals/modal_ea_team_edit_app.html",
      controller: 'ModalTeamEditAppCtrl',
      resolve: {
        App: function() { return app; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
