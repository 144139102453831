'use strict';

/*
 * This widget is able to render just a simple value by the following aggregate function:
 *
 * - sum
 * - count
 * - avg
 *
 * Beside this the widget accepts the following additional options:
 *
 * - title  - The title of the widget
 * - filter - Check the general filter documentation
 *
 * The following JSON structure needs to be applied to the dashboards overview
 *
 * options: {
 *    filter: [],
 *    value: {
 *      property: 'spendings.Costs',
 *      func: 'sum||count||avg',
 *      unit: '€'
 *    }
 * }
 *
 */
angular.module('azureCostsFeApp').directive('eaWidgetSimpleValue', function() {
  return {
    restrict: 'C',
    template: '<div class="widget-simple-value"><p ng-style="colorStyle">{{widgetValue}}&nbsp;{{widgetUnit}}</p></div>',
    controller: 'EaWidgetSimpleValueCtrl'
  };
}).controller('EaWidgetSimpleValueCtrl', function($scope, $eaDataCoordinator, $eaDataAggregateOperations, $eaConfig) {

  // init the value
  $scope.widgetValue = 0;
  $scope.widgetUnit = $scope.options.value.unit;
  $scope.colorStyle = {};
  $scope.format = $scope.options.value.format || '(0.00 a)';

  // patch the values by config
  if ($scope.format.indexOf('config.') !== -1) {
    if ($eaConfig.numberFormat) {
      $scope.format = $eaConfig.numberFormat;
    } else {
      $scope.format = '(0.00 a)';
    }
  }

  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);

    // load the data of the specific property by filter
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.value.class, {maxPeriods: $scope.maxPeriods, collection: $scope.options.value.collection}).then(function(data) {

      // check if we are active or not
      if (!$scope.isActive()) { return; }

      // should we have no data just stay loading because it could be a connected datasource
      if (!data) { return; }

      // if we have data but only a [-1] means no data available
      if (data && data.length == 1 && data[0] == -1) {

        // mark the no data available text
        $scope.widgetValue = 'n/a';
        $scope.widgetUnit = '';

      } else {
        // apply the aggregate function to the data to get the single value
        var aggregatedValue = $eaDataAggregateOperations.execute($scope.options.value.func, data, $scope.options.value.property);

        // define the default value
        var prettyNumberValue = 0.0;

        if (!isNaN(aggregatedValue)) {

          // make the number pretty
          prettyNumberValue = numeral(aggregatedValue).format($scope.format);

          if ($scope.options.value.colorize) {

            if (aggregatedValue < 0) {
              $scope.colorStyle = { color: '#b1271b' }
            } else if (aggregatedValue > 0) {
              $scope.colorStyle = { color: '#b1cf95' }
            }
          }
        }

        // get teh unit value
        var unitValue = null;
        if (data && data[0]) { unitValue = data[0][$scope.options.value.unit]; }
        if (!unitValue) { unitValue = ''; }

        // apply the value
        $scope.widgetValue = prettyNumberValue;
        $scope.widgetUnit = unitValue;
      }

      // switch off the loading mode
      $scope.setLoading(false);

    });
  }

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
