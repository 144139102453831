angular.module('azureCostsFeApp').directive('acChartRaw', function() {
  return {
    restrict: 'C',
    template: '<div id=\"chart-container-{{uuid}}\"></div>',
    controller: 'AcChartRawCtrl',
    scope: {
      uuid: '=',
      configureCallback: '=',
      dataCallback: '=',
      buildChartCallback: '='
    }
  };
}).controller('AcChartRawCtrl', function($scope, $rootScope, $eaChartConfig, $acChartContainerHelpers, $q, $timeout) {

  // placeholder for the chart object as self
  $scope.chartElement = null;

  // define the chart container id
  $scope.chartContainerId = 'chart-container-' + $scope.uuid;

  function generateDiagram() {

    // build the diagram
    var areaOptions = $eaChartConfig.createAreaConfig();
    areaOptions.chart.renderTo = $scope.chartContainerId;

    // set some defaults to prevent crashes
    areaOptions.tooltip.enabled = false;
    areaOptions.yAxis.labels.enabled = false;
    areaOptions.xAxis.labels.enabled = false;

    // add the data elements
    var dataCallbackPromise = $q.when([]);
    if ($scope.dataCallback) { dataCallbackPromise = $q.when($scope.dataCallback(areaOptions.series)); }
    dataCallbackPromise.then(function () {

      // configure the chart
      var configCallbackPromise = $q.when([]);
      if ($scope.configureCallback) { configCallbackPromise = $q.when($scope.configureCallback(areaOptions)); }
      return configCallbackPromise.then(function() {

        // render the diagram
        $timeout(function() {
          $scope.chartElement = new Highcharts.Chart(areaOptions);
          if ($scope.buildChartCallback) {
            $scope.buildChartCallback($scope.chartElement);
          }
        }, 100);
      });
    });
  }

  // wait for a build request
  $rootScope.$on('build-chart-' + $scope.uuid, function() {
    // wait for the container
    return $acChartContainerHelpers.waitUntilChartContainerExists($scope.chartContainerId).then(function() {
      generateDiagram();
    });
  });

});
