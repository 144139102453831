'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackendAzure
 * @description
 * # eaBackendAzure
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$eaBackendAzure', function($q, $timeout, $http, $eaConfig, $eaBackendBase, $base64, $eaBackendDataModeSelector) {
    var self = this;

    // This is the main uri of the backend
    var backendUri  = ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + $eaConfig.apiHost + '/api/v1';

    var backendBoostUris = $eaConfig.apiBoostHosts.map(function(apiBoostHost) {
      return ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + apiBoostHost+ '/api/v1';
    });

    var stsUri      = ($eaConfig.stsProtocol ? $eaConfig.stsProtocol : 'https') + '://' + $eaConfig.stsHost + '/sts';

    var monUri  = ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + $eaConfig.apiHost + '/mon';

    self.stsUpdateToken = function(options, dataToken) {
      options = options || {};

      /*jshint camelcase: false */
      var body = { grant_type: 'update_token' };
      if (options.delegate) { body.delegate = options.delegate; }

      // ask for token update
      return $eaBackendBase.extractDataFromResponse($http.post(stsUri+ '/issue/oauth/token', body, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataPostProfileTouch = function(dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/me', null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetProfile = function(dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/me', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateProfile = function(profileProperties, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/me', profileProperties, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContracts = function(teamId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamId + '/contracts', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContract = function(teamId, contract, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamId + '/contracts/' + contract , {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContractSubscriptions = function(teamId, contract, dataToken, options) {
      var baseUri = backendUri + '/teams/' + teamId + '/contracts/' + contract + '/subscriptions';
      if (options && options.verify) { baseUri += '?verify=true'; }
      return $eaBackendBase.extractDataFromResponse($http.get(baseUri, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataPutContractSubscription = function(teamId, contract, subscriptionId, updatedFields, dataToken) {
      var baseUri = backendUri + '/teams/' + teamId + '/contracts/' + contract + '/subscriptions/' + subscriptionId;
      return $eaBackendBase.extractDataFromResponse($http.put(baseUri, updatedFields, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContractSubscriptionsVerifyConnection = function(teamId, contract, subscriptionId, offerid, country, currency, dataToken) {
      var baseUri = backendUri + '/teams/' + teamId + '/contracts/' + contract + '/subscriptions/' + subscriptionId + '/verify-connection?offerId=' + encodeURIComponent(offerid) + '&country=' + encodeURIComponent(country) + '&currency=' + encodeURIComponent(currency);
      return $eaBackendBase.extractDataFromResponse($http.get(baseUri, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContractReportData = function(teamId, contract, report, cacheIdentifier, dataToken, retry) {
      var queryParameters = '';

      // build the cache identifier
      if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
        queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
      }

      // generate the cycle parameter
      if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
        if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
        queryParameters += 'cycle=billing';
      }

      // generate the nomargin parameter
      if ($eaBackendDataModeSelector.isNoMarginEnabled()) {
        if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
        queryParameters += 'nomargin=true';
      }

      // add the retry parameter
      if (retry)
      {
        if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
        queryParameters += 'retry=true';
      }

      // user our api boost hosts, generate the index based on report name
      var  boostIndex = (parseInt(report.split('-')[1]) % backendBoostUris.length);
      return $eaBackendBase.extractDataFromResponse($http.get(backendBoostUris[boostIndex] + '/teams/' + teamId + '/contracts/' + contract + '/reports/' + report + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRegisterContract = function(teamid, contract, token, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/contracts', { Enrollment: contract, Token: token }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataPostRatePlanRegistrationId = function(teamid, callbackUri, directoryDomain, defaultCurrency, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/contracts/registrationid', { Callback: callbackUri, DirectoryDomain: directoryDomain, DefaultCurrency: defaultCurrency }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataAllSyncReports = function(teamid, contract, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri  + '/teams/' + teamid + '/contracts/' + contract + '/reports/sync', null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataMonthSyncReports = function(teamid, contract, month, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri  + '/teams/' + teamid + '/contracts/' + contract + '/reports/' + month + '/sync', null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateContract = function(teamid, contract, contractInformation, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri  + '/teams/' + teamid + '/contracts/' + contract, contractInformation, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataSetServiceTagsV1 = function(teamid, contract, serviceName, serviceTags, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri  + '/teams/' + teamid + '/contracts/' + contract + '/services/' + $base64.encode(serviceName) + '/tags?enc=64',  { tags: serviceTags }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataSetServiceTagsV2 = function(teamid, contract, serviceName, serviceType, serviceTags, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri  + '/teams/' + teamid + '/contracts/' + contract + '/servicetypes/' + serviceType  + '/services/' + $base64.encode(serviceName) + '/tags?enc=64',  { tags: serviceTags }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataSetServiceTagsV3 = function(teamid, contract, serviceName, serviceType, serviceSubscriptionId, serviceTags, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri  + '/teams/' + teamid + '/contracts/' + contract + '/subscriptions/' + serviceSubscriptionId + '/servicetypes/' + serviceType  + '/services/' + $base64.encode(serviceName) + '/tags?enc=64',  { tags: serviceTags }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateTeamImage = function(teamid, teamImageFile, dataToken) {

      // generate the form data
      var fd = new FormData();
      fd.append('file', teamImageFile);

      // upload the file
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/image', fd, {headers: $eaBackendBase.getDefaultHeadersImageUpload(dataToken)}));
    };

    self.dataGetTeamImageLocation = function(teamid) {
      var defer = $q.defer();

      $http.head(backendUri + '/public/teams/' + teamid + '/image').then(function() {
        defer.resolve(backendUri + '/public/teams/' + teamid + '/image');
      }).catch(function() {
        defer.resolve(undefined);
      });

      return defer.promise;
    };

    /*
     * Notification Settings API
     */
    self.dataGetContractNotifcationSettings = function(teamid, contract, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/contracts/' + contract + '/notifications', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetContractNotifcationSettingsForUser = function(teamid, contract, targetUserId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/contracts/' + contract + '/notifications/' + targetUserId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.updateGetContractNotifcationSettings = function(teamid, contract, notificationSettings, dataToken, options) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/contracts/' + contract + '/notifications', { NotificationSettings: notificationSettings, Options: options }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.updateGetContractNotifcationSettingsForUser = function(teamid, contract, targetUserId, notificationSettings, dataToken, options) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/contracts/' + contract + '/notifications/' + targetUserId, { NotificationSettings: notificationSettings, Options: options }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * History API
     */
    self.dataGetContractReportHistory = function(teamid, contract, dataToken) {

      // generate the nomargin parameter
      var queryParameters = '';
      if ($eaBackendDataModeSelector.isNoMarginEnabled()) {
        queryParameters = '?nomargin=true';
      }

      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/contracts/' + contract + '/history/daily' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };


  /*
   * MetaData API
   */
  self.dataGetMetadata = function(collection, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/metadata/' + collection, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * Teaming API
   */
    self.dataMigrateToTeamAccount = function(teamName,dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/convert', { Name: teamName }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetTeamProfile = function(teamid, members, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '?members=' + (members ? '1' : '0'), {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataInviteUserToTeam = function(teamid, contact, role, subscriptions, callback, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/invitations', { Contact: contact, Role: role, Subscriptions: subscriptions, Callback: callback}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataReInviteUserToTeam = function(teamid, invitationid, callback, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/teams/' + teamid + '/invitations/' + invitationid, { Callback: callback }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRemoveUserFromTeam = function(teamid, userid, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.delete(backendUri  + '/teams/' + teamid + '/members/' + userid, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateTeamProfile = function(teamid, profile, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/teams/' + teamid, profile, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateTeamMembership = function(teamid, userid, role, subscriptions, contact, dataToken) {
      var body = {};

      if (role) { body.Role = role; }
      if (subscriptions) { body.Subscriptions = subscriptions; }
      if (contact) { body.Contact = contact; }

      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/teams/' + teamid + '/members/' + userid, body, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRegisterAppToTeam = function(teamid, name, clientId, clientSecret, role, subscriptions, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/apps', { Name: name, ClientId: clientId, ClientSecret: clientSecret, Role: role, Subscriptions: subscriptions }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRegisterUserToTeam = function(teamid, contact, role, subscriptions, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/users', { Contact: contact, Role: role, Subscriptions: subscriptions}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * Branding API
     */
    self.dataUpdateBranding = function(teamid, brandingSettings, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/css', brandingSettings, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetBranding = function(teamid) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/public/teams/' + teamid + '/css'));
    };

    self.dataGetBucket = function(bucketname) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/public/buckets/' + bucketname));
    };

    self.dataGetTeamBuckets = function(teamid, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/buckets', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCreateTeamBucket = function(teamid, bucketName, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/buckets/' + bucketName, null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataDeleteTeamBucket = function(teamid, bucketName, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.delete(backendUri + '/teams/' + teamid + '/buckets/' + bucketName, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * Azure AAD API (Data Token required)
     */
    self.dataGetAADConnections = function(teamid, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/aad', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRegisterAADConnection = function(teamid, connectionData, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/aad', connectionData, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataDeleteAADConnection = function(teamid, aadId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.delete(backendUri + '/teams/' + teamid + '/aad/' + aadId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetAuthInformation = function(teamid) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/public/teams/' + teamid + '/auth'));
    };

    self.dataGetAADConnectionGroups = function(teamid, tenantId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/aad/' + tenantId + '/groups', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * Billing API
     */
    self.dataStartTrial = function(plan, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/me/trial/' + plan, null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataPurchase = function(plan, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/me/purchase/' + plan, null, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateBillingInformation = function(billingAddress, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/me/billing',  { PaymentMethod: 'invoice', InvoiceAddress: billingAddress }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetBillingInformation = function(dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/me/billing', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * Data Filter API
    */
    self.dataCreateDataFilter = function(teamid, ea, filter, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/teams/' + teamid + '/contracts/' + ea + '/filters', filter, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataGetDataFilters = function(teamid, ea, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/teams/' + teamid + '/contracts/' + ea + '/filters', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataRemoveDataFilter = function(teamid, ea, filterid, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.delete(backendUri + '/teams/' + teamid + '/contracts/' + ea + '/filters/' + filterid, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataUpdateDataFilter = function(teamid, ea, filter, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/teams/' + teamid + '/contracts/' + ea + '/filters/' + filter.Id, filter, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * CSPs
     */
    self.dataCspEnroll = function(teamName, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/csps/enroll', { Name: teamName }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspGetAccounts = function(teamId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/csps/' + teamId + '/accounts', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspRegisterAccountT2 = function(teamId, name, currency, country, billingDay, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.post(backendUri + '/csps/' + teamId + '/accounts', { TierLevel: 'T2', Name: name, Currency: currency, Country: country, BillingDay: billingDay}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspUpdateAccount = function(teamId, accountId, name, clientId, clientSecret, domain, billingDay, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/csps/' + teamId + '/accounts/' + accountId, { Name: name, ClientId: clientId, ClientSecret: clientSecret, Domain: domain, BillingDay: billingDay}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspDeleteAccount = function(teamId, accountId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.delete(backendUri + '/csps/' + teamId + '/accounts/' + accountId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspGetCustomersInAccount = function(teamId, accountId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/csps/' + teamId + '/accounts/' + accountId + '/customers', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspGetCustomersSpendingsInAccount = function(teamId, accountId, month, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/csps/' + teamId + '/accounts/' + accountId + '/spendings/' + month, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspGetResellerFilterForCustomer = function(teamId, accountId, customerId, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.get(backendUri + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/filter', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    self.dataCspSetResellerFilterForCustomer = function(teamId, accountId, customerId, filter, dataToken) {
      return $eaBackendBase.extractDataFromResponse($http.put(backendUri + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/filter', filter, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
    };

    /*
     * Debug API
     */
    self.dataMonDebugCsp = function(clientId, clientSecret, domain, optionalUserCredentials) {

      var headers = {};

      if (optionalUserCredentials) {
        var auth = $base64.encode(optionalUserCredentials.Username + ":" + optionalUserCredentials.Password);
        headers = {"Authorization": "Basic " + auth};
      }

      return $eaBackendBase.extractDataFromResponse($http.get(monUri+ '/debug/csp?clientId=' + clientId + '&clientSecret=' + clientSecret + '&domain=' + domain, {headers: headers}));
    };

    /*
     * Service Charts API
     */
    self.dataGetServiceCharts = function() {
      return $q.when([
        {
          position: 1,
          lastPosition: 3,
          name: 'Azure Virtual Machines'
        },
        {
          position: 2,
          lastPosition: 2,
          name: 'Azure Virtual Machines'
        },
        {
          position: 3,
          lastPosition: 1,
          name: 'Azure Virtual Machines'
        },
        {
          position: 4,
          lastPosition: 4,
          name: 'Azure Virtual Machines'
        },
        {
          position: 5,
          lastPosition: 6,
          name: 'Azure Virtual Machines'
        },
        {
          position: 6,
          lastPosition: 7,
          name: 'Azure Virtual Machines'
        },
        {
          position: 7,
          lastPosition: 5,
          name: 'Azure Virtual Machines'
        },
        {
          position: 8,
          lastPosition: 6,
          name: 'Azure Virtual Machines'
        },
        {
          position: 9,
          lastPosition: 9,
          name: 'Azure Virtual Machines'
        },
        {
          position: 10,
          lastPosition: 10,
          name: 'Azure Virtual Machines'
        }
      ]);
    };
  });
