'use strict';

angular.module('azureCostsFeApp').controller('SurveyCtrlMixin', function($scope, $modal, $eaBackend, $window) {

  $scope.askForSurvey = function() {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/dashboards/modals/modal_ea_survey.html",
      controller: 'ModalSurveyCtrl'
    });

    // returns a promise
    return modalInstance.result;
  };

  $scope.askForSurveyIfNeeded = function(session) {

    // check if we ask for survey
    if (session.Surveys.Influencer === null || session.LoginCount - session.Surveys.Influencer <= 50) {
      return
    }

    // if customer did not the survey we ask after 10 logins or every 20 more logins
    // until he is participating
    if (session.LoginCount !== 10 && (session.LoginCount - 10) % 20 !== 0) {
      return;
    }

    $scope.askForSurvey().then(function () {

      // register that the user participated the survey
      $eaBackend.dataUpdateProfile({SurveyAtLogin: session.LoginCount}, session.DataToken).finally(function () {

        // open survey link
        $window.location.href = 'http://azurecosts.polldaddy.com/s/css';

      });
    });
  }

});
