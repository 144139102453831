'use strict';

function MonthDefinition(monthDefinitionString) {
  var self = this;

  var currentMonthDefinitionString = monthDefinitionString;

  function intDiv(a,b)
  {
    var result = a/b;
    if(result>=0) {
      return Math.floor(result);
    } else {
      return Math.ceil(result);
    }
  }

  self.byAddingMonth = function(numberOfMonthToAdd) {

    // split the current definition by "-"
    var parts = currentMonthDefinitionString.split('-');

    // convert to numbers
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]);

    // add the month
    var newTargetMonthes = (year * 12) + month + numberOfMonthToAdd;

    // get the actual years
    year = intDiv(newTargetMonthes, 12);

    // get the motnh
    month = newTargetMonthes % 12;
    if (month === 0) {
      year = year - 1;
      month = 12;
    }

    // build the new definition
    return new MonthDefinition(year + '-' + ('0' + month).slice(-2));
  };

  self.toString = function() {
    return currentMonthDefinitionString;
  };
}

angular.module('azureCostsFeApp').service('$eaUtils', [function() {
  var self = this;

  self.createMonthDefinition = function(monthDefinitionString) {
    return new MonthDefinition(monthDefinitionString);
  };

}]);
