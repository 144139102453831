'use strict';

angular.module('azureCostsFeApp').controller('ModalTeamEditPermissionsCtrl', function($scope, $modalInstance, $controller, $eaBackend, $q, AvailableSubscriptions, User) {

  $.extend(self, $controller('TeamManagementCtrlMixin', {$scope: $scope, $eaBackend: $eaBackend, $q: $q}));

  $scope.title = "Edit Permissions";
  $scope.availableSubscriptions = AvailableSubscriptions;

  $scope.contractCheckBoxChanged = function(contractModel) {
    // assign the view state to the child objects
    contractModel.Subscriptions.forEach(function(subscription) {
      $scope.viewStateModelContract[contractModel.Contract.Enrollment][subscription.Id] = $scope.viewStateModelContract[contractModel.Contract.Enrollment]['contract'];
    });
  };

  // the view model contains the state for the view
  $scope.viewStateModelContract = {};

  // Expand asterisk
  if (Object.keys(User.Subscriptions).length === 1 && Object.keys(User.Subscriptions)[0] === '*') {
    $scope.availableSubscriptions.forEach(function(contractModel) {
      $scope.viewStateModelContract[contractModel.Contract.Enrollment] = { contract: true };
      contractModel.Subscriptions.forEach(function(subscription) {
        $scope.viewStateModelContract[contractModel.Contract.Enrollment][subscription.Id] = true;
      })
    });
  }

  // visit the data
  Object.keys(User.Subscriptions).forEach(function(contractNumber) {

    if (contractNumber !== '*') {
      var subscritpionSettings = User.Subscriptions[contractNumber];
      if (subscritpionSettings.length === 1 && subscritpionSettings[0] === '*') {
        $scope.viewStateModelContract[contractNumber] = {contract: true };
      } else {

        $scope.viewStateModelContract[contractNumber] = {contract: false };

        subscritpionSettings.forEach(function(subscriptionId) {
          $scope.viewStateModelContract[contractNumber][subscriptionId] = true;
        })
      }
    }
  });

  // generate the viewModel
  $scope.availableSubscriptions.forEach(function(contractModel) {

      // check if we do not have this key
      if ($scope.viewStateModelContract[contractModel.Contract.Enrollment] === undefined || $scope.viewStateModelContract[contractModel.Contract.Enrollment]['contract'] === undefined) {
          $scope.viewStateModelContract[contractModel.Contract.Enrollment] = {contract: false };

          contractModel.Subscriptions.forEach(function (subscription) {

            if ($scope.viewStateModelContract[contractModel.Contract.Enrollment][subscription.Id] === undefined) {

                $scope.viewStateModelContract[contractModel.Contract.Enrollment][subscription.Id] = false;
            }
          })
      }
  });

  // update all childs
  $scope.availableSubscriptions.forEach(function(contractModel) {

    if ($scope.viewStateModelContract[contractModel.Contract.Enrollment]['contract']) {
      $scope.contractCheckBoxChanged(contractModel);
    }
  });

  $scope.ok = function () {

    // reduce to asterisk
    var everyEntryIsTrue = true;
    $scope.availableSubscriptions.forEach(function(contractModel) {
      if (!$scope.viewStateModelContract[contractModel.Contract.Enrollment]['contract']) {
        everyEntryIsTrue = false;
      }
    });

    // set the *
    if (everyEntryIsTrue) {
      User.Subscriptions = {'*': ['*']};
    } else {

      User.Subscriptions = {};

      Object.keys($scope.viewStateModelContract).forEach(function(contractNumber) {

        if ($scope.viewStateModelContract[contractNumber]['contract']) {
          User.Subscriptions[contractNumber] = ['*'];
        } else {
          User.Subscriptions[contractNumber] = [];

          Object.keys($scope.viewStateModelContract[contractNumber]).forEach(function (subscriptionId) {

            if (subscriptionId !== 'contract' && $scope.viewStateModelContract[contractNumber][subscriptionId]) {
              User.Subscriptions[contractNumber].push(subscriptionId);
            }
          })
        }
      });
    }

    $modalInstance.close(User.Subscriptions);
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };
});



