'use strict';

angular.module('azureCostsFeApp').service('$eaBucket', [ '$q', '$eaBackend', '$eaConfig', '$location', function($q, $eaBackend, $eaConfig, $location) {
  var self = this;

  function resolveHostSegmentToTeamId() {
    // load the branding
    var hostSegment = $location.host().split('.')[0];

    if (hostSegment.split('-').length === 5) {
      return $q.when(hostSegment);
    } else {
      // resolve the host segment into team id
      return $eaBackend.dataGetBucket(hostSegment).then(function(bucketModel) {
        return bucketModel.TeamIdentifier;
      });
    }
  }

  self.getTeamIdFromHostSegment = function() {

    // check if we have a host prefix
    if ($location.host().indexOf($eaConfig.feHost)) {
      return resolveHostSegmentToTeamId();
    } else {
      return $q.when();
    }
  };

  self.hashHostPrefix = function() {
    return ($location.host().indexOf($eaConfig.feHost) > 0);
  };

}]);
