'use strict';

angular.module('azureCostsFeApp').controller('ModalCspDefineResellerFeaturesCtrl', function($scope, $modalInstance, Customer, Features, Account) {

  $scope.features = JSON.parse(JSON.stringify(Features));
  $scope.customer = JSON.parse(JSON.stringify(Customer));
  $scope.account = JSON.parse(JSON.stringify(Account));
  
  $scope.ok = function() {
    $modalInstance.close($scope.features);
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.isDisabled = function(element) {
    switch(element)
    {
      case "consumption":
        return $scope.isDisabledConsumption();
      case "reservation":
        return $scope.isDisabledReservation();
      case "office":
        return $scope.isDisabledOffice();        
      case "marketplace":
        return $scope.isDisabledMarketplace();                
      default:
        return true;    
    }    
  }

  $scope.isDisabledConsumption = function() {
    return $scope.account.TierLevel !== 'V1';    
  }

  $scope.isDisabledReservation = function() {
    return $scope.account.TierLevel !== 'T1' && $scope.account.TierLevel !== 'T1N';    
  }

  $scope.isDisabledOffice = function() {
    return $scope.account.TierLevel !== 'T1' && $scope.account.TierLevel !== 'T1N';
  }
  
  $scope.isDisabledMarketplace = function() {
    return true;
  }
});



