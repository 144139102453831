'use strict';

angular.module('azureCostsFeApp').controller('ModalAdminAddUserCtrlMixin', function($scope, $modal) {

  $scope.openAddNewUser = function() {

    var modalInstance = $modal.open({
      templateUrl: "views/admin/modals/model_admin_add_user.html",
      controller: 'ModalAdminAddUserCtrl'
    });

    // returns a promise
    return modalInstance.result;
  };
});
