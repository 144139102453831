'use strict';

angular.module('azureCostsFeApp').controller('ModalCspRegisterCustomerCostMgntCtrl', function($scope, $modalInstance) {

  $scope.newCustomer= {
    CustomerName: undefined,
    HasConsumption: true
  };

  $scope.ok = function () {
    $modalInstance.close({ customer: $scope.newCustomer});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.validateForm = function() {
    return $scope.newCustomer.CustomerName;    
  }
});



