'use strict';

angular.module('azureCostsFeApp').service('$eaBlog', function($http, $q) {
  var self = this;

  self.get = function(maxElements) {
    maxElements = maxElements || 5;

    return $http.get('https://public-api.wordpress.com/rest/v1/sites/35723366/posts/?pretty=true&order_by=date&category=azure-cost-monitor&number=' + maxElements).then(function(result) {
      if (result.status === 200 || result.status === 201) {
        return $q.when(result.data.posts);
      } else {
        return $q.reject();
      }
    }).catch(function(){
      return $q.reject();
    });
  };

  self.shortenedExcerpt = function(blogEntry, length) {

    // remove elements
    blogEntry.excerpt = blogEntry.excerpt.removeHeading('<p>');
    blogEntry.excerpt = blogEntry.excerpt.removeTrailing('\n');
    blogEntry.excerpt = blogEntry.excerpt.removeTrailing('</p>');
    blogEntry.excerpt = blogEntry.excerpt.removeTrailing('[&hellip;]');

    // shorten
    var shortenText = null;
    if (blogEntry.excerpt.length < length) {
      shortenText = blogEntry.excerpt;
    } else {
      shortenText = blogEntry.excerpt.substring(0, length);
    }

    // cut on the last word
    var lastSpace = shortenText.lastIndexOf(' ');
    return shortenText.substring(0, lastSpace);
  };

});
