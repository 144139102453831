'use strict';

angular.module('azureCostsFeApp').service('$eaWidgetRiPurchaseImpactDataSources', function($q, $eaDataSourceManager, $eaDataAggregateOperations) {
  var self = this;


  self.registerDataSources = function(controllerScope)
  {
    // Register all datasources this widget offers
    // Data source the widget provides

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'PotentialSavings', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function () {

        // cummolate the annual spendings
        var overAllSpendings = $eaDataAggregateOperations.sum(controllerScope.lastLoadedData.spendings);

        // diff from the costs for 1year
        var savaings1Year = overAllSpendings - controllerScope.lastLoadedData.rifees.y1;
        var savaings3Year = (overAllSpendings * 3) - controllerScope.lastLoadedData.rifees.y3;

        // build the result
        return $q.when([{ Year1: savaings1Year, Year3: savaings3Year, CurrencySymbol: controllerScope.lastLoadedData.currencysymbol}]);
      });
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'VMDetails', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function () {

        return $q.when({
          ConsumptionMeter: controllerScope.lastLoadedData.consumptionmeter,
          MachineType: controllerScope.lastLoadedData.machinetype,
          RIFeeY1: controllerScope.lastLoadedData.rifees.y1,
          RIFeeY3: controllerScope.lastLoadedData.rifees.y3,
          CurrencySymbol: controllerScope.lastLoadedData.currencysymbol,
          DailyBurnRate: controllerScope.lastLoadedData.burnrate,
          AvgRuntime: controllerScope.lastLoadedData.avgruntime,
          RIBreakEvenY1: controllerScope.lastLoadedData.breakeveny1,
          RIBreakEvenY3: controllerScope.lastLoadedData.breakeveny3
        });
      });
    });
  };

});
