'use strict';

angular.module('azureCostsFeApp').controller('EaAdminUsersCtrl', function ($scope, $eaBackendAdmin, $busy, $eaCurrentSession, $eaSession2, $window, $controller, $modal, $eaBackendAdminV2) {
  $.extend(self, $controller('ModalAdminAddUserCtrlMixin', {$scope: $scope, $modal: $modal}));

  function loadUsers() {
    // load the users
    return $eaBackendAdmin.dataGetUsers($scope.serviceProvider.Id, $eaCurrentSession.DataToken).then(function (users) {

      // adapt the last login date and the state
      users.forEach(function(user) {

        // adapt the last login
        if (user.LastLogin === null) {
          user.LastLogin = '1970-01-01T00:00:00';
        }

        // adapt the status
        if (user.UserId.indexOf('PSEUDO') === 0) {
          user.Status = 'pending';
        } else {
          user.Status = 'active';
        }
      });

      $scope.error = null;
      $scope.orderByField = 'SignUp';
      $scope.reverseSort = true;
      $scope.users = users;
    });
  }

  $scope.updateNavBar();

  $busy.during(loadUsers());

  $scope.onReload(function() {
    $busy.during(loadUsers());
  });


  $scope.removeUser = function(user) {
    var deleteOk = confirm("Do you really want to remove user: " + user.FirstName + ' ' + user.LastName);

    if (deleteOk) {

      // delete contract
      $busy.during($eaBackendAdmin.dataDeleteUser($scope.serviceProvider.Id, user.UserId, $eaCurrentSession.DataToken).finally(function() {

        // remove from array
        var idx = $scope.users.indexOf(user);
        if (idx !== -1) {
          $scope.users.splice(idx, 1);
        }

      }));
    }
  };

  $scope.actAsUser = function(user) {
    var actAsOk = confirm("Do you really want to switch to user: " + user.FirstName + ' ' + user.LastName + ' (' + user.Contact + ')');
    if (actAsOk) { $eaSession2.openActAsSession(user.UserId, $scope.serviceProvider.Id); }
  };

  $scope.addNewUser = function() {
    $scope.openAddNewUser().then(function (result) {      
      $eaBackendAdminV2.dataInviteUser(result.user.upn, $eaCurrentSession.DataToken).then(function() {
        $busy.during(loadUsers());
      })      
    })
  }
});
