'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgSubitemDefaultTemplate', function() {
  return {
    restrict: 'C',
    template:
      '<div ng-if="serviceItem[subitemIconProperty] && serviceItem[subitemIconProperty].indexOf(\'fa-\') !== 0" class="subscriptionServiceIcon" ng-class="serviceItem[subitemIconProperty]"></div>' +
      '<div ng-if="serviceItem[subitemIconProperty] && serviceItem[subitemIconProperty].indexOf(\'fa-\') === 0" class="subscriptionServiceIcon"><i class="fa" ng-class="serviceItem[subitemIconProperty]" style="font-size: 16px; margin-top: 2px; margin-bottom: 2px; display: block;"></i></div>' +
      '<div class="subscriptionServiceName" style="width: calc(100% - 36px);">{{getSubItemTitle(serviceItem)}}</div>' +
      '<div class="clearfix"></div>',
    scope: false
  };
});
