'use strict';

angular.module('azureCostsFeApp').controller('AcCspCtrl', function ($scope, $eaCspService, $eaCurrentSession, $stateParams) {

  // the scope
  $scope.Profile = undefined;

  // load the csp profile
  return $eaCspService.loadProfileById($stateParams.resellerId, $eaCurrentSession).then(function(cspProfile) {

    // set the profile
    $scope.Profile = cspProfile;
  })
});
