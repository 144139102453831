'use strict';

angular.module('azureCostsFeApp').controller('EaAdminCtrl', function ($scope, $busy, $eaCurrentSession, $toolbar, $eaBackendAdmin) {

  // reset the error
  $scope.error = null;

  $scope.serviceProviderDefault = {
    Id: 'default',
    Name: 'Default'
  };

  $scope.serviceProvider = $scope.serviceProviderDefault;

  $scope.serviceProviders = [];

  $scope.updateNavBar = function() {

    // configure the toolbar
    $toolbar.addItem('admin.back', 'Back', 'fa-chevron-left', null, true, '/signin');

    if ($scope.authorizedAsAdmin) {

      $toolbar.addItem('admin.providers', $scope.serviceProvider ? $scope.serviceProvider.Name : 'Default', 'fa-globe', 'Active Service Provider', true, null);

      $scope.serviceProviders.forEach(function(provider) {
        $toolbar.addItem('admin.providers.' + provider.Id, provider.Name, 'fa-globe', provider.Name, true, $scope.switchServiceProviderCallback(provider), null, 'admin.providers');
      });

      $toolbar.addItem('admin.contracts', 'Contracts', 'fa-book', 'Shows all contracts in the system', true, '/admin/contracts');
      $toolbar.addItem('admin.users', 'Users', 'fa-users', 'Shows all users in the system', true, '/admin/users');
      $toolbar.addItem('admin.jobs', 'Jobs', 'fa-tasks', 'Shows all jobs in the system', true, '/admin/jobs');

      $toolbar.addItem('admin.performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadData);
    }
  };

  // check if we have ops permission
  $scope.authorizedAsAdmin = $eaCurrentSession.hasClaim('ops');

  // udpate the navbar
  $eaBackendAdmin.dataGetServiceProviders($eaCurrentSession.DataToken).then(function(providers) {

    $scope.serviceProviders = providers;
    $scope.serviceProviders.push($scope.serviceProviderDefault);
    $scope.updateNavBar();
  });

  var reloadOperation = null;
  $scope.onReload = function(cb) {
    reloadOperation = cb;
  };

  $scope.reloadData = function() {
    if (reloadOperation) {reloadOperation();}
  };

  $scope.switchServiceProviderCallback = function(targetServiceProvider) {
    return function() {
      $scope.serviceProvider = targetServiceProvider;
      $scope.updateNavBar();
      $scope.reloadData();
    }
  }

});
