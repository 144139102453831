'use strict';

angular.module('azureCostsFeApp').service('$eaCspService', function($q, $eaBackend, $eaSession2, $window, $eaTeamManagement, $eaUserProfile) {
  var self = this;

  self.enroll = function(currentSession, callbackLocation) {

    // enroll into CSP
    return $eaBackend.dataCspEnroll('Solution Provider', currentSession.DataToken).then(function() {

      // destroy the session
      $eaSession2.destroySession();

      // visit the callbackLocation
      $window.location.href = callbackLocation;
    });

  };

  self.loadProfiles = function(currentSession) {
    return $eaUserProfile.load(currentSession.DataToken, true).then(function(userProfile) {
      return $eaTeamManagement.getTeamProfilesByRole(userProfile, 'admin', false, currentSession.DataToken).then(function(teams) {

        // filter
        var cspTeams = teams.filter(function(team) {
          return (team.CloudSolutionProvider === true);
        });

        // check
        return $q.when(cspTeams);
      });
    });
  };

  self.loadProfileById = function(cspProfileId, currentSession) {
    return $eaTeamManagement.getTeamProfileById(cspProfileId, false, currentSession.DataToken);
  };

  self.loadAccounts = function(cspTeamProfile, currentSession) {
    return $eaBackend.dataCspGetAccounts(cspTeamProfile.Id, currentSession.DataToken);
  };

  self.loadAccount = function(cspTeamProfile, accountId, currentSession) {
    return $eaBackend.dataCspGetAccount(cspTeamProfile.Id, accountId, currentSession.DataToken);
  };

  self.registerAccount = function(cspTeamProfile, currentSession, name, clientId, clientSecret, domain, billingDay, syncCustomers, autoActivateCustomers) {
    return $eaBackend.dataCspRegisterAccount(cspTeamProfile.Id, name, clientId, clientSecret, domain, billingDay, syncCustomers, autoActivateCustomers, currentSession.DataToken);
  };

  self.registerAccountT2 = function(cspTeamProfile, currentSession, name, currency, country, billingDay) {
    return $eaBackend.dataCspRegisterAccountT2(cspTeamProfile.Id, name, currency, country, billingDay, currentSession.DataToken);
  };

  self.registerAccountEA = function(cspTeamProfile, currentSession, name, currency, token, cmapping) {
    return $eaBackend.dataCspRegisterAccountEA(cspTeamProfile.Id, name, currency, token, cmapping, currentSession.DataToken);
  };

  self.registerAccountV1 = function(cspTeamProfile, currentSession, name, currency) {
    return $eaBackend.dataCspRegisterAccountV1(cspTeamProfile.Id, name, currency, currentSession.DataToken);
  };

  self.registerAccountDemo = function(cspTeamProfile, contractType, cmapping, currentSession) {
    var name = 'Demo Account';
    if (contractType === 'AWS') {
      name = 'Demo Account (AWS)';
    }

    return $eaBackend.dataCspRegisterAccountDemo(cspTeamProfile.Id, name, contractType, 'EUR', cmapping, currentSession.DataToken);
  };

  self.updateAccount = function(cspTeamProfile, accountModel, currentSession) {
    return $eaBackend.dataCspUpdateAccount(cspTeamProfile.Id, accountModel.AccountId, accountModel.Name, accountModel.ClientId, accountModel.ClientSecret, accountModel.Domain, accountModel.BillingDay, currentSession.DataToken);
  };

  self.deleteAccount = function(cspTeamProfile, accountModel, currentSession) {
    return $eaBackend.dataCspDeleteAccount(cspTeamProfile.Id, accountModel.AccountId, currentSession.DataToken);
  };

  self.getCustomers = function(cspTeamProfile, accountModel, currentSession) {
    return $eaBackend.dataCspGetCustomersInAccount(cspTeamProfile.Id, accountModel.AccountId, currentSession.DataToken);
  };

  self.getActiveCustomers = function(cspTeamProfile, accountModel, currentSession) {
    return self.getCustomers(cspTeamProfile, accountModel, currentSession).then(function(customers) {
      return customers.filter(function(c) { return c.State === 'active' || c.state === 'active'});
    });
  };

  self.activateCustomer = function(cspTeamId, cspAccountId, cspCustomerId, currentSession) {
    // activate the account
    return $eaBackend.dataCspActiveCustomersInAccount(cspTeamId, cspAccountId, cspCustomerId, currentSession.DataToken);
  };

  self.deActivateCustomer = function(cspTeamId, cspAccountId, cspCustomerId, currentSession) {
    // de-activate the account
    return $eaBackend.dataCspDeActiveCustomersInAccount(cspTeamId, cspAccountId, cspCustomerId, currentSession.DataToken);
  };

});
