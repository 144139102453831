'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep03Ctrl', function ($scope, $state) {
  $scope.team = $state.params.team;
  $scope.enrollment = $state.params.enrollment;
});
