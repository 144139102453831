/*
 * The elements section needs to have the following structure
 *
 * [
 *  {
 *    "XXX": "Label",
 *    "ZZZ": "Value"
 *  }
 * ]
 *
 * The property value-field-name defines the field which contains the value of the series. The property
 * label-field-name defines the name which is used in the legend.
 */
angular.module('azureCostsFeApp').directive('acChartArea', function() {
  return {
    restrict: 'C',
    template: '',
    controller: 'AcChartAreaCtrl',
    scope: {
      uuid: '=',
      elements: '=',
      valueFieldName: '=',
      labelFieldName: '=',
      options: '='
    }
  };
}).controller('AcChartAreaCtrl', function($scope, $eaChartConfig, $element) {

  // placeholder for the chart object as self
  $scope.chartElement = null;

  // define the chart container id
  $scope.chartContainerId = 'chart-container-' + $scope.uuid;

  // replace the id if necessary
  if ($element[0].id === null || $element[0].id === undefined || $element[0].id === '') {
    $element[0].id = $scope.chartContainerId;
  } else {
    $scope.chartContainerId = $element[0].id;
  }

  function generateDiagram() {
    // generate the series
    var series = $scope.elements.map(function(element) {
      return { name: element[$scope.labelFieldName], y: element[$scope.valueFieldName]};
    });

    // build the diagram
    var areaOptions = $eaChartConfig.createAreaConfig();
    areaOptions.chart.renderTo = $scope.chartContainerId;
    areaOptions.series.push({ data: series});

    if ($scope.options && $scope.options.ToolTipTemplate) { areaOptions.tooltip.pointFormat = $scope.options.ToolTipTemplate; }

    // y-Axis
    if ($scope.options && $scope.options.YAxisTemplate) {
      areaOptions.yAxis.labels.enabled = true;

      if (angular.isFunction($scope.options.YAxisTemplate)) {
        areaOptions.yAxis.labels.formatter= $scope.options.YAxisTemplate;
      } else {
        areaOptions.yAxis.labels.format = $scope.options.YAxisTemplate;
      }
    }

    // x-Axis
    if ($scope.options && $scope.options.XAxisTemplate) {
      areaOptions.xAxis.labels.enabled = true;

      if (angular.isFunction($scope.options.XAxisTemplate)) {
        areaOptions.xAxis.labels.formatter= $scope.options.XAxisTemplate;
      } else {
        areaOptions.xAxis.labels.format = $scope.options.XAxisTemplate;
      }
    }

    areaOptions.xAxis.allowDecimals = false;

    $scope.chartElement = new Highcharts.Chart(areaOptions);
  }

  $scope.$watch('elements', function() {
    if ($scope.chartElement) {
      generateDiagram();
    }
  });

  generateDiagram();
});
