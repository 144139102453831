'use strict';

angular.module('azureCostsFeApp').controller('ManageContractSettingsCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openManageSettingsDialog = function(contract, role, isCspCustomer) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/dashboards/modals/modal_ea_manage_contract_settings.html",
      controller: 'ModalContractSettingsCtrl',
      resolve: {
        contract: function() {
          return contract;
        },
        role: function() {
          return (role ? role : 'member');
        },
        csp: function() {
          return isCspCustomer ? true : false;
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
