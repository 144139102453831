'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceV2Spendings', function($acDataSourceSettings, $acDataSourceClasses, $q, $eaBackend, $injector, $acDataSourceHelper) {
  var self = this;

  // load the subscriptions
  function loadSubscriptions(teamid, contract, dataToken /*, className, optionalParams */) {
    return $eaBackend.dataGetContractSubscriptions(teamid, contract, dataToken).then(function(loadedSubscriptions) {

      var models = [];

      loadedSubscriptions.forEach(function(subscription) {

        var model = new $acDataSourceClasses.SubscriptionEntryClass();
        model.SubscriptionId = subscription.Id;
        model.SubscriptionName = subscription.Name;
        model.OfferingType = subscription.Offering;
        model.Country = subscription.Country;
        model.Contacts = subscription.Contacts ? subscription.Contacts : [];
        models.push(model);
      });

      return $q.when(models);
    });
  }

  // called from our infrastructure to the define the different
  // datasources this service handles
  self.registerDataSources = function(dataSourceManager) {

    // register the dashboard items for the csp portal
    $acDataSourceHelper.registerDataSourceDashboardItemClass(dataSourceManager, null, 'spending_dashboard_definitions.json');
    $acDataSourceHelper.registerDataSourceDashboardItemClass(dataSourceManager, 'spendings.v2', 'spending_dashboard_definitions_v2.json');

    // Register the correct Report loader
    $acDataSourceHelper.registerDataSourceReportClass(dataSourceManager, 'spendings.v2');

    // loads the subscription entries
    dataSourceManager.registerDataSource('global', 'SubscriptionEntryClass', null, loadSubscriptions);
    $acDataSourceHelper.registerDataSourceSpendingEntryClassForCoreApi(dataSourceManager, 'spendings.v2');

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', 'spendings.v2', $acDataSourceSettings.loadStaticDataGenericCallback);

    // load the available periods
    $acDataSourceHelper.registerDataSourcePeriodClass(dataSourceManager, 'spendings');
  };
});
