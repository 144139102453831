'use strict';

angular.module('azureCostsFeApp').controller('ModalCspRegisterCustomerCtrl', function($scope, $modalInstance) {

  $scope.newCustomer= {
    TenantIdOrDomain: undefined
  };

  $scope.ok = function () {
    $modalInstance.close({ customer: $scope.newCustomer});
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel");
  };

});



