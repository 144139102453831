'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep01Ctrl', function ($scope, $state, $stateParams, $busy, $eaCurrentSession, $eaCspService) {

  // contains the form content
  $scope.formStep01 = {
    partnership: 'cspt1'
  };

  $scope.next = function() {

    if ($scope.formStep01.partnership === 'cspt1') {
      return $state.go('authorized.csp.wadd.step02-t1', {resellerId: $stateParams.resellerId});
    } else if ($scope.formStep01.partnership === 'cspt2') {
      return $state.go('authorized.csp.wadd.step02-t2', {resellerId: $stateParams.resellerId});
    } else if ($scope.formStep01.partnership === 'cspea') {
      return $state.go('authorized.csp.wadd.step02-ea', {resellerId: $stateParams.resellerId});
    } else if ($scope.formStep01.partnership === 'cspaws') {
      return $state.go('authorized.csp.wadd.step02-aws', {resellerId: $stateParams.resellerId});
    } else if ($scope.formStep01.partnership === 'cspv1') {
      return $state.go('authorized.csp.wadd.step02-v1', {resellerId: $stateParams.resellerId});
    } else if ($scope.formStep01.partnership === 'cspdemo') {
      return $state.go('authorized.csp.wadd.step02-demo', {resellerId: $stateParams.resellerId});
    }
  };
});
