'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetHost', function($compile) {
  return {
    templateUrl: 'views/directive_ea_widget_host.html',
    controller: 'EaWidgetHostCtrl',
    restrict: 'C',
    scope: {
      widgetFs: '=',
      widget: '=',
      dashboard: '=',
      uuid: '=',
      title: '=',
      team: '=',
      contract: '=',
      token: '=',
      options: '=',
      profiles: '=',
      cust01: '=',
      maxPeriods: '='
    },
    link: function link(scope, element/*, attrs*/) {
      var el = $compile('<div ng-show=\"!loading\" style=\"position: relative; height: 100%; width: 100%;\" class=\"ea-widget-' + scope.widget + ' ea-widget\"></div>')(scope);
      angular.element(element).append(el);
    }
  };
}).controller('EaWidgetHostCtrl', function($scope, $compile, $element, $timeout, $rootScope, $eaWidgetDashboardManager, $q) {

  // set the active mode
  $scope.active = true;

  // set the loading mode
  $scope.loading = true;
  $scope.loadingMessage = undefined;

  // allows to enable and disable the loading mode
  $scope.setLoading = function(loading) {
    $scope.loading = loading;
  };

  $scope.setLoadingMesage = function(message) {
    $scope.loadingMessage= message;
  };

  // gives information about the active state
  $scope.isActive = function() {
    return $scope.active;
  };

  // when we navigate the widget should be disabled
  $rootScope.$on('$stateChangeStart', function() {
    console.log('Disabling widget ' + $scope.dashboard + '#' + $scope.uuid + ' because of navigation');
    $scope.active = false;
  });

  // This triggeres that the widget as self is ready with all things
  // to establish himself in the infrastructure. At this point no data are loaded at all
  $scope.finalizeRegistration = function() {
    $eaWidgetDashboardManager.finishWidgetRegistration($scope.dashboard, $scope.uuid);
  };

  // Notifies all dependent widgets that the data of this widget has changed
  $scope.refreshDependentWidgets = function() {
    $eaWidgetDashboardManager.refreshDependentWidgets($scope.dashboard, $scope.uuid);
  };

  // Post an action
  $scope.executeDashboardAction = function(functionId, args) {
    return $eaWidgetDashboardManager.sendDashboardFunction($scope.dashboard, functionId, args);
  };

  $scope.toggleState = function(actionProperty, stateKey) {
    $scope.options.dataItems[actionProperty + 'State'] = stateKey;
    $eaWidgetDashboardManager.reload($scope.dashboard);
  };

  $scope.isActiveState = function(actionProperty, stateKey) {
    return ($scope.options.dataItems[actionProperty + 'State'] === stateKey);
  };

  $scope.toggleStateFunc = function(action, stateKey) {
    $scope.options.dataItems[action['actionActive'] + 'State'] = stateKey;

    $scope.executeDashboardAction(action['actionToggle'], { state: stateKey }).then(function(reloadNeeded) {
      if (reloadNeeded) {
        $eaWidgetDashboardManager.reload($scope.dashboard);
      }
    });
  };

  $scope.isActiveStateFunc = function(action, stateKey) {
    return ($scope.options.dataItems[action['actionActive'] + 'State'] === stateKey);
  };

  // Initializing of widget
  $scope.onInitialize = function(cb) {
    $eaWidgetDashboardManager.onWidgetInitialize($scope.dashboard, $scope.uuid, function() {

      // initialize the states
      var actionInitializers = [];

      // check if we have actions
      if ($scope.options && $scope.options.periodItems && $scope.options.periodItems.actions) {

        $scope.options.periodItems.actions
          .filter(function(item) { return item.actionType === 'StateToggleFunc'; })
          .forEach(function (action) {
            actionInitializers.push($scope.executeDashboardAction(action['actionActive']).then(function(activeState) {
              $scope.options.dataItems[action['actionActive'] + 'State'] = activeState;
              return $q.when();
            }));
        });
      }

      return $q.all(actionInitializers).then(function() {
        $scope.active = true;
        return cb();
      });
    });
  };

  $scope.onReload = function(cb) {
    $eaWidgetDashboardManager.onWidgetReload($scope.dashboard, $scope.uuid, cb);
  };

  // Resize Handling
  var resizeCallback = null;
  $scope.onResize = function(cb) {
    resizeCallback = cb;
  };

  $scope.getSize = function () {
    return { 'h': $element.height(), 'w': $element.width() };
  };

  $scope.requestResize = function(newValue) {

    // get the newValue
    if (!newValue) { newValue = $scope.getSize(); }

    // adjust the correct size of the widget as self
    var widgetElement = angular.element($element).find('div.ea-widget');

    // check how much we need to remove becasue of title
    var titleHeight = angular.element($element).find('h1.widget-title').height();

    // set the height of the widget element
    angular.element(widgetElement).height(newValue.h - titleHeight);
    angular.element(widgetElement).width(newValue.w);

    // check if we need to call the child callback
    if (resizeCallback) { resizeCallback({ w: newValue.w, h: newValue.h - titleHeight }); }
  };

  $scope.$watch($scope.getSize, function (newValue/*, oldValue*/) {

    // from the height perspective we take a fixed value
    if ($scope.widgetFs === true) { newValue.h = window.innerHeight - 115; }

    // request the resize
    $scope.requestResize(newValue);
  }, true);

});
