'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetGroupFilterListViewPgSubitemSpendingsServiceTemplate', function() {
  return {
    restrict: 'C',
    template:
      '<div class="subscriptionServiceIcon" ng-class="serviceItem[subitemIconProperty]" style="cursor: pointer" popover-title="{{serviceItem.PopoverTitle}}" popover-html-unsafe="{{serviceItem.PopoverHtml}}" popover-trigger="mouseenter" popover-placement="right" ng-click="toggleDetailViewOpenedState(serviceItem)"></div>' +
      '<div class="subscriptionServiceName" style="cursor: pointer" style="width: 200px;" ng-click="toggleDetailViewOpenedState(serviceItem)">{{getSubItemTitle(serviceItem)}}<span ng-if="riPurchaseReportPossible(serviceItem)">&nbsp;&nbsp;<a tooltip="Open Reservation Purchasing Impact Report" ng-click="openRIPurchaseReport(serviceItem); $event.stopPropagation();"<i class="fa fa-money"></i></a></span></div>' +
      '<div ng-if="serviceItem.allowTagging && isOpenedStateClosed(serviceItem)" class="cell-tags">' +
        '<div class="ea-tag-list" data-service="serviceItem" data-tag-property="\'ServiceTags\'" data-tag-added="addTag(serviceItem, tag)" data-tag-removed="removeTag(serviceItem, tag)" data-tag-auto-complete="queryTags(query)"></div>' +
      '</div>' +
      '<div ng-if="isOpenedStateInProgress(serviceItem)" class="cell-tags">' +
        '<div style="margin-top: 3px;"><i class="fa fa-spinner"></i>&nbsp;Loading details ...</div>' +
      '</div>' +
      '<div ng-if="isOpenedStateOpened(serviceItem)" class="cell-tags">' +
        '<div class="btn-group" role="group" aria-label="...">' +
          '<button ng-repeat="diag in availableDetailDiagrams" ng-click="switchDetailDiagramTo(diag)" type="button" class="btn btn-default btn-xs" ng-class="activeDetailDiagram == diag ? \'active\' : \'\' "><i class="fa {{diag.icon}}"></i>&nbsp;{{diag.name}}</button>' +
        '</div>'+
      '</div>' +
      '<div class="clearfix"></div>' +
      '<div ng-if="isOpenedStateOpened(serviceItem)" ng-repeat="meter in activeDetailDiagram.meters"  style="padding-left: 36px; background-color: white; width: calc(100% + 100px);">' +
        '<div ng-if="meter.name && meter.name.length > 0">{{meter.name}} ({{meter.rate}})</div>' +
        '<div class="ac-chart-area" data-uuid="meter.id" data-elements="meter.data" data-value-field-name="activeDetailDiagram.dataValueFieldName" data-label-field-name="activeDetailDiagram.dataLabelFieldName" data-options="meter.options[activeDetailDiagram.dataValueFieldName]" style="background-color: white; width: 100%; height: 150px;"></div>' +
      '</div>',
    scope: false,
    controller: 'EaWidgetGroupFilterListViewPgSubitemSpendingsServiceTemplateCtrl'
  };
}).controller('EaWidgetGroupFilterListViewPgSubitemSpendingsServiceTemplateCtrl', function($scope, $element, $controller, $state, $stateParams, $modal, $q, $window, $eaConfig, gravatarService, $eaDataCoordinator, $eaBackend, $eaDataAggregateOperations, $compile, $eaChartConfig, $eaBackendDataModeSelector, $timeout) {
  var self = this;
  var detailedChartElement = null;

  function getDayOfMonthByIndex(idx) {
    if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
      var dayofMonth = moment($scope.serviceItem.ReportId).add($eaBackendDataModeSelector.getBillingCycleDay() - 1, 'days').add(idx, 'days').date();
      return ("0" + dayofMonth).slice(-2);
    } else {
      return ("0" + (idx + 1)).slice(-2);
    }
  }

  function getDateNameByIndex(idx)
  {
    if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
      var m = moment($scope.serviceItem.ReportId).add($eaBackendDataModeSelector.getBillingCycleDay() - 1, 'days').add(idx, 'days');
      return m.format('YYYY-MM-DD');
    } else {
      return $scope.serviceItem.ReportId + '-' + getDayOfMonthByIndex(idx);
    }
  }

  function getMeterRateSafely(meter, reportId, currency) {

    // check the reportId
    if (!reportId) { return 'n/a'; }

    // check the meter
    if (!meter) { return 'n/a'; }

    // check the Rates
    if (meter.Rates.length == 0) { return 'n/a'; }

    var reportRates = meter.Rates[reportId + '-01T00:00:00'];
    if (!reportRates || reportRates.length == 0) { return 'n/a'; }

    var reportRate = reportRates[0] || reportRates['default'];
    if (!reportRate) { return 'n/a'; }

    return reportRate.toFixed(8) + ' ' + currency
  }

  function areDataInV2Mode(serviceItem) {
    return (Array.isArray(serviceItem.ServiceMeters));
  }

  // enable and disable tagging
  $scope.serviceItem.allowTagging = !($scope.selectedPeriodsMultiNormalized.length > 1);
  $scope.serviceItem.viewDayByDayCostsStatus = 'closed';
  $scope.serviceItem.viewDayByDayCostsId = 'DailyCostsChart-' + $scope.serviceItem.EntityId;

  $scope.switchDetailDiagramTo = function(target) {
    $scope.activeDetailDiagram = target;
  };

  var XAxisTemplate = function() {
    return getDayOfMonthByIndex(this.value);
  };

  var YAxisTemplateCurrency = function() {
    return this.value.toFixed(2) + ' ' + $scope.serviceItem.ReportCurrencySymbol;
  };

  var YAxisTemplateQuantity = function() {
    return this.value.toFixed(2) + ' ' + $scope.serviceItem.ServiceQuantityUnit;
  };

  function updateDiagram() {

    var metersDayByDayCosts = [];
    var metersDayByDayQuantity = [];

    if (areDataInV2Mode($scope.serviceItem)) {

      $scope.serviceItem.ServiceMeters.forEach(function (meter) {

        var viewDayByDayCostsAndQuantityData = [];

        for (var i = 0; i < meter.QuantityDaily.length; i++) {
          viewDayByDayCostsAndQuantityData.push({
            Day: getDateNameByIndex(i),
            Quantity: meter.QuantityDaily[i],
            Costs: meter.CostsDaily[i]
          })
        }

        var options = {
          Costs: dayByDayCostOptions,
          Quantity: {
            ToolTipTemplate: 'Service Quantity: <b>{point.y:,.6f} ' + meter.Unit + '</b>',
            YAxisTemplate: function () {
              return this.value.toFixed(2) + ' ' + meter.Unit;
            },
            XAxisTemplate: XAxisTemplate
          }
        };

        var meterName = meter.Region + '/' + meter.Category + '/' + meter.SubCategory + '/' + meter.Name;
        var meterRate = 'Costs: ' + meter.Costs.toFixed(8) + ' ' + $scope.serviceItem.ReportCurrencySymbol + ' / Rate: ' + getMeterRateSafely(meter, $scope.serviceItem.ReportId, $scope.serviceItem.ReportCurrencySymbol);
        metersDayByDayCosts.push({
          id: $scope.serviceItem.viewDayByDayCostsId + '.' + meter.Id,
          name: meterName,
          rate: meterRate,
          options: options,
          data: viewDayByDayCostsAndQuantityData
        });
        metersDayByDayQuantity.push({
          id: $scope.serviceItem.viewDayByDayCostsId + '.' + meter.Id,
          name: meterName,
          rate: meterRate,
          options: options,
          data: viewDayByDayCostsAndQuantityData
        });
      });
    } else {

      var viewDayByDayCostsData = [];
      var viewDayByDayQunatityData = [];

      // Convert the daily data to the required data
      if ($scope.serviceItem.ServiceDailyCosts) {
        for (var i = 0; i < $scope.serviceItem.ServiceDailyCosts.length; i++) {
          viewDayByDayCostsData.push({
            Day: getDateNameByIndex(i),
            Costs: $scope.serviceItem.ServiceDailyCosts[i]
          });
        }
      }

      if ($scope.serviceItem.ServiceDailyQuantities) {
        for (var i = 0; i < $scope.serviceItem.ServiceDailyQuantities.length; i++) {
          viewDayByDayQunatityData.push({
            Day: getDateNameByIndex(i),
            Quantity: $scope.serviceItem.ServiceDailyQuantities[i]
          });
        }
      }

      metersDayByDayCosts.push({
        id: $scope.serviceItem.viewDayByDayCostsId,
        name: "",
        options: {Costs: dayByDayCostOptions},
        data: viewDayByDayCostsData
      });
      metersDayByDayQuantity.push({
        id: $scope.serviceItem.viewDayByDayCostsId,
        name: "",
        options: {Quantity: dayByDayQunatityOptions},
        data: viewDayByDayQunatityData
      });
    }

    $scope.availableDetailDiagrams = [
      { id: 'costs', name: 'Costs', icon: 'fa-area-chart', meters: metersDayByDayCosts, dataValueFieldName:'Costs', dataLabelFieldName: 'Day'},
      { id: 'quantity', name: 'Quantity', icon: 'fa-area-chart', meters: metersDayByDayQuantity, dataValueFieldName:'Quantity', dataLabelFieldName:'Day'}
    ];

    // set the default detials
    $scope.switchDetailDiagramTo($scope.availableDetailDiagrams[0]);
  }

  // define the diagram options
  var dayByDayCostOptions = {
    ToolTipTemplate: 'Service Costs: <b>{point.y:,.6f} ' + $scope.serviceItem.ReportCurrencySymbol + '</b>',
    YAxisTemplate: YAxisTemplateCurrency,
    XAxisTemplate: XAxisTemplate
  };

  var dayByDayQunatityOptions = {
    ToolTipTemplate: 'Service Quantity: <b>{point.y:,.6f} ' + $scope.serviceItem.ServiceQuantityUnit + '</b>',
    YAxisTemplate: YAxisTemplateQuantity,
    XAxisTemplate: XAxisTemplate
  };

  // update the diagram
  updateDiagram();

  // Handle the tagging requests for a service
  $scope.addTag = function (service, tag) {

    // update the models in data cache with the new values
    $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTags', tag);
    $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTagsV3', tag);

    // create the tags array if needed
    if (!service.ServiceTags || !Array.isArray(service.ServiceTags)) { service.ServiceTags = []; }
    if (!service.ServiceTagsV3 || !Array.isArray(service.ServiceTagsV3)) { service.ServiceTagsV3 = []; }

    // update the ui model for this periond
    if (service.ServiceTags.indexOf(tag) === -1) { service.ServiceTags.push(tag); }
    if (service.ServiceTagsV3.indexOf(tag) === -1) { service.ServiceTagsV3.push(tag); }

    // update the history element
    var itemKey = service.SubscriptionId + '-' + service.ServiceId;
    $scope.groupedSpendingData.History[itemKey].ServiceTags = service.ServiceTags;
    $scope.groupedSpendingData.History[itemKey].ServiceTagsV3 = service.ServiceTagsV3;

    // send to the backend
    if (service.SchemaVersion === 'spendings.v3' || service.SchemaVersion === 'spendings.v2') {
      $eaBackend.dataSetServiceTagsV4($scope.team, $scope.contract, service.ServiceId, service.ServiceTags, $scope.token);
    } else {
      $eaBackend.dataSetServiceTagsV3($scope.team, $scope.contract, service.ServiceName, service.ServiceCategory, service.SubscriptionId, service.ServiceTagsV3, $scope.token);
    }
  };

  function removeTagFromUIElement(service, tagProperty, searchProperties, tagToRemove) {
    $scope.rawItems
      //.filter(function(a) { return a.ServiceId === service.ServiceId; })
      .filter(function(a) {
        var bPassed = true;

        searchProperties.forEach(function (sp) {
          if (a[sp] !== service[sp]) {
            bPassed = false;
          }
        });

        return bPassed;
      }).forEach(function(item) {

        var index = item.ServiceTags.indexOf(tagToRemove);
        if (index !== -1 ) { item.ServiceTags.splice(index, 1); }

        if (tagProperty) {
          index = item[tagProperty].indexOf(tagToRemove);
          if (index !== -1) {
            item[tagProperty].splice(index, 1);
          }
        }
      });
  }

  $scope.removeTag = function (service, tag) {

    // update the ui model for this periond
    var tagIndex = service.ServiceTags.indexOf(tag);
    if ( tagIndex !== -1) { service.ServiceTags.splice(tagIndex, 1); }

    // remove from history data
    var itemKey = service.SubscriptionId + '-' + service.ServiceId;
    $scope.groupedSpendingData.History[itemKey].ServiceTags.splice(tagIndex, 1);

    // remember if removed befor
    var removedBefore = false;

    // understand what type if tag is it
    var tagIndexV1 = service.ServiceTagsV1.indexOf(tag);
    if (tagIndexV1 !== -1) {
      removedBefore = true;
      service.ServiceTagsV1.splice(tagIndexV1, 1);
      $eaBackend.dataSetServiceTagsV1($scope.team, $scope.contract, service.ServiceName, service.ServiceTagsV1, $scope.token).then(function() {

        // update the models in data cache with the new values
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName'], [service.ServiceName], 'ServiceTagsV1', tag, 'remove');
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName'], [service.ServiceName], 'ServiceTags', tag, 'remove');

        // update the UI
        removeTagFromUIElement(service, undefined, ['ServiceName'], tag);
      })
    }

    var tagIndexV2 = service.ServiceTagsV2.indexOf(tag);
    if (tagIndexV2 !== -1) {
      removedBefore = true;
      service.ServiceTagsV2.splice(tagIndexV2, 1);
      $eaBackend.dataSetServiceTagsV2($scope.team, $scope.contract, service.ServiceName, service.ServiceCategory, service.ServiceTagsV2, $scope.token).then(function() {

        // update the models in data cache with the new values
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory'], [service.ServiceName, service.ServiceCategory], 'ServiceTagsV2', tag, 'remove');
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory'], [service.ServiceName, service.ServiceCategory], 'ServiceTags', tag, 'remove');

        // update the UI
        removeTagFromUIElement(service, 'ServiceTagsV2', ['ServiceName', 'ServiceCategory'], tag);
      })
    }

    // Handle V3 tags
    var tagIndexV3 = service.ServiceTagsV3.indexOf(tag);
    if (tagIndexV3 !== -1) {
      removedBefore = true;
      service.ServiceTagsV3.splice(tagIndexV3, 1);

      // remove from history data
      $scope.groupedSpendingData.History[itemKey].ServiceTagsV3.splice(tagIndexV3, 1);

      // remove the tag in backend
      $eaBackend.dataSetServiceTagsV3($scope.team, $scope.contract, service.ServiceName, service.ServiceCategory, service.SubscriptionId, service.ServiceTagsV3, $scope.token).then(function() {

        // update the models in data cache with the new values
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTagsV3', tag, 'remove');
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTags', tag, 'remove');

        // update the UI
        removeTagFromUIElement(service, 'ServiceTagsV3', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], tag);
      })
    }

    if (!removedBefore) {
      // remove the tag in backend
      var removePromise;

      if (service.SchemaVersion === 'spendings.v3' || service.SchemaVersion === 'spendings.v2') {
        removePromise = $eaBackend.dataSetServiceTagsV4($scope.team, $scope.contract, service.ServiceId, service.ServiceTags, $scope.token);
      } else {

        // remove from history data
        $scope.groupedSpendingData.History[itemKey].ServiceTagsV3.splice(tagIndexV3, 1);

        removePromise = $eaBackend.dataSetServiceTagsV3($scope.team, $scope.contract, service.ServiceName, service.ServiceCategory, service.SubscriptionId, service.ServiceTagsV3, $scope.token);
      }
      removePromise.then(function() {

        // update the models in data cache with the new values
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTagsV3', tag, 'remove');
        $eaDataCoordinator.updateModels($scope.team, $scope.contract, 'SpendingEntryClass', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], [service.ServiceName, service.ServiceCategory, service.SubscriptionId], 'ServiceTags', tag, 'remove');

        // update the UI
        removeTagFromUIElement(service, 'ServiceTagsV3', ['ServiceName', 'ServiceCategory', 'SubscriptionId'], tag);
      })
    }
  };

  $scope.queryTags = function (query) {
    // find the distince values of the tags property (filtered by query)
    return $eaDataAggregateOperations.distinct($scope.rawItems, 'ServiceTags', query);
  };


  self.getPopOverTitle = function(groupedServiceDefinitions, metaDataKeyValue, titleProperty) {
    var metaDataElement = groupedServiceDefinitions[metaDataKeyValue];
    if (metaDataElement && metaDataElement[0] && metaDataElement[0][titleProperty]) {
      return metaDataElement[0][titleProperty];
    } else {
      return metaDataKeyValue;
    }
  };

  self.getPopOverContent = function(groupedServiceDefinitions, metaDataKeyValue, contentProperty, element) {
    // get the default value
    var popOverContent = self.getPopOverTitle(groupedServiceDefinitions, metaDataKeyValue, contentProperty);

    // generate the service summary, a virtual property
    if (contentProperty === 'ServiceSummary') {

      var metaDataElement = groupedServiceDefinitions[metaDataKeyValue];
      if (metaDataElement && metaDataElement !== undefined) {
        metaDataElement = metaDataElement[0];
      }

      var serviceDescriptionHtml = '';

      // add the description
      if (metaDataElement && metaDataElement !== undefined) {
        serviceDescriptionHtml += '<p>' + metaDataElement.ServiceDescription + '</p>';
      }

      // handle quantity unit
      var unit = element.ServiceQuantityUnit;
      if (element.ServiceQuantityUnit == 'not_set') {
        unit = '';
      }

      // fill up optionla values
      if (!element.ServiceQuantity) {
        element.ServiceQuantity = 0.0;
      }

      var azureTags = [];
      Object.keys(element).forEach(function (k) {
        if (k.indexOf('AzureTag:') !== 0) {
          return;
        }
        azureTags.push({k: k.replace('AzureTag:', ''), v: element[k]});
      });

      // generate the popup
      serviceDescriptionHtml += '<table class="popover-service-details">';
      serviceDescriptionHtml += '<tr><td colspan="2"><h5>General Information</h5></td></tr>';
      serviceDescriptionHtml += '<tr><td>Subscription:</td><td>' + element.SubscriptionName + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>Name:</td><td>' + element.ServiceName + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>Category:</td><td>' + element.ServiceCategory + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>Type:</td><td>' + element.ServiceType + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>Size:</td><td>' + element.ServiceSize + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>Region:</td><td>' + element.ServiceRegion + '</td></tr>';
      serviceDescriptionHtml += '<tr><td>ResourceGroup:</td><td>' + element.ResourceGroup + '</td></tr>';

      // quantity only when we have no meters
      if (!Array.isArray(element.ServiceMeters)) {
        serviceDescriptionHtml += '<tr><td>Quantity:</td><td>' + element.ServiceQuantity.toFixed(8) + '&nbsp;' + unit + '</td></tr>';
      }

      // add the costs
      serviceDescriptionHtml += '<tr><td>Costs:</td><td>' + element.ServiceCosts.toFixed(8) + '&nbsp;' + element.ReportCurrencySymbol + '</td></tr>';

      // visit all meters if available
      if (Array.isArray(element.ServiceMeters) && element.ServiceMeters.length > 0) {

        serviceDescriptionHtml += '<tr><td colspan="2"><h5>Service Meters (Quantity/Costs/Rate)</h5></td></tr>';

        element.ServiceMeters.forEach(function(meter) {

          // Region / Category / SubCategory / Name  4545 Unit 565€
          serviceDescriptionHtml += '<tr><td>' + meter.SubCategory + '</td><td>' + meter.Quantity.toFixed(8) + '&nbsp;' + meter.Unit + ' / ' + meter.Costs.toFixed(8) + '&nbsp' + element.ReportCurrencySymbol + ' / ' + getMeterRateSafely(meter, element.ReportId, element.ReportCurrencySymbol) + '</td></tr>';
          serviceDescriptionHtml += '<tr><td colspan="2" style="font-size: 10px;">' + meter.Region + '/' + meter.Category + '/' + meter.SubCategory + '/' + meter.Name + '</td></tr>';
        });
      }


      if (azureTags.length > 0) {
        serviceDescriptionHtml += '<tr><td colspan="2"><h5>Grouping Tags</h5></td></tr>';

        azureTags.forEach(function(tag) {
          serviceDescriptionHtml += '<tr><td>' + tag.k + ':</td><td>' + tag.v + '</td></tr>';
        });
      }

      serviceDescriptionHtml += '</table>';

      return serviceDescriptionHtml;
    }
  };

  // build the popup information
  $scope.serviceItem.PopoverTitle = $scope.serviceItem.ServiceName; // self.getPopOverTitle($scope.groupedServiceDefinitions, $scope.serviceItem.ServiceType, 'ServiceTitle');
  $scope.serviceItem.PopoverHtml = self.getPopOverContent($scope.groupedServiceDefinitions, $scope.serviceItem.ServiceType, 'ServiceSummary', $scope.serviceItem);

  // Allow to open the RI report
  $scope.riPurchaseReportPossible = function(serviceItem) {

    // check if the feature is enabled
    if (!$eaConfig.riEnabled) { return false; }

    // check if we have teh right service type
    if (serviceItem.ServiceType !== 'VirtualMachine' && serviceItem.ServiceCategory !== 'Virtual Machines') { return false;}

    // ok good to go
    return true;
  };

  $scope.openRIPurchaseReport = function(serviceItem)
  {
    var url = $state.href('authorized.teams.rimanagement', { service: serviceItem.ServiceId, contract: $stateParams.active });
    $window.open(url,'_blank');
  };


  $scope.isOpenedStateClosed = function(serviceItem) {
    return serviceItem.viewDayByDayCostsStatus === 'closed';
  };

  $scope.isOpenedStateOpened = function(serviceItem) {
    return serviceItem.viewDayByDayCostsStatus === 'opened';
  };

  $scope.isOpenedStateInProgress = function(serviceItem) {
    return serviceItem.viewDayByDayCostsStatus === 'progressing';
  };

  $scope.setOpenedStateClosed = function(serviceItem) {
    serviceItem.viewDayByDayCostsStatus = 'closed';
  };

  $scope.setOpenedStateOpened = function(serviceItem) {
    serviceItem.viewDayByDayCostsStatus = 'opened';
  };

  $scope.setOpenedStateProgress = function(serviceItem) {
    serviceItem.viewDayByDayCostsStatus = 'progressing';
  };

  $scope.toggleDetailViewOpenedState = function(serviceItem) {

    // check if we are closing
    if ($scope.isOpenedStateOpened(serviceItem)) {
      $scope.setOpenedStateClosed(serviceItem);
      return;
    }

    // check if we are in V2 mode if not render instantly
    if (!areDataInV2Mode(serviceItem)) {
      $scope.setOpenedStateOpened(serviceItem);
      return;
    }

    // now that we now we are in V2 mode check if we have preloaded data
    if (serviceItem.ServiceMeters != null && serviceItem.ServiceMeters.length > 0) {
      $scope.setOpenedStateOpened(serviceItem);
      return;
    }

    // start the progressing
    $scope.setOpenedStateProgress(serviceItem);

    // load the meter dynamically
    $eaBackend.dataGetMetersForService($scope.team, $scope.contract, serviceItem.ReportId, serviceItem.ServiceId, $scope.token).then(function(loadedMeters) {

      // set the meters
      if (loadedMeters && loadedMeters.Items) { serviceItem.ServiceMeters = loadedMeters.Items; }

      // update the diagram
      updateDiagram();

      // done
      $scope.setOpenedStateOpened(serviceItem);
    });
  }



});
