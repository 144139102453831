'use strict';

angular.module('azureCostsFeApp').controller('ModalBillingPurchaseCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openPurchaseConfirmationDialog = function(plan, billingAddress) {

    var modalInstance = $modal.open({
      templateUrl: "views/billing/modals/ea-modal-billing-purchase.html",
      controller: 'ModalBillingPurchaseCtrl',
      resolve: {
        Plan: function() { return plan; },
        BillingAddress: function() { return billingAddress; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
