'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('EaAddStep02RateConnectCtrl', function ($scope, $state, $window, $location, $eaBackend, $eaCurrentSession, $eaConfig, $busy, $q) {

  // define the alert message
  $scope.alertMessages = [];
  $scope.contractModel = null;

  $scope.subscriptionPermissionLink = "https://portal.azure.com/#blade/Microsoft_Azure_AD/UserAssignmentsBlade/scope/%2Fsubscriptions%2F";
  $scope.hideSubscriptionsDetailsLink = false;

  // query the enrollment
  $busy.during($eaBackend.dataGetContract($state.params.team, $state.params.enrollment, $eaCurrentSession.DataToken).then(function(contract) {

    // set the contract
    $scope.contractModel = contract;

    // build the offerinfs string
    var offerings = 'offerings';
    if (contract.CloudInstance && contract.CloudInstance === 'analyticsger') {
      offerings = 'offeringsde';
      $scope.subscriptionPermissionLink = "https://portal.microsoftazure.de/#blade/Microsoft_Azure_AD/UserAssignmentsBlade/scope/%2Fsubscriptions%2F";
      $scope.hideSubscriptionsDetailsLink = true;
    }

    // query all offerings
    return $eaBackend.dataGetMetadata(offerings, $eaCurrentSession.DataToken).then(function (offerings) {
      $scope.availableOfferings = offerings;

      return $eaBackend.dataGetMetadata('countries', $eaCurrentSession.DataToken).then(function (countries) {
        $scope.availableCountries = countries;

        // query the subscription
        return $eaBackend.dataGetContractSubscriptions($state.params.team, $state.params.enrollment, $eaCurrentSession.DataToken, {verify: true}).then(function (subscriptions) {
          $scope.subscriptions = subscriptions;
        });

      });
    });
  }));

  function verifySubscritpionSettingsAndGenerateErrorMessage() {
    var errors = [];
    var foundError = false;

    $scope.subscriptions.forEach(function(s) {

      // ignore disabled subscriptions
      if (s.Ignore !== undefined && s.Ignore === true) { return; }

      // verify
      var subscriptionErrorField = { Id: s.Id, Name: s.Name, Errors: []};

      if (s.ArmState !== 'connected') { foundError = true; subscriptionErrorField.Errors.push('Missing Reader-Permissions in Azure (check <a target="_new" href="https://azurecostmonitor.uservoice.com/knowledgebase/articles/805902">KB article</a>)'); }
      if (s.Offering === null || s.Offering === undefined) { foundError = true; subscriptionErrorField.Errors.push('No OfferingId selected!'); }
      if (s.Country === null || s.Country === undefined) { foundError = true; subscriptionErrorField.Errors.push('No Country selected'); }

      if (subscriptionErrorField.Errors.length > 0 ) { errors.push(subscriptionErrorField); }
    });

    return foundError ? errors : null;
  }

  $scope.verifyPermissions = function() {
    $busy.setMessage("Verify permissions");
    return $busy.during($eaBackend.dataGetContractSubscriptions($state.params.team, $state.params.enrollment, $eaCurrentSession.DataToken, {verify: true}).then(function(subscriptions) {
      var subscriptionIndex = subscriptions.buildIndex('Id');
      $scope.subscriptions.forEach(function(s) {
        s.ArmState = subscriptionIndex[s.Id].ArmState;
      });
    }));
  };

  function verifyConnection(subscriptions) {
    var errors = [];

    $busy.setMessage("Checking Rate Card access...");
    return $busy.during($q.all(subscriptions.map(function(s) {

      // ignore if required
      if (s.Ignore !== undefined && s.Ignore) {
        return $q.when();
      } else {
        return $eaBackend.dataGetContractSubscriptionsVerifyConnection($state.params.team, $state.params.enrollment, s.Id, s.Offering, s.Country, $scope.contractModel.Currency, $eaCurrentSession.DataToken).catch(function (result) {
          var subscriptionErrorField = {Id: s.Id, Name: s.Name, Errors: []};

          if (result && result.data.message) {
            subscriptionErrorField.Errors.push(result.data.message);
          } else {
            subscriptionErrorField.Errors.push("Can't download the rate card, verify settings");
          }

          errors.push(subscriptionErrorField);

          return $q.reject();
        });
      }
    })).then(function() {
      return $q.when([]);
    }).catch(function() {
      return $q.when(errors);
    }));
  }

  $scope.toggleIgnoreSubscriptionState = function(subscription) {
    if (subscription.Ignore === undefined) { subscription.Ignore = false; }
    subscription.Ignore = !subscription.Ignore
  };

  $scope.next = function() {

    // verify the permissions
    $scope.verifyPermissions().then(function() {

      var error = verifySubscritpionSettingsAndGenerateErrorMessage();

      if (error) {
        // set the errors
        $scope.alertMessages = error;
      } else {

        // try to download the rate cards
        return verifyConnection($scope.subscriptions).then(function(verifyConnectionErrors) {

          if (verifyConnectionErrors && verifyConnectionErrors.length > 0) {
            $scope.alertMessages = verifyConnectionErrors;
          } else {

            // reset all previous errors
            $scope.alertMessages = null;

            // update every subscription
            var updatePromise = [];
            $scope.subscriptions.forEach(function (s) {

              if (s.ArmState === 'connected' && (s.Ignore === undefined || s.Ignore == false)) {
                updatePromise.push($eaBackend.dataPutContractSubscription($state.params.team, $state.params.enrollment, s.Id, {
                  Offering: s.Offering,
                  Country: s.Country,
                  State: 'valid'
                }, $eaCurrentSession.DataToken));
              }
            });

            // wait
            $busy.during($q.all(updatePromise).then(function () {

              // trigger sync job
              return $eaBackend.dataAllSyncReports($state.params.team, $state.params.enrollment, $eaCurrentSession.DataToken).then(function () {

                // got to the next state
                $state.go('authorized.reportAdd.step03', $state.params);

              });
            }));
          }
        });
      };
    });
  }
});
