'use strict';

angular.module('azureCostsFeApp').controller('EaTeamSettingsCtrl', function ($scope, $stateParams, $busy, $eaCurrentSession, $eaBackend, $window) {
    var self = this;

  $scope.teamProfileForm = {
    Name: null,
    NotifyAdmins: false
  };

  $scope.updateProfile = function() {

    // convert to profile
    var teamProfileData = {
      Name: $scope.teamProfileForm.Name,
      NotificationNewMember: $scope.teamProfileForm.NotifyAdmins ? ['admin'] : []
    };

    $busy.during($eaBackend.dataUpdateTeamProfile($stateParams.teamid, teamProfileData, $eaCurrentSession.DataToken).then(function() {
      $window.history.back();
    }))
  };

  // load the team buckets
  $busy.setMessage("Loading team profile");
  return $busy.during($eaBackend.dataGetTeamProfile($stateParams.teamid, false, $eaCurrentSession.DataToken).then(function(teamProfile) {
    $scope.teamProfileForm.Name = teamProfile.Name;
    $scope.teamProfileForm.NotifyAdmins = teamProfile.NotificationNewMember && teamProfile.NotificationNewMember.length > 0;
  }));

});

