'use strict';

angular.module('azureCostsFeApp').controller('EaBillingUpgradeEditionCtrl', function ($scope, $eaBackend, $toolbar, $busy, $eaSession2, $location, $eaBilling, $controller, $modal, $eaConfig) {

    $.extend(self, $controller('ModalBillingPurchaseCtrlMixin', {$scope: $scope, $modal: $modal }));

    $scope.annualPayment = false;
    $scope.referrer = null;

    $scope.editions = [
      {
        key: "csp",
        name: "Service Provider",
        fsk: null,
        fsk12: null,
        price: "Contact us",
        price12: "Contact us",
        features: [
          'All Azure Plans (incl. EA)',
          'Includes all Enterprise Features',
          'Customer Dashboard',
          'Custom SSL & Domain',
          'Custom pricing & currency',
          'Data access via API',
          'Allows to resell subscriptions'
        ],
        retention: 'Unlimited',
        actionsTitle: 'Contact us',
        actionsIcon: 'fa-envelope-o'
      },
      {
        key: "enterprise",
        name: "Enterprise",
        fsk: 'acent',
        fsk12: 'acent12',
        price: "$199/month",
        price12: "$1990/year",
        features: [
          'All Azure Plans (incl. EA)',
          'Includes all Professional Features',
          'Multi Contract & User Support',
          'Team & Enterprise Support',
          'Subscription Based Permissions',
          'Branding & Customizable Widgets',
          'Azure Active Directory Support',
          'Data Access via RESTful API'
        ],
        retention: 'Unlimited',
        actionsTitle: 'Buy now',
        actionsIcon: 'fa-shopping-cart'
      },
      {
        key: "professional",
        name: "Professional",
        fsk: 'acpro',
        fsk12: 'acpro12',
        price: "$79/month",
        price12: "$790/year",
        features: [
          'All Azure Plans (incl. EA)',
          'Includes all Startup Features',
          'Spending & Cost Alerts',
          'Instant Data Sync',
          'Smart Compare',
          'Annual Financial Reports',
          'Customer Sub Domain',
          'Unlimited amount of spendings'
        ],
        retention: '24 Month',
        actionsTitle: 'Buy now',
        actionsIcon: 'fa-shopping-cart'
      },
      {
        key: "startup",
        fsk: 'acstu',
        fsk12: 'acstu12',
        name: "Startup",
        price: "$29/month",
        price12: "$290/year",
        features: [
          'All Pay as you go / Rate Plans',
          'Includes all Basic Features',
          'Advanced Data Grouping',
          'Mail Reports',
          'Charts in Mail Reports',
          'Trend Analytics',
          'Smart Resource Tagging',
          'Unlimited amount of spendings'
        ],
        retention: '12 Month',
        actionsTitle: 'Buy now',
        actionsIcon: 'fa-shopping-cart'
      },
      {
        key: "basic",
        name: "Basic",
        fsk: null,
        fsk12: null,
        price: "free forever",
        price12: "free forever",
        features: [
          'All Azure Plans (incl. EA)',
          'Spending Stats',
          'Cost Analytics',
          'Automatic Data Import',
          'Spending Dashboards',
          'Advanced Spending Filters',
          'Spending Breakdown',
          'Unlimited amount of spendings'
        ],
        retention: '2 Month',
        actionsTitle: null,
        actionsIcon: null
      }
    ];

    // adapt the pricing for the german cloud
    if ($eaConfig.feHost === 'azure-costs.de' || $eaConfig.feHost === 'costs-test-ger.applimit.de' || $eaConfig.feHost === 'ger.atea.services:9000') {
      // enterprise
      $scope.editions[1].fsk = 'acentde';
      $scope.editions[1].fsk12 = 'acent12de';
      $scope.editions[1].price = '199€/month';
      $scope.editions[1].price12 = '1990€/month';

      // professional
      $scope.editions[2].fsk = 'acprode';
      $scope.editions[2].fsk12 = 'acpro12de';
      $scope.editions[2].price = '79€/month';
      $scope.editions[2].price12 = '790€/month';

      // startup
      $scope.editions[3].fsk = 'acstude';
      $scope.editions[3].fsk12 = 'acstu12de';
      $scope.editions[3].price = '29€/month';
      $scope.editions[3].price12 = '290€/month';
    }


    function setCurrentPlan(billing) {
      // define the current plan
      if ($eaBilling.isInTrial(billing)) {
        $scope.currentPlan = 'basic';
        $scope.currentPlanManagement = null;
      } else {
        $scope.currentPlan = billing.CurrentPlan;
        $scope.currentPlanManagement = billing.Details;
      }
    }

    $busy.during($eaSession2.lazy().then(function(session) {
      return $eaBilling.loadBillingInformation(session).then(function(billing) {

          // set the referrer
          $scope.referrer = session.UserId;

          // check if we have a billing period
          if (billing.Period && billing.Period === 12) {
            $scope.annualPayment = true;
          } else {
            $scope.annualPayment = false;
          }

          // set the current billing information
          setCurrentPlan(billing);

          // offer an upgrade option
          $scope.upgradePlan = function(plan) {
              if (plan === 'csp') {
                return;
              } else {

                $scope.openPurchaseConfirmationDialog(plan, billing.InvoiceAddress).then(function (updatedInvoiceInformation) {
                  $busy.during($eaBackend.dataUpdateBillingInformation(updatedInvoiceInformation, session.DataToken).then(function () {
                    return $eaBilling.purchase(plan, session.DataToken).then(function () {
                      return $eaBilling.loadBillingInformation(session).then(function (billingInfo) {

                        // set the current plan
                        setCurrentPlan(billingInfo);

                        // override the billing info
                        billing = billingInfo;
                      })
                    })
                  })).catch(function () {
                    alert("Something went wrong, we was unable to process your purchase request! Please try again.");
                  })
                })
              }
          };

          $scope.upgradeActionAllowed = function(plan) {
            return $scope.referrer && $eaBilling.isPlanHigherThanPlan(plan, $scope.currentPlan);
          };
      })
    }));

    // Add Back Entry
    $toolbar.addItem('billing.upgradeEditions.Back', 'Back to Billing', 'fa-chevron-left', null, true, '/billing');

  });
