'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackendAzure
 * @description
 * # eaBackendAzure
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$eaBackendAzureV2', function($q, $timeout, $http, $eaConfig, $eaBackendBase, $base64, $eaBackendDataModeSelector, $acWaitHelpers, rfc4122) {
  var self = this;

  var backendUriV2  = ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + $eaConfig.apiHostV2 + '/api/v2';

  self.dataGetContractReportsV2 = function(teamId, contract, dataToken) {
    var queryParameters = '';
    if ($eaBackendDataModeSelector.isBillingCycleEnabled()) { var queryParameters = '?billing=true'; }
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/reports' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  // Is using the new Core API
  self.dataGetContractReportDataV3 = function(teamId, contract, report, cacheIdentifier, dataToken, retry) {
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
    }

    // generate the cycle parameter
    if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'cycle=billing&day=' + $eaBackendDataModeSelector.getBillingCycleDay();
    }

    // generate the nomargin parameter
    if ($eaBackendDataModeSelector.isNoMarginEnabled()) {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'nomargin=true';
    }

    // add no mers
    if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
    queryParameters += 'nometers=true';

    // add the retry parameter
    if (retry)
    {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'retry=true';
    }

    // user our api boost hosts, generate the index based on report name
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/reports/' + report + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetContractReportHistoryV3 = function(teamId, contract, cacheIdentifier, dataToken, retry) {

    // build the query parameter
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = 'cache=' + cacheIdentifier + '&age=31557600&';
    }

    // generate the time
    var endDate = moment().format('YYYY-MM-DD');
    var startDate = moment().add(-31, "days").format('YYYY-MM-DD');
    queryParameters += "startDay=" + startDate + "&endDay=" + endDate;

    // generate the nomargin parameter
    if ($eaBackendDataModeSelector.isNoMarginEnabled()) {
      queryParameters += '&nomargin=true';
    }

      // add the retry parameter if needed
    if (retry) {queryParameters += "&retry=true"; }

    // query
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/history/daily?' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetAccount = function(teamId, accountId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterAccountT1V2 = function(teamId, name, clientId, clientSecret, domain, billingDay, syncCustomers, autoActivateCustomers, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/t1', { Name: name, ClientId: clientId, ClientSecret: clientSecret, Domain: domain, BillingDay: billingDay, Initialize: true, SyncCustomers: syncCustomers, AutoActivateCustomers: autoActivateCustomers}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterAccountEAV2 = function(teamId, name, currency, token, cmapping, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/ea', { Name: name, SecurityToken: token, Currency: currency, CustomerMapping: cmapping, Initialize: true}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterAccountV1 = function(teamId, name, currency, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/virtual', { Name: name, Currency: currency }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterAccountDemo = function(teamId, name, contractType, currency, cmapping, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/demo', { Name: name, Currency: currency, CustomerMapping: cmapping, Initialize: true, FakeServiceType: contractType}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspMonthSyncReportsV2 = function(teamId, accountId, month, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2  + '/csps/' + teamId + '/accounts/' + accountId + '/reports/' + month + '/sync', undefined, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspSyncCustomerInAccount = function(teamId, accountId, customerId, optionalMonth, dataToken) {
    var query = optionalMonth ? '?month=' + optionalMonth : '';
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/sync' + query, {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspUpdateAccountEAV2 = function(teamId, accountId, name, currency, token, exchangeRate, defaultResellerManagement, dataToken) {

    // define the payload
    var updateAccountPayload = { Name: name, Currency: currency, ExchangeRate: exchangeRate, DefaultResellerManagement: defaultResellerManagement };
    if (token) { updateAccountPayload.SecurityToken = token; }

    // send the request
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/ea', updateAccountPayload, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspUpdateAccountAWSV2 = function(teamId, accountId, name, currency, exchangeRate, defaultResellerManagement, dataToken) {

    // define the payload
    var updateAccountPayload = { Name: name, Currency: currency, ExchangeRate: exchangeRate, DefaultResellerManagement: defaultResellerManagement };

    // send the request
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/aws', updateAccountPayload, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspUpdateAccountT1V2 = function(teamId, accountId, name, clientId, clientSecret, domain, billingDay, exchangeRate, autoActivateCustomers, defaultResellerManagement, dataToken) {

    // define the payload
    var updateAccountPayload = { Name: name, ClientId: clientId, ClientSecret: clientSecret, Domain: domain, BillingDay: billingDay, ExchangeRate: exchangeRate, AutoActivateCustomers: autoActivateCustomers, DefaultResellerManagement: defaultResellerManagement };

    // send the request
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/t1', updateAccountPayload, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterCustomer = function(teamId, accountId, tenantIdOrDomain, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers', { domain: tenantIdOrDomain }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspRegisterCostMgntCustomer = function(teamId, accountId, customerName, hasConsumption, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers', { HasConsumption: hasConsumption, CustomerName: customerName, CustomerSubType: 'AzureCostMgnt'}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  // api/v2/csps/{teamId}/accounts/{accountId}/spendings")]
  self.dataCspGetCustomersSpendingsInAccountV2 = function(viewId, teamId, accountId, month, dataToken, cacheIdentifierMap, retry) {

    var queryParameters = '?';

    // build the date
    var reportMonth = month + '-01';

    var splitedMonth = month.split('-');
    var daysInMonth = Date.getDaysInMonth(parseInt(splitedMonth[0]), parseInt(splitedMonth[1]));
    var reportMonthEnd = month + '-' + daysInMonth.toString().padStart(2, '0');

    // generate the cycle parameter
    if ($eaBackendDataModeSelector.isBillingCycleEnabled() && $eaBackendDataModeSelector.getBillingCycleDay() !== 1) {

      // start the month in the last month
      var reportMonth = moment(reportMonth).subtract(1, 'months').toDate();

      // set the start date
      reportMonth = reportMonth.currentMonthDefinition() + '-' + $eaBackendDataModeSelector.getBillingCycleDay().toString().padStart(2, '0');

      // next month
      var nextDate = new Date(reportMonth).addDays(31);
      reportMonthEnd = nextDate.currentMonthDefinition() + '-' + ($eaBackendDataModeSelector.getBillingCycleDay() - 1).toString().padStart(2, '0');
    }

    // set parameters
    queryParameters += "startTime=" + reportMonth + 'T00:00:00.000Z';
    queryParameters += "&endTime=" + reportMonthEnd + 'T00:00:00.000Z';

    // add the view id
    queryParameters += "&viewId=" + viewId;

    // add the cache
    cacheIdentifierMap[reportMonth] = cacheIdentifierMap[reportMonth] || rfc4122.v4();
    queryParameters += "&cache=" + cacheIdentifierMap[reportMonth];

    // retry
    if (retry) { queryParameters += '&retry=true'; }

    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/spendings' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetCustomersInAccount = function(teamId, accountId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspActiveCustomersInAccount = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/activate', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspDeActiveCustomersInAccount = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/deactivate', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetGsiSettings = function(teamId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/gsisettings', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspSetGsiSettings = function(teamId, gsiCurrency, dataToken) {
    var payload = { ReferenceCurrency: gsiCurrency };
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/gsisettings', payload, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataMonthSyncReportsV2 = function(teamid, contract, months, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2  + '/teams/' + teamid + '/contracts/' + contract + '/reports/sync', months.map(function(month) { return { "reportId": month } }), {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataSetServiceTagsV4 = function(teamid, contract, serviceId, serviceTags, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2  + '/teams/' + teamid + '/contracts/' + contract + '/services/' + serviceId + '/tags',  { tags: serviceTags }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspSyncAccount = function(teamId, accountId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/sync', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspUpdateVirtualMetersForCustomerInAccount = function(teamId, accountId, customerId, month, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/updatevmeters', { Month: month }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspUpgradeResellerVersionForCustomer = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/version', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * AWS API
   */
  self.dataAwsVerifyConnection = function(teamId, roleArn, externalId, bucket, dataToken)
  {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/aws/verify', { AssumedRole: roleArn, ExternalId: externalId, Bucket: bucket}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataAwsRegisterContract = function(teamId, roleArn, externalId, bucket, prefix, reportname, dataToken)
  {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/aws', {
      AssumedRole: roleArn, ExternalId: externalId,
      Bucket: bucket, BucketPrefix: prefix,
      ReportName: reportname,
      Initialize: true
    }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  };

  self.dataEARegisterContract = function(teamId, contractId, eaToken, dataToken)
  {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/ea', {
      ContractId: contractId,
      Token: eaToken,
      Initialize: true
    }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function() {
      return $q.when(contractId);
    });
  };

  self.dataCostMgntRegisterContract = function(teamId, dataToken)
  {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/azurecostmgnt', 
    {}, 
    {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  };

  self.dataCostMgntAddContainerToContract = function(teamId, contractId, storageAccountName, storageAccountKey, reportContainer, reportFolder, dataToken) {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/azurecostmgnt/' + contractId + '/containers', {
      AccountName: storageAccountName,
      AccountKey: storageAccountKey,
      ReportContainer: reportContainer,
      ReportFolder: reportFolder,      
    }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  }

  self.dataDemoRegisterContract = function(teamId, dataToken)
  {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/demo', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  };

  /*
   * GCP APi
   */
  self.dataGcpVerifyConnection = function(teamId, accountKey, dataSetName, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/gcp/verify', { ServiceAccountKey: accountKey, BillingDataSet: dataSetName }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGcpRegisterContract = function(teamId, accountKey, dataSetName, dataToken) {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/gcp', {
      ServiceAccountKey: accountKey, BillingDataSet: dataSetName,
      Initialize: true
    }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  };

  /*
   * RI Analysis API
   */

  self.dataGetRiVirtualMachines = function(teamId, contract, cacheIdentifier, dataToken, retry) {
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
    }

    // add the retry parameter
    if (retry) {
      if (queryParameters === '') {
        queryParameters = '?';
      } else {
        queryParameters += '&';
      }
      queryParameters += 'retry=true';
    }

    // user our api boost hosts, generate the index based on report name
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/ri/vms/' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetRiAnalysisResult = function(teamId, contract, resourceInstanceId, cacheIdentifier, dataToken, retry, queryoptions) {
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
    }

    // add the retry parameter
    if (retry)
    {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'retry=true';
    }

    // add query options
    if (queryoptions) {
      Object.keys(queryoptions).forEach(function(qk) {
        queryParameters += '&' + qk + '=' + queryoptions[qk] ;
      })
    }

    // user our api boost hosts, generate the index based on report name
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/ri/' + resourceInstanceId + '/analysis' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * Price Sheet
   */
  self.dataGetPriceComparisionSheet = function(teamId, contract, notUsedResourceId, cacheIdentifier, dataToken, retry, queryOptions) {
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
    }

    // add the retry parameter
    if (retry) {
      if (queryParameters === '') {
        queryParameters = '?';
      } else {
        queryParameters += '&';
      }
      queryParameters += 'retry=true';
    }

    // add our normal values
    if (queryParameters === '') {
      queryParameters = '?';
    } else {
      queryParameters += '&';
    }

    queryParameters += 'region=' + queryOptions.region + '&locale=' + queryOptions.locale + '&currency=' + queryOptions.currency + '&erate=' + queryOptions.erate;

    // user our api boost hosts, generate the index based on report name
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/pricesheet/' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
  * Report Center API for Widgets
  */
  self.dataGetReportCenterReportDefinitions = function(teamid, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamid + '/reportcenter/v2/definitions', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * Teaming API
   */

  self.dataInviteUserToTeamV2 = function(teamid, contact, role, fullAccess, callback, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamid + '/invitations', { Contact: contact, Role: role, FullAccess: fullAccess, Callback: callback}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * Cache Hashes
   */
  self.dataGetTeamHashes = function(teamId, hashCategory, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/hashes/categories/' + hashCategory, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetTeamHash = function(teamId, hashCategory, hashId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/hashes/categories/' + hashCategory + '/' + hashId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  /*
   * Reseller
   */

    self.dataGetCspResellers = function(teamId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/resellers', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataRegisterCspResellers = function(teamId, resellerName, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/resellers', { Name: resellerName }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataDeleteEmptyCspResellers = function(teamId, resellerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(backendUriV2 + '/csps/' + teamId + '/resellers/' + resellerId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPublishCspCustomer = function(teamId, resellerId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/resellers/' + resellerId + '/customers', { AccountId: accountId, CustomerId: customerId }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataUnPublishCspCustomer = function(teamId, resellerId, resellerAccountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(backendUriV2 + '/csps/' + teamId + '/resellers/' + resellerId + '/accounts/' + resellerAccountId + '/customers/' + customerId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetCspResellerSummary = function(teamId, reportId, cacheIdentifier, dataToken, retry)
  {
    var queryParameters = '';

    // build the cache identifier
    if (cacheIdentifier !== undefined && cacheIdentifier !== null) {
      queryParameters = '?cache=' + cacheIdentifier + '&age=31557600';
    }

    // add the retry parameter
    if (retry)
    {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'retry=true';
    }

    // user our api boost hosts, generate the index based on report name
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/resellers/summary/' + reportId + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspSetResellerMarginForReseller = function(teamId, resellerId, margins, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/resellers/margins/' + resellerId, { margins: margins }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetResellerMarginForReseller = function(teamId, resellerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/resellers/margins/' + resellerId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(responseData) {
      return responseData.margins.map(function(m) {
        return { MatchKey: m.matchKey || '*', Margin: m.margin, ValidUntil: m.validUntil };
      });
    });
  };

  /*
   * CSP
   */
  self.dataGetCspDownloadAccountInvoice = function(teamid, accountId, month, dataToken)  {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamid + '/accounts/' + accountId + '/invoices/' + month + '/requestdownload', {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(oneTimeDownloadTicketResponse) {
      return backendUriV2 + '/csps/' + teamid + '/invoices/download?ticket=' + oneTimeDownloadTicketResponse.oneTimeDownloadTicket
    })
  };

  self.dataCspAwsRegisterContract = function(teamId, accountName, roleArn, externalId, bucket, prefix, reportname, customerMapping, dataToken)
  {
    return $eaBackendBase.extractDataFromLocationHeader($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/aws', {
      Name: accountName,
      AssumedRole: roleArn,
      ExternalId: externalId,
      BucketName: bucket,
      Prefix: prefix,
      ReportName: reportname,
      CustomerMapping: customerMapping,
      Initialize: true
    }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(result) {
      return $q.when(result.entityId);
    });
  };


  self.dataCspGetResellerMarginForCustomer = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/margins', {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(responseData) {
      return responseData.margins.map(function(m) {
        return { MatchKey: m.matchKey || '*', Margin: m.margin, ValidUntil: m.validUntil };
      });
    });
  };

  self.dataCspSetResellerMarginForCustomer = function(teamId, accountId, customerId, margins, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/margins', { margins: margins }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetResellerFeaturesForCustomer = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/features', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataCspSetResellerFeaturesForCustomer = function(teamId, accountId, customerId, features, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/features', features, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataPostPurgeContractReports = function(teamId, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/reports/purge-caches', {}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetContractInfo = function(teamId, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contract + '/info', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.triggerContractNotificationForUser = function(teamId, userId, contractId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/contracts/' + contractId + '/notify', { userid: userId}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCspGetUpliftCategories = function(teamId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/settings/upliftcategories', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  // Dashboard Layout

  self.dataGetDashboardLayout = function(teamId, dashboardId, dashboardVersion, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/dashboards/' + dashboardId + '/layout?version=' + dashboardVersion, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataPostDashboardLayout = function(teamId, dashboardId, dashboardVersion, dashboardLayoutItems, dataToken) {
    var model = {
      version: dashboardVersion,
      items: dashboardLayoutItems
    };

    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/dashboards/' + dashboardId + '/layout', model, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataGetDashboardWidgets = function(teamId, dashboardId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/dashboards/' + dashboardId + '/widgets', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataPostDashboardWidget = function(teamId, dashboardId, widgetDescription, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(backendUriV2 + '/teams/' + teamId + '/dashboards/' + dashboardId + '/widgets', { ItemId: widgetDescription.ItemUuid, WidgetDescription: widgetDescription}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataDeletDashboardWidget = function(teamId, dashboardId, widgetId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(backendUriV2 + '/teams/' + teamId + '/dashboards/' + dashboardId + '/widgets/' + widgetId, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  // tags
  self.dataGetContractTags = function(teamId, contractId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contractId + '/tags', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataGetMetersForService = function(teamId, contractId, month, serviceId, dataToken) {

    // generate the nomargin parameter
    var queryParameters = '';
    if ($eaBackendDataModeSelector.isNoMarginEnabled()) {
      if (queryParameters === '') { queryParameters = '?'; } else { queryParameters += '&'; }
      queryParameters += 'nomargin=true';
    }

    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/teams/' + teamId + '/contracts/' + contractId + '/reports/' + month + '/services/' + serviceId + '/meters' + queryParameters, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}))
  };

  self.dataCspGetResellerFilterForCustomer = function(teamId, accountId, customerId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/filter', {headers: $eaBackendBase.getDefaultHeaders(dataToken)})).then(function(fm) {
      return fm.filter;
    });
  };

  self.dataCspSetResellerFilterForCustomer = function(teamId, accountId, customerId, filter, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.put(backendUriV2 + '/csps/' + teamId + '/accounts/' + accountId + '/customers/' + customerId + '/filter', { Filter: filter }, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataCpvGetConsentLink = function(teamId, accountId, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(backendUriV2 + '/cpv/' + teamId + '/accounts/' + accountId + '/consent', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataDeleteContract = function(teamid, contract, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.delete(backendUriV2 + '/teams/' + teamid + '/contracts/' + contract, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  }
  
});