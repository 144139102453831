'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetIframe', function() {
  return {
    restrict: 'C',
    template: '<div class="widget-iframe" id=\"iframe-{{uuid}}\" ng-if="enableFrame">' +
                '<iframe ng-src="{{targetPageTrusted}}" id=\"iframe-{{uuid}}-frame\"></iframe>' +
              '</div>',
    controller: 'EaWidgetIframeCtrl'
  };
}).controller('EaWidgetIframeCtrl', function($scope, $q, $eaConfig, $eaDataCoordinator, $sce) {

  $scope.enableFrame = false;

  function loadIFrame() {

    if ($scope.options.src) {

      // get the uri
      $scope.targetPage = $scope.options.src;
      $scope.targetPageTrusted = $sce.trustAsResourceUrl($scope.targetPage);

      $scope.setLoading(false);
      $scope.enableFrame = true;
      return $q.when();
    } else {
      $scope.setLoading(true);
      return $q.when();
    }
  }

  $scope.onResize(function(newSize) {
    return $q.when();
  });

  $scope.onInitialize(function() {
    return loadIFrame();
  });

  $scope.onReload(function() {
    document.getElementById('iframe-' + $scope.uuid + '-frame').src += '';
    return $q.when();
  });

  $scope.finalizeRegistration();
});
