'use strict';

angular.module('azureCostsFeApp').controller('EaRiManagementCtrl', function ($scope, $stateParams, $state, $eaCurrentSession, $eaUserProfile, $eaWidgetDashboardManager, $eaBilling) {

  var riWelcomeDashboardId = 'ri.welcome';
  var riPurchasedImpactDashboardId = 'ri.impact';

  /*
   * Handle the dataToken
   */
  $scope.dataToken = $eaCurrentSession.DataToken;

  /*
   Manage the Edit Mode
   */
  $scope.dashboardEditMode = false;

  $scope.toggelEditMode = function() {
    $scope.dashboardEditMode = !$scope.dashboardEditMode;
  };

  // Action: Allow to Upgrade the plan
  $eaWidgetDashboardManager.onDashboardFunction(riWelcomeDashboardId, 'func:EC80426D-4EBD-4249-B56A-D0D458F0E3EF', function (args) {

    // navigate to the scope
    $state.go('authorized.profileBillingUpgradeEdition');

  });

    // load the current user profile
  return $eaUserProfile.load($eaCurrentSession.DataToken).then(function(profile) {

    // collect the available contracts for rendering our navigation
    $scope.AvailableContracts = [];

    $eaUserProfile.getContracts(profile, $stateParams.teamid).forEach(function(contract) {

      // add the contact
      $scope.AvailableContracts.push({
        Name: contract.Definition === 'EA' ? 'EA-' + contract.Enrollment : contract.Name,
        Id: contract.Enrollment,
        Team: contract.Team
      });
    });

    // get the team model
    var teamProfile = $eaUserProfile.getTeam(profile, $stateParams.teamid);

    // check if we have the required plan
    if (!$eaBilling.hasRequiredPlan(teamProfile.AccountPlan, 'professional')) {

      // set the active dashboard
      $scope.activeDashboard = {
        Id: riWelcomeDashboardId,
        Name: 'Welcome',
        Profile: profile,
        Team: $stateParams.teamid,
        ContractId: 'RI:Welcome',
        Active: true,
        Collection: 'ri'
      }
    } else {

      // set the active dashboard
      $scope.activeDashboard = {
        Id: riPurchasedImpactDashboardId,
        Name: 'Purchase Impact Details',
        Profile: profile,
        Team: $stateParams.teamid,
        ContractId: 'RI:PurchaseImpact',
        Active: true,
        Collection: 'ri'
      }
    };
  });

});
