'use strict';

angular.module('azureCostsFeApp').service('$eaDataColorThemeGenerator', function() {
  var self = this;

  /*
   * Helper to calculate the colors in a correct way based on our base color
   * color map. In addition it allows to shade when the base color map is out of
   * range.
   */
  function intToHexCodeString(n) {
    n = parseInt(n, 10);

    if (isNaN(n)) {
      return '00';
    } else {
      n = Math.max(0, Math.min(n, 255));

      return '0123456789ABCDEF'.charAt((n - n % 16) / 16) + '0123456789ABCDEF'.charAt(n % 16);
    }
  }

  function rgbToHexWebCode(R,G,B) {return '#' + intToHexCodeString(R)+intToHexCodeString(G)+intToHexCodeString(B); }

  function hexWebCodeShadeRelative(color, percent) {

    /*jshint bitwise: false*/
    var f=parseInt(color.slice(1),16),t=percent<0?0:255,p=percent<0?percent*-1:percent,R=f>>16,G=f>>8&0x00FF,B=f&0x0000FF;
    return '#'+(0x1000000+(Math.round((t-R)*p)+R)*0x10000+(Math.round((t-G)*p)+G)*0x100+(Math.round((t-B)*p)+B)).toString(16).slice(1);
  }

  /*
   * Defines the base color palette we can use for the diagram
   */
  var baseColorPalette = [
    // Red: 242, 80, 34, #F25022
    rgbToHexWebCode(242, 80, 34),

    // Green: 127, 186, 34, #7FBA22
    rgbToHexWebCode(127,186,34),

    // Gray: 150, 150, 150, #1BA4EF
    rgbToHexWebCode(150,150, 150),

    // Yellow: 253, 185, 44, #FDB92C
    rgbToHexWebCode(253, 185, 44),

    // #194280
    //rgbToHexWebCode(25, 66, 128)

    // 89, 200, 242
    rgbToHexWebCode(89, 200, 242)
  ];

  function getSubscriptionColorByIndex(colorIndex) {

    var moduloResult = colorIndex % baseColorPalette.length;
    var divResult = Math.floor(colorIndex / baseColorPalette.length);

    var baseColor = baseColorPalette[moduloResult];
    while(divResult > 0 ) {
      divResult--;
      baseColor = hexWebCodeShadeRelative(baseColor, -0.2);
    }

    return baseColor;
  }

  var colorStreamLastIndexMap = {};
  var colorIndexMap = {};

  // This method implements the existing color theme generator and ensures
  // that for a give tag-value the system returns the same color
  self.getColorFor = function(dataStreamType, colorTagValue) {

    // check parameters
    if (!dataStreamType || !colorTagValue) { return getSubscriptionColorByIndex(0); }

    // generate the color stream index
    var colorStreamIndex = dataStreamType + '#' + colorTagValue.toLowerCase();

    // check if we have an index for this pair
    var index = colorIndexMap[colorStreamIndex];
    if (index === undefined) {

      // get the last index of this stream
      index = colorStreamLastIndexMap[dataStreamType];
      if (index === undefined) { index = 0; } else { index++; }

      // assign the index to this color stream
      colorIndexMap[colorStreamIndex] = index;

      // store this index as last index
      colorStreamLastIndexMap[dataStreamType] = index;
    }

    // generate the color by index
    return getSubscriptionColorByIndex(index);
  };
});
