'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceV1Spendings', function($acDataSourceHelper, $acDataSourceSettings, $acDataSourceClasses, $q, $eaReportData) {
  var self = this;

  function generateServiceIconClass(serviceModel) {

    // get the icon type
    var iconClass = serviceModel.ServiceCategory.toLowerCase();

    // replace spaces with _
    iconClass = iconClass.replace(/ /g, '_');

    return iconClass;
  }

  function mapSpendingEntries(teamid, contract, dataToken, reportsInfo, loadedData)
  {
    // adapt the data
    var models = [];

    // the hash for tags
    var azureTagCaseAdaptionHash = {};

    loadedData.forEach(function (report) {

      // check if we did not get data
      if (typeof(report) === 'string') {
        console.log("Report has no values, skipping");
        return;
      }

      report.services.forEach(function (service) {
        
        // generate the model
        var model = new $acDataSourceClasses.SpendingEntryClass();

        // fill the normal properties
        model.ReportId = report.year + '-' + report.month;
        model.ReportMonth = report.month;
        model.ReportYear = report.year;
        model.ReportLastSync = report.lastSync ? new Date(report.lastSync) : null;
        model.ReportCurrency = reportsInfo.Currency;
        model.ReportCurrencySymbol = $acDataSourceHelper.getCurrencySymbolFromCurrency(reportsInfo.Currency);
        model.ServiceId = service.serviceId;
        model.ServiceName = service.name;
        model.ServiceCosts = service.costs;
        model.ServiceCategory = service.type;
        model.ServiceType = service.subtype;
        model.ServiceSize = service.size;
        model.ServiceQuantity = service.quantity;
        model.ServiceQuantityUnit = service.unit;
        model.ServiceTags = service.tags;
        model.ServiceTagsV1 = service.tagsV1;
        model.ServiceTagsV2 = service.tagsV2;
        model.ServiceTagsV3 = service.tagsV3;
        model.SubscriptionName = service.subscription;
        model.SubscriptionId = service.subscriptionId;
        model.CostCenter = service.costcenter;
        model.Department = service.department;
        model.ResourceGroup = service.resourcegroup;
        model.ResourceUri = service.resourecuri;
        model.EntityId = model.ReportId + '-' + model.ServiceId;
        model.ServiceDailyCosts = service.dailyCosts;
        model.ServiceDailyQuantities = service.dailyQuantity;
        model.ServiceIcon = 'servicetype ' + generateServiceIconClass(model);

        // generate the dynamic tag properties
        if (service.azureTags) {

          Object.keys(service.azureTags).forEach(function (k) {

            // check if we had the tag before
            if (!azureTagCaseAdaptionHash[k.toUpperCase()]) {
              azureTagCaseAdaptionHash[k.toUpperCase()] = k;
            }

            // get the correct tagname just in case of case sensitive duplicates
            var correctedTagName = 'AzureTag:' + azureTagCaseAdaptionHash[k.toUpperCase()];
            model[correctedTagName] = service.azureTags[k];
          })
        }

        // add the model
        models.push(model);
      });
    });

    // done
    return $q.when(models);
  }

  function loadReportHistoryData(teamid, contract, dataToken, className, cacheHash, optionalParams) {

    // load the values from the backend
    return $eaReportData.loadContractDetails(teamid, contract, dataToken, optionalParams).then(function(contractDetails) {

      // load the historical data (we support last 30 days
      return $eaReportData.loadReportHistory(teamid, contract, dataToken).then(function(historyData) {

        var result = [];

        // visit every day
        Object.keys(historyData).forEach(function(day) {

          var dayData = historyData[day];
          var overallCosts = dayData.overall;

          // get the daybefore
          var dayBefore = historyData[moment(day).add(-1, 'days').format('YYYY-MM-DD')];
          if (!dayBefore) { dayBefore = dayData; }

          // visit every subscription
          Object.keys(dayData).forEach(function(subscription) {
            if (subscription !== 'overall') {

              // ensure we have a day before
              if (!dayBefore[subscription]) { dayBefore[subscription] = 0.0; }
              /*jshint -W069 */
              if (!dayBefore['overall']) { dayBefore['overall'] = 0.0; }

              // build the entity
              var entry = new $acDataSourceClasses.SpendingHistoryEntryClass();
              entry.HistoryDay = day;
              entry.HistoryCurrency = contractDetails.Currency;
              entry.HistoryCurrencySymbol = $acDataSourceHelper.getCurrencySymbolFromCurrency(contractDetails.Currency);
              entry.HistoryDayOverallCosts = overallCosts;
              entry.HistoryDayOverallCostsDiff = overallCosts - dayBefore['overall'];
              entry.SubscriptionId = subscription;
              entry.SubscriptionDayCosts = dayData[subscription];
              entry.SubscriptionDayCostsDiff = dayData[subscription] - dayBefore[subscription];

              // check the first day in month
              if (moment(day).date() === 1) {
                entry.SubscriptionDayCostsDiff = entry.SubscriptionDayCosts;
                entry.HistoryDayOverallCostsDiff = entry.HistoryDayOverallCosts;
              }

              // should we have negative values set to 0
              if (entry.SubscriptionDayCostsDiff < 0) { entry.SubscriptionDayCostsDiff = 0; }
              if (entry.HistoryDayOverallCostsDiff < 0) { entry.HistoryDayOverallCostsDiff = 0; }

              // done
              result.push(entry);
            }
          });
        });

        return $q.when(result);
      });
    });
  }

  function mapSpendingHistoryEntries(teamid, contract, dataToken, reportsInfo, loadedData)
  {
    var result = [];

    // check
    if (!Array.isArray(loadedData) || loadedData.length <= 0) {
      return $q.when([]);
    }

    // get the data
    var historyData = loadedData[0];

    // visit every day
    Object.keys(historyData).forEach(function(day) {

      var dayData = historyData[day];
      var overallCosts = dayData.overall;

      // get the daybefore
      var dayBefore = historyData[moment(day).add(-1, 'days').format('YYYY-MM-DD')];
      if (!dayBefore) { dayBefore = dayData; }

      // visit every subscription
      Object.keys(dayData).forEach(function(subscription) {
        if (subscription !== 'overall') {

          // ensure we have a day before
          if (!dayBefore[subscription]) { dayBefore[subscription] = 0.0; }
          /*jshint -W069 */
          if (!dayBefore['overall']) { dayBefore['overall'] = 0.0; }

          // build the entity
          var entry = new $acDataSourceClasses.SpendingHistoryEntryClass();
          entry.HistoryDay = day;
          entry.HistoryCurrency = reportsInfo.Currency;
          entry.HistoryCurrencySymbol = $acDataSourceHelper.getCurrencySymbolFromCurrency(reportsInfo.Currency);
          entry.HistoryDayOverallCosts = overallCosts;
          entry.HistoryDayOverallCostsDiff = overallCosts - dayBefore['overall'];
          entry.SubscriptionId = subscription;
          entry.SubscriptionDayCosts = dayData[subscription];
          entry.SubscriptionDayCostsDiff = dayData[subscription] - dayBefore[subscription];

          // check the first day in month
          if (moment(day).date() === 1) {
            entry.SubscriptionDayCostsDiff = entry.SubscriptionDayCosts;
            entry.HistoryDayOverallCostsDiff = entry.HistoryDayOverallCosts;
          }

          // should we have negative values set to 0
          if (entry.SubscriptionDayCostsDiff < 0) { entry.SubscriptionDayCostsDiff = 0; }
          if (entry.HistoryDayOverallCostsDiff < 0) { entry.HistoryDayOverallCostsDiff = 0; }

          // done
          result.push(entry);
        }
      });
    });

    return $q.when(result);
  }

  self.registerDataSources = function(dataSourceManager) {

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', 'spendings', $acDataSourceSettings.loadStaticDataGenericCallback);

    // Register load for ReportClasses
    $acDataSourceHelper.registerDataSourceReportClass(dataSourceManager, null);
    $acDataSourceHelper.registerDataSourceReportClass(dataSourceManager, 'spendings');

    // Register load for Spendings
    $acDataSourceHelper.registerDataSourceSpendingEntryClassWithMappingCallback(dataSourceManager, null, 1, mapSpendingEntries);
    $acDataSourceHelper.registerDataSourceSpendingEntryClassWithMappingCallback(dataSourceManager, 'spendings', 1, mapSpendingEntries);

    // Register load for SpendingFilterClass
    $acDataSourceHelper.registerDataSourceSpendingFilerClass(dataSourceManager, null);
    $acDataSourceHelper.registerDataSourceSpendingFilerClass(dataSourceManager, 'spendings');

    // Register load for SpendingHistoryEntryClass
    $acDataSourceHelper.registerDataSourceSpendingHistoryEntryClassWithMappingCallback(dataSourceManager, null, 1, mapSpendingHistoryEntries);
    $acDataSourceHelper.registerDataSourceSpendingHistoryEntryClassWithMappingCallback(dataSourceManager, 'spendings', 1, mapSpendingHistoryEntries);
  }
});
