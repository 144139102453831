'use strict';

/*
 * This service just contains general settings which can be used from teh data sources.
 */
angular.module('azureCostsFeApp').service('$acChartContainerHelpers', function($q) {
  var self = this;

  self.waitUntilChartContainerExists = function(containerId, defer) {
    defer = defer ? defer : $q.defer();

    var checkExist = setInterval(function() {
      if ($('div#' + containerId).length) {
        clearInterval(checkExist);
        defer.resolve();
      } else {
        self.waitUntilChartContainerExists(containerId, defer);
      }
    }, 100); // check every 100ms

    return defer.promise;
  };
});
