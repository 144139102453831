'use strict';

angular.module('azureCostsFeApp').service('$eaConfig', function($location, envDeployment, $q) {

  var productionApiHost = 'costs-api-v3.azurewebsites.net';
  var productionApiHostV2 = 'prod-ac-v2-web.azurewebsites.net';
  var productionApiAdminHostV2 = 'prod-ac-v2-web-admin.azurewebsites.net';
  var productionApiBoostHosts = undefined;
  var productionTitle = 'azure <strong>costs</strong>';

  var productionConfiguration = {

    // Host of the backend (V2)
    apiHost: productionApiHost,
    apiHostV2: productionApiHostV2,
    apiHostAdminV2: productionApiAdminHostV2,
    apiBoostHosts: productionApiBoostHosts,

    // Frontend host
    feHost: 'azure-costs.com',

    // AAD Link Config
    aadHost: 'azure-costs.com',

    // Sts Config
    stsHost: 'azure-costs.com',
    stsClientId: 'costsfe',
    stsRedirectUri: 'https://azure-costs.com/app/sso/callback',

    // Authorization Configuration
    authClientId: '0000000044136B0F',
    authRedirectUri: 'https://azure-costs.com/app/auth.html',

    // define environment typ
    envType: 'production',

    // define the cdnhost
    cdn: 'cdnHostProd',

    // define features
    features: {
      buckets: true,
      csp: true,
      fastspring: true,
      billing: true,
      support: true
    },

    // ui
    appTitle: productionTitle
  };

  var productionLocalConfiguration = {

    // Host of the backend (V2)
    apiHost: productionApiHost,
    apiHostV2: productionApiHostV2,
    apiHostAdminV2: productionApiAdminHostV2,

    // Frontend host
    feHost: 'localhost:9000',

    // AAD Link Config
    aadHost: 'azure-costs.com',

    // Sts Config
    stsHost: 'azure-costs.com',
    stsClientId: 'costsfedev',
    stsRedirectUri: 'http://localhost:9000/app/sso/callback',

    // Authorization Configuration
    authClientId: '0000000044136B0F',
    authRedirectUri: 'https://azure-costs.com/app/auth.html',

    // define environment typ
    envType: 'local',

    // define the cdnhost
    cdn: undefined,

    // define features
    features: {
      buckets: true,
      csp: true,
      fastspring: true,
      billing: true,
      support: true
    },

    // ui
    appTitle: productionTitle
  };

  var testConfiguration = {

    // Host of the backend
    apiHost: 'costs-api-test-v2.azurewebsites.net',
    apiHostV2: 'dev-ac-v2-web.azurewebsites.net',
    apiHostAdminV2: 'dev-ac-v2-web-admin.azurewebsites.net',

    // Frontend host
    feHost: 'costs-test.azurewebsites.net',

    // AAD Link Config
    aadHost: 'costs-test.azurewebsites.net',

    // Sts Config
    stsHost: 'costs-test.azurewebsites.net',
    stsClientId: 'costsfetest',
    stsRedirectUri: 'https://costs-test.azurewebsites.net/app/sso/callback',

    // define environment typ
    envType: 'test',

    // define the cdnhost
    cdn: 'cdnHostDev',

    // define features
    features: {
      buckets: true,
      csp: true,
      fastspring: true,
      billing: true,
      support: true
    },

    // ui
    appTitle: 'cloud <strong>costs</strong>'
  };

  var devConfiguration = {

    // Host of the backend
    apiHost: 'costs-api-test-v2.azurewebsites.net',
    apiHostV2: 'dev-ac-v2-web.azurewebsites.net',
    apiHostAdminV2: 'dev-ac-v2-web-admin.azurewebsites.net',

    // Frontend host

    feHost:  $location.$$host,
    feProtocol: 'http',
    fePort: 9000,

    // AAD Link Config
    aadHost: 'costs-test.azurewebsites.net',

    // Sts Config
    stsHost: 'costs-test.azurewebsites.net',
    stsClientId: 'costsfedev',
    stsRedirectUri: 'http://' + $location.$$host + ':9000/app/sso/callback',

    // define environment typ
    envType: 'local',

    // define the cdnhost
    cdn: undefined,

    // define features
    features: {
      buckets: true,
      csp: true,
      fastspring: true,
      billing: true,
      support: true
    },

    // ui
    appTitle: productionTitle
};

var serviceProviderGermanCloudConfiguration = {

    // Host of the backend (V2)
    apiHost: productionApiHost,
    apiHostV2: productionApiHostV2,
    apiHostAdminV2: productionApiAdminHostV2,
    apiBoostHosts: productionApiBoostHosts,

    // Frontend host
    feHost: 'azure-costs.de',

    // AAD Link Config
    aadHost: 'azure-costs.de',

    // Sts Config
    stsHost: 'azure-costs.de',
    stsClientId: '5B68BA87-175C-4E54-B228-A8F844823BBF',
    stsRedirectUri: 'https://azure-costs.de/app/sso/callback',

    // define environment typ
    envType: 'production',

    // define the cdnhost
    cdn: 'cdnHostProd',

    // define features
    features: {
      buckets: false,
      csp: true,
      fastspring: true,
      billing: true,
      support: true
    },

    // svp information
    svp: {
      contact: 'helper@azure-costs.com'
    },

    // ui
    appTitle: productionTitle
  };

  var serviceProviderMatrixConfiguration = {

    // Host of the backend (V2)
    apiHost: productionApiHost,
    apiHostV2: productionApiHostV2,
    apiHostAdminV2: productionApiAdminHostV2,
    apiBoostHosts: productionApiBoostHosts,

    // Frontend host
    feHost: 'cloudcosts.matrix42.com',

    // AAD Link Config
    aadHost: 'cloudcosts.matrix42.com',

    // Sts Config
    stsHost: 'cloudcosts.matrix42.com',
    stsClientId: '6ED1E682-4324-40A7-92B4-6EA244440884',
    stsRedirectUri: 'https://cloudcosts.matrix42.com/app/sso/callback',

    // define environment typ
    envType: 'production',

    // define the cdnhost
    cdn: 'cdnHostProd',

    // define features
    features: {
      buckets: false,
      csp: true,
      fastspring: false,
      billing: false,
      support: true
    },

    // svp information
    svp: {
      contact: 'support@matrix42.com'
    },

    // ui
    appTitle: 'Matrix42 Cloud Costs'
  };

  var serviceProviderCentralityConfiguration = {

    // Host of the backend (V2)
    apiHost: productionApiHost,
    apiHostV2: productionApiHostV2,
    apiHostAdminV2: productionApiAdminHostV2,
    apiBoostHosts: productionApiBoostHosts,

    // Frontend host
    feHost: 'customer.centrality.com',

    // AAD Link Config
    aadHost: 'customer.centrality.com',

    // Sts Config
    stsHost: 'customer.centrality.com',
    stsClientId: '62FDDE45-2A58-4F96-85C6-E007300BE1FC',
    stsRedirectUri: 'https://customer.centrality.com/app/sso/callback',

    // define environment typ
    envType: 'production',

    // define the cdnhost
    cdn: 'cdnHostProd',

    // define features
    features: {
      buckets: false,
      csp: true,
      fastspring: false,
      billing: false,
      support: true
    },

    // svp information
    svp: {
      contact: 'info@centrality.com'
    },

    // ui
    appTitle: productionTitle
  };

  // define the domain to serviceprovider map
  var svpMap = {        
    'azure-costs.de': serviceProviderGermanCloudConfiguration,
    'cloudcosts.matrix42.com': serviceProviderMatrixConfiguration,
    'customer.centrality.com': serviceProviderCentralityConfiguration
  };

  function isLocalHostSetup() {
    var currentHost = $location.host();
    return (currentHost === 'localhost' || currentHost === '127.0.0.1' || currentHost === '0.0.0.0');
  }

  function isDevSetup() {
    var currentHost = $location.host();
    return (currentHost.indexOf('costs-test.azurewebsites.net') !== -1) || (currentHost.indexOf('costs-test-fe.azurewebsites.net') !== -1);
  }

  // use prod as default
  var activeConfiguration = productionConfiguration;

  // check if we have different config
  if (isLocalHostSetup()) {
    activeConfiguration = devConfiguration;
  } else if (isDevSetup()) {
    activeConfiguration = testConfiguration;
  }

  // lookup the service provider map
  var activeSvpConfiguration = svpMap[$location.host()];
  if (activeSvpConfiguration) {
    activeConfiguration = activeSvpConfiguration;
  }

  // Set this for production only testing
  // activeConfiguration = productionLocalConfiguration;

  // inject the application specific config
  activeConfiguration.deployment = envDeployment;

  // generate the cdn entrypoint
  activeConfiguration.cdnEntrypoint = undefined;
  if (activeConfiguration.cdn) {
    activeConfiguration.cdnEntrypoint = 'https://' + activeConfiguration.deployment[activeConfiguration.cdn];
  }

  // generate the data path
  activeConfiguration.dataPath = '/app/data';
  if (activeConfiguration.envType !== 'local') {
    activeConfiguration.dataPath += '-' + activeConfiguration.deployment.deploymentUUID;
  }

  // ensure we have at least one boost host
  if (!activeConfiguration.apiBoostHosts || activeConfiguration.apiBoostHosts.length === 0) {
    activeConfiguration.apiBoostHosts = [activeConfiguration.apiHost];
  }

  // inject some helper function
  activeConfiguration.verifyFeature = function(feature) {
    if (activeConfiguration.features[feature] === true) {
      return $q.when(true);
    } else {
      return $q.reject(new Error(feature + ' not enabled'));
    }
  };

  // allow to enable and disable ri functionaliy
  activeConfiguration.riEnabled = false;

  return activeConfiguration;

});
