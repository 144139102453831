'use strict';

angular.module('azureCostsFeApp').service('$eaTeamManagement', ['$q', '$eaBackend', function($q, $eaBackend) {
  var self = this;

  // Check if we have at least one contract which is part of a
  // team. This is indicated when the contract list contains one contract
  // which is part ofa team different to personal
  self.isPartOfAtLeastOneTime = function(profile) {
    return (self.getTeams(profile).length > 0);
  };

  self.isAdminInAtLeastOneTime = function(profile) {
    return (profile.Teams.filter(function(team) {
        return team.Role === 'admin' && team.Id !== 'personal';
    }).length > 0);
  };

  self.isAdminInOfTeam = function(profile, teamId) {
    return (profile.Teams.filter(function(team) {
      return team.Role === 'admin' && team.Id !== 'personal' && team.Id === teamId;
    }).length > 0);
  };

  /*
   * Gets the teams execept personal
   */
  self.getTeams = function(profile) {
    return profile.Teams.filter(function(team) {
      return (team.Id !== 'personal');
    });
  };

  /*
   * Returns the teams filtered by a given role
   */
  self.getTeamsByRole = function(profile, role) {
    return self.getTeams(profile).filter(function(team) {
      return (team.Role === role || team.Role === 'admin');
    });
  };

  self.getTeamProfilesByRole = function(profile, role, members, dataToken) {

    var teams = self.getTeamsByRole(profile, role);

    var teamProfilePromisses = [];
    teams.forEach(function(team) {
      teamProfilePromisses.push($eaBackend.dataGetTeamProfile(team.Id, members, dataToken).then(function(teamProfile) {
        // inject the role
        teamProfile.Role = team.Role;
        return teamProfile;
      }).catch(function(/* e */) {
        return null;
      }));
    });

    return $q.all(teamProfilePromisses).then(function(teams) {
      return teams.filter(function(team) {
        return (team !== null && team !== undefined);
      });
    });
  };


  self.getTeamProfileById = function(teamId, members, dataToken) {
    return $eaBackend.dataGetTeamProfile(teamId, members, dataToken);
  }

}]);
