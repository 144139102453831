'use strict';

angular.module('azureCostsFeApp').directive('eaFilterPanel', function () {
  return {
    templateUrl: 'views/directive_ea_filter_panel.html',
    restrict: 'C',
    controller: 'EaFilterPanelCtrl',
    scope: {
      filter: '=',
      userId: '=',
      teamId: '=',
      teamRole: '=',
      fields: '=',
      save: '&',
      share: '&',
      unshare: '&'
    }
  };
}).controller('EaFilterPanelCtrl', function($scope, $eaFilterPanel) {

    // This section implements the filter state in the user interface. When the filter is not collapsed
    // the user can see the filter editor
    $scope.collapsed = true;

    $scope.toggleEditor = function() {
      $scope.collapsed = !$scope.collapsed;
    };

    // observes the filter element to make a couple UI decissions
    $scope.$watch('filter', function () {

      // create a duplicate of the filter
      $scope.filterWorkingCopy = $eaFilterPanel.buildFilter($scope.userId);
      $eaFilterPanel.updateFilterWithFilter($scope.filter, $scope.filterWorkingCopy);

      // collapse or ellapse the panel
      if ($scope.collapsed && $scope.filterWorkingCopy) {
        $scope.collapsed = ($scope.filterWorkingCopy.Id !== null);
      }
    }, true);

    // Defines available information for the UI
    $scope.operators = $eaFilterPanel.availableOperations;
    $scope.fieldModels = $eaFilterPanel.buildFieldModelsFromArray($scope.fields);

    $scope.conditions = $eaFilterPanel.availableConditions;

    // Allows to add an condition
    $scope.addCondition = function () {
      $scope.filterWorkingCopy.Rules.push($eaFilterPanel.buildRule('AND'));
    };

    // Removes a rule
    $scope.removeCondition = function (index) {
      $scope.filterWorkingCopy.Rules.splice(index, 1);
    };

    // Delegates the save operation to parent
    $scope.saveFilter = function() {
      // replicate the filter
      $eaFilterPanel.updateFilterWithFilter($scope.filterWorkingCopy, $scope.filter);

      // save it via parent
      $scope.save({teamFilter: $scope.filterWorkingCopy.TeamId === 'personal' ? false : true});
    };

    $scope.shareFilter = function() {
      // replicate the filter
      $eaFilterPanel.updateFilterWithFilter($scope.filterWorkingCopy, $scope.filter);

      // save it via parent
      $scope.share();
    };

    $scope.unShareFilter = function() {
      // replicate the filter
      $eaFilterPanel.updateFilterWithFilter($scope.filterWorkingCopy, $scope.filter);

      // save it via parent
      $scope.unshare();
    };

}).service('$eaFilterPanel', function(){
  var self = this;

  self.buildFilter = function(owner) {
    return {
        Id: null,
        Name: 'New Filter',
        TeamId: 'personal',
        Owner: owner,
        Rules: [
          {
            Operator: 'WHERE',
            Field: null,
            Condition: null,
            Value: null,
            Compare: 'CaseSensitive'
          }
        ]
      };
  };

  self.buildRule = function(operator) {
    return {
        Operator: operator,
           Field: null,
       Condition: null,
           Value: null,
         Compare: 'CaseSensitive'
    };
  };

  self.updateFilterWithFilter = function(srcFilter, targetFilter) {
    targetFilter.Id = srcFilter.Id;
    targetFilter.Name = srcFilter.Name;
    targetFilter.Owner = srcFilter.Owner;
    targetFilter.TeamId = srcFilter.TeamId;

    // remove the rules
    targetFilter.Rules = [];

    // copy the rules
    srcFilter.Rules.forEach(function(rule) {
      var ruleCopy = self.buildRule(rule.Operator);
      ruleCopy.Field = rule.Field;
      ruleCopy.Condition = rule.Condition;
      ruleCopy.Value = rule.Value;
      ruleCopy.Compare = rule.Compare;
      targetFilter.Rules.push(ruleCopy);
    });
  };

  self.buildFieldModelsFromArray = function(fields) {
    return fields.map(function(f) {
      return { name: f };
    });
  };

  self.availableOperations  = [
    { name: 'AND' },
    { name: 'OR' }
  ];

  self.availableConditions = [
    { name: '=' },
    { name: '<>' },
    { name: '<' },
    { name: '<=' },
    { name: '>' },
    { name: '>=' },
    { name: 'contains' },
    { name: 'notContains' },
    { name: 'startsWith' },
    { name: 'notStartsWith' },
    { name: 'hasValue' }
  ];

});
