'use strict';

angular.module('azureCostsFeApp').controller('ModalMessageBoxCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openMessagegeBox = function(title, description) {

    var modalInstance = $modal.open({
      templateUrl: 'views/modals/cc-message-box.html',
      controller: 'ModalMessageBoxCtrl',
      resolve: {
        Metadata: function() { return { Title: title, Description: description }; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
