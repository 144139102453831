'use strict';

angular.module('azureCostsFeApp').controller('ModalObjectPickerCtrl', function($scope, $modalInstance, $controller, $q, ExistingObjects, SelectedObjects, Metadata) {

  $scope.title = Metadata.Title;
  $scope.description = Metadata.Description;

  $scope.existingObjects = ExistingObjects;
  $scope.selectedObjects = SelectedObjects;

  $scope.ok = function () {
    if (Metadata.SingleSelect) {
      $modalInstance.close($scope.selectedObjects[0]);
    } else {
      $modalInstance.close($scope.selectedObjects);
    }
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };

  $scope.getDisplayName = function(model) {
    if (Metadata && Metadata.GetDisplayName && angular.isFunction(Metadata.GetDisplayName)) {
      return Metadata.GetDisplayName(model);
    } else {
      return model.toString();
    }
  };

  $scope.hasDetailsString = function(model) {
    return (Metadata && Metadata.GetDetailsString && angular.isFunction(Metadata.GetDetailsString));
  };

  $scope.getDetailsString = function(model) {
    if ($scope.hasDetailsString(model)) {
      return Metadata.GetDetailsString(model);
    } else {
      return undefined;
    }
  };

  $scope.hasFontawesomeIcon = function(model) {
    var imageUrl = $scope.getImageUrl(model);
    if (imageUrl && imageUrl.indexOf('fa') === 0) {
      return true;
    } else {
      return false;
    }
  };

  $scope.getImageUrl = function(model) {
    if (Metadata && Metadata.GetImageUrl && angular.isFunction(Metadata.GetImageUrl)) {
      return Metadata.GetImageUrl(model);
    } else if (Metadata && Metadata.GetImageUrl && angular.isString(Metadata.GetImageUrl)) {
      return Metadata.GetImageUrl;
    } else {
      return undefined;
    }
  };

  $scope.isSelected = function(model) {
    return ($scope.selectedObjects.indexOf(model) !== -1);
  };

  $scope.selectObject = function(model) {
    if (Metadata.SingleSelect) {
      $scope.selectedObjects = [model];
    } else {
      $scope.selectedObjects.push(model);
    }
  };

  $scope.deselectObject = function(model) {
    if (!Metadata.SingleSelect) {
      var idx = $scope.selectedObjects.indexOf(model);
      $scope.selectedObjects.splice(idx, 1);
    }
  };
});



