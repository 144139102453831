'use strict';

angular.module('azureCostsFeApp').controller('ModalTeamEditAppCtrl', ['$scope', '$modalInstance', '$eaSession2', '$controller', '$eaBackend', '$q', 'App', function($scope, $modalInstance, $eaSession2, $controller, $eaBackend, $q, App) {

  $scope.title = "Manage Application";

  $scope.newApp = {
    Name: "",
    ClientId: "",
    ClientSecret: "",
    Role: "member",
    WildCardSubscriptions: true
  };

  if (App) {
    $scope.newApp = App;
  }

  $scope.ok = function () {
    $modalInstance.close($scope.newApp);
  };

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

}]);



