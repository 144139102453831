'use strict';

angular.module('azureCostsFeApp').controller('CcAddAwsContractConnectCtrl', function ($scope, $eaBackend, $busy, $stateParams, $eaCurrentSession, $state) {

  $scope.form = {
    roleArn: undefined,
    externalId: undefined,
    bucket: undefined,
    prefix: undefined,
    reportname: undefined
  };

  var awsData = sessionStorage.getItem('awsdata');
  if (awsData) {
    $scope.form = JSON.parse(awsData);
  }

  $scope.error = undefined;

  $scope.next = function()
  {
    if(location.href.indexOf('localhost') !== -1) {
      sessionStorage.setItem("awsdata", JSON.stringify($scope.form));
    }

    $busy.setMessage("Verifying connection to AWS...");
    $busy.during($eaBackend.dataAwsVerifyConnection($stateParams.teamid, $scope.form.roleArn, $scope.form.externalId, $scope.form.bucket, $eaCurrentSession.DataToken)).then(function() {

      $busy.setMessage("Registering Contract...");
      return $busy.during($eaBackend.dataAwsRegisterContract($stateParams.teamid, $scope.form.roleArn, $scope.form.externalId, $scope.form.bucket, $scope.form.prefix, $scope.form.reportname, $eaCurrentSession.DataToken)).then(function(contractId) {

        // show the finish page
        $state.go('authorized.teams.contracts.add-aws.finish', {teamid: $stateParams.teamid, cid: contractId});
      })
    }).catch(function(error) {
      $scope.error = error && error.data ? error.data : 'Unknown error';
    })
  }
});
