'use strict';

/*
 options: {
    filter: [],
    class: 'SpendingEntryClass',
    group: 'SubscriptionName',
    value: 'ServiceCosts',
    func: 'sum',
    unit: '€'
 }
*/
angular.module('azureCostsFeApp').directive('eaWidgetChartPie', function() {
  return {
    restrict: 'C',
    template: '<div id=\"chart-container-{{uuid}}\"></div>',
    controller: 'EaWidgetChartPieCtrl'
  };
}).controller('EaWidgetChartPieCtrl', function($scope, $eaChartConfig, $eaDataCoordinator, $eaDataGroupOperations, $eaDataAggregateOperations, $eaDataColorThemeGenerator) {

  // store the chart element
  var chartElement = null;
  var exitWidget = false;

  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);

    // load the data of the specific property by filter
    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.class, {maxPeriods: $scope.maxPeriods}).then(function(data) {

      // check if we are active after data log
      if (!$scope.isActive()) { return; }

      // check if we got data
      if (!data) { return; }

      // group the elements
      return $eaDataGroupOperations.groupBySingleDimension(data, $scope.options.group).then(function (groupedData) {

        // the highcharts container
        var containerId = 'chart-container-' + $scope.uuid;

        // calculate the unit symbol
        var unitSymbol = $scope.options.unit;
        if (data.length > 0) {

          unitSymbol = data[0][$scope.options.unit];

          if (!unitSymbol) {
            unitSymbol = $scope.options.unit;
          }
        }

        var pieOptions = $eaChartConfig.createPieConfig();
        pieOptions.chart.renderTo = containerId;
        pieOptions.tooltip.pointFormat = $scope.options.valueLabel + ': <b>{point.y:.2f}' + unitSymbol + '</b>'

        pieOptions.series.push({
          name: 'Value',
          colorByPoint: true,
          data: []
        });

        // aggregate the groups
        var indexOfBiggestOne = -1;
        var lastBiggestValue = 0;
        Object.keys(groupedData).forEach(function(k) {

          var y = $eaDataAggregateOperations.execute($scope.options.func, groupedData[k].elements, $scope.options.value);
          if (y > lastBiggestValue) {
            lastBiggestValue = y;
            indexOfBiggestOne = pieOptions.series[0].data.length;
          }

          pieOptions.series[0].data.push({
            name: groupedData[k].name,
            y: y,
            color: $eaDataColorThemeGenerator.getColorFor($scope.contract + '#' + $scope.options.group , k)
          });

        });

        // slice the biggest on e
        if ( indexOfBiggestOne != -1) {
          pieOptions.series[0].data[indexOfBiggestOne].sliced = true;
        }

        // check if we are active before painting to prevent exceptions
        if (!$scope.isActive()) { return; }

        // build the chart
        chartElement = new Highcharts.Chart(pieOptions);
        $scope.requestResize();

        // switch off the loading mode
        $scope.setLoading(false);
      })
    });
  }

  // establish the resize callback
  $scope.onResize(function(newSize) {
    if ( chartElement) { chartElement.setSize(newSize.w, newSize.h, false); }
  });

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
