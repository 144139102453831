'use strict';

angular.module('azureCostsFeApp').controller('EaTeamMigrateCtrl', function ($scope, $eaSession2, $eaBackend, $busy, $controller, $eaBackendDataModeSelector, $state, $toolbar, $eaUserProfile, $eaCurrentSession, $location) {

  // inject mixins
  $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));

  // define the form structure
  $scope.formMigrateTeam = {
    Name: undefined
  };

  return $busy.during($eaUserProfile.load($eaCurrentSession.DataToken).then(function(profile) {

    // define the active team
    $scope.team = $eaUserProfile.getTeam(profile, 'personal');

    // migrates a specific user to a team account
    $scope.migrateCurrentUser = function () {

      $busy.during($eaBackend.dataMigrateToTeamAccount($scope.formMigrateTeam.Name, $eaCurrentSession.DataToken)).then(function () {

        // sign out
        $eaSession2.destroySession();

        // sign in and comeback
        var path = '/sso?idp=' + $eaCurrentSession.IdentityProvider + '&redirect=' + encodeURIComponent('/app/teams');
        $location.url(path);

      }).catch(function (error) {
        alert(error);
      })
    };

    // Add Back Entry
    $toolbar.addItem('migrateTeam.Back', 'Back to Reports', 'fa-chevron-left', null, true, '/signin');
  }));
});
