'use strict';

angular.module('azureCostsFeApp').controller('AcCspAddStep02AWSCtrl', function ($scope, $state, $stateParams, $busy, $eaCspService, $eaCurrentSession, jwtHelper, $eaBackend) {

  $scope.availableCustomerMappings = ['Payer Account', 'CostCenter', 'Department', 'Subscription', 'ResourceGroup'];

  $scope.form = {
    name:undefined,
    roleArn: undefined,
    externalId: undefined,
    bucket: undefined,
    prefix: undefined,
    reportname: undefined,
    customermapping: $scope.availableCustomerMappings[0]
  };

  var awsData = sessionStorage.getItem('csp.awsdata');
  if (awsData) {
    $scope.form = JSON.parse(awsData);
  }

  $scope.error = undefined;

  $scope.next = function()
  {
    if(location.href.indexOf('localhost') !== -1) {
      sessionStorage.setItem("csp.awsdata", JSON.stringify($scope.form));
    }

    if ($scope.form.customermapping === 'Payer Account') {
      $scope.form.customermapping = 'Subscription';
    }

    $busy.setMessage("Verifying connection to AWS...");
    $busy.during($eaBackend.dataAwsVerifyConnection($stateParams.resellerId, $scope.form.roleArn, $scope.form.externalId, $scope.form.bucket, $eaCurrentSession.DataToken)).then(function() {

      $busy.setMessage("Registering Contract...");
      return $busy.during($eaBackend.dataCspAwsRegisterContract($stateParams.resellerId, $scope.form.name, $scope.form.roleArn, $scope.form.externalId, $scope.form.bucket, $scope.form.prefix, $scope.form.reportname, $scope.form.customermapping, $eaCurrentSession.DataToken)).then(function(contractId) {

        // show the finish page
        $state.go('authorized.csp.wadd.step03', {resellerId: $stateParams.resellerId});
      })
    }).catch(function(error) {

      if (error && error.data && error.data.message) {
        $scope.error = error.data.message;
      } else if (error && error.data) {
        $scope.error = error.data;
      } else {
        $scope.error = 'Unknown error'
      }
    })
  }

});
