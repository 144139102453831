'use strict';

angular.module('azureCostsFeApp').service('$eaWidgetGroupFilterListViewDataSources', function($q, $eaDataSourceManager) {
  var self = this;


  self.registerDataSources = function(controllerScope)
  {
    // Register all datasources this widget offers
    // Data source the widget provides

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Items', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function () {
        return $q.when(controllerScope.rawItems);
      });
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Items.Filtered', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function () {
        if (controllerScope.activeFilter) {
          return $q.when(controllerScope.rawItemsFiltered);
        } else {
          return $q.when(controllerScope.rawItems);
        }
      });
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Items.Selected.Filtered', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function (groupSpendingData) {
        if (groupSpendingData.All && groupSpendingData.All.ServiceItems) {
          return $q.when(groupSpendingData.All.ServiceItems);
        } else {
          return $q.when([]);
        }
      });
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Items.Checked.Filtered.Overall', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function (groupedSpendingData) {
        if (groupedSpendingData.All && groupedSpendingData.All.ServiceCosts && groupedSpendingData.Currency && groupedSpendingData.Currency.Symbol) {
          return $q.when([{
            ReportCurrencySymbol: groupedSpendingData.Currency.Symbol,
            ServiceCosts: groupedSpendingData.All.ServiceCosts
          }]);
        } else {
          return $q.when([]);
        }
      });
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Groups', null, function () {
      return $q.when(controllerScope.groupOptions);
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Groups.Active', null, function () {
      return controllerScope.buildDashboardDefer.promise.then(function (groupedSpendingData) {
        return $q.when(groupedSpendingData.SelectedGroupOption);
      })
    });

    $eaDataSourceManager.registerDataSource(controllerScope.uuid, 'Periods', null, function () {
      return $q.when({PeriodProperty: 'ReportId', PeriodsAvailable: controllerScope.availablePeriods});
    });
  };

});
