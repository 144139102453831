'use strict';

angular.module('azureCostsFeApp').service('$eaBackendBase', function($q) {
  var self = this;

  // is responsible for setting the auth header
  self.getDefaultHeaders = function(jwtToken) {
    return {
      'Authorization': function () {
        return 'Bearer ' + jwtToken;
      }
    };
  };

  self.getDefaultHeadersImageUpload = function(jwtToken) {
    return {
      'Authorization': function () {
        return 'Bearer ' + jwtToken;
      },
      'Content-Type': function () {
        return undefined;
      }
    };
  };

  // Extract the body data
  self.extractDataFromResponse = function(requestPromise) {
    var defer = $q.defer();

    requestPromise.then(function (result) {
      if (result.status === 200 || result.status === 201 || result.status === 202) {
        defer.resolve(result.data);
      } else {
        defer.reject({status: result.status ? result.status : 500, data: result.data ? result.data : null});
      }
    }).catch(function (result) {
      defer.reject({status: result.status ? result.status : 500, data: result.data ? result.data : null});
    });

    return defer.promise;
  };

  self.extractDataFromLocationHeader = function(requestPromise) {
    return requestPromise.then(function (result) {

      // split the header
      var splittedHeader = result.headers('Location').split('/');

      // take the last value
      return $q.when({
        url: result.headers('Location'),
        entityId: splittedHeader[splittedHeader.length - 1]
      });
    })
  };

});
