'use strict';

/*
 * This service gives access to the users full profile which contains
 * the different teams a user is part of or the different contracts a user
 * can use.
 */
angular.module('azureCostsFeApp').service('$eaUserProfile', ['$eaBackend', '$q', '$rootScope', function($eaBackend, $q, $rootScope) {
  var self = this;
  var cachedProfile = null;

  /* Invalidate the cache when ever we naviagte away */
  $rootScope.$on('$locationChangeStart', function() {
    cachedProfile = null;
  });

  $rootScope.$on('$stateChangeStart', function() {
    cachedProfile = null;
  });

  self.invalidate = function() {
    cachedProfile = null;
  };

  self.load = function(dataToken, force) {

    if (cachedProfile && !force) {
      return $q.when(cachedProfile);
    } else {
      return $eaBackend.dataGetProfile(dataToken).then(function(profile) {

        // duplicate the profile
        var duplicatedProfile = JSON.parse(JSON.stringify(profile));

        // use only values which are important for the hash
        var finalCacheProfile = {};
        finalCacheProfile.Capabilities = duplicatedProfile.Capabilities;
        finalCacheProfile.Teams = duplicatedProfile.Teams;
        finalCacheProfile.UserId = duplicatedProfile.UserId;

        // generate a profile hash
        var shaObj = new jsSHA('SHA-1', 'TEXT');
        shaObj.update(JSON.stringify(finalCacheProfile));
        var hash = shaObj.getHash('HEX');

        // assign to the profile
        profile.cacheHash = hash;

        cachedProfile = profile;
        return profile;
      });
    }
  };

  function ContractCollection(dataElements) {
    var selfContractCollecton = this;
    var data = dataElements ? dataElements : [];

    selfContractCollecton.push = function(contract) {
      data.push(contract);
    };

    selfContractCollecton.getAt = function(index) {
      return data[index];
    };

    selfContractCollecton.__defineGetter__('length', function() {
      return data.length;
    });

    selfContractCollecton.first = function() {
      if (selfContractCollecton.length === 0) {
        return null;
      } else {
        return selfContractCollecton.getAt(0);
      }
    };

    selfContractCollecton.find = function(teamid, enrollment) {
      return new ContractCollection(data.filter(function(element) {
        return ((element.Enrollment === enrollment) && (element.Team === teamid));
      }));
    };

    selfContractCollecton.forEach = function(cb) {
      data.forEach(cb);
    };

    selfContractCollecton.toArray = function() {
      return data.map(function(m) { return m; });
    }
  }

  self.getContracts = function(profile, filterByTeam) {
    var contracts = new ContractCollection();

    profile.Teams.forEach(function(team) {

      /* Filter by Team */
      if (filterByTeam && filterByTeam !== team.Id) { return; }

      /* Use the subscriptions if posible */
      if ( team.Subscriptions && Object.keys(team.Subscriptions).length > 0 && team.Subscriptions['*'] === undefined) {

        // index the contract details by id
        var contractIndex = team.ContractsEx ? team.ContractsEx.buildIndex('Id') : {};

        // do it
        Object.keys(team.Subscriptions).forEach(function (enrollmentNumber) {
          if (team.Subscriptions[enrollmentNumber].length > 0) {
            contracts.push({Team: team.Id, Enrollment: enrollmentNumber, Name: contractIndex[enrollmentNumber].Name, Role: team.Role, Definition: contractIndex[enrollmentNumber].Definition, Periods: contractIndex[enrollmentNumber].Periods || 12, ResellerApiVersion: contractIndex[enrollmentNumber].ResellerApiVersion || 'v1' });
          }
        });
      /* otherwise it looks like personal */
      } else {
        if (!team.ContractsEx) { team.ContractsEx = []; }
        team.ContractsEx.forEach(function(contract) {
          contracts.push({Team: team.Id, Enrollment: contract.Id, Name: contract.Name, Role: team.Role, Definition: contract.Definition, Periods: contract.Periods || 12, ResellerApiVersion: contract.ResellerApiVersion || 'v1' });
        });
      }
    });

    return contracts;
  };

  self.getTeam = function(profile, teamId) {
    var result = profile.Teams.filter(function(team) {
      return (team.Id === teamId);
    });

    if (result.length > 0) {
      return result[0];
    } else {
      return null;
    }
  };

}]);
