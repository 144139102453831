'use strict';

angular.module('azureCostsFeApp').controller('ModalCspDefineResellerMarginCtrl', function($scope, $modalInstance, ResellerMargin, UpliftCategories) {

  $scope.updatedResellerMargins =  JSON.parse(JSON.stringify(ResellerMargin));

  $scope.availableMatchKeys = JSON.parse(JSON.stringify(UpliftCategories));

  $scope.newMarginModel = {
    MatchKey: '*',
    Margin: 0,
    ValidUntil: ''
  };

  $scope.uiHelper = {
    datePopupOpen: false,
    datePopupOptions: {}
  };

  $scope.openDatePopup = function() {
    $scope.uiHelper.datePopupOpen = true;
  };

  $scope.deleteMargin = function(margin) {
    var idx = $scope.updatedResellerMargins.indexOf(margin);
    if (idx !== -1) { $scope.updatedResellerMargins.splice(idx, 1); }
  };

  $scope.addMargin = function() {

    // adapt value
    // if ($scope.newMarginModel.Margin > 1) {  $scope.newMarginModel.Margin =  $scope.newMarginModel.Margin / 100; };
    $scope.newMarginModel.Margin =  $scope.newMarginModel.Margin / 100;

    // add
    $scope.updatedResellerMargins.push($scope.newMarginModel);

    // build new
    $scope.newMarginModel = {
      MatchKey: '*',
      Margin: 0,
      ValidUntil: ''
    };
  };

  $scope.ok = function () {
    // Ask
    var ok = confirm('Changing the margin/uplift outdates all cached reports, when the customer is visiting his portal ' +
                     'the next time it could take a very long time. You can force this process by visiting the portal ' +
                     'in admin on behalf of! Do you really want to change the margin/uplift values?');
    if (ok) {
      $modalInstance.close($scope.updatedResellerMargins);
    }
  };

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
});



