'use strict';

/**
 * @ngdoc service
 * @name azureCostsFeApp.eaBackendAdmin
 * @description
 * # eaBackendAzure
 * Service in the azureCostsFeApp.
 */
angular.module('azureCostsFeApp').service('$eaBackendAdminV2', function($q, $timeout, $http, $eaBackendBase, $eaConfig) {
  var self = this;

  // This is the main uri of the backend
  var adminUri    = ($eaConfig.apiProtocol ? $eaConfig.apiProtocol : 'https') + '://' + $eaConfig.apiHostAdminV2 + '/api/v2';

  self.dataGetJobs = function(hours, optionalTeam, dataToken) {

    var teamFilter = "";
    if (optionalTeam) { teamFilter = '&teamId=' + optionalTeam; }
    return $eaBackendBase.extractDataFromResponse($http.get(adminUri + '/jobs?hours=' + hours + teamFilter, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataGetJobEntries = function(partitionKey, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.get(adminUri + '/jobs/' + partitionKey + '/entries', {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

  self.dataInviteUser = function(userUpn, dataToken) {
    return $eaBackendBase.extractDataFromResponse($http.post(adminUri + '/users', { Upn: userUpn}, {headers: $eaBackendBase.getDefaultHeaders(dataToken)}));
  };

});
