'use strict';

angular.module('azureCostsFeApp').service('$eaBackendAzureCachedContentLoader', function($q, $timeout, $http, $eaConfig, $eaBackendBase, $base64, $eaBackendDataModeSelector, $acDpnService, $acCacheIdentifierGenerator, $eaBackend) {
  var self = this;

  self.dataRequestCachedResourceWithRetry = function(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, timeout, retry, options) {

    // set the timeout to a value
    if (timeout) { timeout = timeout + 5; } else { timeout = 5; }
    if (timeout > 15 ) { timeout = 15; }

    // add nice notification
    $acDpnService.addProcessingItem(resourceId);

    // start the load process
    var loadPromise = $q.when(options.resourceEndpoint(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, retry, options.queryoptions));

    // handle the reload request
    return loadPromise.then(function(resultData) {

      // should we have an error code 202 wait for 30 seconds
      if (resultData.status === 202) {

        // wait 30 second
        return $timeout(function() {}, timeout * 1000).then(function() {

          // try to reload the data
          return self.dataRequestCachedResourceWithRetry(teamid, contractNumber, resourceId, cacheIdentifier, jwtToken, timeout, true, options);

        });

      } else {

        // mark the report as done
        $acDpnService.removeProcessingItem(resourceId);

        // return the loaded data
        return $q.when(resultData);
      }
    });
  };

  // resourceInfomrmation: [{ Id, CacheHash}]
  // options: { cacheIdentifierExtension, resourceEndpoint }
  self.dataRequestCachedResourcesWithRetry = function(teamid, contractNumber, resourceInformation, jwtToken, options) {
    options = options || {};

    var reportLoadPromisses = [];

    // visit the reports
    resourceInformation.forEach(function (resource) {

      // generate the cacheIdentifier
      var cacheIdentifier = resource.CacheIdentifier;
      if (!cacheIdentifier) {
        cacheIdentifier = $acCacheIdentifierGenerator.buildCacheIdentifier(jwtToken, resource.CacheHash, options.cacheIdentifierExtenion);
      }

      // the process of reloading
      reportLoadPromisses.push(self.dataRequestCachedResourceWithRetry(teamid, contractNumber, resource.Id, cacheIdentifier, jwtToken, undefined, false, options));
    });

    return $q.all(reportLoadPromisses);
  };

});

