'use strict';

angular.module('azureCostsFeApp').service('$eaBilling', [ '$q', '$eaBackend', function($q, $eaBackend) {
  var self = this;

  var planValueOrder = {
    'basic':        100,
    'startup':      200,
    'professional': 300,
    'enterprise':   400,
    'csp':          500
  };

  var planDisplayText = {
    'csp': 'Cloud Solutions Provider'
  }


  // Loads the billing information
  self.loadBillingInformation = function(session) {
      return $eaBackend.dataGetBillingInformation(session.DataToken);
  };

  // Checks if we are in trial mode or not
  self.isInTrial = function(billingOrTeamInformation) {

    // check if we have a trial ends date
    if (!billingOrTeamInformation.TrialEnds || billingOrTeamInformation.TrialEnds === '') {
      return false;
    }

    // compare the trialends date with the current date
    var trialEndsDate = new Date(billingOrTeamInformation.TrialEnds);
    var now = new Date();
    return (trialEndsDate > now);
  };

  // checks if we have a value in the TrialsEnds property which indicates that
  // the user ever started a trial
  // ### TODO Change This to an other property###
  self.hasEverStartedTrial = function(billingOrTeamInformation) {
    return (billingOrTeamInformation.TrialEnds !== undefined && billingOrTeamInformation.TrialEnds !== null);
  };

  // calculates the actual retention period for the billing settings
  self.calculateRetentionPeriod = function(billingOrTeamInformation) {
    if (billingOrTeamInformation.CurrentPlan === 'basic' || self.isInTrial(billingOrTeamInformation)) {
      return 0;
    } else if (billingOrTeamInformation.Period) {
      return billingOrTeamInformation.Period;
    } else {
      return 12;
    }
  };

  // calcualtes the current plan price
  self.calculateCurrentPlanPrice = function(billingOrTeamInformation) {
    var billing = (typeof billingOrTeamInformation === 'string') ? { CurrentPlan: billingOrTeamInformation } : billingOrTeamInformation;

    if (self.isInTrial(billing)) {
      return 0.00;
    } else if (billing.CurrentPlan === 'basic') {
      return 0.00;
    } else if (billing.CurrentPlan === 'startup') {
      return 29.00;
    } else if (billing.CurrentPlan === 'professional') {
      return 79.00;
    } else {
      return 199.00;
    }
  };

  self.isPlanHigherThanPlan = function(planA, planB) {
    var valuePlanA = planValueOrder[planA] || 0;
    var valuePlanB = planValueOrder[planB] || 0;

    return valuePlanA > valuePlanB;
  };

  self.hasRequiredPlan = function(currentPlan, requiredPlan) {
    if (currentPlan === requiredPlan) {
      return true;
    } else {
      return self.isPlanHigherThanPlan(currentPlan, requiredPlan);
    }
  };

  self.getDisplayTextForPlan = function(plan) {
    if (planDisplayText[plan]) {
      return planDisplayText[plan];
    } else {
      return plan.charAt(0).toUpperCase() + plan.slice(1);
    }
  };

  // allows to purchase a subscription
  self.purchase = function(plan, dataToken) {
    return $eaBackend.dataPurchase(plan, dataToken);
  };

  // checks if the calling user as the account owner for this team
  self.isAccountOwner = function(userId, teamFromProfile) {
    return (teamFromProfile.Id === 'personal' || userId === teamFromProfile.AccountOwner);
  };
}]);
