'use strict';

angular.module('azureCostsFeApp').controller('NotificationSettingsCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  /*
   contract => {
      DisplayName: '',
      Currency: '',
   }
   */
  $scope.openNotificationSettingsDialog = function(contract, notificationSettings, team, role) {

    var modalInstance = $modal.open({
      templateUrl: "views/teams/dashboards/modals/modal_ea_notification_settings.html",
      controller: 'ModalNotificationSettingsCtrl',
      resolve: {
        contract: function() {
          return contract;
        },
        notificationSettings: function() {
          return notificationSettings;
        },
        role: function() {
          return (role ? role : 'member');
        },
        team: function() {
          return team;
        }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
