'use strict';

angular.module('azureCostsFeApp').controller('EaTeamEditAADCtrl', function ($scope, $location, $stateParams, jwtHelper, $busy, $eaBackend, $eaCurrentSession) {
  var self = this;


  $scope.aadSettings = {
    TenantId: null,
    DefaultIdentityProvider: false,
    RequiredGroup: {
      GroupName: null,
      GroupId: null
    }
  };

  $scope.graphGroups = [];
  $scope.errorMessage = null;

  $scope.updateDirectoryConfig = function() {

    // load the team config
    $busy.setMessage('Saving team settings');
    $busy.during($eaBackend.dataRegisterAADConnection($stateParams.teamid, $scope.aadSettings, $eaCurrentSession.DataToken).then(function(teamProfile) {
      // navigate to manage team overview
      $location.url('/teams/' + $stateParams.teamid + '/manage');
    }).catch(function() {
      $scope.errorMessage = "Failed to save the team settings";
    }));
  };

  $scope.removeLinkAad = function() {
    var deleteOk = confirm("Do you really want to remove the connection to Azure Active Directory");

    if (deleteOk) {
      $busy.during($eaBackend.dataDeleteAADConnection($stateParams.teamid, $scope.aadSettings.TenantId, $eaCurrentSession.DataToken).then(function () {
        // navigate to manage team overview
        $location.url('/teams/' + $stateParams.teamid + '/manage');
      }));
    }
  };

  // load the team profile
  return $eaBackend.dataGetAADConnections($stateParams.teamid, $eaCurrentSession.DataToken).then(function(connections) {
    if (connections && connections.length > 0) {
      $scope.aadSettings = connections[0];
    }

    // load the graph groups
    return $eaBackend.dataGetAADConnectionGroups($stateParams.teamid, $scope.aadSettings.TenantId, $eaCurrentSession.DataToken).then(function(groups) {

      // order the groups by name
      groups.sort(function(a, b){
          return a.GroupName.toLowerCase().localeCompare(b.GroupName.toLowerCase());
      });

      // assign the ordered array
      $scope.graphGroups = groups;

      $scope.graphGroups.forEach(function(group) {
        if ($scope.aadSettings.RequiredGroup && $scope.aadSettings.RequiredGroup.GroupId == group.GroupId) {
          $scope.aadSettings.RequiredGroup = group;
        }
      })
    })
  });
});

