'use strict';

angular.module('azureCostsFeApp').service('$eaDataSourceManager', function($q) {
  var self = this;

  var dataSources = {};

  // This function allows to register a new data source. Every data source needs to offer a query callback which can
  // be used to get data from the source. This query callback needs to have he following siganture
  //
  // function(teamid, contract, dataToken, className, optionalPrams)
  //
  // dataSourceHost - A unique identifier of the hosting service for the datasource
  // className      - The type of elements this datasource will return
  // collection     - Defines the specific bucket we get the data from
  //
  // Hint: dataSourceHost + className must be unique, together
  //
  self.registerDataSource = function(dataSourceHost, className, collection, queryCallback, storeCallback, deleteCallback) {
    dataSources[dataSourceHost + '#' + className + '#' + (collection ? collection : 'default')] = {
        query: queryCallback,
        store: storeCallback,
        delete: deleteCallback
    };
  };

  // Allows to query data from a data source. This calls are never cached so when you query information
  // from a source with high latency (e.g. the network) please use the $eaDataCoordinator service because
  // of the build in caching features.
  //
  self.queryDataSource = function(dataSourceHost, className, collection) {
    var dataSource = dataSources[dataSourceHost + '#' + className + '#' + (collection ? collection : 'default')];
    if (dataSource) {
      return dataSource.query;
    } else {
      return dataSource;
    }
  };

  self.queryInsertOrMergeDataSourceOperation = function(dataSourceHost, className, collection) {

    // get the datasource
    var dataSource = dataSources[dataSourceHost + '#' + className + '#' + (collection ? collection : 'default')];
    if (!dataSource) {
      return $q.reject("DataSource not found");
    } else if (!dataSource.store) {
      return $q.reject("DataSource Store operation not supported");
    } else {
      return dataSource.store;
    }
  };

  self.queryDeleteDataSourceOperation = function(dataSourceHost, className, collection) {

    // get the datasource
    var dataSource = dataSources[dataSourceHost + '#' + className + '#' + (collection ? collection : 'default')];
    if (!dataSource) {
      return $q.reject("DataSource not found");
    } else if (!dataSource.delete) {
      return $q.reject("DataSource Delete operation not supported");
    } else {
      return dataSource.delete;
    }
  };
});
