'use strict';

angular.module('azureCostsFeApp').controller('CspRegisterCustomerCostMgntCtrlMixin', function($scope, $modal) {

  $scope.openCspRegisterCustomerCostMgntDialog = function() {

    var modalInstance = $modal.open({
      templateUrl: 'views/csp/modals/modal_ac_csp_register_customer_costmgnt.html',
      controller: 'ModalCspRegisterCustomerCostMgntCtrl'
    });

    // returns a promise
    return modalInstance.result;
  };
});
