'use strict';

angular.module('azureCostsFeApp').directive('eaWidgetActionBoard', function() {
  return {
    restrict: 'C',
    template: '<div class="action-board" id=\"action-board-{{uuid}}\" style="height: calc(100% - 20px); overflow: scroll;">' +
      '<ul>' +
        '<li ng-repeat="item in items">' +
          '<p class="title" ng-style="getStyle(item)">{{item.Title}}</p>' +
          '<p class="pull-right">' +
            '<a ng-repeat="action in item.Actions" class="{{getActionStyle(action)}}" ng-click="executeAction(action, item.Element)" title="{{action.Action}}">' +
              '<i class="fa" ng-if="action.Icon" ng-class="action.Icon">&nbsp;</i><span ng-if="!options.value.actionsAsLinks">{{action.Action}}</span>' +
            '</a>' +
          '</p>' +
          '<div class="clearfix"></div>' +
        '</li>' +
      '</ul>' +
    '</div>',
    controller: 'EaWidgetActionBoardCtrl'
  };
}).controller('EaWidgetActionBoardCtrl', function($scope, $q, $eaDataCoordinator, $eaDataFilterOperations) {

  // prepare the filter
  var compiledFilter = $scope.options.value.filter ? $eaDataFilterOperations.compileFilter($scope.options.value.filter) : null;

  // store the items
  $scope.items = [];


  $scope.getActionStyle = function(action) {
    if ($scope.options.value.actionsAsLinks) {
      return "";
    } else {
      return "btn btn-xs pull-right " + action.Class;
    }
  };

  // filter operation
  function filter(elements) {
    if (compiledFilter) {
      return $eaDataFilterOperations.applyCompiledFilter(elements, compiledFilter);
    } else {
      return $q.when(elements);
    }
  }

  // build the dashboard
  function buildDashboard() {

    // switch on the loading mode
    $scope.setLoading(true);

    return $eaDataCoordinator.queryData($scope.team, $scope.contract, $scope.token, $scope.options.value.class, {collection: $scope.options.value.collection}).then(function (elements) {

      return filter(elements).then(function (filteredElements) {

        // map the result
        $scope.items = filteredElements.map(function (element) {

          var actions = [];

          if ($scope.options.value.actionTitle)
          {
            actions.push({ Action: $scope.options.value.actionTitle, Icon: $scope.options.value.actionIcon, Operation: $scope.options.value.actionOperation, Class: 'btn-default'});
          } else if ($scope.options.value.actions) {
            $scope.options.value.actions.forEach(function(action) {

              var actionVisible = true;

              if (action && action.actionHideProperty && action.actionHideValue) {                
                var hideElements = action.actionHideValue.split(',');
                actionVisible = $scope.cust01 && $scope.cust01[action.actionHideProperty] && hideElements.indexOf($scope.cust01[action.actionHideProperty]) == -1;
              }

              if (actionVisible) {
                actions.push({
                  Action: action.actionTitle,
                  Icon: action.actionIcon,
                  Operation: action.actionOperation,
                  Class: action.actionClass ? action.actionClass : 'btn-default'
                });
              }
            })
          }

          return {
            Title: element[$scope.options.value.propertyTitle],
            Actions: actions,
            Element: element,
            Width: $scope.options.value.actionsAsLinks ? 20 * actions.length : 75 * actions.length
          }
        });

        // switch off the loading mode
        $scope.setLoading(false);

        // done
        return $scope.items;
      });
    });
  }

  $scope.executeAction = function(item, element) {
    $scope.executeDashboardAction(item.Operation, element).then(function() {
      buildDashboard();
    }).catch(function() {
      alert("Failed to perform action");
    })
  };

  // establish the resize callback
  $scope.onResize(function(newSize) { });

  // initialize when the host is ready
  $scope.onInitialize(function() {
    return buildDashboard();
  });

  $scope.onReload(function() {
    return buildDashboard();
  });

  $scope.getStyle = function(item) {
    return { width: 'calc(100% - ' + item.Width + 'px)' } ;
  };

  // if we are here everyhting is ready to laod data
  $scope.finalizeRegistration();

});
