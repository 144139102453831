'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceV3Spendings', function($acDataSourceHelper, $acDataSourceSettings, $acDataSourceClasses, $q) {
  var self = this;

  function mapSpendingHistoryEntries(teamid, contract, dataToken, reportsInfo, loadedData) {

    if (!Array.isArray(loadedData) || loadedData.length !== 1) {
      return $q.when([]);
    }

    // get the data
    var historyData = loadedData[0];

    // set the result
    var result = [];

    // visit every item
    historyData.ServiceCosts.forEach(function (item, itemIdx) {

      // build the entity
      var entry = new $acDataSourceClasses.SpendingHistoryEntryClass();
      entry.HistoryDay = moment(historyData.StartDate).add(itemIdx, 'days').format("YYYY-MM-DD");
      entry.HistoryCurrency = reportsInfo.Currency;
      entry.HistoryCurrencySymbol = $acDataSourceHelper.getCurrencySymbolFromCurrency(reportsInfo.Currency);
      entry.HistoryDayOverallCosts = item;
      entry.SubscriptionId = 'All';
      entry.SubscriptionDayCosts = item;

      // done
      result.push(entry);
    });

    return $q.when(result);
  }

  self.registerDataSources = function(dataSourceManager) {

    // define the collectionid
    var collectionId = 'spendings.v3';

    // load the correct data file
    $acDataSourceHelper.registerDataSourceDashboardItemClass(dataSourceManager, collectionId, 'spending_dashboard_definitions_v3.json');

    // loads the different group options
    dataSourceManager.registerDataSource('global', 'SpendingGroupOptionClass', collectionId, $acDataSourceSettings.loadStaticDataGenericCallback);

    // Register the generic method to load PeriodClasses correctly
    $acDataSourceHelper.registerDataSourcePeriodClass(dataSourceManager, collectionId);

    // Register the correct Report loader
    $acDataSourceHelper.registerDataSourceReportClass(dataSourceManager, collectionId);

    // Register the data report content loader
    $acDataSourceHelper.registerDataSourceSpendingEntryClassForCoreApi(dataSourceManager, collectionId);

    // History
    $acDataSourceHelper.registerDataSourceSpendingHistoryEntryClassWithMappingCallback(dataSourceManager, 'spendings.v2', 2, mapSpendingHistoryEntries);
    $acDataSourceHelper.registerDataSourceSpendingHistoryEntryClassWithMappingCallback(dataSourceManager, collectionId, 3, mapSpendingHistoryEntries);
  };
});
