'use strict';

angular.module('azureCostsFeApp').controller('AcCspAccountsCtrl', function ($scope, $state, $eaCspService, $eaCurrentSession, $busy, $q, $controller, $modal, $toolbar, $eaBackendDataModeSelector, $eaSession2, $eaWidgetDashboardManager, ngToast, $eaBackend, $eaBackendAzureV2, $timeout, $window, $stateParams, $acTimelineGenerator, $eaWidgetRepository) {

  $.extend(self, $controller('SessionHelperCtrlMixin', {$scope: $scope, $eaBackendDataModeSelector: $eaBackendDataModeSelector, $eaSession2: $eaSession2, $state: $state }));
  $.extend(self, $controller('ModalObjectPickerCtrlMixin', {$scope: $scope, $modal: $modal }));
  $.extend(self, $controller('CspUpdateT1AccountCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspUpdateEAAccountCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspUpdateAWSAccountCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspChooseCustomerCtrlMixin', {$scope: $scope, $modal: $modal, $controller: $controller, $q: $q}));
  $.extend(self, $controller('CspDefineResellerMarginCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspDefineResellerFilterCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspDownloadPriceSheetCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspRegisterCustomerCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspRegisterCustomerCostMgntCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspDefineResellerFeaturesCtrlMixin', {$scope: $scope, $modal: $modal}));

  $scope.ShowUI = false;

  $scope.dashboards = [];
  $scope.dashboardsMap = {};
  $scope.dashboardEditMode = false;
  $scope.dashboardsOrdered = [];
  $scope.activeDashboard = null;
  $scope.selectDropDownData = { selected: null};

  $scope.dataToken = $eaCurrentSession.DataToken;

  // set default to v2
  if (!$stateParams.version) {
    $stateParams.version = 'v2';
  }

  $scope.toggelEditMode = function() {
    $scope.dashboardEditMode = !$scope.dashboardEditMode;
  };

  // update the navbar menu
  $scope.updateControllerNavBarMenu = function() {

    var elementsToRemove = ['csp.analytics', 'csp.importCustomers', 'csp.generalSettings.triggerSync', 'csp.generalSettings.openSettings', 'csp.switchToGsi'];

    elementsToRemove.forEach(function(elementToRemove) {

      // ensure the version switcher does not exists
      var versionSwitcherItemIndex = -1;
      for(var itemsIndex = 0; itemsIndex < $toolbar.items.length; itemsIndex++) {
        if ($toolbar.items[itemsIndex].tag === elementToRemove) {
          versionSwitcherItemIndex = itemsIndex;
        }
      }

      if (versionSwitcherItemIndex !== -1) {
        $toolbar.items.splice(versionSwitcherItemIndex, 1);
      }
    });

    // Add gsi button
    if ($scope.activeDashboard && $scope.activeDashboard.Id !== 'csp.welcome') {
      $toolbar.addItem('csp.switchToGsi', 'System Integrator Portal', 'fa-globe', 'Visit the portal for Gloabl System Integrators', true, function () {
        $state.go('authorized.csp.gsi', {resellerId: $stateParams.resellerId});
      });
    };

    // Add Contract
    $toolbar.addItem('csp.addAccount', 'Add', 'fa-plus', "Adds an additional CSP account", true, $scope.addAccount);

    // Add Contract
    if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && $scope.activeDashboard.AccountModel.TierLevel !== 'EA' && $scope.activeDashboard.AccountModel.TierLevel !== 'AWS' && $scope.activeDashboard.AccountModel.SyncCustomers === true) {
      $toolbar.addItem('csp.importCustomers', 'Import', 'fa-exchange', "Imports not activated customer", true, $scope.syncCustomers);
    } else if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && $scope.activeDashboard.AccountModel.TierLevel !== 'EA' && $scope.activeDashboard.AccountModel.TierLevel !== 'AWS' && $scope.activeDashboard.AccountModel.TierLevel !== 'T1N') {
      $toolbar.addItem('csp.importCustomers', 'Register Customer', 'fa-user-plus', "Registers a new customer", true, $scope.registerCustomers);
    }

    // Only add the settings dialog when not T1N
    if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && $scope.activeDashboard.AccountModel.TierLevel !== 'T1N') {

      // Open the Settings dialog
      $toolbar.addItem('csp.generalSettings', 'Settings', 'fa-cogs', null, true, null);

      // Open the contract settings dialog
      if ($scope.activeDashboard && $scope.activeDashboard.Id !== 'csp.welcome') {
        $toolbar.addItem('csp.generalSettings.openSettings', 'General', 'fa-cog', "Allows to edit the CSP account", true, $scope.openEditCspAccountDialog, null, 'csp.generalSettings');
      }

      // Open the reseller management
      $toolbar.addItem('csp.generalSeetings.openResellermanagement', 'Manage Resellers', 'fa-building-o', 'Allows to manage resellers', true, $scope.openResellers, null, 'csp.generalSettings');

      // Open the virtual meters
      $toolbar.addItem('csp.generalSeetings.openVirtualMeters', 'Virtual Meters', 'fa-id-card', 'Allows to manage virtual meters', true, $scope.openVirtualMeters, null, 'csp.generalSettings');
    }

    // Currently only available for EA & AWS contracts
    if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && ($scope.activeDashboard.AccountModel.TierLevel === 'EA' || $scope.activeDashboard.AccountModel.TierLevel === 'AWS')) {

      // Open the customer segmentation meters
      $toolbar.addItem('csp.generalSeetings.openCustomerSegmentation', 'Segmentation', 'fa-user-md', 'Allows to manage customer segements', true, $scope.openCustomerSegmentation, null, 'csp.generalSettings');

      // Open the contract settings dialog
      $toolbar.addItem('csp.generalSettings.triggerSync', 'Trigger Sync', 'fa-random', "Start synchronization with the Microsoft EA portal", true, $scope.startMonthSync, null, 'csp.generalSettings');
    } else if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && $scope.activeDashboard.AccountModel.TierLevel === 'T1') {
      // Open the contract settings dialog
      $toolbar.addItem('csp.generalSettings.triggerSync', 'Sync Azure Plan', 'fa-random', "Start synchronization for all Azure Plan Customers", true, $scope.startMonthSync, null, 'csp.generalSettings');
    }

    // Open Uplift Categoris
    $toolbar.addItem('csp.generalSeetings.openUpliftCategories', 'Uplift Categories', 'fa-credit-card', 'Allows to manage uplift categories', true, $scope.openUpliftCategories, null, 'csp.generalSettings');

    // Currently only available for EA contracts
    if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && $scope.activeDashboard.AccountModel.TierLevel === 'EA') {

      // Open the Analytics Sub-Menu
      $toolbar.addItem('csp.analytics', 'Analytics', 'fa-lightbulb-o', null, true, null);

      $toolbar.addItem('csp.analytics.pricecomparisions', 'Price Sheet', 'fa-money', "Allows to download the price comparision sheet", true, $scope.openDownloadPriceSheetDialog, null, 'csp.analytics');
    }

    // Reload operation
    $toolbar.addItem('csp.performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadData);
  };

  $scope.startAddCustomWidget = function() {
    $eaWidgetRepository.showRepository();
  };

  $scope.selectDashboardFromDropDown = function() {
    $scope.selectDashboard($scope.selectDropDownData.selected);
  };

  $scope.selectDashboard = function(dashboard) {

    // deactivate teh old one
    if ($scope.activeDashboard !== null) {
      $scope.activeDashboard.Active = false;
    }

    // handel billing cycle
    if (dashboard.AccountModel && $eaBackendDataModeSelector.isBillingCycleEnabled())
    {
      $eaBackendDataModeSelector.enableBillingCycle(true, dashboard.AccountModel.BillingDay);
    }

    // activate the new dashboard
    $scope.activeDashboard = dashboard;
    $scope.activeDashboard.Active = true;
    $scope.selectDropDownData.selected = $scope.activeDashboard;

    // update the url
    $state.transitionTo('authorized.csp.accounts', {resellerId: $stateParams.resellerId, active: dashboard.Id, version: $stateParams.version ? $stateParams.version: 'v1'}, {
      location: true,
      inherit: true,
      relative: $state.$current,
      notify: false
    });

    // update navbar
    $scope.updateControllerNavBarMenu();
  };

  $scope.refreshScreen = function() {
    $state.go($state.current.name, { initial: null }, {reload: true});
  };

  $scope.startMonthSync = function() {

    // preselect the current month
    var currentMonth = (new Date()).currentMonthDefinition();

    // description
    var description = 'Please select all months you want to re-sync. Azure Costs will override all ' +
      'existing information for this month and downloads the spending data freshly ' +
      'from Microsoft:';

    // report timeline
    var reportStrings = $acTimelineGenerator.createMonthList(24, $acTimelineGenerator.addMonth(new Date(), -23));

    // show the month selector
    return $scope.openObjectPickerDialog('Select month to sync', description, 'fa-calendar-o', undefined, undefined, reportStrings.sortSimple().reverse(), [currentMonth], false).then(function (selectedMonthes) {

      // notify the user
      ngToast.create({
        className: 'success',
        content: '<strong>Great!</strong> A new sync for your contract <strong>' + $scope.activeDashboard.Name + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new reports after more than 15 minutes give us a note.'
      });

      // schedule the syncs
      selectedMonthes.forEach(function (month) {

        // start the sync
        $eaBackendAzureV2.dataCspMonthSyncReportsV2($scope.activeDashboard.Team, $scope.activeDashboard.AccountModel.AccountId, month, $eaCurrentSession.DataToken).catch(function (e) {

          // notify regarding error
          ngToast.create({
            className: 'danger',
            content: '<strong>Error!</strong> Failed to process the synchronisation request of month <strong>' + month + '</strong> - Error: ' + e
          });

        });
      })
    });
  };

  $scope.reloadData = function () {
    if ($scope.activeDashboard && $scope.activeDashboard.Id === 'csp.welcome') {
      $scope.refreshScreen();
    } else if ($scope.activeDashboard) {
      $eaWidgetDashboardManager.reload($scope.activeDashboard.Id);
    }
  };

  // allow to reload the data
  function loadDashboards(cspTeamProfile) {

    // load the different accounts
    return $eaCspService.loadAccounts(cspTeamProfile, $eaCurrentSession).then(function(cspAccounts) {

      // check if we have no accounts
      if (!cspAccounts || cspAccounts.length === 0) {

        // generate the collection name
        var collectionName = ($stateParams.version && $stateParams.version == 'v2') ? 'csp.v2' : 'csp';

        // register our welcome dashboard
        $scope.dashboards.push({
          Id: 'csp.welcome',
          Name: 'Welcome',
          Profile: cspTeamProfile,
          Team: cspTeamProfile.Id,
          ContractId: 'CSP:Welcome',
          Active: false,
          Collection: collectionName
        });

        // Action: Add account
        $eaWidgetDashboardManager.onDashboardFunction('csp.welcome', 'func:2A4B35B3-63A1-4B36-A5A2-FE1510CDFA16', function (args) {
          $scope.addAccount();
          $eaWidgetDashboardManager.finishDashboardFunction('csp.welcome', args, null, null);
        });

        $scope.activeDashboard = $scope.dashboards[0];
        $scope.activeDashboard.Active = true;
      } else {

        // generate the dasboard items
        cspAccounts.forEach(function (account) {

          // define the collection prefix
          var collectionPrefix = 'csp';
          if (account.TierLevel === 'T2') {
            collectionPrefix = collectionPrefix + '.t2';
          }

          // generate the collection name
          var collectionName = collectionPrefix + (($stateParams.version && $stateParams.version == 'v2') ? '.v2' : '');
          if (account.TierLevel === 'EA' || account.TierLevel === 'AWS') {
            collectionName = 'csp.ea.v2';
          }

          // define the dashbaord id
          var dashboardId = account.AccountId;

          // create a dashboard
          var dashboardItem = {
            Id: dashboardId,
            Name: account.Name && account.Name.length > 0 ? account.Name : account.Domain,
            AccountModel: account,
            Profile: cspTeamProfile,
            Team: cspTeamProfile.Id,
            ContractId: 'CSP:' + account.AccountId,
            Active: false,
            Collection: collectionName,
            Layout: 'v3-tp02',
            BillingDay: account.BillingDay
          };

          $scope.dashboards.push(dashboardItem);
          $scope.dashboardsMap[dashboardItem.Id] = dashboardItem;

          // Action: Activate a specific customer
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:6D39C9DF-8227-43A2-B1A5-E3AB4E9085C5', function (args) {

            $eaCspService.activateCustomer(cspTeamProfile.Id, args.args.AccountId, args.args.CustomerId, $eaCurrentSession).then(function () {

              $scope.reloadData(cspTeamProfile);

              ngToast.create({
                className: 'success',
                content: '<strong>Great!</strong> Customer <strong>' + args.args.CustomerCompany + '</strong> is activated. The initial data sync could take a couple seconds or minutes. If you don\'t see spending data after more than 15 minutes give us a note.'
              });

              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            }).catch(function (error) {
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, error);
            })
          });

          // Action: De-Activate a specific customer
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:4A3A4453-D7BF-4DBA-B32B-5F2252750C1F', function (args) {

            var removeConfirmed = confirm('Do you really want to de-activate this customer?\n\n!!! Attention: All data of this customer will be removed and the users can\'t access to the dashboard anymore !!!');

            if (removeConfirmed) {
              $eaCspService.deActivateCustomer(cspTeamProfile.Id, args.args.AccountId, args.args.CustomerId, $eaCurrentSession).then(function () {

                $scope.reloadData(cspTeamProfile);

                ngToast.create({
                  className: 'success',
                  content: '<strong>Ok!</strong> Customer <strong>' + args.args.CustomerCompany + '</strong> is de-activated. It could take up to 24hrs until all data are wiped out from the dashboards!'
                });

                $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
              }).catch(function (error) {
                $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, error);
              })
            } else {
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            }
          });

          // Action: Sync a specific customer
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:118D6857-5B03-4424-9D51-25D88420BA4C', function (args) {

            // get all active customers
            return $eaCspService.getActiveCustomers($scope.activeDashboard.Profile, $scope.activeDashboard.AccountModel, $eaCurrentSession).then(function (loadedCustomers) {

              // map the customer data
              var customers = loadedCustomers
                .map(function (c) {
                  return {
                    AccountId: $scope.activeDashboard.AccountModel.AccountId || $scope.activeDashboard.AccountModel.accountId,
                    CustomerId: c.MicrosoftCustomerId || c.microsoftCustomerId,
                    CustomerName: c.CustomerCompany || c.customerCompany,
                    NewCommerce: c.newCommerce || false
                  }
                }).filter(function(c) {
                  return !c.NewCommerce && c.CustomerName.toLowerCase() !== 'default';
                });

              // show the object selector for customers and ask for which customer we should start the sync. Multiple
              // customers are possible
              $scope.openCustomerChooser(customers).then(function (selectedCustomers) {

                // trigger per customer the sync
                selectedCustomers.forEach(function (customer) {
                  $eaBackend.dataCspSyncCustomerInAccount(cspTeamProfile.Id, customer.AccountId, customer.CustomerId, args.args.period, $eaCurrentSession.DataToken).then(function () {
                    ngToast.create({
                      className: 'success',
                      content: '<strong>Great!</strong> Data Sync for the customer <strong>' + customer.CustomerName + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new customers after more than 15 minutes give us a note.'
                    });
                  })
                });

                // we are done with this action
                $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
              })
            });
          });

          // Action: Sync a specific customer
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:DCF83D44-3537-43C9-8CCB-8874008A29A0', function (args) {

            var customer = {
              AccountId: args.args.AccountId,
              CustomerId: args.args.CustomerId,
              CustomerName: args.args.CustomerCompany
            };

            $eaBackend.dataCspSyncCustomerInAccount(cspTeamProfile.Id, customer.AccountId, customer.CustomerId, null, $eaCurrentSession.DataToken).then(function () {

              ngToast.create({
                className: 'success',
                content: '<strong>Great!</strong> Data Sync for the customer <strong>' + customer.CustomerName + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new customers after more than 15 minutes give us a note.'
              });

              // we are done with this action
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            });
          });

          // Open Customer Margin
          function openCustomerMargin(cspTeamId, cspAccountId, cspCustomerId) {

            // load the uplift categories
            return $eaBackend.dataCspGetUpliftCategories(cspTeamId, $eaCurrentSession.DataToken).then(function(upliftCategories) {

              // load the margins
              return $eaBackend.dataCspGetResellerMarginForCustomer(cspTeamId, cspAccountId, cspCustomerId, $eaCurrentSession.DataToken).then(function (resellerMargins) {

                // show the dialog
                return $scope.openCspDefineResellerMarginDialog(resellerMargins, upliftCategories).then(function (updatedMargins) {

                  // save the margins
                  return $eaBackend.dataCspSetResellerMarginForCustomer(cspTeamId, cspAccountId, cspCustomerId, updatedMargins, $eaCurrentSession.DataToken).then(function () {

                    ngToast.create({
                      className: 'success',
                      content: '<strong>Great!</strong> Margin was updated correctly'
                    });

                  });
                });
              });
            });
          }

          // Action: Define reseller margins
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:28A17E4C-960F-4D79-AD31-B6BBC9B7CFF0', function (args) {
            return openCustomerMargin(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId).then(function () {
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            });
          });

          // Action: Define reseller filter
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:4A5811F8-8BA7-484F-9D18-138E4E2F3786', function (args) {

            // load the current reseller filter
            return $eaBackend.dataCspGetResellerFilterForCustomer(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId, $eaCurrentSession.DataToken).then(function (existingFilter) {

              // show the dialog
              return $scope.openCspDefineResellerFilterDialog(existingFilter).then(function (updatedFilter) {

                // save the filter
                return $eaBackend.dataCspSetResellerFilterForCustomer(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId, updatedFilter, $eaCurrentSession.DataToken).then(function () {

                  ngToast.create({
                    className: 'success',
                    content: '<strong>Great!</strong> Filter was updated correctly'
                  });

                  // we are done with this action
                  $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
                });
              });
            });
          });

          // Action: Trigger V-Meter Update
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:632E41FF-BE9B-4793-B22B-B71EC997FEE1', function (args) {

            // get the month
            var currentMonth = undefined;
            if (args.args.ServiceItems && args.args.ServiceItems.length > 0) {
              currentMonth = args.args.ServiceItems[0].ReportId;
            }

            $eaBackend.dataCspUpdateVirtualMetersForCustomerInAccount(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId, currentMonth, $eaCurrentSession.DataToken).then(function () {

              ngToast.create({
                className: 'success',
                content: '<strong>Great!</strong> Update of virtual service meters for customer <strong>' + args.args.PrimaryGroupName + '</strong> triggered. You will receive an e-mail as soon as your request was processed. It should not take longer than 15 minutes.'
              });

              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            }).catch(function (error) {
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, error);
            })
          });

          function openCustomerPortal(dashboardId, args, extractedTeamId, noMargin, forceV2) {

            // update our login sessiong with a new delegation token
            return $eaSession2.updateTokenFromSTS($eaCurrentSession, {delegate: extractedTeamId}).then(function () {

              // read the billing cycle
              var cycle = $eaBackendDataModeSelector.isBillingCycleEnabled() ? 'billing' : 'monthly';

              // build the url
              var url = '/app/teams/' + extractedTeamId + '/dashboards?cycle=' + cycle;
              if (noMargin) {
                url += '&nomargin=true';
              }

              if ($scope.activeDashboard && $scope.activeDashboard.AccountModel && ($scope.activeDashboard.AccountModel.TierLevel === 'EA' || $scope.activeDashboard.AccountModel.TierLevel === 'AWS')) {
                url += '&version=v2';
              } else if ($stateParams.version === 'v2') {
                url += '&version=v2';
              } else if (forceV2) {
                url += '&version=v2';
              } else {
                url += '&version=v1';
              }

              // open new window with the url
              $window.open(url, '_blank');

              // we are done with this action
              return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            }).catch(function () {

              ngToast.create({
                className: 'danger',
                content: '<strong>Permission Denied!</strong> You have no access permissions to open the customer dashboard.'
              });

              // we are done with this action
              return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            })
          }

          function openContainers(customerId)
          {
            var targetUri = '/app2/teams/' + $stateParams.resellerId + '/apps/accounts/' + $stateParams.active + '/customers/' + customerId + '/containers';
            console.log('Target-Uri is: ' + targetUri);
            $window.location.href = targetUri;
          };

          // Action: Open Customer Portal from Action Panel
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:4452C3F9-6FCB-4749-A1FE-DA7FD77FBB5C', function (args) {
            return openCustomerPortal(dashboardId, args, args.args.CustomerTeamId ? args.args.CustomerTeamId :  args.args.CustomerId, false, true);
          });

          // Open container Management
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:B94A8FEE-4EA6-42E5-8F8B-EBC601A06954', function (args) {
            openContainers(args.args.CustomerId);            
            $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null); 
            
          });
                    
          // Action: Define Reseller Margin from Action Panel
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:A40E5CC6-A6CC-4094-BD27-AE6E166048D3', function (args) {
            return openCustomerMargin(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.CustomerId).then(function () {
              $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
            });           
          });          

          // Action: Open Customer Portal
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:2929E37C-B3D7-483C-9BF9-4BFCA5D9C92D', function (args) {
            return openCustomerPortal(dashboardId, args, args.args.TeamId, args.args.NoMargin, args.args.ForceV2);
          });

          // Toggle Reseller Version
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:9148E92E-6C1C-4846-BE9C-A8A5F9417AB5', function (args) {

            // confirm
            var upgradeConfirm = confirm('Do you really want to upgrade the processing engine of this customer?\n\n!!! Attention: This step is not reversable  !!!');
            if (!upgradeConfirm) { return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null); }

            // upgrade
            return $eaBackend.dataCspUpgradeResellerVersionForCustomer(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId, $eaCurrentSession.DataToken).then(function() {

              ngToast.create({
                className: 'success',
                content: '<strong>Greate!</strong> The customer was upgrade, please refresh this page.'
              });

            }).catch(function() {

              ngToast.create({
                className: 'danger',
                content: '<strong>Version Upgrade Failed!</strong> Something went wrong, please try again later.'
              });

            });

          });

          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:FD48824C-1DAE-4787-987C-56A2BF0896BA', function (args) {
            var enabledState = $eaBackendDataModeSelector.isBillingCycleEnabled() ? 'BillingCycle' : 'Default';
            return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, enabledState, null);
          });

          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:408B9A2F-6BDE-447D-A794-26957C719D37', function (args) {
            if (args.args.state === 'BillingCycle') {
              $eaBackendDataModeSelector.enableBillingCycle(true, account.BillingDay);
            } else {
              $eaBackendDataModeSelector.enableBillingCycle(false, 1);
            }

            return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
          });


          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:3D116683-B590-4199-8C70-83E0327616FD', function (args) {
            $state.go('authorized.reportAdd.step01-csp', {
              team: account.TeamId,
              currency: account.Currency,
              accountid: account.AccountId
            });
            return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
          });

          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:2DD4B2BC-983F-434B-B449-7A655AEE2D23', function (args) {

            var hintText = '';
            var periodDate = new Date(args.args.selectedPeriod);

            if ($eaBackendDataModeSelector.isBillingCycleEnabled()) {
              var billingDay = $eaBackendDataModeSelector.getBillingCycleDay();
              if (billingDay !== 1) {
                periodDate = $acTimelineGenerator.addMonth($acTimelineGenerator.addDay(periodDate, billingDay - 1), -1);
              }
            }

            if (periodDate > new Date()) {
              hintText = "No consumption data available because period between <strong>" + periodDate.toDateString() + "</strong> - <strong>" + $acTimelineGenerator.addDay($acTimelineGenerator.addMonth(periodDate), -1).toDateString() + "</strong> not started yet";
            } else {
              hintText = "Consumption between <strong>" + periodDate.toDateString() + "</strong> - <strong>" + $acTimelineGenerator.addDay($acTimelineGenerator.addMonth(periodDate), -1).toDateString() + "</strong>";
            }

            return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, hintText, null);
          });

          // Publish to Reseller
          function publishCustomerToReseller(dashboardId, args, teamId, accountId, customerId) {

            // load all CSP Resellers
            return $eaBackend.dataGetCspResellers($scope.Profile.Id, $eaCurrentSession.DataToken).then(function (cspResellers) {

              // Open the Publish to Reseller Dialog
              var description = 'Please select a reseller your would like to publish the customer to';
              return $scope.openObjectPickerDialog('Select Reseller', description, 'fa-taxi', function (model) {
                return model.resellerName;
              }, function (model) {
                return model.resellerId;
              }, cspResellers, [], true).then(function (selectedReseller) {

                if (selectedReseller) {

                  // Publish the customer to reseller
                  return $eaBackend.dataPublishCspCustomer(teamId, selectedReseller.resellerId, accountId, customerId, $eaCurrentSession.DataToken).then(function () {

                    // show
                    ngToast.create({
                      className: 'success',
                      content: '<strong>Great!</strong> The customer is published to the reseller!'
                    });

                    // we are done with this action
                    return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
                  });
                }
              });
            });
          }

          // Publish to Reseller from Grid
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:55E8F243-E782-426C-825B-70E4C484846A', function (args) {
              return publishCustomerToReseller(dashboardId, args, cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.PrimaryGroupId);
          });

          // Publish to Reseller from Actions Panel
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:CAD64FD1-CDAF-47A5-98FB-34E6E8CBF177', function (args) {
            return publishCustomerToReseller(dashboardId, args, cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.CustomerId);
          });

          // Download Invoice
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:099FBA0F-6CFC-403F-B104-011BD8CE65AB', function (args) {

            // check
            var billingDate = new Date(args.args.period + '-' + ($scope.activeDashboard.AccountModel.BillingDay + 1));
            if (!(new Date() >= billingDate)) {
              // show
              ngToast.create({
                className: 'danger',
                content: '<strong>Error!</strong> The invoice is not available right now, please try again later!'
              });

              // done
              return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
            } else {
              // load the data
              $eaBackend.dataGetCspDownloadAccountInvoice(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, args.args.period, $eaCurrentSession.DataToken).then(function (downloadUri) {

                // redirect in a new tab to the file
                window.open(downloadUri + '&svp=' + $eaCurrentSession.getServiceProviderId(), 'download');

                // done
                return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
              });
            }
          });

          // Open Customer Settings
          $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:EE74EAF3-E461-4A11-9E29-F9A9069799D1', function (args) {

            var customerId = args.args.PrimaryGroupId;
            if (!customerId)
              customerId = args.args.CustomerId;

            // load the margins
            return $eaBackend.dataCspGetResellerFeaturesForCustomer(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, customerId, $eaCurrentSession.DataToken).then(function (features) {

              var customer = {};
              if (args.args.ServiceItems && args.args.ServiceItems.length > 0) {
                customer = args.args.ServiceItems[0];
              } else {
                customer = args.args;
              }

              // show the dialog
              return $scope.openCspDefineResellerFeaturesDialog(features, customer, $scope.activeDashboard.AccountModel).then(function (updatedFeatures) {

                // save the margins
                return $eaBackend.dataCspSetResellerFeaturesForCustomer(cspTeamProfile.Id, $scope.activeDashboard.AccountModel.AccountId, customerId, updatedFeatures, $eaCurrentSession.DataToken).then(function () {

                  // show
                  ngToast.create({
                    className: 'success',
                    content: '<strong>Great!</strong> New features are activated, it could take up to 24hrs until new data are visible!'
                  });

                  // done
                  return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
                });
              });
            });
          });
        });

        // build the ordered dashboards collection
        $scope.dashboardsOrdered = [];
        $scope.dashboardsOrdered.appendArray($scope.dashboards);
        $scope.dashboardsOrdered.sortOn('Name');
      }

      // mark the first dashboard as active
      if ($scope.dashboards.length > 0) {

        // reads the dashboard from last used dashboard
        var targetDashboard = $scope.dashboards[0];

        // check if we need to override this via active parameter
        if ($stateParams.active) { targetDashboard = $scope.dashboardsMap[$stateParams.active]; }

        // use the fallback
        if (!targetDashboard) { targetDashboard = $scope.dashboards[0]; }

        // activate our targetdashboard
        $scope.selectDashboard(targetDashboard);

      }

      // cache the accounts
      $scope.Accounts = cspAccounts;

    });
  }

  // load the accounts
  return loadDashboards($scope.Profile).then(function() {

    $scope.addAccount = function() {
      $state.go('authorized.csp.wadd', {resellerId: $stateParams.resellerId});
    };

    $scope.openEditCspAccountDialog = function() {

      // load the account including all secrets
      $eaCspService.loadAccount($scope.activeDashboard.Profile, $scope.activeDashboard.AccountModel.AccountId, $eaCurrentSession).then(function(loadedAccount) {

        // open the dialog
        var dialogPromise;

        if (loadedAccount.TierLevel && loadedAccount.TierLevel === 'EA') {
          dialogPromise = $scope.openCspUpdateEAAccountDialog(loadedAccount);
        } else if (loadedAccount.TierLevel && loadedAccount.TierLevel === 'AWS') {
          dialogPromise = $scope.openCspUpdateAWSAccountDialog(loadedAccount);
        } else {
          dialogPromise = $scope.openCspUpdateT1AccountDialog(loadedAccount);
        }

        // wait for finishing
        return dialogPromise.then(function (updatedAccountModel) {

          var actionPromise = $q.when();
          // check if we need to remove the account
          if (updatedAccountModel.action === 'remove') {
            actionPromise = $eaCspService.deleteAccount($scope.activeDashboard.Profile, loadedAccount, $eaCurrentSession).then(function () {
              $scope.refreshScreen();
            });
          } else if (updatedAccountModel.action === 'consent') {
            // generate the constent link
            actionPromise = $eaBackendAzureV2.dataCpvGetConsentLink(loadedAccount.TeamId, loadedAccount.AccountId, $eaCurrentSession.DataToken).then(function(result) {
              if (result && result.consentUrl) {
                // Redirect
                $window.open(result.consentUrl,'_blank');
              } else {
                // show message
                ngToast.create({
                  className: 'danger',
                  content: '<strong>Oh Oh!</strong> Failed to retrieve the consent link, please try again!'
                });
              }
            });
          } else if (updatedAccountModel.action === 'update') {

            if (loadedAccount.TierLevel && loadedAccount.TierLevel === 'EA') {
              actionPromise = $eaBackendAzureV2.dataCspUpdateAccountEAV2(loadedAccount.TeamId, loadedAccount.AccountId, updatedAccountModel.account.Name, updatedAccountModel.account.Currency, updatedAccountModel.account.SecurityToken, updatedAccountModel.account.ExchangeRate, updatedAccountModel.account.DefaultResellerManagement, $eaCurrentSession.DataToken).then(function () {
                $scope.refreshScreen();
              });
            } else if (loadedAccount.TierLevel && loadedAccount.TierLevel === 'AWS') {
              actionPromise = $eaBackendAzureV2.dataCspUpdateAccountAWSV2(loadedAccount.TeamId, loadedAccount.AccountId, updatedAccountModel.account.Name, updatedAccountModel.account.Currency, updatedAccountModel.account.ExchangeRate, updatedAccountModel.account.DefaultResellerManagement, $eaCurrentSession.DataToken).then(function() {
                $scope.refreshScreen();
              });
            } else {
              // update the account
              actionPromise = $eaBackendAzureV2.dataCspUpdateAccountT1V2(loadedAccount.TeamId, loadedAccount.AccountId, updatedAccountModel.account.Name, updatedAccountModel.account.ClientId, updatedAccountModel.account.ClientSecret, updatedAccountModel.account.Domain, updatedAccountModel.account.BillingDay, updatedAccountModel.account.ExchangeRate, updatedAccountModel.account.AutoActivateCustomers, updatedAccountModel.account.DefaultResellerManagement, $eaCurrentSession.DataToken).then(function () {

                if ($scope.activeDashboard.AccountModel.BillingDay !== updatedAccountModel.account.BillingDay) {

                  ngToast.create({
                    className: 'info',
                    content: '<strong>Heads Up!</strong> You changed the BillingDay, please re-sync all customers otherwise the changed will not be effective'
                  });

                }

                // update our selected account
                $scope.activeDashboard.AccountModel = updatedAccountModel.account;

                // done
                return $q.when();
              });
            }

          } else {
            console.log(updatedAccountModel.action + '-Action not supported');
          }

          // execute
          $busy.during(actionPromise).then(function () {

            // trigger data relaod
            $scope.reloadData($scope.Profile);

            // show message
            ngToast.create({
              className: 'success',
              content: '<strong>Great!</strong> The CSP Account was updated successfully'
            });

          }).catch(function(e) {
            // show message
            ngToast.create({
              className: 'danger',
              content: '<strong>Oh Oh!</strong> An error occurred when updating the CSP Account, please try again!'
            });
          });
        });
      });
    };

    $scope.openResellers = function()
    {
      $state.go('authorized.csp.resellermanagement', {resellerId: $stateParams.resellerId});
    };

    $scope.openVirtualMeters  = function()
    {
      $window.location.href = '/app2/teams/' + $stateParams.resellerId + '/apps/settings/team/virtualmeters';
    };    

    $scope.openUpliftCategories  = function()
    {
      $window.location.href = '/app2/teams/' + $stateParams.resellerId + '/apps/settings/team/upliftcategories';
    };

    $scope.openCustomerSegmentation = function() {
      $window.location.href = '/app2/teams/' + $stateParams.resellerId + '/apps/accounts/' + $state.params.active + '/customersegmentation';
    };

    $scope.openDownloadPriceSheetDialog = function() {

      // load the account including all secrets
      $eaCspService.loadAccount($scope.Profile, $scope.activeDashboard.AccountModel.AccountId, $eaCurrentSession).then(function(loadedAccount) {
        return $scope.openPriceSheetDownload(loadedAccount.TeamId, loadedAccount.AccountId, loadedAccount.Currency, loadedAccount.Country, $eaCurrentSession.DataToken);
      });
    };

    $scope.syncCustomers = function() {
      $eaBackend.dataCspSyncAccount($scope.Profile.Id, $scope.activeDashboard.AccountModel.AccountId, $eaCurrentSession.DataToken).then(function () {
        ngToast.create({
          className: 'success',
          content: '<strong>Great!</strong> Data Sync for the account <strong>' + $scope.activeDashboard.AccountModel.Name + '</strong> is started. This can take a couple seconds or minutes. If you don\'t see new customers after more than 15 minutes give us a note.'
        });
      });
    };

    $scope.registerCustomers = function() {

      if ($scope.activeDashboard.AccountModel.TierLevel === 'V1') {
        return $scope.openCspRegisterCustomerCostMgntDialog().then(function (newCustomerDialogResult) {
          return $eaBackend.dataCspRegisterCostMgntCustomer($scope.Profile.Id, $scope.activeDashboard.AccountModel.AccountId, newCustomerDialogResult.customer.CustomerName, newCustomerDialogResult.customer.HasConsumption, $eaCurrentSession.DataToken).then(function() {
            $scope.reloadData();
          });
        }).catch(function (result) {

          if (result && result == 'cancel') {
            return;
          }
          
          // notify regarding error
          ngToast.create({
            className: 'danger',
            content: '<strong>Error!</strong> Failed to register CSP customer, please contact our support!'
          });
        });
      } else {
        return $scope.openCspRegisterCustomerDialog().then(function (newCustomerDialogResult) {
          return $eaBackend.dataCspRegisterCustomer($scope.Profile.Id, $scope.activeDashboard.AccountModel.AccountId, newCustomerDialogResult.customer.TenantIdOrDomain, $eaCurrentSession.DataToken).then(function () {
            $scope.reloadData();
          }).catch(function () {

            if (result && result == 'cancel') {
              return;
            }
            
            // notify regarding error
            ngToast.create({
              className: 'danger',
              content: '<strong>Error!</strong> Failed to register CSP customer, please contact our support!'
            });
          });
        });
      }
    };

    $scope.toggleVersion = function() {
      if ($stateParams.version === 'v2') {
        $stateParams.version = 'v1';
      } else {
        $stateParams.version = 'v2';
      }

      $state.go('.', $stateParams,
        { notify: false });

      // reload
      location.reload();
    };

    // update the menu navbar
    $scope.updateControllerNavBarMenu();
  });
});
