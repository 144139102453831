'use strict';

angular.module('azureCostsFeApp').controller('ModalUpgradePlanCtrl', function($scope, $modalInstance, team, plan, description) {

  $scope.team = team;
  $scope.plan = plan;
  $scope.description = description;

  $scope.cancel = function () {
    $modalInstance.dismiss('cancel');
  };
});



