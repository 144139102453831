'use strict';

angular.module('azureCostsFeApp').service('$acWaitHelpers', function($q, $timeout) {
  var self = this;

  self.wait = function(seconds)
  {
    var defer = $q.defer();

    $timeout(function() {
      defer.resolve();
    }, seconds * 1000);

    return defer.promise;
  }

});


