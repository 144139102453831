'use strict';

angular.module('azureCostsFeApp').controller('EaAdminJobsCtrl', function ($scope, $eaBackendAdminV2, $busy, $eaCurrentSession) {

  $scope.loadJobsDuring = 1;
  $scope.loadJobsForTeam = "";

  function loadJobs(hours, team) {
    // load the jobs
    return $eaBackendAdminV2.dataGetJobs(hours, team, $eaCurrentSession.DataToken).then(function (jobs) {

      $scope.error = null;

      $scope.runningJobs = [];
      $scope.delayedJobs = [];
      $scope.pendingJobs = [];
      $scope.otherJobs = [];

      jobs.items.forEach(function(job) {

        // adjust the worker url
        // acbackgroundstaging.deployment-586477d64d-v8r2m (v2)xx
        var podName = job.jobWorker.split(' ')[0];
        job.jobPodUri = 'http://127.0.0.1:8001/#!/log/default/' + podName + '/pod?namespace=default';
                         
        // adjust the status
        if (job.jobStatus === 'running') {
          $scope.runningJobs.push(job);
        } else if (job.jobStatus === 'delayed') {
          $scope.delayedJobs.push(job);
        } else if (job.jobStatus === 'pending') {
          $scope.pendingJobs.push(job);
        } else {
          $scope.otherJobs.push(job);
        }
      })
    });
  }

  $scope.jobMoment = function(job) {
    return moment(job.jobStarted).fromNow();
  };

  $scope.jobDuration = function(job) {
    return job.jobDuration + ' seconds';
  };

  $scope.onReload(function() {
    $scope.reloadJobs();
  });

  $scope.reloadJobs = function() {
    $busy.during(loadJobs($scope.loadJobsDuring, $scope.loadJobsForTeam));
  };

  // init all
  $scope.updateNavBar();
  $scope.reloadJobs();
});
