'use strict';

angular.module('azureCostsFeApp').controller('ModalCspDownloadPriceSheetCtrl', function($scope, $modalInstance, $eaBackendAzureCachedContentLoader, PriceSheetOptions, $eaBackendAzureV2, $eaDownload) {

  $scope.priceSheetOptions = {
    Region: PriceSheetOptions.Region,
    Locale: 'en-US',
    RCCurrency: PriceSheetOptions.Currency,
    Currency: PriceSheetOptions.Currency,
    ERate: 1.0
  };

  $scope.step = 0;

  $scope.cancel = function () {
    $modalInstance.dismiss("cancel")
  };

  $scope.download = function() {

    // set the download mode
    $scope.step = 1;


    // build the options
    var requestOptions = {
      resourceEndpoint: $eaBackendAzureV2.dataGetPriceComparisionSheet,
      queryoptions: {
        region: $scope.priceSheetOptions.Region,
        locale: $scope.priceSheetOptions.Locale,
        currency: $scope.priceSheetOptions.RCCurrency,
        erate: $scope.priceSheetOptions.ERate
      }
    };

    // get the day definition
    var dayDefinition = (new Date()).currentDayDefinition();

    // load the data
    $eaBackendAzureCachedContentLoader.dataRequestCachedResourceWithRetry(PriceSheetOptions.TeamId, PriceSheetOptions.ContractId, undefined, dayDefinition, PriceSheetOptions.Token, undefined, false, requestOptions).then(function(content) {

      // offer for download
      $eaDownload.do(content, 'PriceComparisionSheet.csv', "text/csv");

      // move forward
      $scope.step = 3;
    })
  }


});



