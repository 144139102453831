'use strict';

angular.module('azureCostsFeApp').controller('ModalBillingAddressCtrlMixin', [ '$scope', '$modal', function($scope, $modal) {

  $scope.openUpdateBillingAddressDialog = function(billingAddress) {

    var modalInstance = $modal.open({
      templateUrl: "views/billing/modals/ea-modal-billing-address.html",
      controller: 'ModalBillingAddressCtrl',
      resolve: {
        BillingAddress: function() { return billingAddress; }
      }
    });

    // returns a promise
    return modalInstance.result;
  };
}]);
