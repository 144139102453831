'use strict';

angular.module('azureCostsFeApp').service('$acDataSourceClasses', function() {
  var self = this;

  self.DashboardItemClass = function(initialData) {
    initialData = initialData || {};

    return {
      Size: {
        x: initialData.Size && initialData.Size.x ? initialData.Size.x : 0,
        y: initialData.Size && initialData.Size.y ? initialData.Size.y : 0
      },
      Position: {
        x: initialData.Position && initialData.Position.x ? initialData.Position.x : 0,
        y: initialData.Position && initialData.Position.y ? initialData.Position.y : 0
      },
      ItemType: initialData.ItemType || '',
      ItemUuid: initialData.ItemUuid || '',
      ItemTitle: initialData.ItemTitle || '',
      ItemOptions: initialData.ItemOptions || {},
      ItemDependencies: initialData.ItemDependencies || [],
      ItemWidgetType: initialData.ItemWidgetType || 'System'
    };
  };

  self.ReportClass = function(initialData) {
    return {
      Contract: initialData.Contract,
      Definition: 'unknown',
      DailyReportEnbaled: false,
      DailyReportBreakDownFirst: '',
      DailyReportBreakDownSecond: '',
      MonthlyCostLimit: 0,
      Currency: '€',
      CreatedAt: null,
      UpdatedAt: null,
      Token: null,
      Reports: []
    };
  };

  self.SpendingEntryClass = function() {
    return {
      ReportId: '',
      ReportMonth: -1,
      ReportYear: -1,
      ReportLastSync: 'Xxx',
      ReportCurrency: 'EUR',
      ReportCurrencySymbol: '€',
      ServiceId: '',
      ServiceName: '',
      ServiceCosts: '',
      ServiceType: '',
      ServiceCategory: '',
      ServiceSize: '',
      ServiceTags: [],
      ServiceIcon: '',
      SubscriptionName: '',
      SubscriptionId: '',
      CostCenter: '',
      Department: '',
      ResourceGroup: ''
    };
  };

  self.SubscriptionEntryClass = function() {
    return {
      SubscriptionId: '',
      SubscriptionName: '',
      OfferingType: '',
      Country: '',
      Contacts: []
    };
  };

  self.TeamMembershipEntryClass = function() {
    return {
      TeamId: '',
      UserId: '',
      FirstName: '',
      LastName: '',
      Contact: '',
      LastLogin: null,
      LoginCount: 0,
      Role: '',
      SignUp: null,
      SubscriptionPermissions: null,
      LiveId: null,
      Type: ''
    };
  };

  self.SpendingHistoryEntryClass = function() {
    return {
      HistoryDay:             '0000-00-00',
      HistoryCurrency:        'EUR',
      HisotryCurrencySymbol:  '€',
      HistoryDayOverallCosts: '',
      SubscriptionId:         '',
      SubscriptionDayCosts:   '',
      SubscriptionDayCostsDiff:   ''
    };
  };

  self.ServiceDefinitionClass = function() {
    return {
      ServiceType: '',
      ServiceTitle: '',
      ServiceDescription: ''
    };
  };

  self.SpendingFilterClass = function() {
    return {
      Id: '',
      Name: '',
      Owner: '',
      Rules: null,
      TeamId: 'personal'
    };
  };

  self.SpendingGroupOptionClass = function(initialData) {
    return {
      GroupLabel: initialData.GroupLabel        || undefined,
      GroupProperty: initialData.GroupProperty  || undefined,
      RequiredPlan: initialData.RequiredPlan    || undefined,
      Compare: initialData.Compare ||'CaseSensitive'
    };
  };

  self.PeriodClass = function(initialData) {
    return {
      ReportId: initialData.ReportId
    };
  };

  self.ResellerCustomerEntryClass = function() {
    return {
      ReportId: '',
      ReportMonth: -1,
      ReportYear: -1,
      ReportLastSync: 'Xxx',
      ReportCurrency: 'EUR',
      ReportCurrencySymbol: '€',
      AccountId: '',
      CustomerId: '',
      CustomerCompany: '',
      CustomerContact: '',
      CustomerDomain: '',
      CustomerState: '',
      CustomerTenantId: '',
      EntitlementId: '',
      EntitlementName: '',
      Costs: 0.0
    };
  };

  self.ResellerCustomerClass = function() {
    return {
      AccountId: '',
      CustomerId: '',
      CustomerCompany: '',
      CustomerContact: '',
      CustomerDomain: '',
      CustomerState: '',
      CustomerTenantId: '',
      ActivatedAt: null,
      NewCommerce: false
    };
  };

  self.ReportClassificationClass = function(initialData) {
    return {
      ReportPeriod: initialData.ReportPeriod
    };
  };

  self.ResellerT2CustomerEntryClass = function() {
    return {
      ReportId: '',
      ReportMonth: -1,
      ReportYear: -1,
      ReportCurrency: 'EUR',
      ReportCurrencySymbol: '€',
      ResellerAccountId: '',
      ResellerAccountName: '',
      ResellerTeamId: '',
      CustomerId: '',
      CustomerCompany: '',
      CustomerState: '',
      Costs: 0.0
    };
  };
});
