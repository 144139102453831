'use strict';

/**
 * @ngdoc function
 * @name azureCostsFeApp.controller:MainCtrl
 * @description
 * # MainCtrl
 * Controller of the azureCostsFeApp
 */
angular.module('azureCostsFeApp').controller('CcAddAwsContractDashboardCtrl', function ($scope, $stateParams) {
  $scope.teamid = $stateParams.teamid;
  $scope.cid= $stateParams.cid;
});
