'use strict';

angular.module('azureCostsFeApp').service('$eaDataGroupOperations', function($q) {
  var self = this;

  function groupBy(data, keys) {

    if (keys.length === 0) { return data; }

    // The current key to perform the grouping on:
    var key = keys[0];

    // Loop through the data and construct buckets for
    // all of the unique keys:
    var groups = {};
    for (var i = 0; i < data.length; i++)
    {
      var row = data[i];
      var groupValue = row[key];

      if (groups[groupValue] === undefined)
      {
        groups[groupValue] = [];
      }

      groups[groupValue].push(row);
    }

    // Remove the first element from the groups array:
    keys.reverse();
    keys.pop();
    keys.reverse();

    // If there are no more keys left, we're done:
    if (keys.length === 0) { return groups; }

    // Otherwise, handle further groupings:
    for (var group in groups)
    {
      groups[group] = groupBy(groups[group], keys.slice());
    }

    return groups;
  }

  function addElementToGroupSafely(groupedResult, groupKeyValue, groupElement) {

    // get the lowercase key value
    var groupKeyValueLowerCases = groupKeyValue.toLowerCase();

    // generate a new group if needed
    if (groupedResult[groupKeyValueLowerCases] === undefined) { groupedResult[groupKeyValueLowerCases] = { elements: [], name: groupKeyValue }; }

    // add the element
    groupedResult[groupKeyValueLowerCases].elements.push(groupElement);
  }

  self.groupBy = function(data, properties) {
    if (data) {
      return $q.when(groupBy(data, properties));
    } else {
      return $q.when({});
    }
  };

  self.groupBySingleDimension = function(data, property) {

    var groupedResult = {};

    data.forEach(function(element) {

      var groupKeyValue = element[property];
      if (!groupKeyValue) { return; }

      if (Array.isArray(groupKeyValue) && groupKeyValue.length > 0) {
        groupKeyValue.forEach(function (groupKeyChildValue) {

            // ignore invalid values
            if (groupKeyChildValue === null || groupKeyChildValue === undefined) { return; }

            // add to goup
            addElementToGroupSafely(groupedResult, groupKeyChildValue, element);
        });
      } else if (Array.isArray(groupKeyValue) && groupKeyValue.length === 0) {
        addElementToGroupSafely(groupedResult, '- no tag -', element);
      } else {
        addElementToGroupSafely(groupedResult,groupKeyValue, element);
      }
    });

    return $q.when(groupedResult);
  };
});
