'use strict';

angular.module('azureCostsFeApp').controller('AcCspResellerManagementCtrl', function ($scope, $state, $stateParams, $eaCspService, $eaCurrentSession, $busy, $q, $controller, $modal, $toolbar, $eaBackendDataModeSelector, $eaSession2, $eaWidgetDashboardManager, ngToast, $eaBackend, $eaBackendAzureV2, $timeout, $window, $acTimelineGenerator) {

  $.extend(self, $controller('CspResellerRegisterCtrlMixin', {$scope: $scope, $modal: $modal}));
  $.extend(self, $controller('CspDefineResellerMarginCtrlMixin', {$scope: $scope, $modal: $modal}));

  $scope.dashboardEditMode = false;
  $scope.activeDashboard = null;
  $scope.dataToken = $eaCurrentSession.DataToken;

  $scope.toggelEditMode = function() {
    $scope.dashboardEditMode = !$scope.dashboardEditMode;
  };

  // update the navbar menu
  $scope.updateControllerNavBarMenu = function() {

    // Open the contract settings dialog
    var backUri = $state.href('authorized.csp.accounts', { resellerId: $stateParams.resellerId }, {absolute: true});
    $toolbar.addItem('csp.reseller.back', 'Back', 'fa-chevron-left', null, true, backUri);

    // Reload operation
    $toolbar.addItem('csp.reseller.performReload', 'Reload', 'fa-refresh', "Current data will be refreshed", true, $scope.reloadData);
  };

  var dashboardId = 'resellerManagement';

  $scope.activeDashboard = {
    Id: dashboardId,
    Profile: $scope.Profile,
    Team: $scope.Profile.Id,
    ContractId: 'RESELLER:' + $scope.Profile.Id,
    Collection: 'reseller.v1',
    Layout: 'v1'
  };

  // Action: Register Reseller
  $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:12F2A20E-B5C7-4615-BDE1-867D31266A50', function (args) {

    $scope.openCspResellerRegisterDialog().then(function(resellerREgistrationResult) {

      // register
      $busy.setMessage("Registering new Reseller...");

      return $busy.during($eaBackend.dataRegisterCspResellers($scope.Profile.Id, resellerREgistrationResult.account.Name, $eaCurrentSession.DataToken)).then(function() {
        $scope.reloadData();
        $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
      }).catch(function(e) {
        ngToast.danger("Error: Failed to register new reseller: " + e.message);
        $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, false, e);
      })
    })
  });

  // Action: Visit Reseller Portal
  $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:75FF743F-21BC-4208-BB05-7AC447A17E19', function(args) {

    // update our login sessiong with a new delegation token
    return $eaSession2.updateTokenFromSTS($eaCurrentSession, { delegate: args.args.TeamId }).then(function() {

      // build the url
      var url = $state.href('authorized.csp.accounts', {resellerId: args.args.TeamId }, {absolute: true});

      // open new window with the url
      $window.open(url, '_blank');

      // we are done with this action
      return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
    }).catch(function() {

      ngToast.create({
        className: 'danger',
        content: '<strong>Permission Denied!</strong> You have no access permissions to open the customer dashboard.'
      });

      // we are done with this action
      return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
    })
  });

  // Action: UnPublish Customer
  $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:E6F0F729-6374-4A62-81A9-7D26EFE82346', function(args) {

    var deleteOk = confirm('Do you really want to remove the customer from this reseller?');
    if (deleteOk) {

      return $eaBackend.dataUnPublishCspCustomer($scope.Profile.Id, args.args.ResellerId, args.args.ResellerAccountId, args.args.CustomerId, $eaCurrentSession.DataToken).then(function() {
        $scope.reloadData();
        $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
      }).catch(function() {
        ngToast.create({
          className: 'danger',
          content: '<strong>Error!</strong> Failed to remove customer.'
        });

        // we are done with this action
        return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
      });
    } else {
      // we are done with this action
      return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
    }
  });

  // Action: UnPublish All Customers
  $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:8910991F-B227-4A61-B4FB-3F2C6F69D276', function(args) {

    // no collection give up
    if (!args.args.Customers) {
      return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
    }

    // check if we delete the reseller or the customers
    if (args.args.Customers.length === 1 && args.args.Customers[0].CustomerState === 'pseudo') {
      var deleteOk = confirm('Do you really want to remove the reseller?');
      if (deleteOk) {
        $eaBackend.dataDeleteEmptyCspResellers($scope.Profile.Id, args.args.Customers[0].ResellerId, $eaCurrentSession.DataToken).then(function () {
          ngToast.create({
            className: 'success',
            content: '<strong>Success!</strong> Removed reseller ' + args.args.Customers[0].ResellerName
          });

          $scope.reloadData();
        }).catch(function () {
          ngToast.create({
            className: 'danger',
            content: '<strong>Error!</strong> Failed to remove reseller ' + args.args.Customers[0].ResellerName + ', try again later'
          });
        });
      }
    } else {
      var deleteOk = confirm('Do you really want to remove all customers from this reseller? This process could take a couple minutes up to hours. After that you can come back to remove the reseller with the same action!');
      if (deleteOk) {
        args.args.Customers.forEach(function (customer) {

          $eaBackend.dataUnPublishCspCustomer($scope.Profile.Id, customer.ResellerId, customer.ResellerAccountId, customer.CustomerId, $eaCurrentSession.DataToken).then(function () {
            ngToast.create({
              className: 'success',
              content: '<strong>Success!</strong> Schedule un-publishing of customer ' + customer.CustomerCompany
            });
          }).catch(function () {
            ngToast.create({
              className: 'danger',
              content: '<strong>Error!</strong> Failed to schedule un-publishing of customer ' + customer.CustomerCompany + ', try again later'
            });
          });
        });
      }
    }

    return $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, true, null);
  });

  // Action: Define reseller margins
  $eaWidgetDashboardManager.onDashboardFunction(dashboardId, 'func:AD35F300-7307-4E33-B69C-E5576CDCDA4D', function (args) {

    // load the uplift categories
    $eaBackend.dataCspGetUpliftCategories($scope.Profile.Id, $eaCurrentSession.DataToken).then(function(upliftCategories) {

      // load the margins
      return $eaBackend.dataCspGetResellerMarginForReseller($scope.Profile.Id, args.args.ResellerId, $eaCurrentSession.DataToken).then(function (resellerMargins) {

        // show the dialog
        return $scope.openCspDefineResellerMarginDialog(resellerMargins, upliftCategories).then(function (updatedMargins) {

          // save the margins
          return $eaBackend.dataCspSetResellerMarginForReseller($scope.Profile.Id, args.args.ResellerId, updatedMargins, $eaCurrentSession.DataToken).then(function () {

            // we are done with this action
            $eaWidgetDashboardManager.finishDashboardFunction(dashboardId, args, null, null);
          });
        });
      });
    });
  });

  $scope.reloadData = function () {
    $eaWidgetDashboardManager.reload($scope.activeDashboard.Id);
  };

  // update the menu navbar
  $scope.updateControllerNavBarMenu();
});
